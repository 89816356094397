import styled from "@emotion/styled"

const PostListStack = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    justify-content: space-between;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 19px;
  }
`

export default PostListStack
