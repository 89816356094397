import styled from "@emotion/styled"
import Link from "components/Link"

const BlogListPostWrapper = styled(Link)`
  display: flex;
  border-radius: 20px;
  width: 100%;
  align-items: center;
  text-decoration: none;
  color: inherit;

  ${'' /* ${({ theme }) => theme.breakpoints.up("lg")} {
    &:hover {
      background: #f8f8fb;
    }
  } */}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 20px;
    max-width: 343px;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.between("sm", "lg")} {
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 20px;
    max-width: 480px;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 26px;
    max-width: 860px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 40px;
    max-width: 1294px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 40px;
    max-width: 1344px;
  }
`

export default BlogListPostWrapper
