import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListTenStack = styled.div`
  display: flex;
  justify-content: center;
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 80px;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    column-gap: 150px;
    flex-wrap: wrap;
    row-gap: 59px;
    max-width: 680px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 150px;
    flex-wrap: wrap;
    row-gap: 47px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 187px;
    flex-wrap: wrap;
    row-gap: 59px;
  }
`

export default ListTenStack
