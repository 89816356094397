import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ showImage }) => css`
  ${showImage ? "padding-left: 30px;" : ""}
`

const TestimonialUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${dynamicStyle}
`

export default TestimonialUser
