import styled from "@emotion/styled"

const CTAFormContainer = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;

  ${({ theme, isBlog }) =>
    isBlog
      ? `
        ${theme.breakpoints.down("md")} {
    border-radius: 30px;
    width: 92%;
    flex-direction: column;
  }
  ${theme.breakpoints.between("md", "lg")} {
    border-radius: 30px;
    max-width: 768px;
    width: 100%;
    flex-direction: column;
  }
  ${theme.breakpoints.between("lg", "xl")} {
    border-radius: 30px;
    max-width: 960px;
    width: 100%;
    flex-direction: column;
  }

  ${theme.breakpoints.up("xl")} {
    max-width: 1248px;
    border-radius: 25px;
  }
      `
      : `
    ${theme.breakpoints.down("md")} {
    border-radius: 10px;
    width: 92%;
  }
  ${theme.breakpoints.between("md", "lg")} {
    border-radius: 10px;
    width: 70%;
  }
  ${theme.breakpoints.between("lg", "2xl")} {
    border-radius: 10px;
    width: 70%;
  }
  ${theme.breakpoints.up("2xl")} {
    max-width: 1339px;
    border-radius: 25px;
    min-height: 716px;
  }
  `};
`

export default CTAFormContainer
