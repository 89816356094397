import styled from "@emotion/styled"

const ListThreeText = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;

  p {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    max-width: 330px;
    letter-spacing: 0.05em;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
    max-width: 330px;
    letter-spacing: 0.05em;
  }
`

export default ListThreeText
