import styled from "@emotion/styled"

const CTAFormFinalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  background: #f8f8fb;
`

export default CTAFormFinalWrapper
