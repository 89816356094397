import styled from "@emotion/styled"

const ListFourHeader = styled.div`
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
    align-items: center;

    & > * {
      flex: 1 1 49%;
    }
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    gap: 70px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 2%;
    margin-bottom: 80px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 2%;
    margin-bottom: 100px;
  }
`

export default ListFourHeader
