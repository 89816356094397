import styled from "@emotion/styled"

const ListTenItem = styled.div`
  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 100%;
    max-width: 485px;
    display: flex;
    justify-content: space-between;
    gap: 26px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 100%;
    max-width: 606px;
    display: flex;
    justify-content: space-between;
    gap: 26px;
  }
`

export default ListTenItem
