import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ showImage, theme }) => css`
  ${theme.breakpoints.down("xl")} {
    width: ${showImage ? "32px" : "60px"};
    height: ${showImage ? "24px" : "45px"};
  }

  ${theme.breakpoints.up("xl")} {
    width: ${showImage ? "32px" : "74px"};
    height: ${showImage ? "24px" : "56px"};
  }
  ${showImage ? "position: relative; margin-right: 15px;" : ""}
`

const TestimonialFlag = styled.div`
  display: inline-block;
  margin-left: auto;

  ${dynamicStyle}
`

export default TestimonialFlag
