import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[50]};
`

const ListSeventeenItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 17px 16px 19px 17px;
    border-radius: 10px;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    border-radius: 10px;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    border-radius: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    border-radius: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    border-radius: 20px;
    width: 100%;
  }

  ${({ theme, isBlog }) =>
    isBlog
      ? `
    ${theme.breakpoints.up("md")} {
      padding: 30px 36px;
    }
      `
      : `
    ${theme.breakpoints.between("md", "xl")} {
    padding: 19px 16px 19px 16px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    padding: 40px 38px 40px 38px;
  }

  ${theme.breakpoints.between("2xl", "3xl")} {
    padding: 40px 38px 40px 38px;
  }

  ${theme.breakpoints.up("3xl")} {
    padding: 50px 48px 50px 48px;
  }
  `}

  ${dynamicStyle}
`

export default ListSeventeenItemWrapper
