import styled from "@emotion/styled"

const BlogListPostTitle = styled.h2`
  font-weight: 700;
  margin: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 707px;
    font-size: 28px;
    line-height: 34px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
`

export default BlogListPostTitle
