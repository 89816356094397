import styled from '@emotion/styled'

const GateForContentStageNotice = styled.span`
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 16px;
  }
`

export default GateForContentStageNotice