import styled from "@emotion/styled"
import Button from "components/Buttons/Button"

const ArticleAsideBannerLink = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  text-decoration: none;
  width: min-content;
  white-space: nowrap;
  z-index: 2;
  position: relative;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 20px 32px;
    border-radius: 40px;
    line-height: 22px;
    font-size: 18px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    padding: 16px 26px;
    border-radius: 32px;
    line-height: 18px;
    font-size: 14px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding: 16px 26px;
    border-radius: 32px;
    line-height: 18px;
    font-size: 14px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding: 20px 32px;
    border-radius: 40px;
    line-height: 22px;
    font-size: 18px;
  }
`

export default ArticleAsideBannerLink
