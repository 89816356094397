import styled from '@emotion/styled'

const GateForContentForm = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    flex-direction: column;
    gap: 20px;
    padding-right: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    flex-direction: row;
    gap: 18px;
  }
`

export default GateForContentForm