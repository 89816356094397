import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Title from "components/Title"

const dinamycStyles = ({ theme, subtitle }) => css`
  ${subtitle
    ? `
    ${theme.breakpoints.down("lg")} {
      margin-bottom: 20px;
    }

    ${theme.breakpoints.between("lg", "xl")} {
      margin-bottom: 30px;
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 40px;
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 50px;
    }
  `
    : `
      ${theme.breakpoints.down("lg")} {
      margin-bottom: 39px;
    }

    ${theme.breakpoints.between("lg", "xl")} {
      margin-bottom: 79px;
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;
    }
    `}
`

const ListSevenTitle = styled(Title)`
  text-align: center;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  ${dinamycStyles}

  ${({ theme }) => theme.breakpoints.down("lg")} {
    line-height: 40px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    max-width: 850px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 900px;
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 950px;
    line-height: 88px;
    font-size: 60px;
  }
`

export default ListSevenTitle
