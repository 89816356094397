import styled from "@emotion/styled"

const ListSixItemText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.06em;

  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: 29px;
    grid-area: 2/1 / span 1 / span 2;
    max-width: 343px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "2xl")} {
    line-height: 29px;
    grid-area: 2/2 / span 1 / span 1;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 32px;
    grid-area: 2/2 / span 1 / span 1;
  }

  p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ListSixItemText
