import styled from "@emotion/styled"
import Link from "components/Link"

const HeroCaseLinkClutch = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  max-width: max-content;
  width: 100%;
`

export default HeroCaseLinkClutch
