import styled from "@emotion/styled"

const ListTwelveItemTitle = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 5px;
    gap: 13px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 10px;
    gap: 18px;
  }
`

export default ListTwelveItemTitle
