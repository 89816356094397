import styled from "@emotion/styled"

const CTAFormClientName = styled.span`
  font-weight: bold;

  ${({ theme, isBlog }) =>
    isBlog
      ? `
      font-size: 20px;
  line-height: 30px;

  ${theme.breakpoints.down("xl")} {
    margin-bottom: 5px;
  }

  ${theme.breakpoints.up("xl")} {
    margin-bottom: 20px;
  }
      `
      : `
  margin-bottom: 20px;

  ${theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 30px;
  }

  ${theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 30px;
  }
  `};
`

export default CTAFormClientName
