import styled from '@emotion/styled'

const ArticleAsideBannerFormFieldName = styled.div`
  margin-bottom: 10px;

  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  font-weight: 600;
  color: #7e7e7e;

  & > span {
    color: rgba(56, 176, 72, 1);
  }
`

export default ArticleAsideBannerFormFieldName