import styled from "@emotion/styled"
import Link from "components/Link"

const PostEventsBlogLink = styled(Link)`
  font-size: 18px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  margin-bottom: -1px;
  max-width: max-content;
  width: 100%;
  height: min-content;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export default PostEventsBlogLink
