import { useState } from "react"

const createRandomKey = () =>
  new Array(8)
    .fill("")
    .map(() => "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".charAt(Math.floor(Math.random() * 36)))
        .join("")
    
const userStorageName = 'adexin-lead-id'

const getUserId = () => {
    if (typeof window !== 'undefined') {
        const leadId = window.localStorage.getItem(userStorageName)
        if (leadId) {
            return leadId
        }

        const newLeadId = createRandomKey()
        window.localStorage.setItem(userStorageName, newLeadId)
        return newLeadId
    }
    return null
}

const useUserId = () => {
    const [userID, setUserId] = useState(getUserId())
    return userID
}

export default useUserId
