import styled from "@emotion/styled"

const PostEventsSubtitle = styled.span`
  margin-top: 0;
  line-height: 34px;
  font-weight: 600;
  display: block;
  font-size: 18px;
`

export default PostEventsSubtitle
