import styled from "@emotion/styled"

const ArticleRelatedArticleInfoTitle = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
`

export default ArticleRelatedArticleInfoTitle