import styled from "@emotion/styled"

const ListEighteenHeadline = styled.p`
  font-weight: 400;
  color: #ddddea;
  margin-top: 0;
  margin-bottom: 0;
  word-wrap: break-word;
  position: absolute;
  left: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: block;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 45px;
    line-height: 58px;
    max-width: 360px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    font-size: 60px;
    line-height: 72px;
    max-width: 440px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    font-size: 60px;
    line-height: 72px;
    max-width: 460px;
  }
`

export default ListEighteenHeadline
