import styled from "@emotion/styled"

const HeroCaseDemoBigMobile = styled.div`
  position: absolute;
  bottom: 2%;
  background-color: #f8f8fb;
  border: 2px solid #dddde2;
  border-radius: 15%/6%;
  padding-right: 1.1%;
  padding-left: 1.1%;
  padding-bottom: 3%;
  padding-top: 4%;

  & + & {
    bottom: 8%;
    right: 22%;
    width: 27%;
    height: 95.7%;

    padding-right: 0.75%;
    padding-left: 0.75%;
    padding-bottom: 2.4%;
    padding-top: 3.3%;
  }

  &:first-child:not(:last-child) {
    z-index: 2;
    right: 46%;
    width: 30.8%;
    height: 108.1%;
  }

  &:first-child:last-child {
    right: 34%;
    width: 30.8%;
    height: 108.1%;
  }

  & > * {
    border-radius: 12%/6%;
    border: 2px solid ${({ theme }) => theme.palette.common.white};
    width: 100%;
    height: 100%;
  }
`

export default HeroCaseDemoBigMobile
