import styled from "@emotion/styled"

const PostEventsEventPlace = styled.p`
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: 0.06em;

  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: 29px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    line-height: 29px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 34px;
    min-height: 68px;
    margin-bottom: 30px;
    max-width: 550px;
  }
  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }
  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }
`
export default PostEventsEventPlace
