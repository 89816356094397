import styled from "@emotion/styled"

const ListSixteenItemImageWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    max-width: 51px;
    min-width: 51px;
    max-height: 39px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 60px;
    min-width: 60px;
    max-height: 49px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 76px;
    min-width: 76px;
    max-height: 57px;
  }
`

export default ListSixteenItemImageWrapper
