import styled from "@emotion/styled"

const PostEventsEventWrapper = styled.div`
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    padding-top: 73px;
    padding-bottom: 73px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 80%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin: 0 auto;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 86px;
    padding-bottom: 86px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 108px;
    padding-bottom: 108px;
  }
`

export default PostEventsEventWrapper
