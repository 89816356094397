import styled from "@emotion/styled"

const CTAFormSideBar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.gray[100]};

  ${({ theme, isBlog }) =>
    isBlog
      ? `
    ${theme.breakpoints.down("lg")} {
    width: 100%;
    padding-top: 41px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 46px;
  }

  ${theme.breakpoints.between("lg", "xl")} {
    width: 100%;
    padding: 60px;
  }

  ${theme.breakpoints.up("xl")} {
    max-width: 456px;
    width: 40%;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    padding: 60px;
  }

  ${theme.breakpoints.up("2xl")} {
    padding: 60px;
  }
  `
      : `
        max-width: 456px;
  width: 40%;

  ${theme.breakpoints.down("xl")} {
    display: none;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    padding-top: 76px;
    padding-left: 73px;
    padding-right: 40px;
    padding-bottom: 60px;
  }

  ${theme.breakpoints.up("2xl")} {
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 90px;
    padding-top: 105px;
  }
      `};
`

export default CTAFormSideBar
