import styled from "@emotion/styled"

const ListFiveItemContent = styled.div`
  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 418px;
    flex: 0 0 418px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 521px;
    flex: 0 0 521px;
  }
`

export default ListFiveItemContent
