import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyles = ({ theme, authorpage = false }) => css`
  ${authorpage
    ? `
        gap: 15px;
    `
    : `
        gap: 10px;
    `}
`

const ArticleWrapperIcon = styled.div`
  display: flex;

  ${dynamicStyles}
`

export default ArticleWrapperIcon
