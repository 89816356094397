import * as React from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "components/Breadcrumbs"
import HeroBreadcrumbsLink from "components/Hero/HeroBreadcrumbsLink"
import Arrow from "components/Icons/Arrow"
import HeroAboutWrapper from "components/HeroAbout/HeroAboutWrapper"
import HeroAboutTitle from "components/HeroAbout/HeroAboutTitle"
import HeroAboutText from "components/HeroAbout/HeroAboutText"
import HeroAboutImageWrapper from "components/HeroAbout/HeroAboutImageWrapper"
import HeroAboutContent from "components/HeroAbout/HeroAboutContent"
import HeroContainer from "components/Hero/HeroContainer"
import Image from "components/Image"
import htmlFormatting from "utils/htmlFormatting"
import Meta from "components/Meta"
import MicromarkingWrapper from "components/MicromarkingWrapper"

const HeroAbout = ({ title, titleTag, breadcrumbs, heroText, image }) => (
  <HeroAboutWrapper colorVariant="Dark">
    <HeroContainer>
      {breadcrumbs && (
        <MicromarkingWrapper itemscope="" itemtype="http://schema.org/BreadcrumbList">
          <Breadcrumbs
            itemprop="itemListElement"
            itemscope=""
            itemtype="http://schema.org/ListItem"
          >
            {breadcrumbs.map((el, idx) => (
              <>
                {breadcrumbs.length - 1 !== idx && (
                  <>
                    <HeroBreadcrumbsLink
                      itemprop="item"
                      isActive={breadcrumbs.length - 1 === idx}
                      to={el.link}
                      key={el.link}
                      colorVariant="Dark"
                    >
                      <Meta itemprop="name" content={el.title} />
                      <Meta itemprop="position" content={idx + 1} />
                      {el.title}
                    </HeroBreadcrumbsLink>
                    <Arrow direction="left" color="#BBBBBB" width={14} height={12} />
                  </>
                )}
                {breadcrumbs.length - 1 === idx && (
                  <HeroBreadcrumbsLink
                    colorVariant="Dark"
                    isActive={breadcrumbs.length - 1 === idx}
                  >
                    {el.title}
                  </HeroBreadcrumbsLink>
                )}
              </>
            ))}
          </Breadcrumbs>
        </MicromarkingWrapper>
      )}
      <HeroAboutContent>
        <HeroAboutImageWrapper>
          <Image {...image} />
          <HeroAboutTitle as={titleTag}>{title}</HeroAboutTitle>
        </HeroAboutImageWrapper>
        {heroText && (
          <HeroAboutText
            colorVariant="Dark"
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(heroText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </HeroAboutContent>
    </HeroContainer>
  </HeroAboutWrapper>
)

HeroAbout.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  heroText: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
}

HeroAbout.defaultProps = {
  breadcrumbs: null,
}

export default HeroAbout
