import styled from "@emotion/styled"

const ArticleCTAInnerContent = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: space-between;
    gap: 45px;
    align-items: center;
  }
`

export default ArticleCTAInnerContent
