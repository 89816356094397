import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import Meta from "components/Meta"
import MicromarkingWrapper from "components/MicromarkingWrapper"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListSixteenHeader from "components/ListSixteen/ListSixteenHeader"
import ListSixteenItem from "components/ListSixteen/ListSixteenItem"
import ListSixteenItemImage from "components/ListSixteen/ListSixteenItemImage"
import ListSixteenItemImageWrapper from "components/ListSixteen/ListSixteenItemImageWrapper"
import ListSixteenItemLink from "components/ListSixteen/ListSixteenItemLink"
import ListSixteenItemSubtitle from "components/ListSixteen/ListSixteenItemSubtitle"
import ListSixteenItemTitle from "components/ListSixteen/ListSixteenItemTitle"
import ListSixteenStack from "components/ListSixteen/ListSixteenStack"
import ListSixteenText from "components/ListSixteen/ListSixteenText"
import ListSixteenTitle from "components/ListSixteen/ListSixteenTitle"
import ListSixteenWrapper from "components/ListSixteen/ListSixteenWrapper"
import ListSixteenLinkStack from "components/ListSixteen/ListSixteenLinkStack"
import ListSixteenItemWrapper from "components/ListSixteen/ListSixteenItemWrapper"
import ListSixteenItemContent from "components/ListSixteen/ListSixteenItemContent"
import ListSixteenItemInner from "components/ListSixteen/ListSixteenItemInner"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListSixteen = ({
  title,
  theme: colorVariant,
  titleTag,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListSixteenWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListSixteenHeader>
        <ListSixteenTitle as={titleTag}>{title}</ListSixteenTitle>
        {subtitleText && (
          <ListSixteenText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListSixteenHeader>
      <MicromarkingWrapper itemscope="" itemtype="http://schema.org/LocalBusiness">
        <Meta itemprop="name" content="Adexin" />

        <ListSixteenStack lowerText={lowerText}>
          {listItems.map(
            ({ title: itemTitle, icon, size, addressLine1, addressLine2, number, email, id }) => {
              const arr = addressLine1.split(",")
              const sityLocality = arr[0]
              const country = arr[arr.length - 1]
              const postalCode = arr.length > 2 ? arr[1] : ""

              return (
                <ListSixteenItem key={id} size={size} colorVariant={colorVariant}>
                  <Meta itemprop="image" url={icon.file.url} />
                  <ListSixteenItemWrapper>
                    <ListSixteenItemContent>
                      {!!icon && (
                        <ListSixteenItemImageWrapper>
                          <ListSixteenItemImage itemprop="image" {...icon} />
                        </ListSixteenItemImageWrapper>
                      )}
                      <MicromarkingWrapper
                        itemprop="address"
                        itemscope=""
                        itemtype="http://schema.org/PostalAddress"
                      >
                        <Meta itemprop="addressCountry" content={country} />
                        <Meta itemprop="addressLocality" content={sityLocality} />
                        <Meta itemprop="postalCode" content={postalCode} />
                        <ListSixteenItemInner>
                          <ListSixteenItemTitle>{itemTitle}</ListSixteenItemTitle>
                          <ListSixteenItemSubtitle>{addressLine1}</ListSixteenItemSubtitle>
                          <ListSixteenItemSubtitle itemprop="streetAddress">
                            {addressLine2}
                          </ListSixteenItemSubtitle>
                        </ListSixteenItemInner>
                      </MicromarkingWrapper>
                    </ListSixteenItemContent>
                    <ListSixteenLinkStack size={size} isIcon={!!icon}>
                      {number ? (
                        <ListSixteenItemLink
                          itemprop="telephone"
                          target="_blank"
                          href={`tel:${number}`}
                        >
                          {number}
                        </ListSixteenItemLink>
                      ) : (
                        <Meta itemprop="telephone" content="+1 267 892 9500" />
                      )}
                      {email && (
                        <ListSixteenItemLink
                          itemprop="email"
                          target="_blank"
                          href={`mailto:${email}`}
                        >
                          {email}
                        </ListSixteenItemLink>
                      )}
                    </ListSixteenLinkStack>
                  </ListSixteenItemWrapper>
                </ListSixteenItem>
              )
            },
          )}
        </ListSixteenStack>
      </MicromarkingWrapper>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListSixteenWrapper>
)

ListSixteen.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.object,
      size: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      number: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
}

ListSixteen.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListSixteen
