import styled from '@emotion/styled'

const GateForContentThirdStageText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 10px 0;
  padding: 0;
  max-width: 475px;
`

export default GateForContentThirdStageText