import styled from "@emotion/styled"

const BreadcrumbsEl = (props) => <div {...props} />

const Breadcrumbs = styled(BreadcrumbsEl)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 30px;
`

export default Breadcrumbs
