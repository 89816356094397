import styled from "@emotion/styled"

const CertificationsWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 33px;
    padding-bottom: 60px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 126px;
    padding-bottom: 100px;
  }
`

export default CertificationsWrapper
