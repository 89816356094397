import styled from "@emotion/styled"
import Link from "components/Link"

const ArticleShareButton = styled(Link)`
  background: #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main};
  }
`

export default ArticleShareButton
