import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ colorVariant, theme }) => css`
  ${colorVariant === "Light" && `background-color: ${theme.palette.gray[50]}`}
`

const ListTwoWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  ${dynamicStyle}
`

export default ListTwoWrapper
