import styled from "@emotion/styled"
import { css } from "@emotion/react"
import PropTypes from "prop-types"

const dynamicStyle = ({ tPos = "auto", lPos = "auto", rPos = "auto", bPos = "auto", size }) => css`
  width: ${size};
  height: ${size};
  bottom: ${bPos};
  top: ${tPos};
  left: ${lPos};
  right: ${rPos};
`

const Dot = styled.div`
  position: absolute;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${dynamicStyle}
`

const Circle = styled.div`
  ${({ variant }) =>
    variant === "small"
      ? `
    width: 70px;
    height: 70px;
    left: 1377px;
    top: 222px;
   `
      : `
    width: 100px;
    height: 100px;
    left: 1377px;
    top: 222px;
  `}
  background: #c2e4c9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OuterCircle = styled.div`
  ${({ variant }) =>
    variant === "small"
      ? `
      width: 100px;
      height: 100px;
   `
      : `
  width: 135.24px;
  height: 135.24px;
  `}

  background: rgba(194, 228, 201, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CheckMark = styled.div`
  width: 44px;
  height: 38px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after,
  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  &:before {
    width: 26px;
    transform: rotate(45deg);
    left: -1px;
    top: 22px;
  }

  &:after {
    width: 38px;
    transform: rotate(-53deg);
    right: -4px;
    top: 14px;
  }
`

const ImageWrapper = styled.div`
  ${({ variant }) =>
    variant === "small"
      ? `
  width: 220px;
  height: 100px;
   `
      : `
  width: 294px;
  height: 213.8px;
  `}

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`

const FormFinalImage = ({ variant }) => (
  <ImageWrapper variant={variant}>
    <Dot
      size={variant === "small" ? "8px" : "11px"}
      tPos={variant === "small" ? "8px" : "-12px"}
      rPos={variant === "small" ? "39px" : "139px"}
    />
    <Dot
      size={variant === "small" ? "8px" : "11px"}
      bPos={variant === "small" ? "46px" : "46px"}
      rPos={variant === "small" ? "33px" : "63px"}
    />
    <Dot
      size={variant === "small" ? "8px" : "11px"}
      tPos={variant === "small" ? "77px" : "77px"}
      lPos={variant === "small" ? "67px" : "47px"}
    />
    <Dot
      size={variant === "small" ? "4px" : "6px"}
      tPos={variant === "small" ? "72px" : "82px"}
      rPos={variant === "small" ? "57px" : "17px"}
    />
    <Dot
      size={variant === "small" ? "4px" : "6px"}
      bPos={variant === "small" ? "31px" : "51px"}
      lPos={variant === "small" ? "52px" : "102px"}
    />
    <Dot
      size={variant === "small" ? "4px" : "6px"}
      lPos={variant === "small" ? "73px" : "-17px"}
      bPos={variant === "small" ? "88px" : "88px"}
    />
    <OuterCircle variant={variant}>
      <Circle variant={variant}>
        <CheckMark variant={variant} />
      </Circle>
    </OuterCircle>
  </ImageWrapper>
)

FormFinalImage.propTypes = {
  variant: PropTypes.string,
}

FormFinalImage.defaultProps = {
  variant: "large"
}

export default FormFinalImage
