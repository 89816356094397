import styled from "@emotion/styled"

const TechTwoStack = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 13px;
    row-gap: 14px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    gap: 45px;
    justify-content: center;
    max-width: 1150px;
    margin: 0 auto;
  }
`

export default TechTwoStack
