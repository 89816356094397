import styled from '@emotion/styled'

const GateForContentConfirmation = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #7E7E7E;

    ${({ theme }) => theme.breakpoints.down("xl")} {
        padding-right: 30px;
    }
`

export default GateForContentConfirmation