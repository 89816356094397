import styled from '@emotion/styled'
import UnstyledLink from 'components/UnstyledLink'

const ArticleRelatedArticleImageWrapper = styled(UnstyledLink)`

  ${({ theme }) => theme.breakpoints.down("md")} {
    height: 181px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 200px;
    height: 125px;
  }
`

export default ArticleRelatedArticleImageWrapper