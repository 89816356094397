import styled from "@emotion/styled"
import Image from "components/Image"

const ListFourteenItemFlagImage = styled(Image)`
  border: 1px solid #e4e4eb;

  svg {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 20px;
    height: 16px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    width: 32px;
    height: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 42px;
    height: 32px;
  }
`

export default ListFourteenItemFlagImage
