import PropTypes from "prop-types"
import * as React from "react"
import styled from "@emotion/styled"
import Image from "components/Image"

const DesktopImageWrapper = styled.div`
  position: absolute;
  top: 49.5%;
  left: 50%;
  width: 63.5%;
  height: 70.5%;
  background-color: ${({ theme }) => theme.palette.gray["50"]};
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.palette.gray["300"]};
  border-top-left-radius: 5% 8%;
  border-top-right-radius: 5% 8%;
  padding: 3% 2%;

  &::before {
    content: "";
    position: absolute;
    bottom: -2%;
    left: 50%;
    transform: translateX(-50%);
    width: 124%;
    height: 2%;
    background-color: ${({ theme }) => theme.palette.gray["50"]};
    border: 2px solid ${({ theme }) => theme.palette.gray["300"]};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -4.5%;
    left: 50%;
    transform: translateX(-50%);
    width: 124%;
    height: 3.5%;
    background-color: ${({ theme }) => theme.palette.gray["50"]};
    border: 2px solid ${({ theme }) => theme.palette.gray["300"]};
    border-bottom-left-radius: 50% 50px;
    border-bottom-right-radius: 50% 50px;
  }

  & > * {
    width: 100%;
    height: 100%;
  }
`

const MobileImageWrapper = styled.div`
  position: absolute;
  bottom: 8.5%;
  background-color: ${({ theme }) => theme.palette.gray["50"]};
  border: 2px solid ${({ theme }) => theme.palette.gray["300"]};
  border-radius: 15% / 6%;
  padding: 1% 0.5%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    right: 9%;
    width: 17.5%;
    height: 58%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    right: 14%;
    width: 11.5%;
    height: 40%;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1%;
    left: 35%;
    width: 5%;
    padding-top: 5%;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 50%;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 1%;
    left: 50%;
    width: 10%;
    padding-top: 5%;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 999px;
  }

  & > * {
    border-radius: 12%/6%;
    border: 2px solid ${({ theme }) => theme.palette.common.white};
    width: 100%;
    height: 100%;
  }
`

const BigMobileImageWrapper = styled.div`
  position: absolute;
  bottom: -16.5%;
  background-color: #f8f8fb;
  border: 2px solid #dddde2;
  border-radius: 15%/6%;
  padding-right: 1.1%;
  padding-left: 1.1%;
  padding-bottom: 9%;
  padding-top: 5%;

  & + & {
    bottom: -22.5%;
    right: 22%;
    width: 30.8%;
    height: 108.1%;
  }

  &:first-child:not(:last-child) {
    z-index: 2;
    right: 46%;
    width: 30.8%;
    height: 108.1%;
  }

  &:first-child:last-child {
    right: 34%;
    width: 30.8%;
    height: 108.1%;
  }

  & > * {
    border-radius: 12%/6%;
    border: 2px solid ${({ theme }) => theme.palette.common.white};
    width: 100%;
    height: 100%;
  }
`

const CaseStudyImages = ({ as, desktopImage, mobileImage, type, ...props }) => {
  const Component = styled(as)`
    width: 100%;
    position: relative;
    border: 2px solid ${({ theme }) => theme.palette.gray["100"]};
    padding-top: 60.5%;
    display: block;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.down("xl")} {
      border-radius: 6%/9%;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      border-radius: 4%/6%;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 48.5%;
      background-color: ${({ theme }) => theme.palette.gray["100"]};
      border-bottom-left-radius: 4% 6%;
      border-bottom-right-radius: 4% 6%;
    }
  `
  return (
    <Component {...props}>
      {desktopImage && (type === "Desktop" || type === "Desktop-mobile") && (
        <DesktopImageWrapper>
          <Image itemprop="image" {...desktopImage} objectFit="cover" />
        </DesktopImageWrapper>
      )}
      {mobileImage && type === "Desktop-mobile" && (
        <MobileImageWrapper>
          <Image itemprop="image" {...mobileImage} objectFit="cover" />
        </MobileImageWrapper>
      )}
      {mobileImage && desktopImage && type === "Mobile-mobile" && (
        <>
          <BigMobileImageWrapper>
            <Image itemprop="image" {...desktopImage} objectFit="cover" />
          </BigMobileImageWrapper>
          <BigMobileImageWrapper>
            <Image itemprop="image" {...mobileImage} objectFit="cover" />
          </BigMobileImageWrapper>
        </>
      )}
      {mobileImage && type === "Mobile" && (
        <BigMobileImageWrapper>
          <Image itemprop="image" {...mobileImage} objectFit="cover" />
        </BigMobileImageWrapper>
      )}
    </Component>
  )
}

CaseStudyImages.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  desktopImage: PropTypes.object.isRequired,
  mobileImage: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

CaseStudyImages.defaultProps = {
  as: "div",
}

export default CaseStudyImages
