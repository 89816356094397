import * as React from "react"
import PropTypes from "prop-types"
import HeaderWrapper from "components/Header/HeaderWrapper"
import HeaderContainer from "components/Header/HeaderContainer"
import HeaderLogo from "components/Header/HeaderLogo"
import HeaderNavList from "components/Header/HeaderNavList"
import HeaderButton from "components/Header/HeaderButton"
import HeaderNavItem from "components/Header/HeaderNavItem"
import HeaderNavWrapper from "components/Header/HeaderNavWrapper"
import HeaderBtnToggle from "components/Header/HeaderBtnToggler"
import useScrollingUp from "../hooks/useScrollingUp"

const Header = ({ baseUrl, logo, navigation, buttonTitle, buttonLink }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const handleClick = () => setIsOpen(!isOpen)
  const isScrollingUp = useScrollingUp(70) 

  React.useEffect(() => {
    if (isOpen) document.querySelector("#gatsby-focus-wrapper").style.overflow = "hidden"
    if (!isOpen) document.querySelector("#gatsby-focus-wrapper").style.overflow = ""
  }, [isOpen])

  return (
    <HeaderWrapper isScrollingUp={isScrollingUp}>
      <HeaderContainer>
        <HeaderLogo image={logo} link={baseUrl} />
        <HeaderBtnToggle handleClick={handleClick} />
        <HeaderNavWrapper isOpen={isOpen}>
          <HeaderNavList>
            {navigation?.map(({ id, ...props }) => (
              <HeaderNavItem handleClose={setIsOpen} key={id} {...props} />
            ))}
          </HeaderNavList>
          <HeaderButton title={buttonTitle} link={buttonLink} />
        </HeaderNavWrapper>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  navigation: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      subItems: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string,
          title: PropTypes.string,
          link: PropTypes.string,
          subtitle: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
}

export default Header
