import styled from "@emotion/styled"

const TechThreeText = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;
  letter-spacing: 0.06em;

  & > p:first-child {
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 13px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 13px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    max-width: 570px;
    margin-bottom: 18px;
  }
`

export default TechThreeText
