import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListSixWrapper from "components/ListSix/ListSixWrapper"
import ListSixTitle from "components/ListSix/ListSixTitle"
import ListSixText from "components/ListSix/ListSixText"
import ListSixHeader from "components/ListSix/ListSixHeader"
import ListSixStack from "components/ListSix/ListSixStack"
import ListSixItem from "components/ListSix/ListSixItem"
import ListSixItemText from "components/ListSix/ListSixItemText"
import ListSixItemTitle from "components/ListSix/ListSixItemTitle"
import ListSixContainer from "components/ListSix/ListSixContainer"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListSix = ({ title, titleTag, theme, subtitleText, listItems, lowerText, sectionId }) => (
  <ListSixWrapper id={sectionId} colorVariant={theme}>
    <ListSixContainer lowerText={lowerText}>
      <ListSixHeader>
        <ListSixTitle as={titleTag}>{title}</ListSixTitle>
        {!!subtitleText && (
          <ListSixText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListSixHeader>
      <ListSixStack>
        {listItems.map(({ id, title: itemTitle, titleTag: itemTitleTag, text }) => (
          <ListSixItem key={id}>
            <ListSixItemTitle as={itemTitleTag}>{itemTitle}</ListSixItemTitle>
            {!!text && (
              <ListSixItemText
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(text?.childMarkdownRemark?.html),
                }}
              />
            )}
          </ListSixItem>
        ))}
      </ListSixStack>
    </ListSixContainer>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListSixWrapper>
)

ListSix.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.object,
    }),
  ).isRequired,
}

ListSix.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListSix
