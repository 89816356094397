import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[50]};
`

const ListOneItem = styled.div`
  border-radius: 50%;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    width: 156px;
    height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 187px;
    height: 187px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${dynamicStyle}
`

export default ListOneItem
