import styled from "@emotion/styled"

const BlogListCategoryStack = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 30px;

  & > .swiper .swiper-slide {
    width: min-content;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 40px;
  }
`

export default BlogListCategoryStack
