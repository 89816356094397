import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ size }) => css`
  padding-top: ${(size === "Large" && "86") || "95"}px;
`

const CaseStudiesWrapper = styled.section`
  padding-bottom: 80px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-bottom: 80px;

    ${dynamicStyle}
  }
`

export default CaseStudiesWrapper
