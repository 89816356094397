import styled from "@emotion/styled"

const CaseStudiesBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-top: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: 100px;
  }
`

export default CaseStudiesBtnWrapper
