import styled from "@emotion/styled"

const ListSeventeenItemTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  margin-block-end: 0;
  margin-block-start: 0;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ isBlog }) => (isBlog ? `16px` : "20px")};
    line-height: 22px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "2xl")} {
    font-weight: 500;
    font-size: ${({ isBlog }) => (isBlog ? `16px` : "20px")};
    line-height: 27px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-weight: 500;
    font-size: ${({ isBlog }) => (isBlog ? `16px` : "24px")};
    line-height: ${({ isBlog }) => (isBlog ? `27px` : "41px")};
  }
`

export default ListSeventeenItemTitle
