import styled from '@emotion/styled'
import Link from 'components/Link'

const RelatedArticlesItemHeading = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  & > div {
    width: 181px;
    height: 105px;
    flex-shrink: 0;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 20px;
    flex-direction: column-reverse;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: space-between;
    gap: 31px;
    flex-direction: row;
  }
`

export default RelatedArticlesItemHeading