import styled from "@emotion/styled"
import Title from "components/Title"

const CTAFormTitle = styled(Title)`
  margin: 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: 1.2;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    font-size: 45px;
  }

  ${({ theme, isBlog }) =>
    isBlog
      ? `
        ${theme.breakpoints.up("2xl")} {
    font-size: 40px;
  }
      `
      : `
    padding-left: 14px;
  margin-bottom: 30px;

    ${theme.breakpoints.down("md")} {
    line-height: 1.2;
    padding-left: 5px;
  }

    ${theme.breakpoints.up("2xl")} {
    font-size: 60px;
  }
  `};
`

export default CTAFormTitle
