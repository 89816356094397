import styled from "@emotion/styled"

const CertificationAuthorRole = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 14px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 18px;
  }
`

export default CertificationAuthorRole
