import styled from "@emotion/styled"

const EventListItemDate = styled.span`
  margin-block-start: 0;
  margin-block-end: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 34px;
    letter-spacing: 0.06em;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 32px;
  }
`

export default EventListItemDate
