import styled from "@emotion/styled"
import Link from "components/Link"

const BlogListRecommendationsItem = styled(Link)`
  display: grid;
  grid-template-columns: 101px auto;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  text-decoration: none;
`
export default BlogListRecommendationsItem
