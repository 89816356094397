import styled from "@emotion/styled"
import Title from "components/Title"

const ListNineTitle = styled(Title)`
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 33px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "3xl")} {
    margin-bottom: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    margin-bottom: 89px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 60px;
  }
`

export default ListNineTitle
