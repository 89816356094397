import styled from "@emotion/styled"
import Image from "components/Image"

const ListTwoCardIcon = styled(Image)`
  display: block;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    width: 78px;
    height: 78px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 98px;
    height: 98px;
  }
`

export default ListTwoCardIcon
