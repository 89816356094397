import styled from "@emotion/styled"
import Link from "components/Link"

const ArticleTagsItem = styled(Link)`
  text-decoration: none;
  border: none;
  white-space: nowrap;
  border-radius: 30px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.palette.gray[100]};
  color: ${({ theme }) => theme.palette.gray[900]};
  display: block;
  font-size: 18px;
  line-height: 25px;
  padding: 5px 20px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
  }
`

export default ArticleTagsItem
