import styled from "@emotion/styled"

const ListSixteenItemContent = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 17px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    gap: 17px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 20px;
  }
`

export default ListSixteenItemContent
