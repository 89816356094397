import styled from "@emotion/styled"

const ListTwoCardText = styled.div`
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 0;
  white-space: pre-wrap;

  p {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    line-height: 29px;
  }
`

export default ListTwoCardText
