import styled from "@emotion/styled"
import Image from "components/Image"

const BlogListPostImage = styled(Image)`
  border-radius: 15px;
  padding-top: 20px;
  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
  flex-shrink: 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 100%;
    height: 245px;
    padding-top: 10px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    width: 318px;
    height: 170px;
    padding-top: 13px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 497px;
    height: 266px;
    padding-top: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 497px;
    height: 266px;
    padding-top: 20px;
  }
`

export default BlogListPostImage
