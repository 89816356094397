import styled from "@emotion/styled"

const ListOneItemDescription = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;
  font-weight: 600;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 11px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 18px;
    line-height: 34px;
  }
`

export default ListOneItemDescription
