import styled from "@emotion/styled"

const HeroAboutContent = styled.div`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
    align-items: center;
  }
`

export default HeroAboutContent
