import styled from "@emotion/styled"
import { css } from "@emotion/react"
import NetlifyForm from "components/NetlifyForm"

const dynamicStyle = ({ show = true, sending }) => css`
  ${!show && "display:none;"}
  ${sending &&
  `visibility: hidden;
   height: 0;
   overflow: hidden;
   pointer-events: none;
  `}
`

const HeroFormFormWrapper = styled(NetlifyForm)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    background-color: ${({ theme }) => theme.palette.gray[50]};
    border-radius: 10px;
    padding-top: 41px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 35px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 598px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 695px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 100%;
    padding-top: 80px;
  }

  ${dynamicStyle}
`

export default HeroFormFormWrapper
