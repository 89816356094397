import styled from "@emotion/styled"

const GateForContentSecondStage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  background-color: ${({ theme }) => theme.palette.gray[50]};
  z-index: 1;
  border-radius: 15px;
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
`

export default GateForContentSecondStage
