import styled from "@emotion/styled"
import Button from "components/Buttons/Button"

const CTAButton = styled(Button)`
  z-index: 2;
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;

    > a {
      width: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: auto;
    display: flex;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-right: 35px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "3xl")} {
    margin-right: 35px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    margin-right: 85px;
  }
`

export default CTAButton
