import styled from "@emotion/styled"

const ArticleCTAText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: 0em;
  text-align: left;
`

export default ArticleCTAText
