import React from "react"
import PropTypes, { object } from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"

import CertificationsWrapper from "components/Certifications/CertificationsWrapper"
import Container from "components/Container"
import Certification from "components/Certification/Certification"
import CertificationsTitle from "components/Certifications/CertificationsTitle"
import TestimonialsBtnArrow from "components/Testimonials/TestimonialsBtnArrow"
import TestimonialsControls from "components/Testimonials/TestimonialsControls"
import Button from "components/Buttons/Button"
import Link from "components/Link"
import CertificationModal from "components/Certification/CertificationModal"
import CertificationSpaceModal from "components/Certification/CertificationSpaceModal"
import CertificationModalEsc from "components/Certification/CertificationModalEsc"
import CertificationModalInner from "components/Certification/CertificationModalInner"
import CertificationsList from "components/Certifications/CertificationsList"
import CertificationModalWrapper from "components/Certification/CertificationModalWrapper"

const Certifications = ({ title, titleTag, buttonTitle, buttonLink, certifications, sectionId }) => {
  const [modal, setModal] = React.useState(false)
  const swiperRef = React.useRef(null)
  const swiperConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 36,
    allowTouchMove: false,
    ref: swiperRef,
    breakpoints: {
      1280: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
    },
  }

  const handleModalChange = () => setModal((prev) => !prev)

  const handleSlidePrev = () => swiperRef.current.swiper.slidePrev(300)
  const handleSlideNext = () => swiperRef.current.swiper.slideNext(300)
  if (!certifications?.length) return null

  return (
    <CertificationsWrapper id={sectionId}>
      <Container id="container">
        <CertificationsTitle as={titleTag}>{title}</CertificationsTitle>

        <CertificationModal className={`certification-modal ${modal ? "active" : ""}`}>
          <CertificationModalWrapper>
            <CertificationModalEsc onClick={handleModalChange} />
            <CertificationModalInner className="certificationModalImg" />
          </CertificationModalWrapper>
        </CertificationModal>
        <CertificationSpaceModal onClick={handleModalChange} className={modal ? "active" : ""} />

        <CertificationsList>
          {certifications.length === 1 ? (
            <Certification setModal={setModal} {...certifications[0]} isFullWidth={true} />
          ) : (
            <Swiper {...swiperConfig}>
              {certifications.map(({ id, ...props }) => (
                <SwiperSlide key={id}>
                  <Certification
                    setModal={setModal}
                    buttonView={certifications.length > 2}
                    {...props}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </CertificationsList>

        <TestimonialsControls centeredBtn={certifications.length === 1}>
          {certifications.length > 1 && (
            <TestimonialsBtnArrow
              as="button"
              hideArrow={certifications.length <= 2}
              direction="left"
              width={33}
              height={28}
              color="black"
              className="arrow-icon"
              onClick={handleSlidePrev}
            />
          )}

          {!!buttonLink && buttonTitle && (
            <Button as={Link} to={buttonLink} variant="outlined">
              {buttonTitle}
            </Button>
          )}

          {certifications.length > 1 && (
            <TestimonialsBtnArrow
              as="button"
              hideArrow={certifications.length <= 2}
              direction="right"
              width={33}
              height={28}
              color="black"
              className="arrow-icon"
              onClick={handleSlideNext}
            />
          )}
        </TestimonialsControls>
      </Container>
    </CertificationsWrapper>
  )
}

export default Certifications

Certifications.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  titleTag: PropTypes.string,
  sectionId: PropTypes.string,
  certifications: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      description: PropTypes.object,
      certificationImage: PropTypes.object,
      buttonText: PropTypes.string,
      logo: PropTypes.object,
      author: object,
    }),
  ).isRequired,
}

Certifications.defaultProps = {
  sectionId: "",
  titleTag: "p",
}
