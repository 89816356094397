import styled from "@emotion/styled"

const ListThirteenItemText = styled.div`
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.05em;
  font-size: 18px;

  p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 29px;
    grid-area: 2/1 / span 1 / span 2;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    line-height: 29px;
    grid-area: 2/2 / span 1 / span 1;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 32px;
    grid-area: 2/2 / span 1 / span 1;
    max-width: 670px;
  }
`

export default ListThirteenItemText
