import styled from "@emotion/styled"

const BlogListWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 30px;
  }
`

export default BlogListWrapper
