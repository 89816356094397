import styled from "@emotion/styled"

const HeroFormFormTitle = styled.h2`
  font-weight: 600;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 31px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 34px;
  }
`

export default HeroFormFormTitle
