import styled from "@emotion/styled"

const TextSixBackground = styled.div`
  z-index: -1;
  position: absolute;
  height: 100%;
  left: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export default TextSixBackground
