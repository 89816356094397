import styled from "@emotion/styled"

const ListOneContent = styled.div`
  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 60%;
  }
  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 59.5%;
  }
`

export default ListOneContent
