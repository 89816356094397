import styled from "@emotion/styled"
import Image from "components/Image"

const ListFiveItemContainerIcon = styled(Image)`
  position: absolute;
  width: 50%;
  height: 50%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    top: 50%;
    left: 50%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    transform: translate(calc(-50% + 5px), -50%);
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    transform: translate(calc(-50% + 15px), -50%);
  }
`

export default ListFiveItemContainerIcon
