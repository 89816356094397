import styled from "@emotion/styled"
import Link from "components/Link"

const HeroFormFormPrivacyTextLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.black};
  outline: none;
  text-decoration: underline;
`

export default HeroFormFormPrivacyTextLink
