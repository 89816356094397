import styled from "@emotion/styled"
import NetlifyForm from "components/NetlifyForm"

const GateForContentFirstStage = styled(NetlifyForm)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: contents;
  }
`

export default GateForContentFirstStage
