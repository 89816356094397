import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ theme, active, colorVariant }) => css`
  ${active &&
  `background: ${
    (colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[100]
  };`}
`

const ListEighteenItem = styled.div`
  border-radius: 3px;
  height: 100%;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-bottom: 25px;
  }

  ${dynamicStyle}
`

export default ListEighteenItem
