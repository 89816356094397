import PropTypes from "prop-types"
import Container from "components/Container"
import CaseStudiesTitle from "components/CaseStudies/CaseStudiesTitle"
import CaseStudiesText from "components/CaseStudies/CaseStudiesText"
import CaseStudiesTop from "components/CaseStudies/CaseStudiesTop"
import CaseStudiesWrapper from "components/CaseStudies/CaseStudiesWrapper"
import CaseStudiesList from "components/CaseStudies/CaseStudiesList"
import Link from "components/Link"
import CaseStudyKey from "components/CaseStudy/CaseStudyKey"
import CaseStudyValue from "components/CaseStudy/CaseStudyValue"
import CaseStudyParams from "components/CaseStudy/CaseStudyParams"
import CaseStudyWrapper from "components/CaseStudy/CaseStudyWrapper"
import CaseStudyTitle from "components/CaseStudy/CaseStudyTitle"
import CaseStudyText from "components/CaseStudy/CaseStudyText"
import CaseStudyButton from "components/CaseStudy/CaseStudyButton"
import CaseStudyImages from "components/CaseStudy/CaseStudyImages"
import CaseStudiesBtnWrapper from "components/CaseStudies/CaseStudiesBtnWrapper"
import Button from "components/Buttons/Button"
import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"
import UnstyledLink from "components/UnstyledLink"

const CaseStudies = ({
  titleTag,
  titleSize,
  title,
  text,
  buttonTitle,
  buttonLink,
  caseStudies,
  sectionId,
}) => (
  <CaseStudiesWrapper id={sectionId} size={titleSize}>
    <Container>
      {!!title && !!text && (
        <CaseStudiesTop size={titleSize}>
          {!!title && (
            <CaseStudiesTitle as={titleTag} size={titleSize}>
              {title}
            </CaseStudiesTitle>
          )}
          {!!text && <CaseStudiesText>{text}</CaseStudiesText>}
        </CaseStudiesTop>
      )}
      <CaseStudiesList>
        {caseStudies.map(
          ({
            id,
            title: titleCaseStudy,
            titleTag: titleTagCasesStudy,
            text: textCaseStudy,
            type,
            countryTitle,
            country,
            durationTitle,
            duration,
            serviceTitle,
            service,
            industryTitle,
            industry,
            buttonTitle: buttonTitleCaseStudy,
            buttonLink: buttonLinkCaseStudy,
            desktopImage,
            mobileImage,
          }) => (
            <MicromarkingWrapper key={id} itemscope="" itemtype="https://schema.org/CreativeWork">
              <Meta itemprop="name" content={titleCaseStudy} />
              <Meta itemprop="url" content={buttonLinkCaseStudy} />
              <Meta itemprop="dateCreated" content={duration} />
              <Meta itemprop="keywords" content={`${service} ${industry}`} />

              <CaseStudyWrapper>
                <div>
                  <CaseStudyImages
                    as={Link}
                    type={type}
                    ariaLabel={`Explore more ${titleCaseStudy}`}
                    desktopImage={desktopImage}
                    mobileImage={mobileImage}
                    to={buttonLinkCaseStudy}
                  />
                </div>
                <div>
                  <CaseStudyTitle as={titleTagCasesStudy}>
                      <UnstyledLink
                        ariaLabel={`Explore more ${titleCaseStudy}`}
                        to={buttonLinkCaseStudy}
                      >
                        {titleCaseStudy}
                      </UnstyledLink>
                  </CaseStudyTitle>
                  {textCaseStudy && <CaseStudyText>{textCaseStudy}</CaseStudyText>}
                  <CaseStudyParams>
                    <CaseStudyKey>{countryTitle}</CaseStudyKey>
                    <CaseStudyValue>{country}</CaseStudyValue>
                    <CaseStudyKey>{durationTitle}</CaseStudyKey>
                    <CaseStudyValue>{duration}</CaseStudyValue>
                    <CaseStudyKey>{serviceTitle}</CaseStudyKey>
                    <CaseStudyValue>{service}</CaseStudyValue>
                    <CaseStudyKey>{industryTitle}</CaseStudyKey>
                    <CaseStudyValue>{industry}</CaseStudyValue>
                  </CaseStudyParams>
                  {!!buttonLinkCaseStudy && !!buttonTitleCaseStudy && (
                    <CaseStudyButton
                      as={Link}
                      ariaLabel={`Explore more ${titleCaseStudy}`}
                      variant="outlined"
                      to={buttonLinkCaseStudy}
                    >
                      {buttonTitleCaseStudy}
                    </CaseStudyButton>
                  )}
                </div>
              </CaseStudyWrapper>
            </MicromarkingWrapper>
          ),
        )}
      </CaseStudiesList>
      <CaseStudiesBtnWrapper>
        {!!buttonLink && !!buttonTitle && (
          <Button as={Link} to={buttonLink} variant="outlined">
            {buttonTitle}
          </Button>
        )}
      </CaseStudiesBtnWrapper>
    </Container>
  </CaseStudiesWrapper>
)

CaseStudies.propTypes = {
  titleTag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).isRequired,
  titleSize: PropTypes.oneOf(["Normal", "Large"]).isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonLink: PropTypes.string,
  sectionId: PropTypes.string,
  caseStudies: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
      countryTitle: PropTypes.string,
      country: PropTypes.string,
      durationTitle: PropTypes.string,
      duration: PropTypes.string,
      serviceTitle: PropTypes.string,
      service: PropTypes.string,
      industryTitle: PropTypes.string,
      industry: PropTypes.string,
      buttonTitle: PropTypes.string,
      buttonLink: PropTypes.string,
      desktopImage: PropTypes.object,
      mobileImage: PropTypes.object,
    }),
  ).isRequired,
}

CaseStudies.defaultProps = {
  title: "",
  text: "",
  buttonTitle: "",
  buttonLink: "",
  sectionId: "",
}

export default CaseStudies
