import styled from "@emotion/styled"
import Container from "components/Container"

const PostListContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 1555px;
  }
`

export default PostListContainer
