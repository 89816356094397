import styled from "@emotion/styled"

const ListFourItemTitle = styled.h3`
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  text-decoration: none;
  color: inherit;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 210px;
    margin-bottom: 28px;
    font-size: 20px;
    font-weight: bold;
  }

  ${({ theme }) => theme.breakpoints.between("md", "2xl")} {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    max-width: 290px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    max-width: 290px;
    margin-bottom: 20px;
    letter-spacing: 0.07em;
  }
`

export default ListFourItemTitle
