import styled from "@emotion/styled"

const AuthorName = styled.h1`
  font-family: "Gilroy";
  font-weight: 700;
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 44px;
    line-height: 50px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 60px;
    line-height: 70px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 60px;
    line-height: 70px;
  }
`

export default AuthorName
