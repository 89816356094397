import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Dark" && theme.palette.gray[900]) ||
  (colorVariant === "Light" && theme.palette.gray[50]) ||
  theme.palette.common.white} !important;
`

const TextOneWrapper = styled.section`
  ${dynamicStyle}

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

export default TextOneWrapper
