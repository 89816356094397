import styled from "@emotion/styled"

const CertificationModalInner = styled.div`
  width: 100%;
  overflow: auto;

  > picture {
    > img {
      display: block;
      background-color: white;
      max-height: 90vh;
      max-width: 90vw;
    }
  }
`

export default CertificationModalInner
