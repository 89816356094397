import styled from "@emotion/styled"

const CTAFormClientTitle = styled.span`
  margin-top: 0;
  line-height: 34px;
  font-size: 20px;
  font-weight: bold;

  ${({ theme, isBlog }) =>
    isBlog
      ? `
    ${theme.breakpoints.down("xl")} {
    margin-bottom: 5px;
  }

  ${theme.breakpoints.up("xl")} {
    margin-bottom: 20px;
  }
  `
      : ``};
`

export default CTAFormClientTitle
