const Show = () => (
  <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4115 8.30214C13.4115 10.0481 11.9955 11.4631 10.2495 11.4631C8.5035 11.4631 7.0885 10.0481 7.0885 8.30214C7.0885 6.55514 8.5035 5.14014 10.2495 5.14014C11.9955 5.14014 13.4115 6.55514 13.4115 8.30214Z"
      stroke="#C6C6DB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.248 15.604C14.056 15.604 17.539 12.866 19.5 8.302C17.539 3.738 14.056 1 10.248 1H10.252C6.444 1 2.961 3.738 1 8.302C2.961 12.866 6.444 15.604 10.252 15.604H10.248Z"
      stroke="#C6C6DB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Show
