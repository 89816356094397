import styled from "@emotion/styled"

const TestimonialTop = styled.div`
  display: flex;
  align-items: center;
  height: 80px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 36px;
  }
`

export default TestimonialTop
