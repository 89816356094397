import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  ${colorVariant === "Dark" && `color: ${theme.palette.common.white}`};
`

const ListNineItemTitle = styled.h2`
  margin-block-end: 0;
  margin-block-start: 0;
  letter-spacing: 0.06em;
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 20px;
    line-height: 140%;
  }
  ${({ theme }) => theme.breakpoints.between("md", "3xl")} {
    font-style: normal;
    font-size: 20px;
    line-height: 43px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    font-style: normal;
    font-size: 24px;
    line-height: 43px;
  }

  ${dynamicStyle}
`

export default ListNineItemTitle
