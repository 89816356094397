import styled from "@emotion/styled"

const FooterTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 10px;
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 15px;
    margin-bottom: 15px;
  }
`

export default FooterTitleWrapper