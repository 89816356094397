import styled from "@emotion/styled"
import Container from "components/Container"

const CTAContainer = styled(Container)`
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    justify-content: center;
  }
`

export default CTAContainer
