import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListThirteenWrapper from "components/ListThirteen/ListThirteenWrapper"
import ListThirteenStack from "components/ListThirteen/ListThirteenStack"
import ListThirteenItem from "components/ListThirteen/ListThirteenItem"
import ListThirteenItemTitle from "components/ListThirteen/ListThirteenItemTitle"
import ListThirteenItemText from "components/ListThirteen/ListThirteenItemText"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListThirteen = ({ theme: colorVariant, listItems, lowerText, sectionId }) => (
  <ListThirteenWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListThirteenStack lowerText={lowerText}>
        {listItems.map(({ id, title, titleTag, text }) => (
          <ListThirteenItem key={id}>
            <ListThirteenItemTitle as={titleTag}>{title}</ListThirteenItemTitle>
            {text && (
              <ListThirteenItemText
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(text?.childMarkdownRemark?.html),
                }}
              />
            )}
          </ListThirteenItem>
        ))}
      </ListThirteenStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListThirteenWrapper>
)

ListThirteen.propTypes = {
  theme: PropTypes.string.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      titleTag: PropTypes.string,
      text: PropTypes.object,
    }),
  ).isRequired,
}

ListThirteen.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListThirteen
