import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant, numSlides }) => css`
  ${`border-bottom: 10px solid ${
    (colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[100]
  };`}

  ${theme.breakpoints.up("lg")} {
    .list-eighteen .swiper-slide {
      border-left: 2px solid
        ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[100]};
      &:last-child {
        border-right: 2px solid
          ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[100]};
      }
    }
  }

  .list-eighteen {
    .swiper-slide {
      ${theme.breakpoints.up("xl")} {
        flex: 0 0
          ${(numSlides > 4 && numSlides < 7 && (100 / numSlides).toFixed(2)) ||
          (numSlides >= 7 && "14.29") ||
          "20"}%;
      }
    }
  }
`

const ListEighteenStack = styled.div`
  position: relative;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 0 4px;
    margin-bottom: 65px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 65px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 72px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 90px;
  }

  .list-eighteen {
    .swiper-button-next,
    .swiper-button-prev {
      color: black;
      width: 29px;
      height: 25px;
      transition: 0.3s;
      padding: 25px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        border: solid #000;
        border-width: 0 2px 2px 0;
        display: block;
        width: ${(25 * 0.7).toFixed(2)}px;
        height: ${(25 * 0.7).toFixed(2)}px;
        transition: 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 29px;
        height: 2px;
        background-color: #000;
        transition: 0.3s;
      }
    }

    .swiper-button-prev {
      left: 20px;
      &::before {
        left: 9%;
        transform: translateY(-50%) rotate(135deg);
      }
      &::after {
        left: 35%;
      }
    }

    .swiper-button-next {
      right: 20px;
      &::before {
        right: 9%;
        transform: translateY(-50%) rotate(-45deg);
      }
      &::after {
        left: 62%;
      }
    }

    .swiper-slide {
      height: auto;

      ${({ theme }) => theme.breakpoints.down("md")} {
        flex: 0 0 50%;
      }
      ${({ theme }) => theme.breakpoints.between("md", "xl")} {
        flex: 0 0 25%;
      }
    }
  }

  ${dynamicStyle}
`

export default ListEighteenStack
