import styled from "@emotion/styled"

const HeroFormSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    background-color: ${({ theme }) => theme.palette.gray[50]};
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    border-radius: 10px;
    height: 590px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 50%;
    position: absolute;
    right: 0;
    height: 500px;
  }
`

export default HeroFormSpinnerWrapper
