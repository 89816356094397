import styled from "@emotion/styled"

const ArticleShareStack = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    gap: 13px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 20px;
  }
`

export default ArticleShareStack
