import styled from "@emotion/styled"
import TestimonialsTitle from "components/Testimonials/TestimonialsTitle"

const CertificationsTitle = styled(TestimonialsTitle)`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 35px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 70px;
  }
`

export default CertificationsTitle
