import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.gray[50]) || theme.palette.common.white};
`

const ListFourteenItemFlagImageWrapper = styled.div`
  content: "";
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    height: 36px;
    width: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    height: 50px;
    width: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    height: 80px;
    width: 80px;
  }

  ${dynamicStyle}
`

export default ListFourteenItemFlagImageWrapper
