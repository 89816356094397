import styled from "@emotion/styled"

const ListSevenItemNumber = styled.span`
  display: flex;
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  line-height: 34px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 28px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    margin-top: -8px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 29px;
    width: 52px;
    height: 52px;
    flex: 0 0 52px;
    margin-top: -9px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 36px;
    width: 65px;
    height: 65px;
    flex: 0 0 65px;
    margin-top: -15px;
  }
`

export default ListSevenItemNumber
