import styled from "@emotion/styled"

const TextOneContent = styled.div`
  max-width: 660px;

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-left: 90px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 18px;
    line-height: 31px;
  }
`

export default TextOneContent
