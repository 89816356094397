import styled from '@emotion/styled'

const ArticleAsideNavListItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 10px 7px 13px;
  color: ${({ theme }) => theme.palette.common.black};
  text-decoration: unset;

  ${({ theme, active }) =>
    active
      ? `
        color: #000000;
  `
      : `
        color: #969698;
      `}
`

export default ArticleAsideNavListItem