import styled from "@emotion/styled"

const TextFourHeader = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 610px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 870px;
  }
`

export default TextFourHeader
