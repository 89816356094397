import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ error, display, touched, theme }) => css`
  ${touched &&
  (error
    ? `
      border: 2px solid red;
       padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 19px;
  padding-right: 19px;
    `
    : `
      border: 2px solid ${theme.palette.primary.main};
       padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 19px;
  padding-right: 19px;
    `)}
  ${display && "display: none;"}
`

const HeroFormFormField = styled.input`
  background: transparent;
  background-color: #fff;
  width: 100%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
  font-size: 16px;
  line-height: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 15px;
  resize: vertical;

  &::placeholder {
    color: #b2b2b2;
  }

  ${dynamicStyle}
`

export default HeroFormFormField
