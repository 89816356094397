import * as React from "react"
import PropTypes from "prop-types"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Swiper, SwiperSlide } from "swiper/react"
import * as yup from "yup"
import { useFormik } from "formik"

import Image from "components/Image"
import Link from "components/Link"
import Arrow from "components/Icons/Arrow"
import Container from "components/Container"
import NetlifyForm from "components/NetlifyForm"

import Show from "components/Icons/Show"
import Calendar from "components/Icons/Calendar"
import Time from "components/Icons/Time"
import Facebook from "components/Icons/Facebook"
import Twitter from "components/Icons/Twitter"
import LinkedIn from "components/Icons/Linkedin"

import Breadcrumbs from "components/Breadcrumbs"
import BreadcrumbsItem from "components/BreadcrumbsBlogArticle/BreadcrumbsItem"
import ArticleContainer from "components/Article/ArticleContainer"
import ArticleTitle from "components/Article/ArticleTitle"
import ArticleWrapper from "components/Article/ArticleWrapper"
import ArticleContent from "components/Article/ArticleContent"
import ArticleAsideWrapper from "components/Article/ArticleAsideWrapper"
import ArticleRelatedArticle from "components/Article/ArticleRelatedArticle/ArticleRelatedArticle"
import ArticleRelatedArticleImage from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleImage"
import ArticleRelatedArticleInfo from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleInfo"
import ArticleRelatedArticleInfoTitle from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleInfoTitle"
import ArticleRelatedArticleInfoAuthor from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleInfoAuthor"
import ArticleRelatedArticleInfoNotice from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleInfoNotice"
import ArticleRelatedArticleInfoAuthorInfo from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleInfoAuthorInfo"
import ArticleRelatedArticleInfoImage from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleInfoImage"
import ArticleRelatedArticleInfoAuthorName from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleInfoAuthorName"
import ArticleRelatedArticleImageWrapper from "components/Article/ArticleRelatedArticle/ArticleRelatedArticleImageWrapper"
// import ArticleContentWrapper from "components/Article/ArticleContentWrapper"

import {
  // Article Share
  ArticleShare,
  ArticleShareButton,
  ArticleShareStack,
  ArticleShareTitle,
  // Article Content Variants
  ArticleContentBold,
  ArticleContentLink,
  ArticleContentOL,
  ArticleContentTable,
  ArticleContentTableCell,
  ArticleContentTableRow,
  ArticleContentText,
  ArticleContentTitle,
  ArticleContentUL,
  // Article Author
  // ArticleAuthorInfoLink,
  ArticleAuthorInfoWrapper,
  ArticleAuthorName,
  ArticleAuthorPhoto,
  // ArticleAuthorPhotoLink,
  ArticleAuthorPhotoWrapper,
  ArticleAuthorRole,
  ArticleAuthorWrapper,
  // Article Aside Tabs
  // ArticleAsideTabsColumn,
  // ArticleAsideTabsHeading,
  // ArticleAsideTabsItem,
  // ArticleAsideTabsTab,
  // ArticleAsideTabsWrapper,
  // Article Aside Nav
  ArticleAsideNavHeading,
  ArticleAsideNavHeadingIcon,
  ArticleAsideNavHeadingTitle,
  ArticleAsideNavList,
  ArticleAsideNavListItem,
  // ArticleAsideNavListItemIcon,
  ArticleAsideNavListItemTitle,
  ArticleAsideNavWrapper,
  // Article Aside Blog
  // ArticleAsideBlogPostContent,
  // ArticleAsideBlogPostImage,
  // ArticleAsideBlogPostLink,
  // ArticleAsideBlogPostTitle,
  // ArticleAsideBlogPostWrapper,
  // Article Aside Banner
  ArticleAsideBannerFormField,
  ArticleAsideBannerImage,
  ArticleAsideBannerLink,
  ArticleAsideBannerText,
  ArticleAsideBannerWrapper,
  ArticleAsideBannerContent,
  ArticleAsideFinalBtn,
  ArticleAsideFinalWrapper,
  ArticleAsideFinalText,
  ArticleAsideSpinner,
  ArticleAsideSpinnerWrapper,
  ArticleAsideBannerFormText,
} from "components/Article"

import ArticleInfoStack from "components/Article/ArticleInfoStack"
import ArticleInfoItem from "components/Article/ArticleInfoItem"
import ArticleImageTitle from "components/Article/ArticleImageTitle"

// import ArticleIcon from "components/Article/ArticleIcon"
// import ArticleWrapperIcon from "components/Article/ArticleWrapperIcon"
// import AuthorEmailLinkWrapper from "components/Author/AuthorEmailLinkWrapper"
// import AuthorEmailLink from "components/Author/AuthorEmailLink"
import ArticleAuthorContactInfoWrapper from "components/Article/ArticleAuthor/ArticleAuthorContactInfoWrapper"
import ArticleInfoArticleWrapper from "components/Article/ArticleAuthor/ArticleInfoArticleWrapper"
import ArticleAuthorsWrapper from "components/Article/ArticleAuthor/ArticleAuthorsWrapper"

import ArticleAsideFixedWrapper from "components/Article/ArticleAsideFixedWrapper"
import ArticleAsideBannerFormFieldName from "components/Article/ArticleAsideBannerFormFieldName"

import ArticleVideoWrapper from "components/Article/ArticleVideoWrapper"

import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"

import ArticleTagsItem from "components/Article/ArticleTagsItem"
import ArticleTagsWrapper from "components/Article/ArticleTagsWrapper"

import FormFinalImage from "components/Icons/FormFinalImage"
import UnstyledLink from "components/UnstyledLink"

import IntlDate from "components/IntlDate"
import pushGTMEvent from "utils/pushGTMEvent"

import useUserId from "../hooks/useUserId"
import ArticleCTA from "./ArticleCTA"
import BlogArticleTwoImages from "./BlogArticleTwoImages"
import CTAText from "./CTAText"
import GateForContent from "./GateForContent"

const validationSchema = yup.object({
  email: yup.string("Email").email("Enter a valid email").required("Email is required"),
})

const prettifyAnchorLink = (text) =>
  text.toLowerCase().replace(/\W/g, (symb) => (symb === " " ? "-" : ""))

const getText = (el) => {
  return typeof el === "string" ? el : getText(el.props.children)
}

const options = (recaptcha) => {
  return {
    renderMark: {
      [MARKS.BOLD]: (text) => <ArticleContentBold>{text}</ArticleContentBold>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <ArticleContentTitle as="h1" fontSize="Large" fontFamily="Gilroy" mb={40} pt={90}>
          {children}
        </ArticleContentTitle>
      ),
      [BLOCKS.HEADING_2]: (node, children) => {
        const text = prettifyAnchorLink(
          // eslint-disable-next-line react/destructuring-assignment
          [...children.map((el) => getText(el).trim())].join(" ").trim(),
        )
        return (
          <ArticleContentTitle
            id={text}
            as="h2"
            fontSize="Normal"
            fontFamily="Gilroy"
            mb={40}
            pt={90}
          >
            {children}
          </ArticleContentTitle>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => (
        <ArticleContentTitle as="h3" fontSize="Small" fontFamily="Montserrat" mb={40} pt={60}>
          {children}
        </ArticleContentTitle>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <ArticleContentTitle as="h4" fontSize="exSmall" fontFamily="Montserrat" mb={40} pt={60}>
          {children}
        </ArticleContentTitle>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <ArticleContentTitle as="h5" fontSize="exSmall" fontFamily="Montserrat" mb={40} pt={60}>
          {children}
        </ArticleContentTitle>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <ArticleContentTitle as="h6" fontSize="exSmall" fontFamily="Montserrat" mb={40} pt={60}>
          {children}
        </ArticleContentTitle>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <ArticleContentText>{children}</ArticleContentText>,
      [BLOCKS.UL_LIST]: (node, children) => <ArticleContentUL>{children}</ArticleContentUL>,
      [BLOCKS.OL_LIST]: (node, children) => <ArticleContentOL>{children}</ArticleContentOL>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const {
          data: { target },
        } = node
        return (
          <>
            <Image {...target} />
            {target.alt && <ArticleImageTitle>{target.alt}</ArticleImageTitle>}
          </>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const {
          data: { target },
        } = node
        if (target.component === "Article") {
          return (
            <ArticleRelatedArticle>
              <ArticleRelatedArticleImageWrapper to={target.link}>
                <ArticleRelatedArticleImage {...target.coverImage} />
              </ArticleRelatedArticleImageWrapper>
              <ArticleRelatedArticleInfo>
                <UnstyledLink to={target.link}>
                  <ArticleRelatedArticleInfoTitle>{target.title}</ArticleRelatedArticleInfoTitle>
                </UnstyledLink>
                <ArticleRelatedArticleInfoAuthor>
                  <ArticleRelatedArticleInfoNotice>Article by:</ArticleRelatedArticleInfoNotice>
                  <ArticleRelatedArticleInfoAuthorInfo
                    as={UnstyledLink}
                    to={`${target.author.path}/`}
                  >
                    <ArticleRelatedArticleInfoImage {...target.author.authorPhoto} />
                    <ArticleRelatedArticleInfoAuthorName>
                      {target.author.authorName}
                    </ArticleRelatedArticleInfoAuthorName>
                  </ArticleRelatedArticleInfoAuthorInfo>
                </ArticleRelatedArticleInfoAuthor>
              </ArticleRelatedArticleInfo>
            </ArticleRelatedArticle>
          )
        }
        if (target.component === "CTAText") {
          return <CTAText {...target} />
        }
        if (target.component === "ArticleCTA") {
          return <ArticleCTA {...target} />
        }
        if (target.component === "GateForContent") {
          return <GateForContent recaptcha={recaptcha} {...target} />
        }
        if (target.component === "BlogArticleTwoImages") {
          return <BlogArticleTwoImages {...target} />
        }
        if (target.component === "Video") {
          return (
            <>
              <ArticleContentTitle as="h3" fontSize="Normal" fontFamily="Gilroy" mb={40} pt={60}>
                {target.title}
              </ArticleContentTitle>
              <ArticleVideoWrapper {...target} />
            </>
          )
        }
        if (target.component === "VideoIframe") {
          return <ArticleVideoWrapper iframe={target} />
        }
        return null
      },
      [BLOCKS.TABLE]: (node, children) => (
        <ArticleContentTable>
          <tbody>{children}</tbody>
        </ArticleContentTable>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => (
        <ArticleContentTableRow>{children}</ArticleContentTableRow>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <ArticleContentTableCell>
          <ArticleContentTableRow>{children}</ArticleContentTableRow>
        </ArticleContentTableCell>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const {
          data: {
            target: { title, link, noFollow },
          },
        } = node
        return (
          <ArticleContentLink target="_blank" to={link} noFollow={noFollow}>
            {title}
          </ArticleContentLink>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri },
        } = node
        const linkAttrs = uri.match(/\[.+\]/)?.[0]

        return (
          <ArticleContentLink
            rel={linkAttrs ? linkAttrs.replace("[", "").replace("]", "") : ""}
            to={uri.replace(linkAttrs, "")}
            target="_blank"
          >
            {children}
          </ArticleContentLink>
        )
      },
    },
  }
}

const swiperConfig = {
  slidesPerView: "auto",
  spaceBetween: 10,
}

const Article = ({
  title,
  hideTableOfContents,
  breadcrumbs,
  content,
  postContent,
  date,
  viewsCount,
  readingTime,
  author = undefined,
  reviewer = undefined,
  // asideTop,
  asideBannerText,
  asideBannerLinkText,
  // asideBannerLink,
  asideBannerImage,
  // postsTabTitle,
  // postsTabItems,
  // linksTabTitle,
  // linksTabItems,
  tags,
  // coverImage,
  recaptcha,
}) => {
  const userID = useUserId()

  const formik = useFormik({
    initialValues: {
      userID,
      formId: "subscriptionForm",
      email: "",
    },
    isInitialValid: false,
    validationSchema,
  })

  const [prevFormState, setPrevFormState] = React.useState(formik.initialValues)

  const [sending, setSending] = React.useState(false)
  const [showFinal, setShowFinal] = React.useState(false)

  const showSendingSpinnerHandler = () => {
    setSending(!sending)
  }
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!recaptcha) {
      // eslint-disable-next-line no-console
      console.log("Execute recaptcha not yet available")
      return
    }
    if (typeof window !== "undefined") {
      try {
        showSendingSpinnerHandler()
        const token = await recaptcha.execute("submit")
        const response = await window
          .fetch(`/api/recaptcha-check`, {
            method: "POST",
            body: JSON.stringify({
              token,
            }),
          })
          .then((res) => res.json())
        // eslint-disable-next-line consistent-return
        return response.success && response.score > 0.5
      } catch (error) {
        // eslint-disable-next-line consistent-return
        return false
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptcha])

  const showFinalHandler = () => {
    setShowFinal(!showFinal)
  }

  const isBrowser = typeof window !== "undefined"
  const [currentHash, setCurrentHash] = React.useState(isBrowser && window.location.hash)
  const [url, setUrl] = React.useState(null)
  const [isActive, setIsActive] = React.useState(false)
  const setActiveHandler = () => {
    setIsActive(!isActive)
  }

  React.useEffect(() => {
    const updateUrl = () => {
      if (!url) {
        setUrl(isBrowser && window.location)
        document.querySelector("#gatsby-focus-wrapper").removeEventListener("scroll", updateUrl)
      }
    }

    if (isBrowser) {
      document.querySelector("#gatsby-focus-wrapper").addEventListener("scroll", updateUrl)
    }

    return () => {
      if (isBrowser) {
        document.querySelector("#gatsby-focus-wrapper").removeEventListener("scroll", updateUrl)
      }
    }
  }, [isBrowser, url])

  React.useEffect(() => {
    const contentItems =
      isBrowser &&
      ["beginning", ...postContent].map((el) =>
        document.getElementById(`${prettifyAnchorLink(el)}-content-item`),
      )

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && isBrowser) {
            setTimeout(
              () =>
                document
                  ?.getElementById("scroll-container")
                  ?.scrollTo(
                    0,
                    contentItems.find((el) => el.id === `${entry.target.id}-content-item`)
                      .offsetTop,
                  ),
              10,
            )

            setCurrentHash(
              document.querySelector("#gatsby-focus-wrapper").scrollTop < 100
                ? "#beginning"
                : `#${entry.target.id}`,
            )
          }
        })
      },
      { threshold: 1 },
    )
    const observedNodes = ["beginning", ...postContent]
      .map((el) => isBrowser && document.getElementById(prettifyAnchorLink(el)))
      .filter((el) => el)

    observedNodes.forEach((el) => observer.observe(el))

    return () => observedNodes.forEach(() => observer.disconnect())
  }, [postContent, isBrowser])

  return (
    <ArticleWrapper>
      <Container>
        {breadcrumbs && (
          <MicromarkingWrapper itemscope="" itemtype="http://schema.org/BreadcrumbList">
            <Breadcrumbs
              itemprop="itemListElement"
              itemscope=""
              itemtype="http://schema.org/ListItem"
            >
              {breadcrumbs.map(
                ({ id: breadcrumbId, title: breadcrumbTitle, link: breadcrumbLink }, idx) => (
                  <>
                    {breadcrumbs.length - 1 !== idx && (
                      <>
                        <BreadcrumbsItem
                          itemprop="item"
                          key={breadcrumbId}
                          isActive={false}
                          to={breadcrumbLink}
                        >
                          <Meta itemprop="name" content={breadcrumbTitle} />
                          <Meta itemprop="position" content={idx + 1} />
                          {breadcrumbTitle}
                        </BreadcrumbsItem>
                        <Arrow
                          key={breadcrumbLink}
                          direction="left"
                          color="#BBBBBB"
                          width={14}
                          height={12}
                        />
                      </>
                    )}
                    {breadcrumbs.length - 1 === idx && (
                      <BreadcrumbsItem key={breadcrumbId} isActive={true}>
                        {breadcrumbTitle}
                      </BreadcrumbsItem>
                    )}
                  </>
                ),
              )}
            </Breadcrumbs>
          </MicromarkingWrapper>
        )}
      </Container>

      <MicromarkingWrapper itemscope="" itemtype="https://schema.org/BlogPosting">
        <Meta itemprop="name" content={title} />
        <Meta itemprop="datePublished" content={date} />
        <Meta itemprop="headline" content={title} />
        <ArticleContainer>
          <ArticleAsideWrapper>
            {/* <ArticleAsideBlogPostWrapper>
                {asideTop.blockImage && <ArticleAsideBlogPostImage {...asideTop.blockImage} />}
                <div>
                  {asideTop.blockTitle && (
                    <ArticleAsideBlogPostTitle> {asideTop.blockTitle}</ArticleAsideBlogPostTitle>
                  )}
                  {asideTop.blockText && (
                    <ArticleAsideBlogPostContent
                      dangerouslySetInnerHTML={{
                        __html: asideTop.blockText?.childMarkdownRemark.html,
                      }}
                    />
                  )}
                  {asideTop.blockLink && asideTop.blockLinkText && (
                    <ArticleAsideBlogPostLink to={asideTop.blockLink}>
                      <span>{asideTop.blockLinkText}</span>
                      <Arrow direction="right" color="#38B148" width={14} height={12} />
                    </ArticleAsideBlogPostLink>
                  )}
                </div>
              </ArticleAsideBlogPostWrapper> */}
            <ArticleAsideFixedWrapper>
              {postContent && !hideTableOfContents && (
                <ArticleAsideNavWrapper>
                  <ArticleAsideNavHeading onClick={setActiveHandler}>
                    <ArticleAsideNavHeadingIcon active={isActive} />
                    <ArticleAsideNavHeadingTitle>Table of contents</ArticleAsideNavHeadingTitle>
                  </ArticleAsideNavHeading>
                  <ArticleAsideNavList id="scroll-container" active={isActive}>
                    <ArticleAsideNavListItem
                      active={currentHash === "#beginning"}
                      onClick={() => setCurrentHash("#beginning")}
                      href="#beginning"
                      id="beginning-content-item"
                    >
                      <ArticleAsideNavListItemTitle>Beginning</ArticleAsideNavListItemTitle>
                    </ArticleAsideNavListItem>
                    {postContent.map((text) => {
                      const pretyfiedText = `${prettifyAnchorLink(text)}`
                      const hash = `#${pretyfiedText}`
                      return (
                        <ArticleAsideNavListItem
                          onClick={() => setCurrentHash(hash)}
                          href={hash}
                          key={text}
                          id={`${pretyfiedText}-content-item`}
                          active={currentHash === hash}
                        >
                          <ArticleAsideNavListItemTitle>{text}</ArticleAsideNavListItemTitle>
                        </ArticleAsideNavListItem>
                      )
                    })}
                  </ArticleAsideNavList>
                </ArticleAsideNavWrapper>
              )}
              {/* <ArticleAsideTabsWrapper>
                  <ArticleAsideTabsHeading>
                    <ArticleAsideTabsTab active={active} onClick={() => setActive(true)}>
                      {postsTabTitle}
                    </ArticleAsideTabsTab>
                    <ArticleAsideTabsTab active={!active} onClick={() => setActive(false)}>
                      {linksTabTitle}
                    </ArticleAsideTabsTab>
                  </ArticleAsideTabsHeading>
                  {active && postsTabItems && (
                    <ArticleAsideTabsColumn>
                      {postsTabItems.map(({ id: postId, title: postTitle, link: postLink }) => (
                        <ArticleAsideTabsItem key={postId} to={postLink}>
                          {postTitle}
                        </ArticleAsideTabsItem>
                      ))}
                    </ArticleAsideTabsColumn>
                  )}
                  {!active && linksTabItems && (
                    <ArticleAsideTabsColumn>
                      {linksTabItems.map(({ id: linksId, title: linksTitle, link: linksUrl }) => (
                        <ArticleAsideTabsItem key={linksId} to={linksUrl}>
                          {linksTitle}
                        </ArticleAsideTabsItem>
                      ))}
                    </ArticleAsideTabsColumn>
                  )}
                </ArticleAsideTabsWrapper> */}
              {hideTableOfContents && (
                <ArticleAsideBannerWrapper>
                  <ArticleAsideBannerContent>
                    {!showFinal && !sending && (
                      <>
                        <ArticleAsideBannerText>{asideBannerText}</ArticleAsideBannerText>
                        <NetlifyForm
                          id="subscriptionForm"
                          formName="Subscription"
                          formValues={formik.values}
                          preSubmit={handleReCaptchaVerify}
                          postSubmit={() => {
                            formik.resetForm()
                            setSending(false)
                            showFinalHandler()
                          }}
                        >
                          <ArticleAsideBannerFormField type="hidden" key="formId" name="formId" />
                          <ArticleAsideBannerFormFieldName>
                            Email<span>*</span>
                          </ArticleAsideBannerFormFieldName>
                          <ArticleAsideBannerFormField
                            error={formik.errors.email}
                            onChange={formik.handleChange}
                            value={formik.values.email || ""}
                            type="email"
                            key="email"
                            name="email"
                            required
                            touched={formik.touched?.email}
                            onFocus={() => {
                              formik.setFieldTouched("email", true, true)
                            }}
                            onBlur={() => {
                              if (
                                formik.values.email !== "" &&
                                prevFormState.email !== formik.values.email
                              )
                                setPrevFormState(formik.values)
                              if (
                                formik.values.email !== "" &&
                                !formik.errors.email &&
                                prevFormState.email !== formik.values.email
                              )
                                pushGTMEvent("SubscribeFormEmail", formik.values.email)
                            }}
                          />
                          <ArticleAsideBannerFormText>
                            By sending this form I confirm that I have read and accept the{" "}
                            <Link target="_blank" to="/privacy-policy/">
                              Privacy Policy
                            </Link>
                          </ArticleAsideBannerFormText>
                          <ArticleAsideBannerLink
                            id="subscription-form-submit"
                            variant="contained"
                            type="submit"
                          >
                            {asideBannerLinkText}
                          </ArticleAsideBannerLink>
                        </NetlifyForm>
                      </>
                    )}
                    {sending && (
                      <ArticleAsideSpinnerWrapper>
                        <ArticleAsideSpinner />
                      </ArticleAsideSpinnerWrapper>
                    )}
                    {showFinal && (
                      <ArticleAsideFinalWrapper>
                        <FormFinalImage variant="small" />
                        <ArticleAsideFinalText>Subscribed!</ArticleAsideFinalText>
                        <ArticleAsideFinalBtn onClick={showFinalHandler}>
                          Close
                        </ArticleAsideFinalBtn>
                      </ArticleAsideFinalWrapper>
                    )}
                  </ArticleAsideBannerContent>
                  <ArticleAsideBannerImage backgroundImage={asideBannerImage} />
                </ArticleAsideBannerWrapper>
              )}
            </ArticleAsideFixedWrapper>
          </ArticleAsideWrapper>
          <ArticleContent>
            <ArticleTitle itemprop="name" id="beginning">
              {title}
            </ArticleTitle>

            {author ? (
              <ArticleInfoArticleWrapper>
                <ArticleInfoStack>
                  {date && (
                    <ArticleInfoItem>
                      <Calendar />
                      <IntlDate date={date} />
                    </ArticleInfoItem>
                  )}
                  {readingTime && (
                    <ArticleInfoItem>
                      <Time />
                      <span>{readingTime}</span>
                    </ArticleInfoItem>
                  )}
                  {viewsCount && (
                    <ArticleInfoItem>
                      <Show />
                      <span>{viewsCount}</span>
                    </ArticleInfoItem>
                  )}
                </ArticleInfoStack>
                <ArticleAuthorsWrapper>
                  <MicromarkingWrapper
                    itemprop="author"
                    itemscope=""
                    itemtype="https://schema.org/Person"
                  >
                    <ArticleAuthorWrapper
                      as={UnstyledLink}
                      to={`${author.link}/`}
                      target="_blank"
                      withoutNoreferer={true}
                    >
                      <Meta itemprop="url" content={`https://adexin.com${author.link}/`} />
                      <ArticleAuthorPhotoWrapper>
                        <ArticleAuthorPhoto {...author.authorPhoto} />
                      </ArticleAuthorPhotoWrapper>
                      <ArticleAuthorContactInfoWrapper>
                        <ArticleAuthorInfoWrapper>
                          <ArticleAuthorName itemprop="name">{author.authorName}</ArticleAuthorName>
                          <ArticleAuthorRole>{author.authorRole}</ArticleAuthorRole>
                        </ArticleAuthorInfoWrapper>
                        {/*
                        <ArticleWrapperIcon>
                          {author?.linkedLink?.link && (
                            <Link to={author.linkedLink.link} target="_blank">
                              <ArticleIcon {...author.linkedinIcon} />
                            </Link>
                          )}
                          {author?.emailLink && (
                            <AuthorEmailLinkWrapper>
                              <ArticleIcon {...author.emailIcon} />
                              <AuthorEmailLink
                                aria-label="email address"
                                href={`mailto:${author.emailLink}`}
                              />
                            </AuthorEmailLinkWrapper>
                          )}
                        </ArticleWrapperIcon>
*/}
                      </ArticleAuthorContactInfoWrapper>
                    </ArticleAuthorWrapper>
                  </MicromarkingWrapper>

                  {reviewer && (
                    <MicromarkingWrapper
                      itemprop="reviewedBy"
                      itemscope=""
                      itemtype="https://schema.org/Person"
                    >
                      <ArticleAuthorWrapper
                        as={UnstyledLink}
                        to={`${reviewer.link}/`}
                        target="_blank"
                        withoutNoreferer={true}
                      >
                        <Meta itemprop="url" content={`https://adexin.com${reviewer.link}/`} />
                        <ArticleAuthorPhotoWrapper>
                          <ArticleAuthorPhoto {...reviewer.authorPhoto} />
                        </ArticleAuthorPhotoWrapper>
                        <ArticleAuthorContactInfoWrapper>
                          <ArticleAuthorInfoWrapper>
                            <ArticleAuthorName itemprop="name">
                              {reviewer.authorName}
                              <span>Reviewer</span>
                            </ArticleAuthorName>
                            <ArticleAuthorRole>{reviewer.authorRole}</ArticleAuthorRole>
                          </ArticleAuthorInfoWrapper>
                          {/*
                          <ArticleWrapperIcon>
                            {reviewer?.linkedLink?.link && (
                              <Link to={reviewer.linkedLink.link} target="_blank">
                                <ArticleIcon {...reviewer.linkedinIcon} />
                              </Link>
                            )}
                            {reviewer?.emailLink && (
                              <AuthorEmailLinkWrapper>
                                <ArticleIcon {...reviewer.emailIcon} />
                                <AuthorEmailLink
                                  aria-label="email address"
                                  href={`mailto:${reviewer.emailLink}`}
                                />
                              </AuthorEmailLinkWrapper>
                            )}
                          </ArticleWrapperIcon>
*/}
                        </ArticleAuthorContactInfoWrapper>
                      </ArticleAuthorWrapper>
                    </MicromarkingWrapper>
                  )}
                </ArticleAuthorsWrapper>
              </ArticleInfoArticleWrapper>
            ) : (
              <MicromarkingWrapper
                itemprop="author"
                itemscope=""
                itemtype="https://schema.org/Person"
              >
                <Meta itemprop="url" content="https://adexin.com/" />
                <Meta itemprop="name" content="adexin" />
              </MicromarkingWrapper>
            )}

            {/* eslint-disable-next-line */}
            {content /* eslint-disable react/no-unknown-property */ && (
              <div itemprop="articleBody">{renderRichText(content, options(recaptcha))}</div>
            )}
            {tags && (
              <ArticleTagsWrapper>
                <Swiper {...swiperConfig}>
                  {tags?.map(({ id: tagId, title: tagTitle, link: tagLink }) => {
                    return (
                      <SwiperSlide key={tagId}>
                        <ArticleTagsItem to={tagLink}>{tagTitle}</ArticleTagsItem>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </ArticleTagsWrapper>
            )}
            <ArticleShare>
              <ArticleShareTitle>Share:</ArticleShareTitle>

              <ArticleShareStack>
                <ArticleShareButton to={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
                  <Facebook />
                </ArticleShareButton>
                <ArticleShareButton to={`https://twitter.com/share?ref_src=twsrc%5Etfw&url=${url}`}>
                  <Twitter />
                </ArticleShareButton>
                <ArticleShareButton
                  to={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                >
                  <LinkedIn />
                </ArticleShareButton>
              </ArticleShareStack>
            </ArticleShare>
          </ArticleContent>
        </ArticleContainer>
      </MicromarkingWrapper>
    </ArticleWrapper>
  )
}

Article.propTypes = {
  title: PropTypes.string.isRequired,
  hideTableOfContents: PropTypes.bool.isRequired,
  relatedArticle: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  postsTabTitle: PropTypes.string.isRequired,
  linksTabTitle: PropTypes.string.isRequired,
  linksTabItems: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,

  postsTabItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      coverImage: PropTypes.object,
      shortContent: PropTypes.object,
    }),
  ).isRequired,

  asideTop: PropTypes.exact({
    blockImage: PropTypes.object.isRequired,
    blockTitle: PropTypes.string.isRequired,
    blockText: PropTypes.object.isRequired,
    blockLinkText: PropTypes.string.isRequired,
    blockLink: PropTypes.string.isRequired,
  }).isRequired,

  asideBannerText: PropTypes.string.isRequired,
  asideBannerLinkText: PropTypes.string.isRequired,
  asideBannerLink: PropTypes.string.isRequired,
  asideBannerImage: PropTypes.object.isRequired,
  coverImage: PropTypes.object.isRequired,
  asideBlogPost: PropTypes.exact({
    link: PropTypes.string,
    title: PropTypes.string,
    coverImage: PropTypes.object,
    shortContent: PropTypes.object,
    buttonText: PropTypes.string,
  }).isRequired,

  author: PropTypes.exact({
    authorPhoto: PropTypes.object.isRequired,
    authorName: PropTypes.string.isRequired,
    authorRole: PropTypes.string.isRequired,
  }).isRequired,

  reviewer: PropTypes.exact({
    authorPhoto: PropTypes.object.isRequired,
    authorName: PropTypes.string.isRequired,
    authorRole: PropTypes.string.isRequired,
  }).isRequired,

  date: PropTypes.string.isRequired,
  viewsCount: PropTypes.string.isRequired,
  readingTime: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  postContent: PropTypes.array.isRequired,
  recaptcha: PropTypes.object,
}

Article.defaultProps = {
  recaptcha: null,
}

export default Article
