import styled from "@emotion/styled"

const ArticleContentText = styled.p`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 0;

  & + ul, 
  & + ol {
    margin-top: 0px;
  }

  & + div {
    margin-top: 20px;
  }

  &:first-child {
    margin-top: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > u {
    word-wrap: break-word;
  }
`

export default ArticleContentText
