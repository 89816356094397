import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ colorVariant, active, theme }) => css`
  ${active &&
  ` border-radius: 5px 0 0 5px; 
     background-color: ${
       colorVariant === "White" ? theme.palette.gray[50] : theme.palette.common.white
     };
  `}
`

const TechOneTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
    margin-bottom: 30px;
    padding-bottom: 7px;
    gap: 10px;
    display: flex;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    border: none;
    display: flex;
    border-right: 2px solid #38b148;
    margin-bottom: 0;

    padding-bottom: 20px;
    padding-top: 20px;
    gap: 20px;
    min-height: 90px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    border: none;
    display: flex;
    border-right: 2px solid #38b148;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 100px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    border: none;
    display: flex;
    border-right: 2px solid #38b148;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 113px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    ${dynamicStyle}
  }
`

export default TechOneTitleWrapper
