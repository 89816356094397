import styled from "@emotion/styled"

const ListFiveItemText = styled.div`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px;

  & > p:first-child {
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 29px;
  }
`

export default ListFiveItemText
