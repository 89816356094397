import styled from "@emotion/styled"

const HeroFormFormPrivacy = styled.div`
  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    padding-right: 18px;
    align-items: center;
    justify-content: space-between;
  }

  & > div:last-child {
    ${({ theme }) => theme.breakpoints.up("xl")} {
      display: flex;
      justify-content: flex-end;
    }
  }
`

export default HeroFormFormPrivacy
