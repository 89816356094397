import styled from "@emotion/styled"

const ListEightItemText = styled.div`
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    letter-spacing: 0.06em;
    font-weight: 600;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    letter-spacing: 0.06em;
    font-weight: 500;
  }
`

export default ListEightItemText
