import styled from "@emotion/styled"

const BlogListRecommendationsWrapper = styled.div`
  > div {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-right: unset;
      padding-left: unset;
    }
  }
`

export default BlogListRecommendationsWrapper
