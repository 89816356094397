import styled from "@emotion/styled"

const PostListItemSubtitle = styled.span`
  display: flex;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-wrap: wrap;
  }
`

export default PostListItemSubtitle
