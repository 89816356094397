import styled from "@emotion/styled"

const TextSixContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: grid;
    grid-template-rows: 1fr 1fr; 
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
  }
`

export default TextSixContainer
