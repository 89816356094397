import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ headingVariant, titleAlignment }) => css`
  ${(headingVariant === "Column" &&
    titleAlignment === "Left" &&
    "align-items: flex-start; flex-direction: column;") ||
  "align-items: center; flex-direction: column;"}
  ${headingVariant === "Row" && "flex-direction: row;"}
`

const TechOneHeader = styled.div`
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    gap: 70px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 70px;
    margin-bottom: 80px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 100px;
    margin-bottom: 100px;
  }

  ${dynamicStyle}
`
export default TechOneHeader
