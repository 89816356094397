import styled from "@emotion/styled"

const ListFifteenItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 30px;
  }
`

export default ListFifteenItemHeader
