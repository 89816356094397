import styled from "@emotion/styled"
import Title from "components/Title"

const TechOneCategoryTitle = styled(Title)`
  margin-bottom: 0 !important;
  margin-top: 0;
  font-family: ${({ theme }) => theme.typography.fontFamilyMontserrat};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 20px !important;
    line-height: 31px !important;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    padding-left: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    padding-left: 29px;
  }
`

export default TechOneCategoryTitle
