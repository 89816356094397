import styled from "@emotion/styled"

const ListEighteenContent = styled.div`
  z-index: 1;

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 524px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 655px;
  }
`

export default ListEighteenContent
