import styled from "@emotion/styled"

const ListFiveItemTitle = styled.span`
  display: block;
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    text-align: center;
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }
`

export default ListFiveItemTitle
