import styled from "@emotion/styled"

const TextOneSubtitle = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  margin: 0;

  @media screen and (min-width: 0px) and (max-width: 374.98px) {
    font-size: 30px;
    line-height: 30px;
  }

  @media screen and (min-width: 375px) and (max-width: 991.98px) {
    font-size: 34px;
    line-height: 34px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 49px;
    line-height: 49px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 64px;
    line-height: 64px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 80px;
    line-height: 80px;
  }
`

export default TextOneSubtitle
