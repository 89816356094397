import styled from "@emotion/styled"

const TextSixContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 257px;
    ${({order}) => order ? "order: -1" : "order: 1"};
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
    min-height: 388px;
    width: 50%;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 120px;
    padding-bottom: 120px;
    min-height: 485px;
    width: 50%;
  }
`

export default TextSixContent
