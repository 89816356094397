import styled from "@emotion/styled"

const TechItem = styled.div`
  text-align: center;
  text-decoration: none;
  color: inherit;

  ${({ theme }) => theme.breakpoints.down("xl")} {
  }
`

export default TechItem
