import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListEighteenDescription = styled.div`
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    position: relative;
    justify-content: center;
  }
`

export default ListEighteenDescription
