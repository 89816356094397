import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import Image from "components/Image"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"

import ListFourWrapper from "components/ListFour/ListFourWrapper"
import ListFourHeader from "components/ListFour/ListFourHeader"
import ListFourTitle from "components/ListFour/ListFourTitle"
import ListFourText from "components/ListFour/ListFourText"
import ListFourStack from "components/ListFour/ListFourStack"
import ListFourItem from "components/ListFour/ListFourItem"
import ListFourItemImageWrapper from "components/ListFour/ListFourItemImageWrapper"
import ListFourItemContent from "components/ListFour/ListFourItemContent"
import ListFourItemTitle from "components/ListFour/ListFourItemTitle"
import ListFourItemText from "components/ListFour/ListFourItemText"
import ListFourItemLink from "components/ListFour/ListFourItemLink"
import UnstyledLink from "components/UnstyledLink"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListFour = ({
  title,
  titleTag,
  theme: colorVariant,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListFourWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListFourHeader>
        <ListFourTitle as={titleTag}>{title}</ListFourTitle>
        {subtitleText && (
          <ListFourText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListFourHeader>
      <ListFourStack lowerText={lowerText}>
        {listItems.map(({ id, icon, title: cardTitle, text, buttonTitle, url }) => (
          <ListFourItem key={id}>
            <ListFourItemImageWrapper as={url ? UnstyledLink : "div"} to={url}>
              <Image {...icon} />
            </ListFourItemImageWrapper>
            <ListFourItemContent colorVariant={colorVariant}>
              {url ? (
                <UnstyledLink to={url}>
                  <ListFourItemTitle>{cardTitle}</ListFourItemTitle>
                </UnstyledLink>
              ) : (
                <ListFourItemTitle>{cardTitle}</ListFourItemTitle>
              )}
              {text && (
                <ListFourItemText
                  dangerouslySetInnerHTML={{
                    __html: htmlFormatting(text?.childMarkdownRemark?.html),
                  }}
                />
              )}
              <ListFourItemLink to={url}>{buttonTitle}</ListFourItemLink>
            </ListFourItemContent>
          </ListFourItem>
        ))}
      </ListFourStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListFourWrapper>
)
ListFour.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      title: PropTypes.string,
      text: PropTypes.object,
      buttonTitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
}

ListFour.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListFour
