import styled from "@emotion/styled"
import Title from "components/Title"
import { css } from "@emotion/react"

const dynamicStyle = ({ colorVariant, theme, align, titleSize }) => css`
  text-align: ${align};
  color: ${(colorVariant === "Dark" && theme.palette.common.white) || theme.palette.common.black};

  ${theme.breakpoints.down("lg")} {
    font-size: 36px;
  }

  ${theme.breakpoints.between("lg", "2xl")} {
    font-size: 45px;
  }

  ${theme.breakpoints.up("2xl")} {
    font-size: ${(titleSize === "Large" && "72px") || "60px"};
  }
`

const TextsTitle = styled(Title)`
  margin-top: 0;
  line-height: 1.1;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 60px;
  }

  ${dynamicStyle}
`

export default TextsTitle
