import styled from "@emotion/styled"

const RelatedArticlesControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 60px;
  }
`

export default RelatedArticlesControls
