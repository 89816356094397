import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListEightStack = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    justify-content: center;
    gap: 29px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    gap: 71px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 51px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 71px;
  }
`

export default ListEightStack
