import styled from "@emotion/styled"

const BlogListPostContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 13px;

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    max-width: 478px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "sxl")} {
    max-width: 597px;
  }

  ${({ theme }) => theme.breakpoints.between("sxl", "2xl")} {
    max-width: 747px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 747px;
  }
`

export default BlogListPostContent
