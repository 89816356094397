import styled from "@emotion/styled"

const CaseStudyWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: grid;
    grid-template-columns: 49% 46.5%;
    justify-content: space-between;
    align-items: center;
  }
`

export default CaseStudyWrapper
