import styled from "@emotion/styled"

const ArticleShareTitle = styled.span`
  display: block;
  font-weight: 600;
  text-transform: capitalize;
  color: #1c1c1c;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 10px;
  }
`

export default ArticleShareTitle
