const Facebook = () => (
  <svg width="13" height="27" viewBox="0 0 13 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.50158 26.1115V13.0542H12.106L12.5836 8.5546H8.50158L8.5077 6.30251C8.5077 5.12894 8.61921 4.50012 10.3048 4.50012H12.5581V0H8.95321C4.62316 0 3.0991 2.18279 3.0991 5.85357V8.55511H0.400024V13.0547H3.0991V26.1115H8.50158Z"
      fill="#ffffff"
    />
  </svg>
)

export default Facebook
