const QuotesIcon = () => (
  <svg
    width="192"
    height="151"
    viewBox="0 0 192 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.36243 135.728C18.5414 127.841 31.2125 118.663 40.3758 108.195C49.5392 97.727 54.8367 85.0361 56.2685 70.1225H44.2416C35.3647 70.1225 28.1342 69.1187 22.5503 67.1111C16.9664 65.1035 12.4564 62.3072 9.02014 58.7222C5.72708 55.2806 3.36465 51.4805 1.93289 47.3219C0.644302 43.1633 0 38.8613 0 34.416C0 25.2384 3.651 17.208 10.953 10.3248C18.3982 3.4416 27.132 0 37.1544 0C52.9038 0 65.0022 5.23409 73.4497 15.7023C81.8971 26.1705 86.1208 40.6538 86.1208 59.1524C86.1208 76.3604 78.8188 93.7118 64.2148 111.207C49.7539 128.701 32.2864 141.966 11.8121 151L2.36243 135.728ZM108.242 135.728C124.421 127.841 137.092 118.663 146.255 108.195C155.418 97.727 160.716 85.0361 162.148 70.1225H150.121C141.244 70.1225 134.013 69.1187 128.43 67.1111C122.846 65.1035 118.336 62.3072 114.899 58.7222C111.606 55.2806 109.244 51.4805 107.812 47.3219C106.523 43.1633 105.879 38.8613 105.879 34.416C105.879 25.2384 109.53 17.208 116.832 10.3248C124.277 3.4416 133.011 0 143.034 0C158.783 0 170.881 5.23409 179.329 15.7023C187.776 26.1705 192 40.6538 192 59.1524C192 76.3604 184.698 93.7118 170.094 111.207C155.633 128.701 138.166 141.966 117.691 151L108.242 135.728Z"
      fill="#F8F8FB"
    />
  </svg>
)

export default QuotesIcon
