import styled from "@emotion/styled"

const PostEventsEventsEvent = styled.div`
  flex: 0 0 100%;

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    min-height: 300px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    min-height: 316px;
  }
`

export default PostEventsEventsEvent
