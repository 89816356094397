import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[50]};
`

const ListFiveItemWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 20px 16px 69px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    padding: 60px 26px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    border-radius: 0 0 20px 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 100%;
    border-radius: 0 20px 20px 0;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding: 48px 27px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding: 60px 34px;
  }

  ${dynamicStyle}
`

export default ListFiveItemWrapper
