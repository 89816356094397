import styled from "@emotion/styled"
import Image from "components/Image"

const BlogListRecommendationsItemImage = styled(Image)`
  width: 101px;
  height: 59px;
  border-radius: 12px;
  overflow: hidden;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export default BlogListRecommendationsItemImage
