import styled from "@emotion/styled"

const CaseStudiesTitle = styled.h1`
  display: inline-flex;
  justify-content: flex-end;

  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  font-weight: bold;
  color: ${({ theme }) => theme.palette.gray["900"]};

  margin-top: 0;
  max-width: max-content;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 23px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    margin-bottom: 0;
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 0;
    font-size: 60px;
  }
`

export default CaseStudiesTitle
