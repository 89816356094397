import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText
    ? `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `
    : `
    margin-bottom: 0;
  `}
`

const ListSevenStack = styled.ul`
  padding: 0;
  list-style-type: none;
  margin-top: 0;
  max-width: 1620px;
  ${dinamicStyles}
`

export default ListSevenStack
