import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ error, touched, theme }) => css`
  ${touched &&
  (error
    ? `
      border: 2px solid red;

    ${theme.breakpoints.down("lg")} {
    padding-left: 19px;
    padding-bottom: 2px;
    padding-top: 8px;

    &:not(textarea) {
      padding-top: 2px;
    }
  }

  ${theme.breakpoints.up("lg")} {
    padding-top: 14px;
    padding-left: 19px;
    padding-bottom: 14px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    padding-top: 14px;
    padding-left: 19px;
    padding-bottom: 14px;
  }
    `
    : `
      border: 2px solid ${theme.palette.primary.main};

            ${theme.breakpoints.down("lg")} {
    padding-left: 19px;
    padding-bottom: 2px;
    padding-top: 8px;

    &:not(textarea) {
      padding-top: 2px;
    }
  }

  ${theme.breakpoints.up("lg")} {
    padding-top: 14px;
    padding-left: 19px;
    padding-bottom: 14px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    padding-top: 14px;
    padding-left: 19px;
    padding-bottom: 14px;
  }
    `)}
`

const CTAFormField = styled.input`
  font-size: 16px;
  line-height: 20px;
  background-color: #fff;
  border: none;
  width: 100%;
  padding-right: 15px;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 15px;
  resize: vertical;

  &::placeholder {
    color: #b2b2b2;
  }

  &:focus-visible {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-left: 20px;
    padding-bottom: 3px;
    padding-top: 9px;

    &:not(textarea) {
      height: 44px;
      padding-top: 3px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
    &:not(textarea) {
      height: 50px;
    }
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 5%;
    row-gap: 33px;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
    &:not(textarea) {
      height: 50px;
    }
  }

  ${dynamicStyle}
`

export default CTAFormField
