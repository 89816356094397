import styled from "@emotion/styled"

const ListThreeContent = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 31px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: 30px;
    width: 30%;
    flex: 1 1 30%;
  }
`

export default ListThreeContent
