import styled from "@emotion/styled"

const ListNineItemContainer = styled.div`
  max-width: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 42px;
    padding-bottom: 40px;
    padding-left: 16px;
    padding-right: 16px;
    gap: 20px;
  }
  ${({ theme }) => theme.breakpoints.between("md", "3xl")} {
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 64px;
    padding-right: 64px;
    min-height: 421px;
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    padding-top: 76px;
    padding-bottom: 76px;
    padding-left: 80px;
    padding-right: 80px;
    min-height: 421px;
    gap: 40px;
  }
`

export default ListNineItemContainer
