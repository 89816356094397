import styled from "@emotion/styled"

const ListEighteenText = styled.div`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 7px;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 34px;
  }
`

export default ListEighteenText
