import styled from "@emotion/styled"

const ListFifteenItemText = styled.div`
  font-size: 18px;

  p {
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    line-height: 35px;
    max-width: 300px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    line-height: 35px;
    max-width: 370px;
  }
`

export default ListFifteenItemText
