import styled from "@emotion/styled"

const PostEventsEventTitle = styled.p`
  line-height: 34px;
  font-weight: 600;
  margin-top: 0;

  color: ${({ theme }) => theme.palette.common.white};
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 0;
    flex: 0 0 65%;
  }

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }
`

export default PostEventsEventTitle
