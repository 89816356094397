import PropTypes from "prop-types"
import * as React from "react"
import * as yup from "yup"
import { useFormik } from "formik"
import Arrow from "components/Icons/Arrow"
import HeroSectionWrapper from "components/Hero/HeroSectionWrapper"
import HeroWrapper from "components/Hero/HeroWrapper"
import Breadcrumbs from "components/Breadcrumbs"
import HeroBreadcrumbsLink from "components/Hero/HeroBreadcrumbsLink"
import HeroTextBeforeTitle from "components/Hero/HeroTextBeforeTitle"
import HeroImageContainer from "components/Hero/HeroImageContainer"
import HeroBackgroundImage from "components/Hero/HeroBackgroundImage"
import HeroCaseInfo from "components/HeroCase/HeroCaseInfo"
import HeroCaseLinkClutch from "components/HeroCase/HeroCaseLinkClutch"
import HeroFormContainer from "components/HeroForm/HeroFormContainer"
import HeroFormForm from "components/HeroForm/HeroFormForm"
import HeroFormFormWrapper from "components/HeroForm/HeroFormFormWrapper"
import HeroFormFormHeader from "components/HeroForm/HeroFormFormHeader"
import HeroFormFormField from "components/HeroForm/HeroFormFormField"
import HeroFormFormFieldWrapper from "components/HeroForm/HeroFormFormFieldWrapper"
import HeroFormFormFieldName from "components/HeroForm/HeroFormFormFieldName"
import HeroFormFormText from "components/HeroForm/HeroFormFormText"
import HeroFormFormLead from "components/HeroForm/HeroFormFormLead"
import HeroFormFormTitle from "components/HeroForm/HeroFormFormTitle"
import HeroFormFormSubtitle from "components/HeroForm/HeroFormFormSubtitle"
import HeroFormFormFooter from "components/HeroForm/HeroFormFormFooter"
import HeroFormFormPrivacy from "components/HeroForm/HeroFormFormPrivacy"
import HeroFormFormPrivacyText from "components/HeroForm/HeroFormFormPrivacyText"
import HeroFormFormPrivacyTextLink from "components/HeroForm/HeroFormFormPrivacyTextLink"
import HeroFormFormDenyButton from "components/HeroForm/HeroFormFormDenyButton"
import HeroFormFormImage from "components/HeroForm/HeroFormFormImage"
import HeroFormContent from "components/HeroForm/HeroFormContent"
import HeroFormText from "components/HeroForm/HeroFormText"
import HeroFormTitle from "components/HeroForm/HeroFormTitle"
import HeroFormSpinnerWrapper from "components/HeroForm/HeroFormSpinnerWrapper"
import FormFinalImage from "components/Icons/FormFinalImage"
import HeroFormFinalWrapper from "components/HeroForm/HeroFormFinalWrapper"
import HeroFormFinalText from "components/HeroForm/HeroFormFinalText"
import HeroFormFinalBtn from "components/HeroForm/HeroFormFinalBtn"
import HeroFormSpinner from "components/HeroForm/HeroFormSpinner"
import htmlFormatting from "utils/htmlFormatting"
import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"
import Button from "components/Buttons/Button"

import pushGTMEvent from "utils/pushGTMEvent"
import useUserId from "../hooks/useUserId"

const mainValidationSchema = yup.object({
  formId: yup.string("Form Id").required("Form Id is required"),
  name: yup.string("Full Name").required("Name is required"),
  company: yup.string("Full Name").required("Company is required"),
  email: yup.string("Email").email("Enter a valid email").required("Email is required"),
  phone: yup.string("Phone").required("Phone is required"),
  message: yup.string("About project").required("Message is required"),
})

const secondValidationSchema = yup.object({
  formId: yup.string("Form Id").required("Form Id is required"),
  message: yup.string("About project").required("Message is required"),
})

const HeroForm = ({
  breadcrumbs,
  textBeforeTitle,
  textBeforeTitleTag,
  addQuotes,
  title,
  titleTag,
  heroText,
  backgroundImage,
  buttonText,
  clutchRating,
  clutchText,
  clutchLink,
  formId,
  formPlaceholderName,
  formPlaceholderCompany,
  formPlaceholderPhone,
  formPlaceholderEmail,
  formPlaceholderMessage,
  secondFormId,
  secondFormPlaceholderAnswer,
  formAgreementText,
  formAgreementLink,
  formAgreementLinkText,
  secondFormImage,
  secondFormTitle,
  secondFormSubtitle,
  secondFormText1,
  secondFormText2,
  secondFormLead,
  secondFormDenyButtonText,
  secondFormSendButtonText,
  finalStageBtnText,
  finalStageText,
  recaptcha,
}) => {
  const [showSecondForm, setShowSecondForm] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [showThirdStep, setShowThirdStep] = React.useState(false)
 
  const userID = useUserId()

  const mainForm = useFormik({
    initialValues: {
      formId,
      userID,
      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",
    },
    isInitialValid: false,
    validationSchema: mainValidationSchema,
  })

  const secondForm = useFormik({
    initialValues: {
      formId: secondFormId,
      userID,
      message: "",
    },
    isInitialValid: false,
    validationSchema: secondValidationSchema,
  })

  const [prevMainFormState, setPrevMainFormState] = React.useState(mainForm.initialValues)
  const [prevSecondFormState, setPrevSecondFormState] = React.useState(secondForm.initialValues)

  const showSendingSpinnerHandler = () => {
    setSending(!sending)
  }

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!recaptcha) {
      // eslint-disable-next-line no-console
      console.log("Execute recaptcha not yet available")
      return
    }
    if (typeof window !== "undefined") {

      try {
        showSendingSpinnerHandler()
        const token = await recaptcha.execute("submit")
        const response = await window
        .fetch(`/api/recaptcha-check`, {
          method: "POST",
          body: JSON.stringify({
            token,
          }),
        })
        .then((res) => res.json())
        // eslint-disable-next-line consistent-return
        return response.success && response.score > 0.5
      } catch (error) {
        showSendingSpinnerHandler()
        // eslint-disable-next-line consistent-return
        return false
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptcha])

  const showSecondFormHandler = () => {
    setShowSecondForm(!showSecondForm)
  }

  const showThirdStepHandler = () => {
    setShowThirdStep(!showThirdStep)
  }

  return (
    <HeroSectionWrapper>
      <HeroWrapper backgroundImage={backgroundImage} addQuotes={addQuotes}>
        <HeroFormContainer>
          <HeroFormContent>
            {breadcrumbs && (
              <MicromarkingWrapper itemscope="" itemtype="http://schema.org/BreadcrumbList">
                <Breadcrumbs
                  itemprop="itemListElement"
                  itemscope=""
                  itemtype="http://schema.org/ListItem"
                >
                  {breadcrumbs.map((el, idx) => (
                    <>
                      {breadcrumbs.length - 1 !== idx && (
                        <>
                          <HeroBreadcrumbsLink
                            itemprop="item"
                            isActive={breadcrumbs.length - 1 === idx}
                            to={el.link}
                            key={el.id}
                          >
                            <Meta itemprop="name" content={el.title} />
                            <Meta itemprop="position" content={idx + 1} />
                            {el.title}
                          </HeroBreadcrumbsLink>
                          <Arrow
                            key={el.link}
                            direction="left"
                            color="#BBBBBB"
                            width={14}
                            height={12}
                          />
                        </>
                      )}
                      {breadcrumbs.length - 1 === idx && (
                        <HeroBreadcrumbsLink key={el.id} isActive={breadcrumbs.length - 1 === idx}>
                          {el.title}
                        </HeroBreadcrumbsLink>
                      )}
                    </>
                  ))}
                </Breadcrumbs>
              </MicromarkingWrapper>
            )}
            {textBeforeTitle && (
              <HeroTextBeforeTitle as={textBeforeTitleTag}>{textBeforeTitle}</HeroTextBeforeTitle>
            )}
            <HeroFormTitle backgroundImage={backgroundImage} addQuotes={addQuotes} as={titleTag}>
              {(addQuotes ? "“‎‎" : "") + title + (addQuotes ? "”‎" : "")}
            </HeroFormTitle>
            {heroText && (
              <HeroFormText
                backgroundImage={backgroundImage}
                addQuotes={addQuotes}
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(heroText?.childMarkdownRemark?.html),
                }}
              />
            )}
            {(clutchLink && (
              <HeroCaseLinkClutch to={clutchLink}>
                <HeroCaseInfo text={clutchText || ""} rating={clutchRating} />
              </HeroCaseLinkClutch>
            )) || <HeroCaseInfo text={clutchText || ""} rating={clutchRating} />}
          </HeroFormContent>

          <HeroImageContainer backgroundImage={backgroundImage} addQuotes={addQuotes}>
            {backgroundImage !== "None" && (
              <HeroBackgroundImage backgroundImage={backgroundImage} />
            )}
          </HeroImageContainer>

          {!showSecondForm && !showThirdStep && (
            <HeroFormFormWrapper
              formName="HeroMain"
              postSubmit={() => {
                showSecondFormHandler()
                setSending(false)
                mainForm.resetForm()
              }}
              preSubmit={handleReCaptchaVerify}
              formValues={mainForm.values}
              sending={sending}
            >
              <HeroFormForm variant="First">
                <HeroFormFormField display type="text" key="formId" name="formId" />
                <HeroFormFormField display type="text" key="userID" name="userID" />
                <HeroFormFormFieldWrapper>
                  <HeroFormFormFieldName>
                    {(formPlaceholderName && `${formPlaceholderName}`) || "Full name "}
                    <span>*</span>
                  </HeroFormFormFieldName>
                  <HeroFormFormField
                    error={mainForm.errors.name}
                    onChange={mainForm.handleChange}
                    value={mainForm.values.name}
                    type="text"
                    key="name"
                    name="name"
                    required
                    touched={mainForm.touched?.name}
                    onFocus={() => {
                      mainForm.setFieldTouched("name", true, true)
                    }}
                    onBlur={() => {
                      if (
                        mainForm.values.name !== "" &&
                        prevMainFormState.name !== mainForm.values.name
                      )
                        setPrevMainFormState(mainForm.values)
                      if (
                        mainForm.values.name !== "" &&
                        !mainForm.errors.name &&
                        prevMainFormState.name !== mainForm.values.name
                      )
                        pushGTMEvent("heroFormName", mainForm.values.name)
                    }}
                  />
                </HeroFormFormFieldWrapper>
                <HeroFormFormFieldWrapper>
                  <HeroFormFormFieldName>
                    {formPlaceholderCompany || "Company"}
                  </HeroFormFormFieldName>
                  <HeroFormFormField
                    onChange={mainForm.handleChange}
                    value={mainForm.values.company}
                    type="text"
                    key="company"
                    name="company"
                    touched={mainForm.touched?.company}
                    onFocus={() => {
                      mainForm.setFieldTouched("company", true, true)
                    }}
                    onBlur={() => {
                      if (
                        mainForm.values.company !== "" &&
                        prevMainFormState.company !== mainForm.values.company
                      )
                        setPrevMainFormState(mainForm.values)
                      if (
                        mainForm.values.company !== "" &&
                        !mainForm.errors.company &&
                        prevMainFormState.company !== mainForm.values.company
                      )
                        pushGTMEvent("heroFormCompany", mainForm.values.company)
                    }}
                  />
                </HeroFormFormFieldWrapper>
                <HeroFormFormFieldWrapper>
                  <HeroFormFormFieldName>
                    {(formPlaceholderEmail && `${formPlaceholderEmail}`) || "Email address "}
                    <span>*</span>
                  </HeroFormFormFieldName>
                  <HeroFormFormField
                    error={mainForm.errors.email}
                    onChange={mainForm.handleChange}
                    value={mainForm.values.email}
                    type="email"
                    key="email"
                    name="email"
                    required
                    touched={mainForm.touched?.email}
                    onFocus={() => {
                      mainForm.setFieldTouched("email", true, true)
                    }}
                    onBlur={() => {
                      if (
                        mainForm.values.email !== "" &&
                        prevMainFormState.email !== mainForm.values.email
                      )
                        setPrevMainFormState(mainForm.values)
                      if (
                        mainForm.values.email !== "" &&
                        !mainForm.errors.email &&
                        prevMainFormState.email !== mainForm.values.email
                      )
                        pushGTMEvent("heroFormEmail", mainForm.values.email)
                    }}
                  />
                </HeroFormFormFieldWrapper>
                <HeroFormFormFieldWrapper>
                  <HeroFormFormFieldName>
                    {formPlaceholderPhone || "Phone number"}
                  </HeroFormFormFieldName>
                  <HeroFormFormField
                    onChange={mainForm.handleChange}
                    value={mainForm.values.phone}
                    type="tel"
                    key="phone"
                    name="phone"
                    touched={mainForm.touched?.phone}
                    onFocus={() => {
                      mainForm.setFieldTouched("phone", true, true)
                    }}
                    onBlur={() => {
                      if (
                        mainForm.values.phone !== "" &&
                        prevMainFormState.phone !== mainForm.values.phone
                      )
                        setPrevMainFormState(mainForm.values)
                      if (
                        mainForm.values.phone !== "" &&
                        !mainForm.errors.phone &&
                        prevMainFormState.phone !== mainForm.values.phone
                      )
                        pushGTMEvent("heroFormPhone", mainForm.values.phone)
                    }}
                  />
                </HeroFormFormFieldWrapper>
                <HeroFormFormFieldWrapper>
                  <HeroFormFormFieldName>
                    {(formPlaceholderMessage && `${formPlaceholderMessage}`) || "About project "}
                    <span>*</span>
                  </HeroFormFormFieldName>
                  <HeroFormFormField
                    as="textarea"
                    rows="5"
                    error={mainForm.errors.message}
                    onChange={mainForm.handleChange}
                    value={mainForm.values.message}
                    type="text"
                    key="message"
                    name="message"
                    required
                    touched={mainForm.touched?.message}
                    onFocus={() => {
                      mainForm.setFieldTouched("message", true, true)
                    }}
                    onBlur={() => {
                      if (
                        mainForm.values.message !== "" &&
                        prevMainFormState.message !== mainForm.values.message
                      )
                        setPrevMainFormState(mainForm.values)

                      if (
                        mainForm.values.message !== "" &&
                        !mainForm.errors.message &&
                        prevMainFormState.message !== mainForm.values.message
                      )
                        pushGTMEvent("heroFormMessage", mainForm.values.message)
                    }}
                  />
                </HeroFormFormFieldWrapper>
              </HeroFormForm>
              <HeroFormFormPrivacy>
                <HeroFormFormPrivacyText>
                  {formAgreementText}
                  <HeroFormFormPrivacyTextLink target="_blank" to={formAgreementLink}>
                    {formAgreementLinkText}
                  </HeroFormFormPrivacyTextLink>
                </HeroFormFormPrivacyText>
                <div>
                  <Button type="submit" variant="contained">
                    {buttonText}
                  </Button>
                </div>
              </HeroFormFormPrivacy>
            </HeroFormFormWrapper>
          )}

          {(typeof window === "undefined" || showSecondForm) && (
            <HeroFormFormWrapper
              formName="HeroFeedback"
              postSubmit={() => {
                secondForm.resetForm()
                showThirdStepHandler()
                showSecondFormHandler()
                setSending(false)
              }}
              formValues={secondForm.values}
              preSubmit={handleReCaptchaVerify}
              show={showSecondForm}
              sending={sending}
            >
              <HeroFormFormHeader>
                <HeroFormFormImage {...secondFormImage} />
                <div>
                  <HeroFormFormTitle>{secondFormTitle}</HeroFormFormTitle>
                  <HeroFormFormSubtitle>{secondFormSubtitle}</HeroFormFormSubtitle>
                  <HeroFormFormText>{secondFormText1}</HeroFormFormText>
                </div>
              </HeroFormFormHeader>
              <HeroFormFormText>{secondFormText2}</HeroFormFormText>
              <HeroFormFormLead>{secondFormLead}</HeroFormFormLead>
              <HeroFormForm variant="Second">
                <HeroFormFormField display type="text" key="formId" name="formId" />
                <HeroFormFormField display type="text" key="userID" name="userID" />
                <HeroFormFormField
                  error={secondForm.errors.message}
                  onChange={secondForm.handleChange}
                  value={secondForm.values.message}
                  type="text"
                  key="message"
                  name="message"
                  placeholder={secondFormPlaceholderAnswer || "Your answer"}
                  required
                  touched={secondForm.touched?.message}
                  onFocus={() => {
                    secondForm.setFieldTouched("message", true, true)
                  }}
                  onBlur={() => {
                    if (
                      secondForm.values.message !== "" &&
                      prevSecondFormState.message !== secondForm.values.message
                    )
                      setPrevSecondFormState(secondForm.values)
                  }}
                />
              </HeroFormForm>
              <HeroFormFormFooter>
                <HeroFormFormDenyButton
                  onClick={() => {
                    secondForm.resetForm()
                    showSecondFormHandler()
                    showThirdStepHandler()
                  }}
                >
                  {secondFormDenyButtonText}
                </HeroFormFormDenyButton>
                <Button type="submit" variant="contained">
                  {secondFormSendButtonText}
                </Button>
              </HeroFormFormFooter>
            </HeroFormFormWrapper>
          )}

          {sending && (
            <HeroFormSpinnerWrapper>
              <HeroFormSpinner size={125} thickness={100} speed={100} color="#36ad47" />
            </HeroFormSpinnerWrapper>
          )}
          {showThirdStep && (
            <HeroFormFinalWrapper>
              <FormFinalImage />
              <HeroFormFinalText
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(finalStageText?.childMarkdownRemark?.html),
                }}
              />
              <HeroFormFinalBtn onClick={showThirdStepHandler}>
                {finalStageBtnText || "Close"}
              </HeroFormFinalBtn>
            </HeroFormFinalWrapper>
          )}
        </HeroFormContainer>
      </HeroWrapper>
    </HeroSectionWrapper>
  )
}

HeroForm.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  formId: PropTypes.string.isRequired,
  formPlaceholderName: PropTypes.string.isRequired,
  formPlaceholderCompany: PropTypes.string.isRequired,
  formPlaceholderPhone: PropTypes.string.isRequired,
  formPlaceholderEmail: PropTypes.string.isRequired,
  formPlaceholderMessage: PropTypes.string.isRequired,
  secondFormId: PropTypes.string.isRequired,
  secondFormPlaceholderAnswer: PropTypes.string.isRequired,
  textBeforeTitle: PropTypes.string,
  textBeforeTitleTag: PropTypes.string.isRequired,
  addQuotes: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  heroText: PropTypes.object.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  clutchRating: PropTypes.string.isRequired,
  clutchText: PropTypes.string.isRequired,
  clutchLink: PropTypes.string.isRequired,
  formAgreementText: PropTypes.string.isRequired,
  formAgreementLink: PropTypes.string.isRequired,
  formAgreementLinkText: PropTypes.string.isRequired,
  secondFormImage: PropTypes.object.isRequired,
  secondFormTitle: PropTypes.string.isRequired,
  secondFormSubtitle: PropTypes.string.isRequired,
  secondFormText1: PropTypes.string.isRequired,
  secondFormText2: PropTypes.string.isRequired,
  secondFormLead: PropTypes.string.isRequired,
  secondFormDenyButtonText: PropTypes.string.isRequired,
  secondFormSendButtonText: PropTypes.string.isRequired,
  finalStageText: PropTypes.object.isRequired,
  finalStageBtnText: PropTypes.string.isRequired,
  recaptcha: PropTypes.object,
}

HeroForm.defaultProps = {
  textBeforeTitle: null,
  recaptcha: null,
}

export default HeroForm
