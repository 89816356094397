import styled from "@emotion/styled"
import Image from "components/Image"

const ArticleAuthorPhoto = styled(Image)`
  background-color: #e5e5e5;
  overflow: hidden;
  border-radius: 50% !important;
  display: inline-block;
  position: relative;
  z-index: 4;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 42px;
    height: 42px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    width: 42px;
    height: 42px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 42px;
    height: 42px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 42px;
    height: 42px;
  }
`

export default ArticleAuthorPhoto
