import PropTypes from "prop-types"
import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import Breadcrumbs from "components/Breadcrumbs"
import BreadcrumbsItem from "components/BreadcrumbsBlogArticle/BreadcrumbsItem"
import BlogListCategoryStack from "components/BlogList/BlogListCategoryStack"
import BlogListCategoryItem from "components/BlogList/BlogListCategoryItem"
import BlogListPostWrapper from "components/BlogList/BlogListPostWrapper"
import BlogListPostImage from "components/BlogList/BlogListPostImage"
import BlogListPostStack from "components/BlogList/BlogListPostStack"
import BlogListPostContent from "components/BlogList/BlogListPostContent"
import BlogListPostHeading from "components/BlogList/BlogListPostHeading"
import BlogListPostTitle from "components/BlogList/BlogListPostTitle"
import BlogListPostDate from "components/BlogList/BlogListPostDate"
import BlogListPostText from "components/BlogList/BlogListPostText"
import BlogListTitle from "components/BlogList/BlogListTitle"
import BlogListWrapper from "components/BlogList/BlogListWrapper"
import BlogListPaginationStack from "components/BlogList/BlogListPaginationStack"
import BlogListPaginationItem from "components/BlogList/BlogListPaginationItem"
import BlogListNavigationWrapper from "components/BlogList/BlogListNavigationWrapper"
import BlogListNavigationButton from "components/BlogList/BlogListNavigationButton"
import Container from "components/Container"
import Arrow from "components/Icons/Arrow"
import Link from "components/Link"
import htmlFormatting from "utils/htmlFormatting"

import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"

import CTAText from "./CTAText"
import BlogListRecommendations from "./BlogListRecommendations"

const BlogList = ({
  pageTitle,
  titleTag,
  breadcrumbs,
  link,
  currentPage,
  categories,
  articleSeparator,
  postsRecommendation,
  postItems,
  pageCount,
  locale,
}) => {
  const firstInit = React.useRef(false)
  const [arrOfCurrButtons, setArrOfCurrButtons] = React.useState([])
  const url = typeof window !== "undefined" && window.location.pathname.split("/")
  const numOfButtons = []

  for (let i = 1; i <= pageCount; i += 1) {
    numOfButtons.push(i)
  }

  let blogCategory = ""
  if (/^\d+$/.test(url[url.length - 2])) {
    blogCategory = url[url.length - 3]
  } else {
    blogCategory = url[url.length - 2]
  }

  const metaGenerator = (authorEl) => {
    if (authorEl) {
      return (
        <MicromarkingWrapper itemprop="author" itemscope="" itemtype="https://schema.org/Person">
          <Meta itemprop="name" content={authorEl.authorName} />
          <Meta itemprop="url" content={`${authorEl.link}/`} />
        </MicromarkingWrapper>
      )
    }

    return (
      <MicromarkingWrapper itemprop="author" itemscope="" itemtype="https://schema.org/Person">
        <Meta itemprop="url" content="https://adexin.com/" />
        <Meta itemprop="name" content="adexin" />
      </MicromarkingWrapper>
    )
  }

  const swiperConfig = {
    slidesPerView: "auto",
    spaceBetween: 10,
  }

  const prepareButtonsArray = () => {
    let tempNumberOfButtons = [...arrOfCurrButtons]

    const dots = "..."

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons
    } else if (+currentPage >= 1 && +currentPage <= 2) {
      tempNumberOfButtons = [1, 2, 3, dots, numOfButtons.length]
    } else if (+currentPage === 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dots, numOfButtons.length]
    } else if (+currentPage === 4) {
      const sliced = numOfButtons.slice(0, 5)
      tempNumberOfButtons = [...sliced, dots, numOfButtons.length]
    } else if (+currentPage > 4 && +currentPage < numOfButtons.length - 2) {
      const sliced1 = numOfButtons.slice(+currentPage - 2, +currentPage)
      const sliced2 = numOfButtons.slice(+currentPage, +currentPage + 1)
      tempNumberOfButtons = [1, dots, ...sliced1, ...sliced2, dots, numOfButtons.length]
    } else if (+currentPage > numOfButtons.length - 2) {
      const sliced = numOfButtons.slice(numOfButtons.length - 3)
      tempNumberOfButtons = [1, dots, ...sliced]
    } else if (+currentPage > numOfButtons.length - 3) {
      const sliced = numOfButtons.slice(numOfButtons.length - 4)
      tempNumberOfButtons = [1, dots, ...sliced]
    }

    setArrOfCurrButtons(tempNumberOfButtons)
  }

  if (firstInit.current === false) {
    prepareButtonsArray()
    firstInit.current = true
  }

  return (
    <BlogListWrapper>
      <Container>
        {breadcrumbs && (
          <MicromarkingWrapper itemscope="" itemtype="http://schema.org/BreadcrumbList">
            <Breadcrumbs
              itemprop="itemListElement"
              itemscope=""
              itemtype="http://schema.org/ListItem"
            >
              {breadcrumbs.map(
                ({ id: breadcrumbId, title: breadcrumbTitle, link: breadcrumbLink }, idx) => (
                  <>
                    {breadcrumbs.length - 1 !== idx && (
                      <>
                        <BreadcrumbsItem
                          itemprop="item"
                          key={breadcrumbId}
                          as={Link}
                          isActive={false}
                          to={breadcrumbLink}
                        >
                          <Meta itemprop="name" content={breadcrumbTitle} />
                          <Meta itemprop="position" content={idx + 1} />
                          {breadcrumbTitle}
                        </BreadcrumbsItem>
                        <Arrow
                          key={breadcrumbLink}
                          direction="left"
                          color="#BBBBBB"
                          width={14}
                          height={12}
                        />
                      </>
                    )}
                    {breadcrumbs.length - 1 === idx && (
                      <BreadcrumbsItem key={breadcrumbId} isActive={true}>
                        {breadcrumbTitle}
                      </BreadcrumbsItem>
                    )}
                  </>
                ),
              )}
            </Breadcrumbs>
          </MicromarkingWrapper>
        )}

        <BlogListCategoryStack>
          <Swiper {...swiperConfig}>
            {categories &&
              categories
                .sort((a, b) => +a.position - +b.position)
                .map(({ id: categoryId, title: categoryTitle, link: categoryUrl }) => {
                  return (
                    <SwiperSlide key={categoryId}>
                      <BlogListCategoryItem
                        active={blogCategory === categoryUrl.split("/").pop()}
                        to={categoryUrl}
                      >
                        {categoryTitle}
                      </BlogListCategoryItem>
                    </SwiperSlide>
                  )
                })}
          </Swiper>
        </BlogListCategoryStack>
      </Container>
      <BlogListRecommendations items={postsRecommendation} />
      <Container>
        <BlogListTitle as={titleTag}>{pageTitle}</BlogListTitle>
      </Container>
      <BlogListPostStack
        articleSeparatorTop={!!articleSeparator}
        articleSeparatorBottom={true}
        pt="60"
        pb={articleSeparator ? "160" : "40"}
      >
        {postItems &&
          postItems
            .slice(0, Math.ceil(postItems.length / 2))
            .map(
              ({
                id: postId,
                title: postTitle,
                coverImage: postImage,
                shortContent: postContent,
                date: postDate,
                link: postLink,
                author,
              }) => (
                <MicromarkingWrapper
                  key={postId}
                  itemscope=""
                  itemtype="https://schema.org/BlogPosting"
                >
                  <Meta itemprop="datePublished" content={postDate} />
                  <Meta itemprop="headline" content={postTitle} />
                  {metaGenerator(author)}

                  <BlogListPostWrapper itemprop="url" to={postLink} key={postId}>
                    <BlogListPostImage itemprop="image" {...postImage} objectFit="cover" />
                    <BlogListPostContent>
                      <BlogListPostHeading>
                        <BlogListPostTitle>{postTitle}</BlogListPostTitle>
                        <BlogListPostDate date={postDate} locale={locale} />
                      </BlogListPostHeading>
                      {postContent && postContent.childMarkdownRemark && (
                        <BlogListPostText
                          itemprop="description"
                          dangerouslySetInnerHTML={{
                            __html: htmlFormatting(postContent?.childMarkdownRemark?.html),
                          }}
                        />
                      )}
                    </BlogListPostContent>
                  </BlogListPostWrapper>
                </MicromarkingWrapper>
              ),
            )}
      </BlogListPostStack>
      {articleSeparator && postItems.length > 1 && <CTAText {...articleSeparator} />}
      <BlogListPostStack
        articleSeparatorTop={true}
        articleSeparatorBottom={!!articleSeparator}
        pt={articleSeparator ? "160" : "0"}
        pb="60"
      >
        {postItems &&
          postItems
            .slice(Math.ceil(postItems.length / 2))
            .map(
              ({
                id: postId,
                title: postTitle,
                coverImage: postImage,
                shortContent: postContent,
                date: postDate,
                link: postLink,
                author,
              }) => (
                <MicromarkingWrapper
                  key={postId}
                  itemscope=""
                  itemtype="https://schema.org/BlogPosting"
                >
                  <Meta itemprop="datePublished" content={postDate} />
                  <Meta itemprop="headline" content={postTitle} />
                  {metaGenerator(author)}

                  <BlogListPostWrapper itemprop="url" to={postLink}>
                    <BlogListPostImage itemprop="image" {...postImage} objectFit="cover" />
                    <BlogListPostContent>
                      <BlogListPostHeading>
                        <BlogListPostTitle>{postTitle}</BlogListPostTitle>
                        <BlogListPostDate date={postDate} locale={locale} />
                      </BlogListPostHeading>
                      {postContent && (
                        <BlogListPostText
                          itemprop="description"
                          dangerouslySetInnerHTML={{
                            __html: htmlFormatting(postContent.childMarkdownRemark.html),
                          }}
                        />
                      )}
                    </BlogListPostContent>
                  </BlogListPostWrapper>
                </MicromarkingWrapper>
              ),
            )}
      </BlogListPostStack>
      {pageCount > 1 && (
        <BlogListNavigationWrapper>
          {+currentPage !== 1 && (
            <BlogListNavigationButton to={+currentPage === 2 ? link : `${link}/${currentPage - 1}`}>
              <Arrow direction="left" color="#38B148" width={14} height={12} />
            </BlogListNavigationButton>
          )}
          <BlogListPaginationStack>
            {arrOfCurrButtons.map((el) => (
              <BlogListPaginationItem
                as={el !== "..." ? Link : "div"}
                isActive={+el === +currentPage}
                to={link + (el !== 1 ? `/${el}` : "")}
                key={el}
              >
                {el}
              </BlogListPaginationItem>
            ))}
          </BlogListPaginationStack>
          {+currentPage !== +pageCount && (
            <BlogListNavigationButton to={`${link}/${+currentPage + 1}`}>
              <Arrow direction="right" color="#38B148" width={14} height={12} />
            </BlogListNavigationButton>
          )}
        </BlogListNavigationWrapper>
      )}
    </BlogListWrapper>
  )
}

BlogList.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageTitle: PropTypes.string.isRequired,
  postsRecommendation: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  titleTag: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  articleSeparator: PropTypes.exact({
    theme: PropTypes.string,
    textBeforeTitle: PropTypes.string,
    titleTag: PropTypes.string,
    titleSize: PropTypes.string,
    title: PropTypes.string,
    textSize: PropTypes.string,
    text: PropTypes.string,
    buttonTitle: PropTypes.string,
    buttonLink: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  postItems: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      coverImage: PropTypes.string,
      category: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          link: PropTypes.string,
        }),
      ).isRequired,
      shortContent: PropTypes.string,
      buttonText: PropTypes.string,
      link: PropTypes.string,
      date: PropTypes.string,
    }),
  ).isRequired,
}

export default BlogList
