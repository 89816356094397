import styled from "@emotion/styled"

const ListFifteenItemEmail = styled.span`
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.07em;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 31px;
  }
  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 39px;
  }
`

export default ListFifteenItemEmail
