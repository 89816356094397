import * as React from "react"
import PropTypes from "prop-types"
import Seo from "components/Seo"
import { Global, ThemeProvider } from "@emotion/react"
import theme from "theme"
import modernNormalize from "styles/modernNormalize"
import reboot from "styles/reboot"
import fontGilroy from "styles/fontGilroy"
import fontGothamPro from "styles/fontGothamPro"
import CookieConsent from "components/CookieConsent"
import * as Sections from "sections"
import { load } from "recaptcha-v3"

import "swiper/css"
import "swiper/css/navigation"

const Template = ({ pageContext: { baseUrl, seo, sections, cookieConsent, recaptchaKey } }) => {
  const [recaptcha, setRecaptcha] = React.useState(null)

  const [cookie, setCookie] = React.useState(false)
  const [cookieAccept, setCookieAccept] = React.useState(false)

  const cookieCheck = () =>
    document.cookie
      .split(";")
      .map((el) => (el.trim() === "adexin-google-analytics=true" ? setCookieAccept(true) : el))
  
  React.useEffect(() => cookieCheck(), [])
  React.useEffect(() => cookieCheck(), [cookie])

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const asyncFunction = async () => {
        setRecaptcha(
          await load(recaptchaKey, {
            useRecaptchaNet: true,
            autoHideBadge: true,
          }),
        )
      }

      setTimeout(asyncFunction, 3500)
    }
  }, [recaptchaKey])

  React.useEffect(() => {
    const isActive = sessionStorage.getItem("ActiveSeccion")

    if (!isActive && cookieAccept) {
      sessionStorage.setItem("ActiveSeccion", true)
      const date = new Date(Date.now() + 7.863e9).toUTCString()
      const sourceSite = document.referrer || "direct"
      const currentUrlSearch = [...new URL(window.location.href).searchParams]
      if (sourceSite !== "direct" || !`${document.cookie}`.match(/sourceRefer=\S+/)) {
        document.cookie = `sourceRefer=${sourceSite}; path=/; expires=${date}`
      }

      if (currentUrlSearch && cookieAccept) {
        const utmMark = currentUrlSearch
          .filter((n) => n[0].startsWith("utm_"))
          .reduce((acc, el) => ({ ...acc, [el[0]]: el[1] }), {})

        if (Object.keys(utmMark).length > 0) {
          document.cookie = `utmSource=${utmMark.utm_source ?? "empty"}; path=/; expires=${date}`
          document.cookie = `utmMedium=${utmMark.utm_medium ?? "empty"}; path=/; expires=${date}`
          document.cookie = `utmCampaign=${
            utmMark.utm_campaign ?? "empty"
          }; path=/; expires=${date}`
        }
      }
    }
  }, [cookieAccept])

  return (
    <ThemeProvider theme={theme}>
      <Seo {...seo} />
      <Global styles={[modernNormalize, reboot, fontGilroy, fontGothamPro]} />
      <CookieConsent setCookie={setCookie} {...cookieConsent} />
      <div>
        {sections.map(({ id, component, ...props }) => {
          const Component = Sections[component]
          if (!Component) return null
          return (
            <Component
              cookie={cookie}
              key={id}
              baseUrl={baseUrl}
              recaptcha={recaptcha}
              {...props}
            />
          )
        })}
      </div>
    </ThemeProvider>
  )
}

Template.propTypes = {
  pageContext: PropTypes.exact({
    baseUrl: PropTypes.string,
    seo: PropTypes.object,
    cookieConsent: PropTypes.object,
    recaptchaKey: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        component: PropTypes.string,
      }),
    ),
  }).isRequired,
}

export default Template
