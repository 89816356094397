import styled from "@emotion/styled"

const ListFourItemImageWrapper = styled.div`
  background-color: #efeff6;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 47.9%;
    border-radius: 20px 0 0 20px;
  }

  & > div {
    width: 100%;

    & > svg {
      width: 100%;
    }
  }
`

export default ListFourItemImageWrapper
