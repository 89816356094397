import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, isFullWidth = false }) => css`
  ${isFullWidth &&
  `
    ${theme.breakpoints.up("xl")} {
      display: none;
    }
  `}
`

const CertificationButton = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 18px;
  margin-top: 17px;
  border: 0;
  background-color: inherit;
  padding: 7px 0;
  cursor: pointer;

  ${dynamicStyle}
`

export default CertificationButton
