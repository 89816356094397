import * as React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListThreeWrapper from "components/ListThree/ListThreeWrapper"
import ListThreeContainer from "components/ListThree/ListThreeContainer"
import ListThreeContent from "components/ListThree/ListThreeContent"
import ListThreeTitle from "components/ListThree/ListThreeTitle"
import ListThreeText from "components/ListThree/ListThreeText"
import ListThreeItems from "components/ListThree/ListThreeItems"
import ListThreeSlider from "components/ListThree/ListThreeSlider"
import ListThreeItem from "components/ListThree/ListThreeItem"
import ListThreeItemImage from "components/ListThree/ListThreeItemImage"
import ListThreeItemTitle from "components/ListThree/ListThreeItemTitle"
import ListThreeItemText from "components/ListThree/ListThreeItemText"

import htmlFormatting from "utils/htmlFormatting"
import useMedia from "../hooks/useMedia"
import useHasMounted from "../hooks/useHasMounted"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListThree = ({
  title,
  titleTag,
  theme: colorVariant,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => {
  const showSwiper = useMedia(["(min-width: 1536px)"], [false], true)
  const swiperConfig = {
    slidesPerView: "auto",
    spaceBetween: 30,
  }
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }
  return (
    <ListThreeWrapper id={sectionId} colorVariant={colorVariant}>
      <ListThreeContainer lowerText={lowerText}>
        <ListThreeContent>
          <ListThreeTitle as={titleTag}>{title}</ListThreeTitle>
          {subtitleText && (
            <ListThreeText
              dangerouslySetInnerHTML={{
                __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
              }}
            />
          )}
        </ListThreeContent>
        <ListThreeItems>
          <ListThreeSlider>
            {showSwiper ? (
              <Swiper {...swiperConfig}>
                {listItems.map(({ id, icon, title: itemTitle, text }) => (
                  <SwiperSlide key={id}>
                    <ListThreeItem>
                      <ListThreeItemImage {...icon} />
                      <ListThreeItemTitle>{itemTitle}</ListThreeItemTitle>
                      {text && (
                        <ListThreeItemText
                          dangerouslySetInnerHTML={{
                            __html: htmlFormatting(text?.childMarkdownRemark?.html),
                          }}
                        />
                      )}
                    </ListThreeItem>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              listItems.map(({ id, icon, title: itemTitle, text }) => (
                <ListThreeItem key={id}>
                  <ListThreeItemImage {...icon} />
                  <ListThreeItemTitle>{itemTitle}</ListThreeItemTitle>
                  {text && (
                    <ListThreeItemText
                      dangerouslySetInnerHTML={{
                        __html: htmlFormatting(text?.childMarkdownRemark?.html),
                      }}
                    />
                  )}
                </ListThreeItem>
              ))
            )}
          </ListThreeSlider>
        </ListThreeItems>
      </ListThreeContainer>
      {lowerText && (
        <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
      )}
    </ListThreeWrapper>
  )
}

ListThree.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      listThreeTitle: PropTypes.string,
      text: PropTypes.object,
    }),
  ).isRequired,
}

ListThree.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListThree
