import styled from "@emotion/styled"

const HeroCaseDemoDesktop = styled.div`
  position: absolute;
  top: 49.5%;
  left: 50%;
  width: 63.5%;
  height: 70.5%;
  background-color: ${({ theme }) => theme.palette.gray[50]};
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.palette.gray[300]};
  border-top-left-radius: 5% 8%;
  border-top-right-radius: 5% 8%;
  padding: 3% 2%;

  &::before {
    content: "";
    position: absolute;
    bottom: -2%;
    left: 50%;
    transform: translateX(-50%);
    width: 124%;
    height: 2%;
    background-color: ${({ theme }) => theme.palette.gray[50]};
    border: 2px solid ${({ theme }) => theme.palette.gray[300]};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -4.5%;
    left: 50%;
    transform: translateX(-50%);
    width: 124%;
    height: 3.5%;
    background-color: ${({ theme }) => theme.palette.gray[50]};
    border: 2px solid ${({ theme }) => theme.palette.gray[300]};
    border-bottom-left-radius: 50% 50px;
    border-bottom-right-radius: 50% 50px;
  }

  & > * {
    width: 100%;
    height: 100%;
  }
`

export default HeroCaseDemoDesktop
