import styled from "@emotion/styled"

const ArticleInfoItem = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 600;
  text-transform: capitalize;
  color: #c6c6db;
  align-items: center;
  font-size: 16px;
  line-height: 34px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    svg {
      display: none;
    }
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    &:nth-child(2) {
      display: none;
    }
  }
`

export default ArticleInfoItem
