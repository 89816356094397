import styled from "@emotion/styled"

const ArticleAsideWrapper = styled.aside`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 100%;
    display: contents;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    flex-direction: column;
    gap: 47px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 303px;
    min-width: 303px;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    min-width: 379px;
    max-width: 379px;
    width: 100%;
  }
`

export default ArticleAsideWrapper
