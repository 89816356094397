import styled from "@emotion/styled"

const ListOneHeader = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 50px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 40%;
  }
`

export default ListOneHeader
