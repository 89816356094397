import styled from "@emotion/styled"

const ListSevenItem = styled.li`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: block;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-left: 138px;
    padding-right: 138px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-left: 173px;
    padding-right: 173px;
    justify-content: space-between;
  }

  &:not(:last-child) {
    border-bottom: 3px solid ${({ theme }) => theme.palette.gray[100]};

    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-bottom: 32px;
      padding-bottom: 31px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-bottom: 41px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
      padding-bottom: 40px;
    }

    ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
      padding-bottom: 48px;
    }

    ${({ theme }) => theme.breakpoints.up("2xl")} {
      margin-bottom: 56px;
      padding-bottom: 60px;
    }
  }
`

export default ListSevenItem
