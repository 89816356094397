import styled from "@emotion/styled"

const ListSeventeenColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    row-gap: 20px;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    row-gap: 32px;
    width: calc(50% - 20px);
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    row-gap: 40px;
    width: calc(50% - 20px);
  }
`

export default ListSeventeenColumn
