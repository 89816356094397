import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[50]};
`

const ListFourteenItemFlagWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    bottom: -2px;
    height: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    bottom: -5px;
    height: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    bottom: -12px;
    height: 80px;
  }

  &::before,
  &::after {
    content: "";
    display: block;

    position: absolute;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      width: calc(50% - 19px);
      height: 18px;
      bottom: 9px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
      width: calc(50% - 26px);
      height: 25px;
      bottom: 12px;
    }

    ${({ theme }) => theme.breakpoints.up("2xl")} {
      width: calc(50% - 38px);
      height: 40px;
      bottom: 20px;
    }

    ${dynamicStyle}
  }

  &::before {
    left: 0;
    ${({ theme }) => theme.breakpoints.down("lg")} {
      border-radius: 0 0 20px 20px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
      border-radius: 0 0 30px 20px;
    }

    ${({ theme }) => theme.breakpoints.up("2xl")} {
      border-radius: 0 0 50px 20px;
    }
  }

  &::after {
    right: 0;
    ${({ theme }) => theme.breakpoints.down("lg")} {
      border-radius: 0 0 20px 20px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
      border-radius: 0 0 20px 30px;
    }

    ${({ theme }) => theme.breakpoints.up("2xl")} {
      border-radius: 0 0 20px 50px;
    }
  }
`

export default ListFourteenItemFlagWrapper
