import styled from '@emotion/styled'

const ArticleAsideNavHeadingTitle = styled.span`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    ${({ theme }) => theme.breakpoints.up("md")} {
        padding-left: 10px;    
    }
`

export default ArticleAsideNavHeadingTitle