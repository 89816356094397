import React from "react"
import PropTypes from "prop-types"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import htmlFormatting from "utils/htmlFormatting"

import Container from "components/Container"
import Breadcrumbs from "components/Breadcrumbs"
import BreadcrumbsItem from "components/BreadcrumbsBlogArticle/BreadcrumbsItem"
import Arrow from "components/Icons/Arrow"
import Link from "components/Link"

import ArticleWrapperIcon from "components/Article/ArticleWrapperIcon"
import ArticleIcon from "components/Article/ArticleIcon"

import AuthorInner from "components/Author/AuthorInner"
import AuthorInfo from "components/Author/AuthorInfo"
import AuthorPhoto from "components/Author/AuthorPhoto"
import AuthorRole from "components/Author/AuthorRole"
import AuthorName from "components/Author/AuthorName"
import AuthorDescription from "components/Author/AuthorDecription"
import AuthorPostsWrapper from "components/Author/AuthorPostsWrapper"
import AuthorPostsTitle from "components/Author/AuthorPostsTitle"
import AuthorEmailLinkWrapper from "components/Author/AuthorEmailLinkWrapper"
import AuthorEmailLink from "components/Author/AuthorEmailLink"
import AuthorWrapper from "components/Author/AuthorWrapper"

import BlogListWrapper from "components/BlogList/BlogListWrapper"
import BlogListPostWrapper from "components/BlogList/BlogListPostWrapper"
import BlogListPostImage from "components/BlogList/BlogListPostImage"
import BlogListPostContent from "components/BlogList/BlogListPostContent"
import BlogListPostHeading from "components/BlogList/BlogListPostHeading"
import BlogListPostTitle from "components/BlogList/BlogListPostTitle"
import BlogListPostDate from "components/BlogList/BlogListPostDate"
import BlogListPostText from "components/BlogList/BlogListPostText"
import BlogListNavigationWrapper from "components/BlogList/BlogListNavigationWrapper"
import BlogListNavigationButton from "components/BlogList/BlogListNavigationButton"
import BlogListPaginationStack from "components/BlogList/BlogListPaginationStack"
import BlogListPaginationItem from "components/BlogList/BlogListPaginationItem"
import BlogListPostStack from "components/BlogList/BlogListPostStack"

import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"

import CTAText from "./CTAText"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const {
        data: {
          target: { title, link, noFollow },
        },
      } = node
      return (
        <Link to={link} noFollow={noFollow}>
          {title}
        </Link>
      )
    },
  },
}

const Author = ({
  breadcrumbs,
  authorName,
  authorPhoto,
  authorRole,
  descriptionText,
  currentPage,
  linkedLink,
  linkedinIcon,
  emailLink,
  emailIcon,
  posts,
  locale,
  pageCount,
  link,
  articleSeparator,
  postsTitle,
}) => {
  const firstInit = React.useRef(false)
  const [arrOfCurrButtons, setArrOfCurrButtons] = React.useState([])
  const url = typeof window !== "undefined" && window.location.pathname.split("/")
  const numOfButtons = []


  for (let i = 1; i <= pageCount; i += 1) {
    numOfButtons.push(i)
  }

  const prepareButtonsArray = () => {
    let tempNumberOfButtons = [...arrOfCurrButtons]

    const dots = "..."

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons
    } else if (+currentPage >= 1 && +currentPage <= 2) {
      tempNumberOfButtons = [1, 2, 3, dots, numOfButtons.length]
    } else if (+currentPage === 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dots, numOfButtons.length]
    } else if (+currentPage === 4) {
      const sliced = numOfButtons.slice(0, 5)
      tempNumberOfButtons = [...sliced, dots, numOfButtons.length]
    } else if (+currentPage > 4 && +currentPage < numOfButtons.length - 2) {
      const sliced1 = numOfButtons.slice(+currentPage - 2, +currentPage)
      const sliced2 = numOfButtons.slice(+currentPage, +currentPage + 1)
      tempNumberOfButtons = [1, dots, ...sliced1, ...sliced2, dots, numOfButtons.length]
    } else if (+currentPage > numOfButtons.length - 2) {
      const sliced = numOfButtons.slice(numOfButtons.length - 3)
      tempNumberOfButtons = [1, dots, ...sliced]
    } else if (+currentPage > numOfButtons.length - 3) {
      const sliced = numOfButtons.slice(numOfButtons.length - 4)
      tempNumberOfButtons = [1, dots, ...sliced]
    }

    setArrOfCurrButtons(tempNumberOfButtons)
  }

  if (firstInit.current === false) {
    prepareButtonsArray()
    firstInit.current = true
  }

  return (
    <BlogListWrapper>
      <Container>
        {breadcrumbs && (
          <MicromarkingWrapper itemscope="" itemtype="http://schema.org/BreadcrumbList">
            <Breadcrumbs
              itemprop="itemListElement"
              itemscope=""
              itemtype="http://schema.org/ListItem"
            >
              {breadcrumbs.map(
                ({ id: breadcrumbId, title: breadcrumbTitle, link: breadcrumbLink }, idx) => (
                  <React.Fragment key={breadcrumbId}>
                    {breadcrumbs.length - 1 !== idx && (
                      <>
                        <BreadcrumbsItem
                          itemprop="item"
                          key={breadcrumbId}
                          isActive={false}
                          to={breadcrumbLink}
                        >
                          <Meta itemprop="name" content={breadcrumbTitle} />
                          <Meta itemprop="position" content={idx + 1} />
                          {breadcrumbTitle}
                        </BreadcrumbsItem>
                        <Arrow
                          key={breadcrumbLink}
                          direction="left"
                          color="#BBBBBB"
                          width={14}
                          height={12}
                        />
                      </>
                    )}
                    {breadcrumbs.length - 1 === idx && (
                      <BreadcrumbsItem key={breadcrumbId} isActive={true}>
                        {breadcrumbTitle}
                      </BreadcrumbsItem>
                    )}
                  </React.Fragment>
                ),
              )}
            </Breadcrumbs>
          </MicromarkingWrapper>
        )}
        <AuthorWrapper>
          <AuthorInner>
            <AuthorPhoto {...authorPhoto} />
            <AuthorInfo>
              <AuthorName>{authorName}</AuthorName>
              <AuthorRole>{authorRole}</AuthorRole>
              <AuthorDescription>
                {descriptionText && renderRichText(descriptionText, options)}
              </AuthorDescription>
              {(linkedinIcon || emailIcon) && (
                <ArticleWrapperIcon authorpage={{}}>
                  {linkedLink?.link && (
                    <Link to={linkedLink.link}>
                      <ArticleIcon authorpage={{}} {...linkedinIcon} />
                    </Link>
                  )}
                  {emailLink && (
                    <AuthorEmailLinkWrapper>
                      <ArticleIcon authorpage={{}} {...emailIcon} />
                      <AuthorEmailLink aria-label="email address" href={`mailto:${emailLink}`} />
                    </AuthorEmailLinkWrapper>
                  )}
                </ArticleWrapperIcon>
              )}
            </AuthorInfo>
          </AuthorInner>
        </AuthorWrapper>

        <AuthorPostsTitle>{postsTitle}</AuthorPostsTitle>
        <BlogListPostStack
          articleSeparatorTop={!!articleSeparator}
          articleSeparatorBottom={true}
          pt="20"
          pb={articleSeparator ? "160" : "40"}
        >
          {posts &&
            posts
              .slice(0, posts.length / 2)
              .map(
                ({
                  id: postId,
                  title: postTitle,
                  coverImage: postImage,
                  shortContent: postContent,
                  date: postDate,
                  link: postLink,
                }) => (
                  <MicromarkingWrapper
                    key={postId}
                    itemscope=""
                    itemtype="https://schema.org/BlogPosting"
                  >
                    <Meta itemprop="datePublished" content={postDate} />
                    <Meta itemprop="headline" content={postTitle} />

                    <MicromarkingWrapper
                      itemprop="author"
                      itemscope=""
                      itemtype="https://schema.org/Person"
                    >
                      <Meta itemprop="name" content={authorName} />
                      <Meta itemprop="url" content={`https://adexin.com${postLink}`} />
                    </MicromarkingWrapper>

                    <BlogListPostWrapper itemprop="url" to={postLink}>
                      <BlogListPostImage itemprop="image" {...postImage} objectFit="cover" />
                      <BlogListPostContent>
                        <BlogListPostHeading>
                          <BlogListPostTitle>{postTitle}</BlogListPostTitle>
                          <BlogListPostDate date={postDate} locale={locale} />
                        </BlogListPostHeading>
                        {postContent && postContent.childMarkdownRemark && (
                          <BlogListPostText
                            itemprop="description"
                            dangerouslySetInnerHTML={{
                              __html: htmlFormatting(postContent?.childMarkdownRemark?.html),
                            }}
                          />
                        )}
                      </BlogListPostContent>
                    </BlogListPostWrapper>
                  </MicromarkingWrapper>
                ),
              )}
        </BlogListPostStack>
      </Container>
      {articleSeparator && posts.length > 1 && <CTAText {...articleSeparator} />}
      <Container>
        <BlogListPostStack
          articleSeparatorTop={true}
          articleSeparatorBottom={!!articleSeparator}
          pt="60"
          pb={articleSeparator ? "160" : "40"}
        >
          {posts &&
            posts
              .slice(posts.length / 2)
              .map(
                ({
                  id: postId,
                  title: postTitle,
                  coverImage: postImage,
                  shortContent: postContent,
                  date: postDate,
                  link: postLink,
                }) => (
                  <MicromarkingWrapper
                    key={postId}
                    itemscope=""
                    itemtype="https://schema.org/BlogPosting"
                  >
                    <Meta itemprop="datePublished" content={postDate} />
                    <Meta itemprop="headline" content={postTitle} />

                    <MicromarkingWrapper
                      itemprop="author"
                      itemscope=""
                      itemtype="https://schema.org/Person"
                    >
                      <Meta itemprop="name" content={authorName} />
                      <Meta itemprop="url" content={`https://adexin.com${postLink}`} />
                    </MicromarkingWrapper>

                    <BlogListPostWrapper itemprop="url" to={postLink} key={postId}>
                      <BlogListPostImage itemprop="image" {...postImage} objectFit="cover" />
                      <BlogListPostContent>
                        <BlogListPostHeading>
                          <BlogListPostTitle>{postTitle}</BlogListPostTitle>
                          <BlogListPostDate date={postDate} locale={locale} />
                        </BlogListPostHeading>
                        {postContent && postContent.childMarkdownRemark && (
                          <BlogListPostText
                            itemprop="description"
                            dangerouslySetInnerHTML={{
                              __html: htmlFormatting(postContent?.childMarkdownRemark?.html),
                            }}
                          />
                        )}
                      </BlogListPostContent>
                    </BlogListPostWrapper>
                  </MicromarkingWrapper>
                ),
              )}
        </BlogListPostStack>
        {pageCount > 1 && (
          <BlogListNavigationWrapper>
            {+currentPage !== 1 && (
              <BlogListNavigationButton
                to={+currentPage === 2 ? link : `${link}/${currentPage - 1}`}
              >
                <Arrow direction="left" color="#38B148" width={14} height={12} />
              </BlogListNavigationButton>
            )}
            <BlogListPaginationStack>
              {arrOfCurrButtons.map((el) => (
                <BlogListPaginationItem
                  as={el !== "..." ? Link : "div"}
                  isActive={+el === +currentPage}
                  to={link + (el !== 1 ? `/${el}` : "")}
                  key={el}
                >
                  {el}
                </BlogListPaginationItem>
              ))}
            </BlogListPaginationStack>
            {+currentPage !== +pageCount && (
              <BlogListNavigationButton to={`${link}/${+currentPage + 1}`}>
                <Arrow direction="right" color="#38B148" width={14} height={12} />
              </BlogListNavigationButton>
            )}
          </BlogListNavigationWrapper>
        )}
      </Container>
    </BlogListWrapper>
  )
}

Author.propTypes = {
  authorName: PropTypes.string.isRequired,
  authorPhoto: PropTypes.object.isRequired,
  authorRole: PropTypes.string.isRequired,
  countPostOnPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  articleSeparator: PropTypes.object,
  postsTitle: PropTypes.string.isRequired,
  descriptionText: PropTypes.exact({
    raw: PropTypes.string,
  }).isRequired,
  linkedLink: PropTypes.object,
  linkedinIcon: PropTypes.object,
  emailLink: PropTypes.string,
  emailIcon: PropTypes.object,
  locale: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  posts: PropTypes.arrayOf(PropTypes.object),
}

Author.defaultProps = {
  linkedLink: null,
  linkedinIcon: null,
  emailLink: null,
  emailIcon: null,
  pageCount: null,
  posts: null,
  articleSeparator: null,
}

export default Author
