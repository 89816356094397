import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Image from "components/Image"

const dynamicStyles = ({ theme, authorpage = false }) => css`
  ${authorpage
    ? `
        width: 48px;
        height: 48px;
    `
    : `
        ${theme.breakpoints.down("lg")} {
            width: 40px;
            height: 40px;
        }

        ${theme.breakpoints.up("lg")} {
            width: 32px;
            height: 32px;
        }
    `}
`

const ArticleIcon = styled(Image)`
  display: block;

  ${dynamicStyles};
`

export default ArticleIcon
