import styled from "@emotion/styled"
import IntlDate from "components/IntlDate"

const PostListItemDate = styled(IntlDate)`
  margin-block-start: 0;
  margin-block-end: 0;
  font-style: normal;
  font-weight: normal;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.06em;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.06em;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 18px;
    line-height: 32px;
  }
`

export default PostListItemDate
