import styled from "@emotion/styled"

const ListSevenItemHeader = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 17px;
    margin-bottom: 22px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    gap: 21px;
    flex-basis: 83%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    flex: 0 0 322px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 30px;
  }
`

export default ListSevenItemHeader
