import styled from "@emotion/styled"

const AuthorInner = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    gap: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 90px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    max-width: 860px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 1075px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    max-width: 1344px;
  }
`

export default AuthorInner
