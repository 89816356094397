import styled from "@emotion/styled"

const Section404Text = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  color: ${({ theme }) => theme.palette.gray[900]};
  letter-spacing: 0.02em;
  margin-bottom: 15px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    font-size: 20px;
    line-height: 19px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 30px;
  }
`

export default Section404Text
