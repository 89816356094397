import styled from "@emotion/styled"
import IntlDate from "components/IntlDate"

const BlogListPostDate = styled(IntlDate)`
  display: block;
  font-weight: 600;
  text-transform: capitalize;
  color: #c6c6db;
  font-size: 16px;
  line-height: 1;
`

export default BlogListPostDate
