import styled from "@emotion/styled"

const ArticleAuthorPhotoWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: block;
  }
`

export default ArticleAuthorPhotoWrapper
