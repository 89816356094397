import styled from "@emotion/styled"

const Section404Stack = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    gap: 15px;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.between("sm", "md")} {
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    gap: 19px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 30px;
  }
`

export default Section404Stack
