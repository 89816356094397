import styled from "@emotion/styled"

const ListFiveItemContainer = styled.div`
  display: flex;
  align-items: flex-end;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    height: 49px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 82px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 102px;
  }
`

export default ListFiveItemContainer
