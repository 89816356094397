import styled from "@emotion/styled"
import TextsTitle from "components/Texts/TextsTitle"

const TextThreeTitle = styled(TextsTitle)`
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 29px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 29px;
    max-width: 530px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 48px;
    max-width: 744px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 60px;
    max-width: 930px;
  }
`

export default TextThreeTitle
