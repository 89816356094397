import styled from '@emotion/styled'

const RelatedArticlesItem = styled.div`
  background-color: ${({ theme }) => theme.palette.gray[100]};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
`

export default RelatedArticlesItem