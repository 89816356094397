import styled from "@emotion/styled"

const ListFourItem = styled.div`
  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    display: flex;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    width: calc(50% - 20px);
  }
`

export default ListFourItem
