import * as React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import Container from "components/Container"
import TechItem from "components/TechItem/TechItem"
import TechOneTitle from "components/TechOne/TechOneTitle"
import TechOneWrapper from "components/TechOne/TechOneWrapper"
import TechOneText from "components/TechOne/TechOneText"
import TechOneHeader from "components/TechOne/TechOneHeader"
import TechOneCategories from "components/TechOne/TechOneCategories"
import TechOneCategoriesColumn from "components/TechOne/TechOneCategoriesColumn"

import TechOneStack from "components/TechOne/TechOneStack"
import TechOneCategoryTitle from "components/TechOne/TechOneCategoryTitle"
import TechOneTitleWrapper from "components/TechOne/TechOneTitleWrapper"
import TechItemImage from "components/TechItem/TechItemImage"
import TechItemImageWrapper from "components/TechItem/TechItemImageWrapper"
import TechItemTitle from "components/TechItem/TechItemTitle"
import TechOneButton from "components/TechOne/TechOneButtonIcon"
import Link from "components/Link"
import htmlFormatting from "utils/htmlFormatting"
import useMedia from "../hooks/useMedia"
import useHasMounted from "../hooks/useHasMounted"

const TechOne = ({
  theme,
  headingVariant,
  titleAlignment,
  title,
  titleTag,
  sublineText,
  techCategory,
  sectionId,
}) => {
  const [activeID, setActiveID] = React.useState(techCategory[0].id)
  const showSwiper = useMedia(["(min-width: 992px)"], [false], true)
  const swiperConfig = {
    slidesPerView: "auto",
    spaceBetween: 40,
    allowTouchMove: true,
    navigation: true,
  }

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <TechOneWrapper id={sectionId} colorVariant={theme}>
      <Container>
        <TechOneHeader headingVariant={headingVariant} titleAlignment={titleAlignment}>
          <TechOneTitle as={titleTag}>{title}</TechOneTitle>
          {sublineText && (
            <TechOneText
              dangerouslySetInnerHTML={{
                __html: htmlFormatting(sublineText?.childMarkdownRemark?.html),
              }}
            />
          )}
        </TechOneHeader>
        <TechOneCategories>
          <TechOneCategoriesColumn>
            {techCategory.map(({ title: categoryTitle, titleTag: categoryTitleTag, id }, index) => (
              <TechOneTitleWrapper
                colorVariant={theme}
                key={id}
                style={{ order: index }}
                active={activeID === id}
                onClick={() => setActiveID(id)}
              >
                <TechOneCategoryTitle as={categoryTitleTag}>{categoryTitle}</TechOneCategoryTitle>
              </TechOneTitleWrapper>
            ))}
          </TechOneCategoriesColumn>
          <TechOneCategoriesColumn>
            {techCategory.map(({ buttonText: titleButton, buttonUrl, id, technologies }, index) => (
              <div style={{ order: index }} key={id}>
                {technologies && (
                  <TechOneStack active={activeID === id}>
                    {showSwiper ? (
                      <Swiper modules={[Navigation]} {...swiperConfig}>
                        {technologies.map(({ id: techId, techImage, techName, techUrl }) => (
                          <SwiperSlide key={techId}>
                            <TechItem
                              as={(techUrl !== undefined && techUrl !== null && Link) || "div"}
                              to={techUrl}
                            >
                              <TechItemImageWrapper colorVariant={theme} size="large">
                                <TechItemImage {...techImage} />
                              </TechItemImageWrapper>
                              <TechItemTitle size="large" url={techUrl}>
                                {techName}
                              </TechItemTitle>
                            </TechItem>
                          </SwiperSlide>
                        ))}
                        {buttonUrl && (
                          <SwiperSlide>
                            <TechItem as={Link} to={buttonUrl}>
                              <TechItemImageWrapper colorVariant={theme} size="large">
                                <TechOneButton />
                              </TechItemImageWrapper>
                              <TechItemTitle size="large" url={buttonUrl}>
                                {titleButton}
                              </TechItemTitle>
                            </TechItem>
                          </SwiperSlide>
                        )}
                      </Swiper>
                    ) : (
                      <>
                        {technologies.map(({ id: techId, techImage, techName, techUrl }) => (
                          <TechItem
                            as={(techUrl !== undefined && techUrl !== null && Link) || "div"}
                            key={techId}
                            to={techUrl}
                          >
                            <TechItemImageWrapper colorVariant={theme} size="large">
                              <TechItemImage {...techImage} />
                            </TechItemImageWrapper>
                            <TechItemTitle size="large" url={techUrl}>
                              {techName}
                            </TechItemTitle>
                          </TechItem>
                        ))}
                        {buttonUrl && (
                          <TechItem as={Link} to={buttonUrl}>
                            <TechItemImageWrapper colorVariant={theme} size="large">
                              <TechOneButton />
                            </TechItemImageWrapper>
                            <TechItemTitle size="large" url={buttonUrl}>
                              {titleButton}
                            </TechItemTitle>
                          </TechItem>
                        )}
                      </>
                    )}
                  </TechOneStack>
                )}
              </div>
            ))}
          </TechOneCategoriesColumn>
        </TechOneCategories>
      </Container>
    </TechOneWrapper>
  )
}

TechOne.propTypes = {
  theme: PropTypes.string.isRequired,
  headingVariant: PropTypes.string.isRequired,
  titleAlignment: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  sublineText: PropTypes.object.isRequired,
  sectionId: PropTypes.string,
  techCategory: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      text: PropTypes.object,
      buttonText: PropTypes.string,
      buttonUrl: PropTypes.string,
      id: PropTypes.string,
      technologies: PropTypes.arrayOf(
        PropTypes.exact({
          techName: PropTypes.string,
          techUrl: PropTypes.string,
          id: PropTypes.string,
          techImage: PropTypes.object,
        }),
      ),
    }),
  ).isRequired,
}

TechOne.defaultProps = {
  sectionId: "",
}

export default TechOne
