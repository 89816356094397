import PropTypes from "prop-types"
import Image from "components/Image"

import htmlFormatting from "utils/htmlFormatting"
import TestimonialWrapper from "./TestimonialWrapper"
import TestimonialTop from "./TestimonialTop"
import TestimonialPhoto from "./TestimonialPhoto"
import TestimonialUser from "./TestimonialUser"
import TestimonialFlag from "./TestimonialFlag"
import TestimonialTitle from "./TestimonialTitle"
import TestimonialPosition from "./TestimonialPosition"
import TestimonialText from "./TestimonialText"
import TestimonialBottom from "./TestimonialBottom"
import TestimonialLink from "./TestimonialLink"

import useHasMounted from "../../hooks/useHasMounted"

const Testimonial = ({ name, position, flag, photo, text, social, isGray, isFullWidth }) => {
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <TestimonialWrapper isFullWidth={isFullWidth} isGray={isGray}>
      <TestimonialTop>
        {!!photo?.alt && (
          <TestimonialPhoto>
            <Image {...photo} objectFit="scale-down" />
          </TestimonialPhoto>
        )}
        <TestimonialUser showImage={!!photo?.alt}>
          <div>
            {!!photo?.alt && (
              <TestimonialFlag showImage={!!photo?.alt}>
                <Image {...flag} />
              </TestimonialFlag>
            )}
            <TestimonialTitle>{name}</TestimonialTitle>
          </div>
          <TestimonialPosition>{position}</TestimonialPosition>
        </TestimonialUser>
        {!photo?.alt && (
          <TestimonialFlag showImage={!!photo?.alt}>
            <Image {...flag} />
          </TestimonialFlag>
        )}
      </TestimonialTop>
      {text && (
        <TestimonialText
          dangerouslySetInnerHTML={{
            __html: htmlFormatting(text?.childMarkdownRemark?.html),
          }}
        />
      )}

      {social && (
        <TestimonialBottom>
          {social.map(({ id, link: socialLink, title }) => (
            <TestimonialLink key={id} to={socialLink}>
              {title}
            </TestimonialLink>
          ))}
        </TestimonialBottom>
      )}
    </TestimonialWrapper>
  )
}

Testimonial.propTypes = {
  flag: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.object,
  position: PropTypes.string.isRequired,
  social: PropTypes.array.isRequired,
  text: PropTypes.object.isRequired,
  isGray: PropTypes.bool,
  isFullWidth: PropTypes.bool,
}

Testimonial.defaultProps = {
  isGray: false,
  isFullWidth: false,
  photo: null,
}

export default Testimonial
