import styled from "@emotion/styled"

const ListOneStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 30px;
    column-gap: 31px;
    row-gap: 9px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 32px;
    gap: 72px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 40px;
    gap: 90px;
  }
`

export default ListOneStack
