import styled from "@emotion/styled"
import { css } from "@emotion/react"

const sizesPC = {
  small: "150px",
  normal: "150px",
  large: "180px",
}

const sizesMediumPC = {
  small: "141px",
  normal: "141px",
  large: "160px",
}

const sizesSmallPC = {
  small: "120px",
  normal: "120px",
  large: "134px",
}

const sizesTablet = {
  small: "120px",
  normal: "120px",
  large: "100px",
}

const sizesMobile = {
  small: "105px",
  normal: "150px",
  large: "150px",
}

const dynamicStyle = ({ size = "normal", theme, colorVariant = "Light" }) => css`
  ${theme.breakpoints.down("xl")} {
    width: ${sizesMobile[size]};
    height: ${sizesMobile[size]};
  }

  background-color: ${colorVariant === "Light"
    ? theme.palette.common.white
    : theme.palette.gray[50]};

  ${theme.breakpoints.between("lg", "xl")} {
    width: ${sizesTablet[size]};
    height: ${sizesTablet[size]};
  }
  ${theme.breakpoints.between("xl", "2xl")} {
    width: ${sizesSmallPC[size]};
    height: ${sizesSmallPC[size]};
  }
  ${theme.breakpoints.between("2xl", "3xl")} {
    width: ${sizesMediumPC[size]};
    height: ${sizesMediumPC[size]};
  }
  ${theme.breakpoints.up("3xl")} {
    width: ${sizesPC[size]};
    height: ${sizesPC[size]};
  }
`

const TechItemImageWrapper = styled.div`
  border-radius: 15px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 4px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 3px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  ${dynamicStyle}
`
export default TechItemImageWrapper
