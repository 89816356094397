import styled from "@emotion/styled"
import Link from "components/Link"

const ListNineItemLink = styled(Link)`
  font-style: normal;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  letter-spacing: 0.06em;
  font-size: 18px;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  margin-bottom: -1px;
  max-width: max-content;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "3xl")} {
    line-height: 38px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    line-height: 38px;
  }
`

export default ListNineItemLink
