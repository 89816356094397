import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Arrow from "components/Icons/Arrow"
import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListTwoWrapper from "components/ListTwo/ListTwoWrapper"
import ListTwoHeader from "components/ListTwo/ListTwoHeader"
import ListTwoTitle from "components/ListTwo/ListTwoTitle"
import ListTwoSubtitleText from "components/ListTwo/ListTwoSubtitleText"
import ListTwoStack from "components/ListTwo/ListTwoStack"
import ListTwoCard from "components/ListTwo/ListTwoCard"
import ListTwoCardHeader from "components/ListTwo/ListTwoCardHeader"
import ListTwoCardIcon from "components/ListTwo/ListTwoCardIcon"
import ListTwoCardTitle from "components/ListTwo/ListTwoCardTitle"
import ListTwoCardText from "components/ListTwo/ListTwoCardText"
import ListTwoCardButton from "components/ListTwo/ListTwoCardButton"
import ListTwoCardLink from "components/ListTwo/ListTwoCardLink"
import ListTwoCardBtnLink from "components/ListTwo/ListTwoCardBtnLink"

import htmlFormatting from "utils/htmlFormatting"
import UnstyledLink from "components/UnstyledLink"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListTwo = ({ sectionId, title, titleTag, subtitleText, theme, listItems, lowerText }) => {
  if (!listItems?.length) return null

  return (
    <ListTwoWrapper id={sectionId} colorVariant={theme}>
      <Container>
        <ListTwoHeader center={!subtitleText}>
          <ListTwoTitle as={titleTag}>{title}</ListTwoTitle>
          {subtitleText && (
            <ListTwoSubtitleText
              className="list-two__text"
              dangerouslySetInnerHTML={{
                __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
              }}
            />
          )}
        </ListTwoHeader>
        <ListTwoStack lowerText={lowerText}>
          {listItems?.map(({ id, icon, title: cardTitle, titleTag: cardTitleTag, text, url }) => {
            return (
            // <ListTwoCard key={id} colorVariant={theme} as={!!url && Link} to={url}>
            <ListTwoCard key={id} colorVariant={theme}>
              <ListTwoCardHeader as={url ? UnstyledLink : "div"} to={url}>
                {!!icon && <ListTwoCardIcon {...icon} />}
                <ListTwoCardTitle as={cardTitleTag}> {cardTitle} </ListTwoCardTitle>
              </ListTwoCardHeader>
              {text && (
                <ListTwoCardText
                  dangerouslySetInnerHTML={{
                    __html: htmlFormatting(text?.childMarkdownRemark?.html),
                  }}
                />
              )}
              {!!url && (
                <div>
                  <ListTwoCardButton variant="contained" className="btn" aria-label="Button">
                    <Arrow
                      className="arrow-icon"
                      direction="right"
                      width={33}
                      height={28}
                      color="white"
                    />
                    <ListTwoCardBtnLink to={url} />
                  </ListTwoCardButton>
                </div>
              )}
            </ListTwoCard>
          )})}
        </ListTwoStack>
      </Container>
      {lowerText && (
        <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
      )}
    </ListTwoWrapper>
  )
}

ListTwo.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      title: PropTypes.string.isRequired,
      text: PropTypes.object.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
}

ListTwo.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListTwo
