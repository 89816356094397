import * as React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListEighteenContent from "components/ListEighteen/ListEighteenContent"
import ListEighteenDescription from "components/ListEighteen/ListEighteenDescription"
import ListEighteenHeader from "components/ListEighteen/ListEighteenHeader"
import ListEighteenHeadline from "components/ListEighteen/ListEighteenHeadline"
import ListEighteenItem from "components/ListEighteen/ListEighteenItem"
import ListEighteenItemContent from "components/ListEighteen/ListEighteenItemContent"
import ListEighteenItemIcon from "components/ListEighteen/ListEighteenItemIcon"
import ListEighteenItemIconWrapper from "components/ListEighteen/ListEighteenItemIconWrapper"
import ListEighteenItemText from "components/ListEighteen/ListEighteenItemText"
import ListEighteenLink from "components/ListEighteen/ListEighteenLink"
import ListEighteenStack from "components/ListEighteen/ListEighteenStack"
import ListEighteenSubtitle from "components/ListEighteen/ListEighteenSubtitle"
import ListEighteenText from "components/ListEighteen/ListEighteenText"
import ListEighteenTitle from "components/ListEighteen/ListEighteenTitle"
import ListEighteenWrapper from "components/ListEighteen/ListEighteenWrapper"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListEighteen = ({
  title,
  theme: colorVariant,
  titleTag,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => {
  const [activeItem, setActiveItem] = React.useState(listItems[0])
  const swiperConfig = {
    slidesPerView: "auto",
    spaceBetween: 0,
    allowTouchMove: true,
    navigation: true,
  }
  return (
    <ListEighteenWrapper id={sectionId} colorVariant={colorVariant}>
      <Container>
        <ListEighteenHeader>
          <ListEighteenTitle as={titleTag}>{title}</ListEighteenTitle>
          {subtitleText && (
            <ListEighteenSubtitle
              dangerouslySetInnerHTML={{
                __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
              }}
            />
          )}
        </ListEighteenHeader>
        <ListEighteenStack numSlides={listItems?.length} colorVariant={colorVariant}>
          <Swiper className="list-eighteen" modules={[Navigation]} {...swiperConfig}>
            {listItems.map((listItem) => {
              const { id, icon, activeIcon, title: titleItem, titleTag: titleTagBlock } = listItem
              return (
                <SwiperSlide key={id}>
                  <ListEighteenItem
                    onClick={() => setActiveItem(listItem)}
                    active={id === activeItem.id}
                    colorVariant={colorVariant}
                  >
                    <ListEighteenItemIconWrapper>
                      {id === activeItem.id && activeIcon ? (
                        <ListEighteenItemIcon {...activeIcon} />
                      ) : (
                        <ListEighteenItemIcon {...icon} />
                      )}
                    </ListEighteenItemIconWrapper>
                    <ListEighteenItemContent>
                      <ListEighteenItemText as={titleTagBlock || 'div'}>{titleItem}</ListEighteenItemText>
                    </ListEighteenItemContent>
                  </ListEighteenItem>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </ListEighteenStack>
        <ListEighteenDescription lowerText={lowerText}>
          <ListEighteenHeadline as={activeItem.subtitleTag}>
            {activeItem.subtitle}
          </ListEighteenHeadline>
          <ListEighteenContent>
            {activeItem?.text && (
              <ListEighteenText
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(activeItem?.text?.childMarkdownRemark?.html),
                }}
              />
            )}
            {activeItem.url && (
              <ListEighteenLink to={activeItem.url}>{activeItem.buttonTitle}</ListEighteenLink>
            )}
          </ListEighteenContent>
        </ListEighteenDescription>
      </Container>

      {lowerText && (
        <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
      )}
    </ListEighteenWrapper>
  )
}

ListEighteen.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      activeIcon: PropTypes.object,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      subtitleTag: PropTypes.string,
      text: PropTypes.object,
      buttonTitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
}

ListEighteen.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListEighteen
