import styled from "@emotion/styled"

const ListThreeItem = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: calc(33.33% - 20px);
    width: 100%;
  }
`

export default ListThreeItem
