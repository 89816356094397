import styled from "@emotion/styled"

const ListTwoCardHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 34px;
  }
`

export default ListTwoCardHeader
