const Twitter = () => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2812 5.50752L11.3316 6.33798L10.4922 6.23629C7.43686 5.84649 4.76765 4.52454 2.50134 2.30434L1.39336 1.20272L1.10798 2.01623C0.503625 3.82967 0.889738 5.7448 2.1488 7.03285C2.8203 7.74467 2.66921 7.84636 1.51088 7.42265C1.10798 7.28707 0.755438 7.18538 0.721863 7.23623C0.60435 7.35486 1.00725 8.89713 1.32621 9.50726C1.76269 10.3547 2.65243 11.1851 3.6261 11.6766L4.44869 12.0664L3.47501 12.0834C2.53491 12.0834 2.50134 12.1003 2.60206 12.4562C2.93781 13.5578 4.26403 14.7273 5.74133 15.2357L6.78215 15.5916L5.87563 16.1339C4.53263 16.9136 2.9546 17.3542 1.37658 17.3881C0.621138 17.4051 0 17.4728 0 17.5237C0 17.6932 2.04808 18.6423 3.23999 19.0151C6.81573 20.1167 11.063 19.6422 14.2526 17.761C16.5189 16.4221 18.7852 13.7612 19.8428 11.1851C20.4136 9.81233 20.9844 7.30402 20.9844 6.10071C20.9844 5.3211 21.0347 5.21941 21.9748 4.28727C22.5288 3.74493 23.0492 3.15175 23.15 2.98227C23.3178 2.66025 23.3011 2.66025 22.4449 2.94837C21.018 3.45681 20.8165 3.38902 21.5216 2.62636C22.042 2.08402 22.6631 1.10103 22.6631 0.812917C22.6631 0.762073 22.4113 0.846813 22.1259 0.999345C21.8238 1.16883 21.1523 1.42305 20.6486 1.57558L19.7421 1.8637L18.9195 1.30441C18.4663 0.999345 17.8283 0.660385 17.4926 0.558696C16.6364 0.321424 15.327 0.35532 14.5548 0.626488C12.4563 1.38915 11.1301 3.35512 11.2812 5.50752Z"
      fill="#ffffff"
    />
  </svg>
)

export default Twitter
