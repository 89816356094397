import styled from "@emotion/styled"
import Title from "components/Title"

const TechThreeCategoryTitle = styled(Title)`
  margin-bottom: 0;
  margin-top: 0;

  font-family: ${({ theme }) => theme.typography.fontFamilyMontserrat};

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 31px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 31px;
    padding-bottom: 30px;
  }
`

export default TechThreeCategoryTitle
