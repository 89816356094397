import styled from "@emotion/styled"
import Title from "components/Title"

const TestimonialsTitle = styled(Title)`
  color: ${({ theme }) => theme.palette.gray["900"]};
  margin-top: 0;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 20px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    margin-bottom: 32px;
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 32px;
    font-size: 60px;
  }
`

export default TestimonialsTitle
