import PropTypes from "prop-types"
import BlogArticleTwoImagesWrapper from "components/BlogArticleTwoImages/BlogArticleTwoImagesWrapper"
import BlogArticleTwoImagesItem from "components/BlogArticleTwoImages/BlogArticleTwoImagesItem"
import BlogArticleTwoImagesItemTitle from "components/BlogArticleTwoImages/BlogArticleTwoImagesItemTitle"
import Image from "components/Image"

const BlogArticleTwoImages = ({
  firstImage,
  firstImageDescription,
  secondImage,
  secondImageDescription,
}) => (
  <BlogArticleTwoImagesWrapper>
    <BlogArticleTwoImagesItem>
      <Image {...firstImage} />
      <BlogArticleTwoImagesItemTitle>{firstImageDescription}</BlogArticleTwoImagesItemTitle>
    </BlogArticleTwoImagesItem>
    <BlogArticleTwoImagesItem>
      <Image {...secondImage} />
      <BlogArticleTwoImagesItemTitle>{secondImageDescription}</BlogArticleTwoImagesItemTitle>
    </BlogArticleTwoImagesItem>
  </BlogArticleTwoImagesWrapper>
)

BlogArticleTwoImages.propTypes = {
  firstImage: PropTypes.object.isRequired,
  firstImageDescription: PropTypes.string.isRequired,
  secondImage: PropTypes.object.isRequired,
  secondImageDescription: PropTypes.string.isRequired,
}

export default BlogArticleTwoImages
