import styled from "@emotion/styled"

const PostEventsEventDate = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    margin-bottom: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 4px;
  }
`

export default PostEventsEventDate
