import styled from "@emotion/styled"

const ListThreeSlider = styled.div`
  display: flex;

  & .swiper-slide {
    width: auto;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 26px;
    margin-left: -16px;
    margin-right: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-left: -16px;
    margin-right: -16px;
    padding-right: 16px;
    padding-left: 16px;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    justify-content: center;
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    justify-content: center;
    flex-wrap: wrap;
  }
`

export default ListThreeSlider
