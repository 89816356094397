import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Clutch from "components/Icons/Clutch"

const HeroCaseInfoContainer = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const HeroCaseInfoTop = styled.div`
  display: flex;
`

const HeroCaseInfoText = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
`

const HeroCaseInfoBottom = styled.div`
  font-size: 12px;
  line-height: 22px;
`

const HeroCaseInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 46px;
`

const HeroCaseInfo = ({ text, rating }) => {
  const StarsWrapper = styled.div`
    display: inline-block;
    font-size: 22px;
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;
    padding-left: 10px;

    &::before {
      content: "★★★★★";
      letter-spacing: 4px;
      background: linear-gradient(
        90deg,
        ${({ theme }) => theme.palette.primary.main} ${`${(rating / 5) * 100}%`},
        ${({ theme }) => theme.palette.gray[300]} ${`${(rating / 5) * 100}%`}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `

  return (
    <HeroCaseInfoWrapper>
      <Clutch />
      <HeroCaseInfoContainer>
        <HeroCaseInfoTop>
          <HeroCaseInfoText>{rating}</HeroCaseInfoText>
          <StarsWrapper rating={rating} />
        </HeroCaseInfoTop>
        <HeroCaseInfoBottom>{text}</HeroCaseInfoBottom>
      </HeroCaseInfoContainer>
    </HeroCaseInfoWrapper>
  )
}

HeroCaseInfo.propTypes = {
  text: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
}

export default HeroCaseInfo
