import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ topPartTheme, bottomPartTheme, theme }) => css`
  &::before {
    top: 0;
    background-color: ${topPartTheme === "Dark"
      ? theme.palette.gray[900]
      : theme.palette.common.white};
  }
  &::after {
    bottom: 0;
    background-color: ${bottomPartTheme === "Dark"
      ? theme.palette.gray[900]
      : theme.palette.common.white};
  }
`

const CTAFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: -1;
  }
  ${dynamicStyle}
`

export default CTAFormWrapper
