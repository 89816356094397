import styled from "@emotion/styled"

const ListFifteenItem = styled.div`
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    flex: 0 0 15px;
    margin-top: 12px;
    display: block;
  }
  display: flex;
  gap: 15px;

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    width: 100%;
    max-width: 300px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 100%;
    max-width: 377px;
  }
`

export default ListFifteenItem
