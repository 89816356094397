import styled from "@emotion/styled"

const PostEventsHeader = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 34px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 42px;
  }
`

export default PostEventsHeader
