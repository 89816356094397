import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Link from "components/Link"
import { PropTypes } from "prop-types"

const HeroBreadcrumbsLinkEl = ({ isActive, to, ...props }) => {
  if (to) {
    return <Link isActive={isActive} to={to} {...props} />
  }

  return <span {...props} />
}

const dynamicStyles = ({ theme, isActive, colorVariant }) => css`
  color: ${colorVariant === "Dark"
    ? (isActive && theme.palette.common.white) || theme.palette.gray[600]
    : (isActive && theme.palette.gray[900]) || theme.palette.gray[600]};
`

const HeroBreadcrumbsLink = styled(HeroBreadcrumbsLinkEl)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  padding: 5px 13px;
  ${dynamicStyles}
`

export default HeroBreadcrumbsLink

HeroBreadcrumbsLinkEl.propTypes = {
  isActive: PropTypes.bool,
  to: PropTypes.string,
}

HeroBreadcrumbsLinkEl.defaultProps = {
  isActive: false,
  to: "",
}
