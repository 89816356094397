import styled from "@emotion/styled"
import Link from "components/Link"

const Section404Link = styled(Link)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: 22px;
  font-size: 18px;
`

export default Section404Link
