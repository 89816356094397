import React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"

import Button from "components/Buttons/Button"
import Link from "components/Link"
import Container from "components/Container"
import TestimonialsWrapper from "components/Testimonials/TestimonialsWrapper"
import TestimonialsTitle from "components/Testimonials/TestimonialsTitle"
import TestimonialsInfo from "components/Testimonials/TestimonialsInfo"
import TestimonialsBtnArrow from "components/Testimonials/TestimonialsBtnArrow"
import TestimonialsList from "components/Testimonials/TestimonialsList"
import TestimonialsControls from "components/Testimonials/TestimonialsControls"
import Testimonial from "components/Testimonial/Testimonial"

const Testimonials = ({
  title,
  titleTag,
  text,
  buttonTitle,
  buttonLink,
  rating,
  testimonials,
  sectionId,
}) => {
  const swiperRef = React.useRef(null)
  const swiperConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    spaceBetween: 0,
    allowTouchMove: false,
    ref: swiperRef,
    breakpoints: {
      1280: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
    },
  }

  const handleSlidePrev = () => swiperRef.current.swiper.slidePrev(300)
  const handleSlideNext = () => swiperRef.current.swiper.slideNext(300)
  if (!testimonials?.length) return null

  return (
    <TestimonialsWrapper id={sectionId}>
      <Container>
        <TestimonialsTitle as={titleTag}>{title}</TestimonialsTitle>
        {!!buttonLink && text !== null && text !== undefined && text !== " " && (
          <TestimonialsInfo link={buttonLink} text={text} rating={rating} />
        )}
        <TestimonialsList>
          {testimonials.length === 1 ? (
            <Testimonial {...testimonials[0]} isFullWidth={true} />
          ) : (
            <Swiper {...swiperConfig}>
              {testimonials.map(({ id, ...props }, index) => (
                <SwiperSlide key={id}>
                  <Testimonial {...props} isGray={index % 2 !== 1} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </TestimonialsList>

        <TestimonialsControls centeredBtn={testimonials.length === 1}>
          {testimonials.length !== 1 && (
            <TestimonialsBtnArrow
              as="button"
              hideArrow={testimonials.length <= 2}
              direction="left"
              width={33}
              height={28}
              color="black"
              className="arrow-icon"
              onClick={handleSlidePrev}
            />
          )}
          {!!buttonLink && buttonTitle && (
            <Button as={Link} to={buttonLink} variant="outlined">
              {buttonTitle}
            </Button>
          )}
          {testimonials.length !== 1 && (
            <TestimonialsBtnArrow
              as="button"
              hideArrow={testimonials.length <= 2}
              direction="right"
              width={33}
              height={28}
              color="black"
              className="arrow-icon"
              onClick={handleSlideNext}
            />
          )}
        </TestimonialsControls>
      </Container>
    </TestimonialsWrapper>
  )
}

Testimonials.propTypes = {
  titleTag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  logoImage: PropTypes.object.isRequired,
  testimonials: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      flag: PropTypes.object,
      name: PropTypes.string,
      photo: PropTypes.object,
      showPhoto: PropTypes.bool,
      position: PropTypes.string,
      social: PropTypes.array,
      text: PropTypes.object,
    }),
  ).isRequired,
}

Testimonials.defaultProps = {
  sectionId: "",
}

export default Testimonials
