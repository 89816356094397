import styled from "@emotion/styled"

const TextFourSubtitle = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  font-style: normal;
  font-weight: normal;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 22px;
    line-height: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 45px;
  }
`

export default TextFourSubtitle
