import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListTwelveItem from "components/ListTwelve/ListTwelveItem"
import ListTwelveItemList from "components/ListTwelve/ListTwelveItemList"
import ListTwelveItemListItem from "components/ListTwelve/ListTwelveItemListItem"
import ListTwelveItemTitle from "components/ListTwelve/ListTwelveItemTitle"
import ListTwelveStack from "components/ListTwelve/ListTwelveStack"
import ListTwelveTitle from "components/ListTwelve/ListTwelveTitle"
import ListTwelveWrapper from "components/ListTwelve/ListTwelveWrapper"
import ListTwelveLink from "components/ListTwelve/ListTwelveLink"
import ListTwelveHeader from "components/ListTwelve/ListTwelveHeader"
import ListTwelveSubtitleText from "components/ListTwelve/ListTwelveSubtitleText"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListTwelve = ({
  title,
  titleTag,
  subtitleText,
  theme: colorVariant,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListTwelveWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListTwelveHeader>
        <ListTwelveTitle as={titleTag}>{title}</ListTwelveTitle>
        {subtitleText && (
          <ListTwelveSubtitleText
            className="list-two__text"
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListTwelveHeader>
      <ListTwelveStack lowerText={lowerText}>
        {listItems.map(({ id, title: itemTitle, titleTag: itemTitleTag, columnItems }) => (
          <ListTwelveItem key={id}>
            <ListTwelveItemList>
              <div>
                {itemTitle && <ListTwelveItemTitle as={itemTitleTag}>{itemTitle}</ListTwelveItemTitle>}
                {columnItems &&
                  columnItems.map(({ id: subItemId, title: subItemTitle, link }) => (
                    <ListTwelveItemListItem key={subItemId}>
                      {(link && <ListTwelveLink to={link}>{subItemTitle}</ListTwelveLink>) ||
                        subItemTitle}
                    </ListTwelveItemListItem>
                  ))}
              </div>
            </ListTwelveItemList>
          </ListTwelveItem>
        ))}
      </ListTwelveStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListTwelveWrapper>
)

ListTwelve.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      columnItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
}

ListTwelve.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListTwelve
