import styled from '@emotion/styled'

const ArticleAsideNavHeading = styled.div`
  display: flex;
  gap: 17px;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 14px;
  }
`

export default ArticleAsideNavHeading