import styled from "@emotion/styled"

const HeroCaseDescription = styled.ul`
  font-size: 18px;
  line-height: 30px;
  padding: 0;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }

  ${({ theme }) => theme.breakpoints.between("sm", "md")} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: grid;
    align-items: baseline;
    grid-row-gap: 16px;
    grid-column-gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }
`

export default HeroCaseDescription
