import styled from "@emotion/styled"

const AuthorDescription = styled.div`
  font-size: 16px;
  line-height: 30px;
  max-width: 1067px;

  p {
    margin-top: 0;
    margin-bottom: 0;
    & + p {
      margin-top: 20px;
    }
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 30px;
  }

  > a {
    text-decoration: none;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.gray[900]};
    border-bottom: 1px solid transparent;
    transition: all 0.2s;
    margin-bottom: -1px;
    max-width: max-content;
    width: 100%;

    &:hover {
      border-color: transparent;
    }
  }
`

export default AuthorDescription
