import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background-color: ${colorVariant === "Light"
    ? theme.palette.common.white
    : theme.palette.gray[50]};
`

const ListTwoCard = styled.div`
  display: block;
  height: auto;
  margin-bottom: 43px;
  position: relative;
  color: ${({ theme }) => theme.palette.gray[900]};
  text-decoration: none;
  border-radius: 7px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
    margin-bottom: 51px;
    padding: 47px 15px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    min-height: 350px;
    max-width: 683px;
    padding: 68px 60px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding: 58px 40px;
    min-height: 387px;
    width: calc(33% - 15px);
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding: 86px 83px;
    min-height: 413px;
    width: calc(33% - 20px);
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    @media (hover: hover) {
      &:not(:hover) {
        .btn {
          transform: translate(-50%, 50%);
        }

        .arrow-icon {
          opacity: 0;
          transform: scale(0);
        }
      }

      &:hover {
        .arrow-icon {
          opacity: 1;
          transform: scale(0.27);
        }

        .btn {
          ${({ theme }) => theme.breakpoints.between("md", "xl")} {
            transform: translate(-50%, 50%) scale(2.6);
          }

          ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
            transform: translate(-50%, 50%) scale(2.95);
          }

          ${({ theme }) => theme.breakpoints.up("2xl")} {
            transform: translate(-50%, 50%) scale(3.7);
          }

          &::before {
            border: 1px solid ${({ theme }) => theme.palette.primary.main};

            ${({ theme }) => theme.breakpoints.between("md", "xl")} {
              transform: translate(-50%, -50%) scale(0.59);
            }

            ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
              transform: translate(-50%, -50%) scale(0.59);
            }

            ${({ theme }) => theme.breakpoints.up("2xl")} {
              transform: translate(-50%, -50%) scale(0.59);
            }
          }
        }
      }
    }
  }

  @media (hover: none) {
    .btn {
      ${({ theme }) => theme.breakpoints.between("md", "xl")} {
        width: 52px;
        height: 52px;
      }

      ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
        width: 59px;
        height: 59px;
      }

      ${({ theme }) => theme.breakpoints.up("2xl")} {
        width: 74px;
        height: 74px;
      }

      &::before {
        top: 50%;
        left: 50%;

        ${({ theme }) => theme.breakpoints.between("md", "xl")} {
          width: 67px;
          height: 67px;
        }

        ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
          width: 76px;
          height: 76px;
        }

        ${({ theme }) => theme.breakpoints.up("2xl")} {
          width: 95px;
          height: 95px;
        }
      }
    }
  }

  ${dynamicStyle}
`

export default ListTwoCard
