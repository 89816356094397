import PropTypes from "prop-types"
import Container from "components/Container"
import TechTwoWrapper from "components/TechTwo/TechTwoWrapper"
import TechTwoTitle from "components/TechTwo/TechTwoTitle"
import TechTwoContent from "components/TechTwo/TechTwoContent"
import TechTwoText from "components/TechTwo/TechTwoText"
import TechTwoStack from "components/TechTwo/TechTwoStack"
import TechItem from "components/TechItem/TechItem"
import TechItemImage from "components/TechItem/TechItemImage"
import TechItemImageWrapper from "components/TechItem/TechItemImageWrapper"
import TechItemTitle from "components/TechItem/TechItemTitle"
import Link from "components/Link"
import htmlFormatting from "utils/htmlFormatting"

const TechTwo = ({ title, titleTag, sublineText: subtitleText, technologies, sectionId }) => (
  <TechTwoWrapper id={sectionId}>
    <Container>
      <TechTwoContent>
        <TechTwoTitle as={titleTag}>{title}</TechTwoTitle>
        {subtitleText && (
          <TechTwoText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </TechTwoContent>
      {technologies && (
        <TechTwoStack>
          {technologies.map(({ id: techId, techImage, techName, techUrl }) => (
            <TechItem
              as={(techUrl !== undefined && techUrl !== null && Link) || "div"}
              key={techId}
              to={techUrl}
            >
              <TechItemImageWrapper size="small">
                <TechItemImage {...techImage} />
              </TechItemImageWrapper>
              <TechItemTitle size="small" url={techUrl}>
                {techName}
              </TechItemTitle>
            </TechItem>
          ))}
        </TechTwoStack>
      )}
    </Container>
  </TechTwoWrapper>
)

TechTwo.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  sublineText: PropTypes.object.isRequired,
  sectionId: PropTypes.string,
  technologies: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      techImage: PropTypes.object,
      techName: PropTypes.string,
      techUrl: PropTypes.string,
    }),
  ).isRequired,
}

TechTwo.defaultProps = {
  sectionId: "",
}

export default TechTwo
