import styled from "@emotion/styled"

const ListFifteenItemTitle = styled.span`
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.05em;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 31px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 39px;
  }
`

export default ListFifteenItemTitle
