import styled from "@emotion/styled"

const PostListItemTag = styled.span`
  margin-block-start: 0;
  margin-block-end: 0;
  letter-spacing: 0.06em;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-decoration: none;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 16px;
    line-height: 34px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    font-size: 18px;
    line-height: 34px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 18px;
    line-height: 32px;
  }
`

export default PostListItemTag
