import styled from "@emotion/styled"

const TestimonialsList = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 51px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 68px;
  }
`

export default TestimonialsList
