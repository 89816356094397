import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ error, touched, theme }) => css`
  ${touched &&
  (error
    ? `
    border: 2px solid red;
    ${theme.breakpoints.down("lg")} {
      padding-left: 19px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    ${theme.breakpoints.up("lg")} {
      padding-left: 19px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    `
    : `
      border: 2px solid ${theme.palette.primary.main};
      ${theme.breakpoints.down("lg")} {
      padding-left: 19px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    ${theme.breakpoints.up("lg")} {
      padding-left: 19px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    `)}
`

const ArticleAsideBannerFormField = styled.input`
  font-size: 16px;
  line-height: 20px;
  background-color: #fff;
  padding-right: 15px;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 15px;
  width: 100%;
  resize: vertical;
  color: ${({ theme }) => theme.palette.common.black};
  margin-bottom: 15px;

  &::placeholder {
    color: #b2b2b2;
  }

  &:focus-visible {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 44px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-left: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  ${dynamicStyle}
`

export default ArticleAsideBannerFormField
