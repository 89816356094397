import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant, size }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) ||
  theme.palette.gray[100]};

  ${theme.breakpoints.between("md", "lg")} {
    flex: ${size === "Large" ? "1 1 100%" : "0 0 49%"};
  }

  ${theme.breakpoints.between("lg", "xl")} {
    flex: ${size === "Large" ? "1 1 100%" : "0 0 49%"};
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    flex: ${size === "Large" ? "0 0 48%" : "0 0 23%"};
  }

  ${theme.breakpoints.up("2xl")} {
    flex: ${size === "Large" ? "0 0 48%" : "0 0 23%"};
  }
`

const ListSixteenItem = styled.li`
  display: flex;
  border-radius: 10px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    justify-content: flex-start;
    padding: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    min-height: 266px;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    min-height: 266px;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 266px;
    justify-content: center;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    min-height: 266px;
    justify-content: center;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    min-height: 266px;
    justify-content: center;
    align-items: center;
  }

  ${dynamicStyle}
`

export default ListSixteenItem
