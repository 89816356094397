import PropTypes from "prop-types"
import * as React from "react"
import htmlFormatting from "utils/htmlFormatting"

import ListSeventeenItemWrapper from "./ListSeventeenItemWrapper"
import ListSeventeenItemTitle from "./ListSeventeenItemTitle"
import ListSeventeenItemButton from "./ListSeventeenItemButton"
import ListSeventeenItemHead from "./ListSeventeenItemHead"
import ListSeventeenItemText from "./ListSeventeenItemText"

const ListSeventeenItem = ({ id, title: itemTitle, titleTag, text, colorVariant, isBlog }) => {
  const [isActive, setIsActive] = React.useState(false)
  const setActiveHandler = () => {
    setIsActive(!isActive)
  }
  return (
    <ListSeventeenItemWrapper
      isBlog={isBlog}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      key={id}
      colorVariant={colorVariant}
    >
      <ListSeventeenItemHead onClick={setActiveHandler}>
        <ListSeventeenItemButton active={isActive} />
        <ListSeventeenItemTitle isBlog={isBlog} as={titleTag} itemProp="name">
          {itemTitle}
        </ListSeventeenItemTitle>
      </ListSeventeenItemHead>
      {text && (
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <ListSeventeenItemText
            isBlog={isBlog}
            itemProp="text"
            active={isActive}
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(text?.childMarkdownRemark?.html),
            }}
          />
        </div>
      )}
    </ListSeventeenItemWrapper>
  )
}

ListSeventeenItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  colorVariant: PropTypes.string.isRequired,
  isBlog: PropTypes.bool.isRequired
}

export default ListSeventeenItem
