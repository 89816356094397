import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Container from "components/Container"

const dynamicStyle = ({ textPosition }) => css`
  flex-direction: ${(textPosition === "Left" && "row") || "Right" || "row-reverse"};
`

const TextsTwoWrapper = styled(Container)`
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  ${dynamicStyle}
`
export default TextsTwoWrapper
