import React from "react"
import PropTypes from "prop-types"
import Link from "components/Link"
import FooterWrapper from "components/Footer/FooterWrapper"
import Container from "components/Container"
import FooterRow from "components/Footer/FooterRow"
// import FooterCompanyName from "components/Footer/FooterCompanyName"
import FooterCompanyTagline from "components/Footer/FooterCompanyTagline"
import FooterCompanyShortDesc from "components/Footer/FooterCompanyShortDesc"
import FooterSocialLink from "components/Footer/FooterSocialLink"
import FooterSubLink from "components/Footer/FooterSubLink"
import FooterNavTitle from "components/Footer/FooterNavTitle"
import FooterTitleWrapper from "components/Footer/FooterTitleWrapper"
import FooterOfficeText from "components/Footer/FooterOfficeText"
import FooterOfficeItem from "components/Footer/FooterOfficeItem"
import FooterBottom from "components/Footer/FooterBottom"
import FooterBottomText from "components/Footer/FooterBottomText"
import FooterFirstColumn from "components/Footer/FooterFirstColumn"
import FooterLegalLinks from "components/Footer/FooterLegalLinks"
import FooterColumnsStack from "components/Footer/FooterColumnsStack"
import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"
import FooterImage from "components/Footer/FooterImage"
import FooterRating from "components/Footer/FooterRatingWrap"
import UnstyledLink from "components/UnstyledLink"
import DangerouslySetHtmlContent from "utils/DangerouslySetHtmlContent"

const Footer = ({
  baseUrl,
  companyName,
  companyTagline,
  companyShortDescription,
  embdedContent,
  social,
  navigation,
  lastColumnTitle,
  offices,
  copyright,
  legalLinks,
  sectionId,
  logoImage,
  ratingText,
  ratingFooter,
  quantityReviews,
}) => (
  <FooterWrapper id={sectionId}>
    {embdedContent?.embdedContent && <DangerouslySetHtmlContent html={embdedContent?.embdedContent} />}
    <Container>
      <MicromarkingWrapper itemscope="" itemtype="http://schema.org/Organization">
        <Meta itemprop="name" content={companyName} />

        <FooterRow>
          <FooterFirstColumn>
            <FooterTitleWrapper>
              <UnstyledLink to={baseUrl}>
                <FooterImage itemprop="image" {...logoImage} />
              </UnstyledLink>
              {/* <FooterCompanyName to={baseUrl}>{companyName}</FooterCompanyName> */}
            </FooterTitleWrapper>
            <FooterCompanyTagline dangerouslySetInnerHTML={{ __html: companyTagline }} />
            <FooterCompanyShortDesc
              itemprop="description"
              dangerouslySetInnerHTML={{ __html: companyShortDescription }}
            />
            {social.map(({ id, title, link }) => (
              <div key={id}>
                <FooterSocialLink to={link} className="footer-link">
                  {title}
                </FooterSocialLink>
              </div>
            ))}
          </FooterFirstColumn>

          <FooterColumnsStack>
            {navigation.map(({ id, title, link, navigationSubItems }) => (
              <div key={id}>
                <div>
                  <FooterNavTitle as={Link} to={link}>
                    {title}
                  </FooterNavTitle>
                </div>
                <div>
                  {navigationSubItems.map(({ id: idItem, title: titleItem, link: linkItem }) => (
                    <div key={idItem}>
                      <FooterSubLink to={linkItem}>{titleItem}</FooterSubLink>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div>
              <FooterNavTitle>{lastColumnTitle}</FooterNavTitle>
              <div>
                {offices.map(({ id, email, location, phone }) => (
                  <FooterOfficeItem key={id}>
                    <div>
                      <FooterOfficeText as={Link} itemprop="email" to={`mailto:${email}`}>
                        {email}
                      </FooterOfficeText>
                    </div>
                    <div>
                      <MicromarkingWrapper
                        itemprop="address"
                        itemscope
                        itemtype="http://schema.org/PostalAddress"
                      >
                        <FooterOfficeText>{location}</FooterOfficeText>
                      </MicromarkingWrapper>
                    </div>
                    <div>
                      {!!phone && (
                        <FooterOfficeText as={Link} to={`tel:${phone.replace(/[^+\d]+/g, "")}`}>
                          {phone}
                          <Meta itemprop="telephone" content={phone} />
                        </FooterOfficeText>
                      )}
                    </div>
                  </FooterOfficeItem>
                ))}
              </div>
            </div>
          </FooterColumnsStack>
        </FooterRow>
      </MicromarkingWrapper>
      <FooterBottom>
        <FooterBottomText>
          © {new Date().getFullYear()} {copyright}
        </FooterBottomText>
        <FooterLegalLinks>
          {legalLinks.map(({ id, title, link }) => (
            <FooterBottomText as={Link} key={id} to={link} className="footer-link">
              {title}
            </FooterBottomText>
          ))}
        </FooterLegalLinks>
        <FooterRating
          quantityReviews={quantityReviews}
          logoImage={logoImage}
          ratingFooter={ratingFooter}
          ratingText={ratingText}
        />
      </FooterBottom>
    </Container>
  </FooterWrapper>
)

Footer.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyTagline: PropTypes.string.isRequired,
  companyShortDescription: PropTypes.string.isRequired,
  embdedContent: PropTypes.any.isRequired,
  sectionId: PropTypes.string,
  logoImage: PropTypes.object.isRequired,
  ratingText: PropTypes.string.isRequired,
  ratingFooter: PropTypes.number.isRequired,
  quantityReviews: PropTypes.number.isRequired,
  social: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  navigation: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      navigationSubItems: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string,
          title: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  lastColumnTitle: PropTypes.string.isRequired,
  offices: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      email: PropTypes.string,
      location: PropTypes.string,
      phone: PropTypes.string,
    }),
  ).isRequired,
  copyright: PropTypes.string.isRequired,
  legalLinks: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
}

Footer.defaultProps = {
  sectionId: "",
}

export default Footer
