import styled from '@emotion/styled'
import Button from 'components/Buttons/Button'

const GateForContentButton = styled(Button)`
  
   ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 100%;
  }
   
  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-left: 80px;
    padding-right: 80px;
  }
`

export default GateForContentButton