import styled from "@emotion/styled"
import Button from "components/Buttons/Button"

const CaseStudyButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-top: 30px;
    line-height: 16px;
    padding: 15px 30px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-top: 45px;
  }
`

export default CaseStudyButton
