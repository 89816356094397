import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ colorVariant, theme }) => css`
  color: ${colorVariant === "Dark" ? theme.palette.common.white : theme.palette.common.black};
`

const CTANotice = styled.div`
  display: block;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 18px;

    br {
      display: none;
    }
  }

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }

  ${dynamicStyle}
`

export default CTANotice
