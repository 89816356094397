import styled from "@emotion/styled"

const ListSixteenItemTitle = styled.span`
  display: block;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 20px;
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 20px;
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 24px;
  }
`

export default ListSixteenItemTitle
