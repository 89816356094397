import styled from "@emotion/styled"

const CTAFormClientPosition = styled.span`
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    line-height: 100%;
  }
  ${({ theme }) => theme.breakpoints.up("2xl")} {
    letter-spacing: 1.1px;
  }
`

export default CTAFormClientPosition
