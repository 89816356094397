import styled from "@emotion/styled"
import Title from "components/Title"

const ListFifteenTitle = styled(Title)`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 34px;
    margin-left: 30px;
    line-height: 1.1;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 45px;
    padding-bottom: 88px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-bottom: 111px;
    font-size: 60px;
  }
`

export default ListFifteenTitle
