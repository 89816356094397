import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import Link from "components/Link"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListFiveHeader from "components/ListFive/ListFiveHeader"
import ListFiveItem from "components/ListFive/ListFiveItem"
import ListFiveItemContainer from "components/ListFive/ListFiveItemContainer"
import ListFiveItemContainerBlock from "components/ListFive/ListFiveItemContainerBlock"
import ListFiveItemContainerCircle from "components/ListFive/ListFiveItemContainerCircle"
import ListFiveItemContainerIcon from "components/ListFive/ListFiveItemContainerIcon"
import ListFiveItemContainerSVG from "components/ListFive/ListFiveItemContainerSVG"
import ListFiveItemContent from "components/ListFive/ListFiveItemContent"
import ListFiveItemLink from "components/ListFive/ListFiveItemLink"
import ListFiveItemList from "components/ListFive/ListFiveItemList"
import ListFiveItemListItem from "components/ListFive/ListFiveItemListItem"
import ListFiveItemText from "components/ListFive/ListFiveItemText"
import ListFiveItemTitle from "components/ListFive/ListFiveItemTitle"
import ListFiveItemWrapper from "components/ListFive/ListFiveItemWrapper"
import ListFiveStack from "components/ListFive/ListFiveStack"
import ListFiveText from "components/ListFive/ListFiveText"
import ListFiveTitle from "components/ListFive/ListFiveTitle"
import ListFiveWrapper from "components/ListFive/ListFiveWrapper"
import ListFiveItemInner from "components/ListFive/ListFiveItemInner"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListFive = ({
  title,
  titleTag,
  theme: colorVariant,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListFiveWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListFiveHeader>
        <ListFiveTitle as={titleTag}>{title}</ListFiveTitle>
        {subtitleText && (
          <ListFiveText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListFiveHeader>
      <ListFiveStack lowerText={lowerText}>
        {listItems.map(({ id, icon, title: itemTitle, titleTag: itemTitleTag, text, url, buttonTitle, columnItems }) => (
          <ListFiveItem key={id}>
            <ListFiveItemContainer>
              <ListFiveItemContainerBlock colorVariant={colorVariant} />
              <ListFiveItemContainerSVG colorVariant={colorVariant}>
                <ListFiveItemContainerCircle colorVariant={colorVariant}>
                  {(url && (
                    <Link to={url}>
                      <ListFiveItemContainerIcon {...icon} />
                    </Link>
                  )) || <ListFiveItemContainerIcon {...icon} />}
                </ListFiveItemContainerCircle>
              </ListFiveItemContainerSVG>
              <ListFiveItemContainerBlock colorVariant={colorVariant} />
            </ListFiveItemContainer>
            <ListFiveItemWrapper colorVariant={colorVariant}>
            <ListFiveItemTitle as={itemTitleTag}>{itemTitle}</ListFiveItemTitle>
              <ListFiveItemInner>
                <ListFiveItemContent>
                  {text && (
                    <ListFiveItemText
                      dangerouslySetInnerHTML={{
                        __html: htmlFormatting(text?.childMarkdownRemark?.html),
                      }}
                    />
                  )}
                  {url && <ListFiveItemLink to={url}>{buttonTitle}</ListFiveItemLink>}
                </ListFiveItemContent>
                {columnItems && (
                  <ListFiveItemList>
                    {columnItems.map(({ id: columnItemId, title: columnItemTitle }) => (
                      <ListFiveItemListItem key={columnItemId}>
                        {columnItemTitle}
                      </ListFiveItemListItem>
                    ))}
                  </ListFiveItemList>
                )}
              </ListFiveItemInner>
            </ListFiveItemWrapper>
          </ListFiveItem>
        ))}
      </ListFiveStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListFiveWrapper>
)

ListFive.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      title: PropTypes.string,
      text: PropTypes.object,
      url: PropTypes.string,
      buttonTitle: PropTypes.string,
      columnItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
}

ListFive.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListFive
