import styled from "@emotion/styled"

const Section404Wrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 66px;
    padding-bottom: 151px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    padding-top: 82px;
    padding-bottom: 189px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 103px;
    padding-bottom: 236px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 129px;
    padding-bottom: 296px;
  }
`

export default Section404Wrapper
