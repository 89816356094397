import styled from "@emotion/styled"

const ListElevenItemTitle = styled.span`
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    line-height: 27px;
    margin-bottom: 35px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    line-height: 34px;
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }
`

export default ListElevenItemTitle
