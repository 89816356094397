import styled from "@emotion/styled"

const ListElevenItem = styled.li`
  &:not(:last-child) {
    ${({ theme }) => theme.breakpoints.down("xl")} {
      margin-bottom: 148px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    flex-basis: 33%;
  }
`

export default ListElevenItem
