import styled from "@emotion/styled"
import Image from "components/Image"

const ListTenItemIcon = styled(Image)`
  width: 80px;
  height: 72px;
  margin-left: auto;
  margin-right: auto;
  display: block;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 29px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    flex: 0 0 64px;
    max-width: 64px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    flex: 0 0 80px;
    max-width: 80px;
  }
`

export default ListTenItemIcon
