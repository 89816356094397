import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.gray[50]) || theme.palette.common.white};
`

const ListSeventeenWrapper = styled.section`
  ${({ theme, isBlog }) =>
    !isBlog
      ? `${theme.breakpoints.down("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${theme.breakpoints.up("2xl")} {
    padding-top: 120px;
    padding-bottom: 120px;
  }`
      : `${theme.breakpoints.down("xl")} {
        padding-top: 30px;
    padding-bottom: 20px;
  }

  ${theme.breakpoints.up("xl")} {
        padding-top: 30px;
    padding-bottom: 30px;
  }`}

  ${dynamicStyle}
`

export default ListSeventeenWrapper
