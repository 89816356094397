import styled from "@emotion/styled"
import Link from "components/Link"

const ListTwoCardBtnLink = styled(Link)`
  position: absolute;
  width: 160%;
  height: 125%;
  top: 0;
  left: -28%;
`

export default ListTwoCardBtnLink
