import styled from "@emotion/styled"
import Image from "components/Image"

const CertificationPhoto = styled(Image)`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    position: relative;

    > picture {
      > img {
        object-fit: contain !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
    }
  }
`

export default CertificationPhoto
