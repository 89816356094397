import styled from "@emotion/styled"

const ArticleAuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: max-content;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 15px;
  }
`

export default ArticleAuthorWrapper
