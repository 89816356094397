import styled from "@emotion/styled"

const ListEighteenItemIconWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 80%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-top: 70%;
  }
`

export default ListEighteenItemIconWrapper
