const Circle = () => (
  <svg viewBox="0 0 902 902" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M128.346 136.527C240.75 21.2003 406.142 -25.3992 562.22 14.2821C718.298 53.9634 841.35 173.897 885.024 328.905C928.698 483.913 886.359 650.447 773.955 765.773C661.551 881.1 496.159 927.7 340.081 888.018C184.003 848.337 60.9508 728.403 17.2769 573.395C-26.3971 418.387 15.9424 251.854 128.346 136.527Z"
      fill="url(#paint0_linear_2:727)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2:727"
        x1="309.994"
        y1="-49.8439"
        x2="592.307"
        y2="952.144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFEFF6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default Circle
