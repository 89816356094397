import styled from "@emotion/styled"
import Image from "components/Image"

const ListThreeItemImage = styled(Image)`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 211px;
    height: 228px;
    margin-bottom: 2px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 148px;
    height: 160px;
    margin-bottom: 10px;
  }
`

export default ListThreeItemImage
