import styled from '@emotion/styled'

const GateForContentStageHeading = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 29px;
    order: -1;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    gap: 5px;
  }
`
export default GateForContentStageHeading
