import styled from "@emotion/styled"

const ArticleCTATitle = styled.h3`
  font-family: Gilroy;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #38b148;
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 20px;
    line-height: 36px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 15px;
    line-height: 28px;
    font-size: 28px;
  }
`

export default ArticleCTATitle
