import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import MicromarkingWrapper from "components/MicromarkingWrapper"

import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListSeventeenWrapper from "components/ListSeventeen/ListSeventeenWrapper"
import ListSeventeenHeader from "components/ListSeventeen/ListSeventeenHeader"
import ListSeventeenTitle from "components/ListSeventeen/ListSeventeenTitle"
import ListSeventeenText from "components/ListSeventeen/ListSeventeenText"
import ListSeventeenStack from "components/ListSeventeen/ListSeventeenStack"
import ListSeventeenItem from "components/ListSeventeen/ListSeventeenItem"
import ListSeventeenColumn from "components/ListSeventeen/ListSeventeenColumn"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListSeventeen = ({
  title,
  isBlog,
  theme: colorVariant,
  titleTag,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => {
  return (
    <ListSeventeenWrapper isBlog={isBlog} id={sectionId} colorVariant={colorVariant}>
      <Container>
        <ListSeventeenHeader isBlog={isBlog}>
          <ListSeventeenTitle isBlog={isBlog} as={titleTag}>
            {title}
          </ListSeventeenTitle>
          {subtitleText && (
            <ListSeventeenText
              dangerouslySetInnerHTML={{
                __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
              }}
            />
          )}
        </ListSeventeenHeader>
        <MicromarkingWrapper itemscope="" itemtype="http://schema.org/FAQPage">
          <ListSeventeenStack isBlog={isBlog} lowerText={lowerText}>
            <ListSeventeenColumn>
              {listItems.slice(0, Math.ceil(listItems.length / 2)).map((el) => (
                <ListSeventeenItem
                  isBlog={isBlog}
                  key={el.id}
                  {...el}
                  colorVariant={colorVariant}
                />
              ))}
            </ListSeventeenColumn>
            <ListSeventeenColumn>
              {listItems.slice(Math.ceil(listItems.length / 2)).map((el) => (
                <ListSeventeenItem
                  isBlog={isBlog}
                  key={el.id}
                  {...el}
                  colorVariant={colorVariant}
                />
              ))}
            </ListSeventeenColumn>
          </ListSeventeenStack>
        </MicromarkingWrapper>
      </Container>
      {lowerText && (
        <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
      )}
    </ListSeventeenWrapper>
  )
}

ListSeventeen.propTypes = {
  title: PropTypes.string.isRequired,
  isBlog: PropTypes.bool.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.object,
    }),
  ).isRequired,
}

ListSeventeen.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListSeventeen
