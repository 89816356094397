import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[50]};
`

const ListFourItemContent = styled.div`
  border-radius: 0 0 20px 20px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 52%;
    border-radius: 0 20px 20px 0;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding: 39px 53px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding: 39px 23px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding: 59px 53px;
  }

  ${dynamicStyle}
`

export default ListFourItemContent
