import styled from "@emotion/styled"

const ArticleContentUL = styled.ul`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-left: 28px;
    margin-top: -10px;
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    padding-left: 18px;
    margin-top: -10px;
    margin-bottom: 19px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-left: 22px;
    margin-top: -10px;
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-left: 28px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
`

export default ArticleContentUL
