import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ showImage, theme }) => css`
  ${theme.breakpoints.down("md")} {
    width: ${showImage ? "auto" : "100%"};
  }
  ${theme.breakpoints.between("md", "lg")} {
    width: ${showImage ? "auto" : "70%"};
  }
  ${theme.breakpoints.between("lg", "xl")} {
    ${showImage
      ? `padding-top: 40px;
      padding-bottom: 40px;`
      : ""}
  }

  ${theme.breakpoints.up("xl")} {
    ${showImage
      ? `padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 35px;`
      : ""}
  }
`

const CTATextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 50px;
  }
  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    padding-right: 20px;
  }
  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    width: 60%;
    padding-right: 20px;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: auto;
    justify-content: center;
    height: 100%;
    padding-right: 30px;
  }
  ${dynamicStyle}
`

export default CTATextWrapper
