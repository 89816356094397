import { React, useRef, useEffect, useState } from "react"

import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import BlogListRecommendationsWrapper from "components/BlogListRecommendations/BlogListRecommendationsWrapper"
import BlogListRecommendationsInnerWrapper from "components/BlogListRecommendations/BlogListRecommendationsInnerWrapper"
import BlogListRecommendationsItem from "components/BlogListRecommendations/BlogListRecommendationsItem"
import BlogListRecommendationsItemTitle from "components/BlogListRecommendations/BlogListRecommendationsItemTitle"
import BlogListRecommendationsItemImage from "components/BlogListRecommendations/BlogListRecommendationsItemImage"
import BlogListRecommendationsBtn from "components/BlogListRecommendations/BlogListRecommendationsBtn"
import Container from "components/Container"

const BlogListRecommendations = ({ items }) => {
  const swiperRef = useRef(null)
  const slidesPerViewPC = useRef(2)
  const [realIndex, setRealIndex] = useState(0)
  const [posts, setPosts] = useState([]);

  const handleSlideChange = () => {
    setRealIndex(swiperRef.current.swiper.realIndex)
  }

  const handleSlidePrev = () => {
    swiperRef.current.swiper.slidePrev(500)
    setRealIndex(swiperRef.current.swiper.realIndex)
  }

  const handleSlideNext = () => {
    swiperRef.current.swiper.slideNext(500)
    setRealIndex(swiperRef.current.swiper.realIndex)
  }

  const swiperConfig = {
    slidesPerView: 1.2,
    spaceBetween: 15,
    ref: swiperRef,
    onSlideChange: handleSlideChange,
    breakpoints: {
      992: {
        slidesPerView: slidesPerViewPC.current,
        spaceBetween: 25,
      },
      1440: {
        slidesPerView: slidesPerViewPC.current,
        spaceBetween: 25,
      },
    },
  }

  useEffect(() => {
    setPosts(items.filter((post)=> post.showingRecomendations))
  },[items])

  if (!posts?.length) return null;
 
  return (
    <BlogListRecommendationsWrapper>
      <Container>
        <BlogListRecommendationsInnerWrapper centeredBlock={posts.length === 1}>
          {posts.length > 2 && (
            <BlogListRecommendationsBtn
              onClick={handleSlidePrev}
              isHidden={realIndex === 0}
              direction="left"
            />
          )}
          <Swiper style={{gridColumn: '2'}} ref={swiperRef} {...swiperConfig}>
            {posts.map(({ id, title, coverImage, link }) => (
              <SwiperSlide key={id}>
                <BlogListRecommendationsItem to={link}>
                  <BlogListRecommendationsItemImage
                    itemprop="image"
                    {...coverImage}
                    objectFit="cover"
                  />
                  <BlogListRecommendationsItemTitle>{title}</BlogListRecommendationsItemTitle>
                </BlogListRecommendationsItem>
              </SwiperSlide>
            ))}
          </Swiper>
          {posts.length > 2 && (
            <BlogListRecommendationsBtn
              isHidden={slidesPerViewPC.current + realIndex === posts.length}
              onClick={handleSlideNext}
            />
          )}
        </BlogListRecommendationsInnerWrapper>
      </Container>
    </BlogListRecommendationsWrapper>
  )
}

BlogListRecommendations.propTypes = {
  items: PropTypes.object.isRequired,
}

export default BlogListRecommendations
