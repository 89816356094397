import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  ${colorVariant === "Dark" && `color: ${theme.palette.common.white}`};
`

const ListNineItemText = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;
  letter-spacing: 0.06em;
  margin-bottom: auto;
  font-style: normal;
  font-size: 18px;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-weight: normal;
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-weight: 500;
    line-height: 35px;
  }

  ${dynamicStyle}
`
export default ListNineItemText
