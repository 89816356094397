import styled from '@emotion/styled'

const ArticleAuthorsWrapper = styled.div`
display: flex;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    flex-direction: row;
    gap: 25px;
  }
`

export default ArticleAuthorsWrapper