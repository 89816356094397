import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ colorVariant, showImage, theme }) => css`
  background-color: ${(colorVariant === "Dark" && theme.palette.gray[900]) ||
  theme.palette.common.white};

  ${theme.breakpoints.down("lg")} {
    min-height: ${showImage ? "641px" : "330px"};
  }

  ${theme.breakpoints.up("lg")} {
    min-height: ${showImage ? "auto" : "330px"};
    ${showImage
      ? ""
      : `padding-top: 60px;
      padding-bottom: 60px;`}
  }
`

const CTASectionWrapper = styled.section`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 60px;
    padding-bottom: 60px;
    flex-direction: column;
  }
  ${dynamicStyle}
`

export default CTASectionWrapper
