import styled from "@emotion/styled"

const CertificationModalEsc = styled.span`
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 10;
  cursor: pointer;
  padding: 20px;
  margin-left: auto;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  &:before {
    transform: translateX(-50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) rotate(-45deg);
  }
`

export default CertificationModalEsc
