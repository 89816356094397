import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { css } from "@emotion/react"
import Link from "components/Link"

const BreadcrumbsItemEl = ({ isActive, to, ...props }) => {
  if (to) {
    return <Link isActive={isActive} to={to} {...props} />
  }

  return <span {...props} />
}

const dynamicStyles = ({ theme, isActive }) => css`
  color: ${(isActive && theme.palette.gray[900]) || theme.palette.gray[600]};
`

const BreadcrumbsItem = styled(BreadcrumbsItemEl)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  padding: 5px 13px;
  ${dynamicStyles}
`

export default BreadcrumbsItem

BreadcrumbsItemEl.propTypes = {
  isActive: PropTypes.bool,
  to: PropTypes.string,
}

BreadcrumbsItemEl.defaultProps = {
  isActive: false,
  to: "",
}
