import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import useHasMounted from "../hooks/useHasMounted"
import useUserId from "../hooks/useUserId"

const hubspotContact = async ({ name, email, company, message, phone, userID }) => {
  const utmMark = document.cookie
    .split(";")
    .filter((n) => n.trim().startsWith("utm") || n.trim().startsWith("sourceRefer"))
    .map((e) => e.trim().split("="))
    .reduce((accum, el) => ({ ...accum, [el[0]]: el[1] }), {})

  const obj = {
    firstname: name?.split(" ")[0],
    lastname: name?.split(" ")?.slice(1)?.join(" ") ?? "empty",
    email,
    userID,
    company,
    message,
    phone,
    ...utmMark,
  }

  fetch("/.netlify/functions/hubspot-contact", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  })
}

const NetlifyForm = ({ children, formName, preSubmit, postSubmit, formValues, ...props }) => {
  const [inNetlifyBuild, setInNetlifyBuild] = useState(true)
  const [honey, setHoney] = useState("")
  const hasMounted = useHasMounted()
  const leadId = useUserId()

  useEffect(() => {
    setInNetlifyBuild(false)
  }, [])

  const formEncodeString = (str) => encodeURIComponent(str).replace(/%20/g, "+")
  const encodeData = (obj) =>
    Object.entries(obj)
      .map((pair) => `${formEncodeString(pair[0])}=${formEncodeString(pair[1])}`)
      .join("&")

  const handleSubmit = async () => {
    try {
      return await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeData({
          ...formValues,
          userID: leadId,
          "form-name": formName,
          infoo: honey,
        }),
      })
    } catch (error) {
      return null
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if ((preSubmit && (await preSubmit())) || !preSubmit) {
      if (await handleSubmit()) {
        if (postSubmit) {
          postSubmit()
          if (formName !== "HeroFeedback") {
            hubspotContact({ ...formValues, userID: leadId })
            if (typeof window !== "undefined" && window.dataLayer) window.dataLayer.push({ event: "cta_form", params: { lead_id: leadId, form_name: formName } })
          }
        }
      } else {
        throw new Error("Error submitting to Netlify")
      }
    } else {
      throw new Error("Error validation ReCaptcha. You are bot.")
    }
  }

  if (inNetlifyBuild && !hasMounted)
    return (
      <form
        {...props}
        name={formName}
        onSubmit={onSubmit}
        data-netlify="true"
        // eslint-disable-next-line react/no-unknown-property
        netlify-honeypot="infoo"
      >
        {children}
        <p style={{ opacity: "0", position: "absolute", top: "0", left: "0" }}>
          <input
            style={{ width: "0", height: "0", zIndex: "-1" }}
            name="userID"
            aria-label="userID"
            value=""
          />
        </p>
      </form>
    )
    return (
      <form {...props} onSubmit={onSubmit}>
        {children}
        <p style={{ opacity: "0", position: "absolute", top: "0", left: "0" }}>
          <input
            style={{ width: "0", height: "0", zIndex: "-1" }}
            name="description"
            aria-label="description"
            value={honey}
            onChange={(e) => setHoney(e.target.value)}
          />
          <input
            style={{ width: "0", height: "0", zIndex: "-1" }}
            name="userID"
            aria-label="userID"
            value=""
          />
        </p>
      </form>
    )
}

NetlifyForm.propTypes = {
  formName: PropTypes.string.isRequired,
  formValues: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  preSubmit: PropTypes.func,
  postSubmit: PropTypes.func,
}

NetlifyForm.defaultProps = {
  children: null,
  preSubmit: () => true,
  postSubmit: () => {},
}

export default NetlifyForm
