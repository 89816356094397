import styled from "@emotion/styled"

const ListSevenSubtitle = styled.div`
  letter-spacing: 0.06em;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 39px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 79px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 850px;
    margin-bottom: 94px;
  }

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    max-width: 900px;
    font-size: 20px;
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 950px;
    font-size: 24px;
    line-height: 41px;
    margin-bottom: 137px;
  }

  p {
    margin: 0;
  }
`

export default ListSevenSubtitle
