import styled from "@emotion/styled"

const HeroCaseDemoWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("xl")} {
    position: absolute;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 629px;
    right: -70px;
    top: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 786px;
    right: 30px;
    top: 10px;
  }
`

export default HeroCaseDemoWrapper
