import styled from "@emotion/styled"

const EventListItemHeader = styled.span`
  display: flex;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 10px;
    gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    justify-content: space-between;
    margin-bottom: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    justify-content: space-between;
    margin-bottom: 33px;
  }
`

export default EventListItemHeader
