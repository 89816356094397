import styled from "@emotion/styled"

const TechThreeStack = styled.div`
  background-color: ${({ theme }) => theme.palette.gray[50]};
  margin-bottom: 45px;
  display: flex;
  gap: 39px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    overflow-x: auto;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    grid-area: 2/1;
    flex-wrap: wrap;
    gap: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    grid-area: 2/1;
    flex-wrap: wrap;
  }
  & .swiper-slide {
    width: auto;
  }
`
export default TechThreeStack
