import { css } from "@emotion/react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const dynamicStyles = ({ maxWidth }) => css`
  ${maxWidth && `max-width: ${maxWidth}px;`}

  ${!maxWidth && `padding-top: 56%;`}

  ${maxWidth >= 300 &&
  maxWidth <= 400 &&
  `
    height: 220px;
  `}

  ${maxWidth >= 401 &&
  maxWidth <= 500 &&
  `
    height: 280px;
  `}

  ${maxWidth >= 501 &&
  maxWidth <= 600 &&
  `
    height: 360px;
  `}

   ${maxWidth >= 601 &&
  maxWidth <= 700 &&
  `
    height: 400px;
  `}

  ${maxWidth >= 701 &&
  maxWidth <= 800 &&
  `
    height: 420px;
  `}

  ${maxWidth >= 801 &&
  maxWidth <= 900 &&
  `
    height: 490px;
  `}

   ${maxWidth >= 901 &&
  maxWidth <= 1000 &&
  `
    height: 520px;
  `}
`

const AlienVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-top: 56%;
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${dynamicStyles};
    margin-bottom: 50px;
  }
`

const VideoInner = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const ArticleVideoWrapper = ({ maxWidth, video, iframe }) => {
  if (iframe) {
    return (
      <VideoWrapper maxWidth={iframe.maxWidth}>
        <AlienVideo src={iframe.iframeLink} name={iframe.name} allowfullscreen />
      </VideoWrapper>
    )
  }

  return (
    <VideoWrapper maxWidth={maxWidth}>
      <VideoInner controls name="media">
        <source src={video.file.url} type="video/mp4" />
      </VideoInner>
    </VideoWrapper>
  )
}

export default ArticleVideoWrapper

ArticleVideoWrapper.propTypes = {
  video: PropTypes.exact().isRequired,
  iframe: PropTypes.string,
  maxWidth: PropTypes.number,
}

ArticleVideoWrapper.defaultProps = {
  iframe: false,
  maxWidth: null,
}
