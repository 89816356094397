import styled from "@emotion/styled"
import TextsTitle from "components/Texts/TextsTitle"

const TextFourTitle = styled(TextsTitle)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 40px;
    line-height: 79px;
  }
`

export default TextFourTitle
