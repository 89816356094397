import * as React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"

import Container from "components/Container"
import TechThreeWrapper from "components/TechThree/TechThreeWrapper"
import TechThreeTitle from "components/TechThree/TechThreeTitle"
import TechThreeContent from "components/TechThree/TechThreeContent"
import TechThreeText from "components/TechThree/TechThreeText"
import TechThreeLink from "components/TechThree/TechThreeLink"
import TechThreeTitleWrapper from "components/TechThree/TechThreeTitleWrapper"
import TechThreeStack from "components/TechThree/TechThreeStack"
import TechThreeCategory from "components/TechThree/TechThreeCategory"
import TechThreeCategoryTitle from "components/TechThree/TechThreeCategoryTitle"
import TechItem from "components/TechItem/TechItem"
import TechItemImage from "components/TechItem/TechItemImage"
import TechItemImageWrapper from "components/TechItem/TechItemImageWrapper"
import TechItemTitle from "components/TechItem/TechItemTitle"
import Link from "components/Link"
import htmlFormatting from "utils/htmlFormatting"
import useMedia from "../hooks/useMedia"
import useHasMounted from "../hooks/useHasMounted"

const TechThree = ({ title, titleTag, techCategory, sectionId }) => {
  const showSwiper = useMedia(["(min-width: 992px)"], [false], true)
  const swiperConfig = {
    slidesPerView: "auto",
    spaceBetween: 40,
    allowTouchMove: true,
    navigation: true,
  }

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }
  return (
    <TechThreeWrapper id={sectionId}>
      <Container>
        <TechThreeTitle as={titleTag}>{title}</TechThreeTitle>
        {techCategory.map(
          ({ title: categoryTitle, titleTag: categoryTitleTag, text, buttonText, buttonUrl, id, technologies }) => (
            <TechThreeCategory key={id}>
              <TechThreeTitleWrapper>
                <TechThreeCategoryTitle as={categoryTitleTag}>{categoryTitle}</TechThreeCategoryTitle>
              </TechThreeTitleWrapper>
              {technologies && (
                <TechThreeStack>
                  {showSwiper ? (
                    <Swiper modules={[Navigation]} {...swiperConfig}>
                      {technologies.map(({ id: techId, techImage, techName, techUrl }) => (
                        <SwiperSlide key={techId}>
                          <TechItem
                            as={(techUrl !== undefined && techUrl !== null && Link) || "div"}
                            to={techUrl}
                          >
                            <TechItemImageWrapper size="normal">
                              <TechItemImage {...techImage} />
                            </TechItemImageWrapper>
                            <TechItemTitle size="normal" url={techUrl}>
                              {techName}
                            </TechItemTitle>
                          </TechItem>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    technologies.map(({ id: techId, techImage, techName, techUrl }) => (
                      <TechItem
                        as={(techUrl !== undefined && techUrl !== null && Link) || "div"}
                        to={techUrl}
                        key={techId}
                      >
                        <TechItemImageWrapper size="normal">
                          <TechItemImage {...techImage} />
                        </TechItemImageWrapper>
                        <TechItemTitle size="normal" url={techUrl}>
                          {techName}
                        </TechItemTitle>
                      </TechItem>
                    ))
                  )}
                </TechThreeStack>
              )}
              <TechThreeContent>
                {text && (
                  <TechThreeText
                    dangerouslySetInnerHTML={{
                      __html: htmlFormatting(text?.childMarkdownRemark?.html),
                    }}
                  />
                )}
                <TechThreeLink to={buttonUrl}>{buttonText}</TechThreeLink>
              </TechThreeContent>
            </TechThreeCategory>
          ),
        )}
      </Container>
    </TechThreeWrapper>
  )
}

TechThree.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  sublineText: PropTypes.object,
  sectionId: PropTypes.string,
  techCategory: PropTypes.arrayOf(
    PropTypes.shape({
      categoryTitle: PropTypes.string,
      text: PropTypes.object,
      buttonText: PropTypes.string,
      buttonUrl: PropTypes.string,
      id: PropTypes.string,
      technologies: PropTypes.arrayOf(
        PropTypes.exact({
          techName: PropTypes.string,
          techUrl: PropTypes.string,
          id: PropTypes.string,
          techImage: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
}

TechThree.defaultProps = {
  sublineText: "",
  sectionId: "",
}

export default TechThree
