import styled from "@emotion/styled"

const ListTwoCardTitle = styled.span`
  font-weight: 700;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }
`

export default ListTwoCardTitle
