import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ addMargin, theme }) => css`
  ${theme.breakpoints.down("3xl")} {
    margin-bottom: ${addMargin ? "15px" : "0"};
  }

  ${theme.breakpoints.up("3xl")} {
    margin-bottom: ${addMargin ? "25px" : "0"};
  }
`

const CTATitle = styled.h1`
  display: block;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  font-weight: bold;
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    br {
      display: none;
    }
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 80px;
    font-size: 60px;
  }

  ${dynamicStyle}
`

export default CTATitle
