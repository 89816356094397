import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ active }) => css`
  ${active
    ? `
    margin-top: 30px;
      transition: 1s cubic-bezier(0, 1, 0, 1);
      max-height: 243px;
    `
    : `
    transition: 1s cubic-bezier(1, 0, 1, 0);
    max-height: 0;
  `}
`

const ArticleAsideNavList = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  scroll-behavior: smooth;

  ${'' /* &::before {
    content: '';
    display: block;
    width: 100%;
    height: 48px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #f8f8f8 100%);
  } */}

  ::-webkit-scrollbar {
    width: 8px;

    &-track {
      background: #e8e7e7;
      border-radius: 6px;
    }

    &-thumb {
      background: ${({ theme }) => theme.palette.primary.main};
      border-radius: 6px;
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    ${dynamicStyle}
    padding-right: 0px;

  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-height: 480px; {/* 27vh */};
    padding-right: 0px;

  }
`

export default ArticleAsideNavList
