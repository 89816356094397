import styled from "@emotion/styled"

const AuthorEmailLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`

export default AuthorEmailLink
