import styled from "@emotion/styled"

const ListElevenItemNumber = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.palette.primary.main};
  opacity: 0.3;
  font-family: ${({ theme }) => theme.typography.fontFamilyMontserrat};
  font-weight: 700;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 17px));
  z-index: -1;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 130px;
    line-height: 130px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 137px;
    line-height: 137px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 144px;
    line-height: 144px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 180px;
    line-height: 180px;
  }
`

export default ListElevenItemNumber
