import styled from '@emotion/styled'

const GateForContentWrapper = styled.section`
  background-color: ${({ theme }) => theme.palette.gray[50]};
  border-radius: 25px;
  border: 1px solid #e1e1e1;
  display: flex;
  gap: 20px;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    flex-direction: column;
    padding: 40px 0 40px 30px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    flex-direction: row;
  }
`

export default GateForContentWrapper