import styled from "@emotion/styled"

const CertificationSpaceModal = styled.div`
  cursor: pointer;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.common.black};
  opacity: 0;
  transition: opacity 0.4s;
  z-index: 15;
  pointer-events: none;

  &.active {
    opacity: 0.5;
    pointer-events: auto;
  }
`

export default CertificationSpaceModal
