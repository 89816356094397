import styled from "@emotion/styled"

const ListSevenItemHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-top: -5px;
  }
`

export default ListSevenItemHeaderWrapper
