import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ isGray, isFullWidth, theme }) => css`
  ${theme.breakpoints.up("xl")} {
    ${isGray ? `background-color: ${theme.palette.gray[100]};` : ""}
    ${!isFullWidth
      ? "max-width: 789px;"
      : `
    max-width: 990px;
    margin: 0 auto;
    `}
  }
`

const TestimonialWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding: 46px 82px 66px 78px;
    border-radius: ${({ theme }) => theme.borderRadius}px;
  }

  ${dynamicStyle}
`

export default TestimonialWrapper
