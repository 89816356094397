import styled from '@emotion/styled'
import { css } from "@emotion/react"

const dynamicStyle = ({ error, touched, theme }) => css`
  ${touched &&
  (error
    ? "border: 2px solid red; padding: 14px 19px 14px 19px;"
    : `border: 2px solid ${theme.palette.primary.main};   padding: 14px 19px 14px 19px;`)}
`

const GateForContentFormField = styled.input`
  height: 50px;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 15px;
  padding: 15px 20px 15px 20px;
  background-color: #fff;

  &::placeholder {
    color: #b2b2b2;
  }

  &:focus-visible {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 250px;
  }

  ${dynamicStyle}
`

export default GateForContentFormField