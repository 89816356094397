import * as React from "react"
import PropTypes from "prop-types"
import Button from "components/Buttons/Button"
import Link from "components/Link"
import { Swiper, SwiperSlide } from "swiper/react"
import PostListWrapper from "components/PostEvents/PostListWrapper"
import PostListStack from "components/PostEvents/PostListStack"
import PostListContainer from "components/PostEvents/PostListContainer"
import PostListItem from "components/PostItem/PostListItem"
import PostListTitle from "components/PostEvents/PostListTitle"
import PostListBtnArrow from "components/PostEvents/PostListBtnArrow"
import PostListControls from "components/PostEvents/PostListControls"
import PostListBackground from "components/PostEvents/PostListBackground"

import DottedBgBig from "components/Icons/DottedBgBig"

const PostList = ({ title, titleTag, postItems, url, buttonText, showPoints }) => {
  const swiperRef = React.useRef(null)
  const swiperConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    spaceBetween: 0,
    allowTouchMove: false,
    ref: swiperRef,
    breakpoints: {
      1280: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 140,
      },
    },
  }

  const handleSlidePrev = () => swiperRef.current.swiper.slidePrev(300)
  const handleSlideNext = () => swiperRef.current.swiper.slideNext(300)

  return (
    <PostListWrapper>
      {showPoints && (
        <PostListBackground>
          <DottedBgBig />
        </PostListBackground>
      )}
      <PostListContainer>
        <PostListTitle as={titleTag}>{title}</PostListTitle>
        {postItems && (
          <PostListStack>
            {postItems.length === 1 ? (
              <PostListItem {...postItems[0]} />
            ) : (
              <Swiper {...swiperConfig}>
                {postItems.map(({ id, ...props }) => (
                  <SwiperSlide key={id}>
                    <PostListItem {...props} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </PostListStack>
        )}

        {postItems && (
          <PostListControls>
            {postItems.length !== 1 && (
              <PostListBtnArrow
                as="button"
                hideArrow={postItems.length <= 2}
                direction="left"
                width={33}
                height={28}
                color="black"
                className="arrow-icon"
                onClick={handleSlidePrev}
              />
            )}
            {!!url && buttonText && (
              <Button as={Link} to={url} variant="outlined">
                {buttonText}
              </Button>
            )}
            {postItems.length !== 1 && (
              <PostListBtnArrow
                as="button"
                hideArrow={postItems.length <= 2}
                direction="right"
                width={33}
                height={28}
                color="black"
                className="arrow-icon"
                onClick={handleSlideNext}
              />
            )}
          </PostListControls>
        )}
      </PostListContainer>
    </PostListWrapper>
  )
}

PostList.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  postItems: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      coverImage: PropTypes.object,
      shortContent: PropTypes.object,
      buttonText: PropTypes.string,
      url: PropTypes.string,
      date: PropTypes.string,
      category: PropTypes.array,
    }),
  ).isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  showPoints: PropTypes.bool.isRequired,
}

export default PostList
