import styled from "@emotion/styled"
import Title from "components/Title"

const ArticleTitle = styled(Title)`
  padding-top: 0;
  margin-top: 0;
  line-height: 110%;
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 44px;
    line-height: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 60px;
    line-height: 70px;
  }
`

export default ArticleTitle
