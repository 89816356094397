const Dodecagon = () => (
  <svg viewBox="0 0 995 1010" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M359.388 6.89493C362.405 5.10069 365.842 4.13237 369.353 4.08732L628.044 0.767144C631.554 0.722088 635.015 1.60188 638.078 3.31808L863.771 129.788C866.834 131.505 869.391 133.997 871.185 137.014L1003.41 359.388C1005.2 362.405 1006.17 365.842 1006.21 369.352L1009.53 628.044C1009.58 631.554 1008.7 635.015 1006.98 638.077L880.512 863.771C878.796 866.833 876.304 869.39 873.286 871.185L650.913 1003.41C647.896 1005.2 644.459 1006.17 640.948 1006.21L382.257 1009.53C378.747 1009.58 375.286 1008.7 372.223 1006.98L146.53 880.512C143.467 878.796 140.91 876.304 139.116 873.286L6.89524 650.913C5.101 647.895 4.13268 644.459 4.08763 640.948L0.767446 382.257C0.722399 378.746 1.60218 375.286 3.31839 372.223L129.789 146.53C131.505 143.467 133.997 140.91 137.015 139.116L359.388 6.89493Z"
      fill="url(#paint0_linear_2:821)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2:821"
        x1="363.994"
        y1="4.15609"
        x2="646.307"
        y2="1006.14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFEFF6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default Dodecagon
