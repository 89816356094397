import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ active }) => css`
  ${active &&
  `
    &::before{
      transform: rotate(0);
    }
    &::after{
      transform: rotate(0);
    }
  `}
`

const ListSeventeenItemButton = styled.div`
  height: 24px;
  min-width: 24px;
  position: relative;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::after,
  &::before {
    content: "";
    width: 24px;
    height: 2px;
    background: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    transition: 0.5s;
  }
  &::after {
    transform: rotate(-180deg);
  }
  &::before {
    transform: rotate(90deg);
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    height: 30px;
    min-width: 30px;
    &::after {
      width: 30px;
    }
    &::before {
      width: 30px;
    }
  }

  ${dynamicStyle}
`

export default ListSeventeenItemButton
