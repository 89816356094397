import styled from "@emotion/styled"

const ArticleAsideBannerFormText = styled.div`
  color: ${({ theme }) => theme.palette.common.black};

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  a {
    color: black;
  }
`

export default ArticleAsideBannerFormText
