import styled from "@emotion/styled"
import Title from "components/Title"

const HeroAboutTitle = styled(Title)`
  position: absolute;
  display: block;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  transform: translate(-50%, -50%);

  width: max-content;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    top: 50%;
    left: 33%;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    top: 50%;
    left: 41%;
  }
`

export default HeroAboutTitle
