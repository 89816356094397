import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"

import ListSevenWrapper from "components/ListSeven/ListSevenWrapper"
import ListSevenTitle from "components/ListSeven/ListSevenTitle"
import ListSevenStack from "components/ListSeven/ListSevenStack"
import ListSevenItem from "components/ListSeven/ListSevenItem"
import ListSevenItemHeader from "components/ListSeven/ListSevenItemHeader"
import ListSevenItemNumber from "components/ListSeven/ListSevenItemNumber"
import ListSevenItemHeaderWrapper from "components/ListSeven/ListSevenItemHeaderWrapper"
import ListSevenItemTitle from "components/ListSeven/ListSevenItemTitle"
import ListSevenItemSubtitle from "components/ListSeven/ListSevenItemSubtitle"
import ListSevenItemText from "components/ListSeven/ListSevenItemText"

import ListSevenSubtitle from "components/ListSeven/ListSevenSubtitle"

import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListSeven = ({
  title,
  titleTag,
  theme: colorVariant,
  listItems,
  subtitleText,
  lowerText,
  sectionId,
}) => (
  <ListSevenWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListSevenTitle subtitle={subtitleText} as={titleTag}>
        {title}
      </ListSevenTitle>
      {subtitleText && (
        <ListSevenSubtitle
          dangerouslySetInnerHTML={{
            __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
          }}
        />
      )}
      <ListSevenStack lowerText={lowerText}>
        {listItems.map(({ title: itemTitle, titleTag: itemTitleTag, subtitle: itemSubtitle, text, id }, idx) => (
          <ListSevenItem key={id}>
            <ListSevenItemHeader>
              <ListSevenItemNumber>{idx + 1}</ListSevenItemNumber>
              <ListSevenItemHeaderWrapper>
                <ListSevenItemTitle as={itemTitleTag}>{itemTitle}</ListSevenItemTitle>
                <ListSevenItemSubtitle>{itemSubtitle}</ListSevenItemSubtitle>
              </ListSevenItemHeaderWrapper>
            </ListSevenItemHeader>
            {text && (
              <ListSevenItemText
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(text?.childMarkdownRemark?.html),
                }}
              />
            )}
          </ListSevenItem>
        ))}
      </ListSevenStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListSevenWrapper>
)

ListSeven.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      text: PropTypes.object,
    }),
  ).isRequired,
}

ListSeven.defaultProps = {
  subtitleText: null,
  lowerText: null,
  sectionId: "",
}

export default ListSeven
