import styled from "@emotion/styled"

const ListEighteenItemText = styled.div`
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    max-width: 150px;
  }
`

export default ListEighteenItemText
