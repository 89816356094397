import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ theme = "Dark" }) => css`
  ${theme === "White" ? `fill: #38B148` : `fill: #EFEFF6`}
`

const CTABackgroundIconInner = styled.div`
  position: relative;

  > svg {
    position: absolute;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;

    > g {
      > path {
        opacity: 0.05;
        ${dynamicStyle}
      }
    }

    > path {
      opacity: 0.05;
      ${dynamicStyle}
    }
  }
`

export default CTABackgroundIconInner
