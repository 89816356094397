import styled from "@emotion/styled"

const PostEventsEventBackground = styled.div`
  position: absolute;
  overflow: hidden;
  svg {
    height: 100%;
  }
  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 100%;
    height: 100%;
    inset: 0;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 50%;
    height: 100%;
    left: 0;
  }
`

export default PostEventsEventBackground
