import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Title from "components/Title"

const fontSizesPC = {
  Large: "80px",
  Normal: "40px",
  Small: "28px",
  exSmall: "20px",
}

const fontSizesTablet = {
  Large: "70px",
  Normal: "40px",
  Small: "28px",
  exSmall: "20px",
}

const fontSizesMobile = {
  Large: "44px",
  Normal: "36px",
  Small: "28px",
  exSmall: "20px",
}

const lineHeightPC = {
  Large: "90px",
  Normal: "50px",
  Small: "30px",
  exSmall: "24px",
}

const lineHeightTablet = {
  Large: "80px",
  Normal: "50px",
  Small: "30px",
  exSmall: "24px",
}

const lineHeightMobile = {
  Large: "44px",
  Normal: "40px",
  Small: "30px",
  exSmall: "24px",
}

const paddingTop = {
  "h2": "40px",
  "h3": "30px",
  "h4": "20px"
}

const marginBottom = {
  h2: "20px",
  h3: "20px",
  h4: "5px",
}
const dynamicStyles = ({ theme, fontSize, fontFamily, pt, mb }) => css`
  font-family: ${(fontFamily === "Gilroy" && theme.typography.fontFamilyGilroy) ||
  theme.typography.fontFamilyMontserrat};

  ${theme.breakpoints.down("lg")} {
    font-size: ${fontSizesMobile[fontSize]};
    line-height: ${lineHeightMobile[fontSize]};
  }

  ${theme.breakpoints.between("lg", "xl")} {
    font-size: ${fontSizesTablet[fontSize]};
    line-height: ${lineHeightTablet[fontSize]};
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    font-size: ${fontSizesTablet[fontSize]};
    line-height: ${lineHeightTablet[fontSize]};
  }

  ${theme.breakpoints.up("2xl")} {
    font-size: ${fontSizesPC[fontSize]};
    line-height: ${lineHeightPC[fontSize]};
  }
`

const ArticleContentTitle = styled(Title)`
  line-height: 1;
  margin-top: 0;
  scroll-margin-top: 80px;
  scroll-snap-margin-top: 80px;
  margin-bottom: ${({ as }) => marginBottom[as] || "5px"};

  &:first-child {
    padding-top: 30px;
  }

  &:not(:first-child) {
    padding-top: ${({ as }) => paddingTop[as] || "20px"};
  }

  ${dynamicStyles}
`

export default ArticleContentTitle
