import styled from "@emotion/styled"

const ListThreeItemTitle = styled.span`
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  color: #bbbbca;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 6px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 1px;
  }
`

export default ListThreeItemTitle
