import styled from "@emotion/styled"
import HeroTitle from "components/Hero/HeroTitle"

const HeroFormTitle = styled(HeroTitle)`
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 370px;
    margin-bottom: 34px;
    line-height: 48px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    max-width: 510px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 550px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 830px;
  }
`

export default HeroFormTitle
