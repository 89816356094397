import styled from "@emotion/styled"
import Link from "components/Link"

const ArticleContentLink = styled(Link)`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.gray[900]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[900]};
  transition: all 0.2s;
  margin-bottom: -1px;
  max-width: max-content;
  text-decoration: none;
  width: 100%;

  u {
    text-decoration: none;
  }

  &:hover {
    border-color: transparent;
  }
`

export default ArticleContentLink
