import styled from "@emotion/styled"

const ListFiveItem = styled.div`
  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    min-height: 355px;
  }

  &:not(:last-child) {
    ${({ theme }) => theme.breakpoints.down("xl")} {
      margin-bottom: 67px;
    }

    ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 32px;
    }

    ${({ theme }) => theme.breakpoints.up("2xl")} {
      margin-bottom: 40px;
    }
  }
`

export default ListFiveItem
