import styled from "@emotion/styled"

const ArticleContent = styled.div`
  ${({ fullwidth, theme }) =>
    fullwidth
      ? `
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        margin-top: 50px;

        div.gatsby-image-wrapper {
          max-width: 100%;
          border-radius: 15px;

          div {
            max-width: 100% !important;
          }
        }
      `
      : `
        div.gatsby-image-wrapper {
          border-radius: 15px;
        }

        p + p {
          margin-top: 20px;
        }

        ${theme.breakpoints.down("xl")} {
          width: 100%;
          p + section,
          ul + section,
          ol + section {
            margin: 40px 0;
          }

          h1 + section,
          h2 + section,
          h3 + section,
          h4 + section,
          h5 + section,
          h6 + section {
            margin: 40px 0 40px 0;
          }

          h1 + ul,
          h2 + ul,
          h3 + ul,
          h4 + ul,
          h5 + ul,
          h6 + ul,
          h1 + ol,
          h2 + ol,
          h3 + ol,
          h4 + ol,
          h5 + ol,
          h6 + ol {
            margin-top: 0px;
          }

          li > p {
            margin-top: 0 !important;
          }

          section + h2,
          ul + h2,
          ol + h2 {
            padding-top: 20px !important;
          }

          section + h3,
          ul + h3,
          ol + h3 {
            padding-top: 10px !important;
          }

          section + h4,
          ul + h4,
          ol + h4 {
            padding-top: 0 !important;
          }
        }

        ${theme.breakpoints.between("xl", "2xl")} {
          width: 74%;
          p + section,
          ul + section,
          ol + section {
            margin: 40px 0;
          }

          h1 + section,
          h2 + section,
          h3 + section,
          h4 + section,
          h5 + section,
          h6 + section {
            margin: 40px 0 40px 0;
          }

          h1 + ul,
          h2 + ul,
          h3 + ul,
          h4 + ul,
          h5 + ul,
          h6 + ul,
          h1 + ol,
          h2 + ol,
          h3 + ol,
          h4 + ol,
          h5 + ol,
          h6 + ol {
            margin-top: 0px;
          }

          li > p {
            margin-top: 0 !important;
          }

          section + h2,
          ul + h2,
          ol + h2 {
            padding-top: 20px !important;
          }

          section + h3,
          ul + h3,
          ol + h3 {
            padding-top: 10px !important;
          }

          section + h4,
          ul + h4,
          ol + h4 {
            padding-top: 0 !important;
          }
        }

        ${theme.breakpoints.between("2xl", "3xl")} {
          width: 73.1%;
          p + section,
          ul + section,
          ol + section {
            margin: 40px 0;
          }

          h1 + section,
          h2 + section,
          h3 + section,
          h4 + section,
          h5 + section,
          h6 + section {
            margin: 40px 0 40px 0;
          }

          h1 + ul,
          h2 + ul,
          h3 + ul,
          h4 + ul,
          h5 + ul,
          h6 + ul,
          h1 + ol,
          h2 + ol,
          h3 + ol,
          h4 + ol,
          h5 + ol,
          h6 + ol {
            margin-top: 0px;
          }

          li > p {
            margin-top: 0 !important;
          }

          section + h2,
          ul + h2,
          ol + h2 {
            padding-top: 20px !important;
          }

          section + h3,
          ul + h3,
          ol + h3 {
            padding-top: 10px !important;
          }

          section + h4,
          ul + h4,
          ol + h4 {
            padding-top: 0 !important;
          }
        }

        ${theme.breakpoints.up("3xl")} {
          width: 73.1%;
          p + section,
          ul + section,
          ol + section {
            margin: 40px 0;
          }

          h1 + section,
          h2 + section,
          h3 + section,
          h4 + section,
          h5 + section,
          h6 + section {
            margin: 40px 0 40px 0;
          }

          h1 + ul,
          h2 + ul,
          h3 + ul,
          h4 + ul,
          h5 + ul,
          h6 + ul,
          h1 + ol,
          h2 + ol,
          h3 + ol,
          h4 + ol,
          h5 + ol,
          h6 + ol {
            margin-top: 0px;
          }

          li > p {
            margin-top: 0 !important;
          }

          section + h2,
          ul + h2,
          ol + h2 {
            padding-top: 20px !important;
          }

          section + h3,
          ul + h3,
          ol + h3 {
            padding-top: 10px !important;
          }

          section + h4,
          ul + h4,
          ol + h4 {
            padding-top: 0 !important;
          }
        }
      `}
`

export default ArticleContent
