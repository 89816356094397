import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import Link from "components/Link"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListFourteenWrapper from "components/ListFourteen/ListFourteenWrapper"
import ListFourteenTitle from "components/ListFourteen/ListFourteenTitle"
import ListFourteenStack from "components/ListFourteen/ListFourteenStack"
import ListFourteenItemWrapper from "components/ListFourteen/ListFourteenItemWrapper"
import ListFourteenItemImage from "components/ListFourteen/ListFourteenItemImage"
import ListFourteenItemFlagWrapper from "components/ListFourteen/ListFourteenItemFlagWrapper"
import ListFourteenItemFlagImageWrapper from "components/ListFourteen/ListFourteenItemFlagImageWrapper"
import ListFourteenItemFlagImage from "components/ListFourteen/ListFourteenItemFlagImage"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListFourteen = ({
  title,
  theme: colorVariant,
  titleTag,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListFourteenWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListFourteenTitle as={titleTag}>{title}</ListFourteenTitle>
      <ListFourteenStack lowerText={lowerText}>
        {listItems.map(
          ({ id, icon, url, flag }) =>
            (!!url && (
              <Link to={url} key={id}>
                <ListFourteenItemWrapper colorVariant={colorVariant}>
                  <ListFourteenItemImage {...icon} />
                  {!!flag && (
                    <ListFourteenItemFlagWrapper colorVariant={colorVariant}>
                      <ListFourteenItemFlagImageWrapper colorVariant={colorVariant}>
                        <ListFourteenItemFlagImage {...flag} />
                      </ListFourteenItemFlagImageWrapper>
                    </ListFourteenItemFlagWrapper>
                  )}
                </ListFourteenItemWrapper>
              </Link>
            )) || (
              <ListFourteenItemWrapper colorVariant={colorVariant} key={id} flag={flag}>
                <ListFourteenItemImage {...icon} />
                {!!flag && (
                  <ListFourteenItemFlagWrapper colorVariant={colorVariant}>
                    <ListFourteenItemFlagImageWrapper colorVariant={colorVariant}>
                      <ListFourteenItemFlagImage {...flag} />
                    </ListFourteenItemFlagImageWrapper>
                  </ListFourteenItemFlagWrapper>
                )}
              </ListFourteenItemWrapper>
            ),
        )}
      </ListFourteenStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListFourteenWrapper>
)

ListFourteen.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      url: PropTypes.string,
      flag: PropTypes.object,
    }),
  ).isRequired,
}

ListFourteen.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListFourteen
