import styled from "@emotion/styled"

const ListSixteenItemSubtitleEl = (props) => <span {...props} />

const ListSixteenItemSubtitle = styled(ListSixteenItemSubtitleEl)`
  display: block;
  font-weight: 500;

  & + & {
    margin-bottom: 13px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 20px;
    line-height: 28px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 31px;
    white-space: nowrap;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 34px;
    white-space: nowrap;
  }
`

export default ListSixteenItemSubtitle
