import styled from "@emotion/styled"
import HeroContainer from "components/Hero/HeroContainer"

const HeroFormContainer = styled(HeroContainer)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: 16px;
    padding-right: 16px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

export default HeroFormContainer
