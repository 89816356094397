import styled from "@emotion/styled"

const TestimonialPhoto = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
`

export default TestimonialPhoto
