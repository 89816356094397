import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"

import ListEightWrapper from "components/ListEight/ListEightWrapper"
import ListEightTitle from "components/ListEight/ListEightTitle"
import ListEightText from "components/ListEight/ListEightText"
import ListEightStack from "components/ListEight/ListEightStack"
import ListEightItemText from "components/ListEight/ListEightItemText"
import ListEightItem from "components/ListEight/ListEightItem"
import ListEightHeader from "components/ListEight/ListEightHeader"
import ListEightContainer from "components/ListEight/ListEightContainer"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListEight = ({
  title,
  titleTag,
  subtitleText,
  theme: colorVariant,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListEightWrapper id={sectionId} colorVariant={colorVariant}>
    <ListEightContainer>
      <ListEightHeader>
        <ListEightTitle as={titleTag}>{title}</ListEightTitle>
        {subtitleText && (
          <ListEightText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListEightHeader>
      <ListEightStack lowerText={lowerText}>
        {listItems.map(
          ({ id, text }) =>
            text && (
              <ListEightItem key={id}>
                <ListEightItemText
                  dangerouslySetInnerHTML={{
                    __html: htmlFormatting(text?.childMarkdownRemark?.html),
                  }}
                />
              </ListEightItem>
            ),
        )}
      </ListEightStack>
    </ListEightContainer>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListEightWrapper>
)

ListEight.propTypes = {
  title: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
    }),
  ).isRequired,
}

ListEight.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListEight
