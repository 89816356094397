import styled from "@emotion/styled"

const HeroCaseItem = styled.li`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: grid;
    grid-template-columns: 35% 65%;
    gap: 12px;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: flex;
    gap: 5px;
    justify-content: center;
    flex-direction: column;
  }
`

export default HeroCaseItem
