import styled from "@emotion/styled"

const CertificationLogo = styled.div`
  display: grid;
  position: relative;
  width: 318px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    order: -1;
    margin-bottom: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 318px;
    height: 75px;
    margin-top: 40px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    height: 57px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    height: 75px;
  }
`

export default CertificationLogo
