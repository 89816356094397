import styled from "@emotion/styled"

const CTABackgroundIconWrapper = styled.div`
  position: absolute;

  ${({ theme }) => theme.breakpoints.down("md")} {
    right: -90px;
    width: 360px;
    bottom: 200px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    top: 38px;
    right: -44px;
    width: 367px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    right: -70px;
    width: 560px;
    top: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    right: -90px;
    width: 700px;
    top: 10px;
  }
`

export default CTABackgroundIconWrapper
