import styled from "@emotion/styled"
import Button from "components/Buttons/Button"

const ListTwoCardButton = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 99px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 34px;
    height: 34px;

    .arrow-icon {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;

      &::before {
        width: 12px;
        height: 12px;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    @media (hover: hover) {
      width: 20px;
      height: 20px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    transform: translate(-50%, -50%);
    border-radius: 99px;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    transition: 0.3s;

    ${({ theme }) => theme.breakpoints.down("md")} {
      top: 50%;
      left: 50%;
      width: 44px;
      height: 44px;
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
      @media (hover: hover) {
        top: 50%;
        left: 50%;
        width: 44px;
        height: 44px;
      }
    }
  }
`

export default ListTwoCardButton
