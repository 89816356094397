import styled from "@emotion/styled"

const GateForContentThirdStage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 30px 30px 30px;
  background-color: ${({ theme }) => theme.palette.gray[50]};
  z-index: 1;
`

export default GateForContentThirdStage
