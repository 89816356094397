import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const TestimonialTitleEl = ({ tag, ...props }) => {
  return React.createElement(tag, props);
};

const TestimonialTitle = styled(TestimonialTitleEl)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.black};
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 30px;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }
`

TestimonialTitleEl.propTypes = {
  tag: PropTypes.string,
}

TestimonialTitleEl.defaultProps = {
  tag: 'h3',
}

export default TestimonialTitle
