import styled from "@emotion/styled"

const PostEventsEventValue = styled.span`
  color: ${({ theme }) => theme.palette.common.white};
  display: block;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.06em;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.06em;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 18px;
    line-height: 32px;
  }
`

export default PostEventsEventValue
