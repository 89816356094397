import styled from "@emotion/styled"

const ListSevenItemSubtitle = styled.span`
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    line-height: 29px;
  }
`

export default ListSevenItemSubtitle
