import styled from "@emotion/styled"

const TextCalendlyContent = styled.div`
  height: 800px;

  > div {
    height: 100%;
  }

  iframe {
    height: 100%;
  }
`

export default TextCalendlyContent
