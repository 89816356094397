import styled from "@emotion/styled"

const HeroFormFormFooter = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-end;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    justify-content: flex-end;
  }

  ${({ theme }) => theme.breakpoints.between("sm", "lg")} {
    gap: 29px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    gap: 39px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 48px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 60px;
  }
`

export default HeroFormFormFooter
