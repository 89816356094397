import styled from "@emotion/styled"
import Image from "components/Image"

const ListSixteenItemImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`

export default ListSixteenItemImage
