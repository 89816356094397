import styled from "@emotion/styled"

const ArticleImageTitle = styled.span`
  display: block;
  color: #c6c6db;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 12px;
    line-height: 22px;
    padding-top: 15px;
    margin-bottom: 30px;
    & + h2 {
      padding-top: 10px !important;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 20px;
    margin-bottom: 30px;
    & + h2 {
      padding-top: 10px !important;
    }
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 12px;
    line-height: 22px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 14px;
    line-height: 27px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 18px;
    line-height: 34px;
  }
`

export default ArticleImageTitle
