import styled from "@emotion/styled"

const CaseStudyTitle = styled.h3`
  color: ${({ theme }) => theme.palette.common.black};
  text-decoration: none;
  display: block;
  font-weight: bold;
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 21px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 517px;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 34px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    max-width: 413px;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    max-width: 517px;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 40px;
  }
`

export default CaseStudyTitle
