import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListThirteenStack = styled.section`
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }
  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    display: flex;
    gap: 130px;
  }
  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    display: flex;
    gap: 62px;
  }
  ${({ theme }) => theme.breakpoints.up("3xl")} {
    display: flex;
    gap: 179px;
  }
`

export default ListThirteenStack
