import styled from '@emotion/styled'

const GetForContentStageTitle = styled.h3`
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  
  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 30px;
    padding-right: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 32px;
  }
`
export default GetForContentStageTitle
