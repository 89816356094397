import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListFourStack = styled.div`
  display: flex;
  justify-content: center;
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    gap: 28px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    flex-wrap: wrap;
    gap: 28px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    flex-wrap: wrap;
    column-gap: 34px;
    row-gap: 40px;
  }
`

export default ListFourStack
