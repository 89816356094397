import styled from '@emotion/styled'

const ArticleRelatedArticle = styled.section`
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid #e1e1e1;
  box-shadow: 0 1px 22.600000381469727px 0 #00000012;

  &:hover {
    cursor: pointer;
    background-color: #e3f4e6;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    border-radius: 15px;
    padding: 30px;
    gap: 30px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    border-radius: 30px;
    padding: 20px;
    gap: 20px;
  }
`

export default ArticleRelatedArticle