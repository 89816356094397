import styled from "@emotion/styled"
import TextsText from "components/Texts/TextsText"

const TextFourText = styled(TextsText)`
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 500px;
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 32px;
    max-width: 592px;
    padding-top: 40px;
  }
`

export default TextFourText
