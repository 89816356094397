import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Image from "components/Image"
import Link from "components/Link"

const MyLink = styled(Link)`
  display: block;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    max-width: 130px;
    max-height: 35px;
    padding-left: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 154px;
    max-height: 46px;
  }

  div {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const HeaderLogo = ({ image, link }) => (
  <MyLink to={link} ariaLabel="Adexin">
    <Image {...image} />
  </MyLink>
)

HeaderLogo.propTypes = {
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
}

export default HeaderLogo
