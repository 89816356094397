import styled from "@emotion/styled"

const TechThreeCategory = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 48px;
    position: relative;
    .swiper {
      position: static;
    }
    .swiper-button-prev,
    .swiper-button-next {
      color: black;
      top: 30px;

      display: inline-block;
      width: 28px;
      height: 24px;
      transition: 0.3s;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        border: solid #000;
        border-width: 0 2px 2px 0;
        display: block;
        width: ${(24 * 0.7).toFixed(2)}px;
        height: ${(24 * 0.7).toFixed(2)}px;
        transition: 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 2px;
        background-color: #000;
        transition: 0.3s;
      }
    }

    .swiper-button-prev {
      &::before {
        left: 9%;
        transform: translateY(-50%) rotate(135deg);
      }
    }
    .swiper-button-next {
      &::before {
        right: 9%;
        transform: translateY(-50%) rotate(-45deg);
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 2px;
        background-color: #000;
        transition: 0.3s;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 69px;
    display: grid;
    grid-template-columns: 59.1% 34.9%;
    margin-bottom: 56px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    display: grid;
    grid-template-columns: 57.5% 42.5%;
    margin-bottom: 56px;
    column-gap: 87px;
  }
`

export default TechThreeCategory
