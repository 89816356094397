import styled from "@emotion/styled"

const ListSevenLowerText = styled.p`
  margin: 0 auto 34px;
  font-size: 18px;
  line-height: 29px;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    max-width: 968px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 1210px;
  }
`

export default ListSevenLowerText
