import styled from "@emotion/styled"

const CertificationInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: space-between;
  }
`

export default CertificationInner
