import styled from "@emotion/styled"

const TestimonialPosition = styled.span`
  color: ${({ theme }) => theme.palette.common.black};
  margin-top: 0;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 18px;
  }
`

export default TestimonialPosition
