import styled from "@emotion/styled"

const ListThreeItems = styled.div`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-left: -16px;
    margin-right: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 70%;
    flex: 1 1 70%;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 70%;
    flex: 1 1 87%;
  }
`

export default ListThreeItems
