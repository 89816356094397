import styled from "@emotion/styled"

const ListSeventeenItemHead = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 17px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 38px;
  }
`

export default ListSeventeenItemHead
