import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Container from "components/Container"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListSixContainer = styled(Container)`
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 1078px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 1384px;
  }
`

export default ListSixContainer
