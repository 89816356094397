import styled from "@emotion/styled"

const ListFiveItemList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, minmax(29px, max-content));
    row-gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 118px;
  }
`

export default ListFiveItemList
