import styled from "@emotion/styled"
import { SpinnerDotted } from "spinners-react"

const CTAFormSpinner = styled(SpinnerDotted)`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 100px !important;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 125px !important;
  }
`

export default CTAFormSpinner
