import styled from "@emotion/styled"
import Title from "components/Title"

const ListThreeTitle = styled(Title)`
  margin-block-end: 0;
  margin-block-start: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 25px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 45px;
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 45px;
    font-size: 60px;
  }
`

export default ListThreeTitle
