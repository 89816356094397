const Time = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 10.25C19.5 15.359 15.359 19.5 10.25 19.5C5.141 19.5 1 15.359 1 10.25C1 5.141 5.141 1 10.25 1C15.359 1 19.5 5.141 19.5 10.25Z"
      stroke="#C6C6DB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6816 13.1922L9.91162 10.9432V6.09619"
      stroke="#C6C6DB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Time
