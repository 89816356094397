import styled from "@emotion/styled"

const ListThreeItemText = styled.div`
  font-style: normal;
  font-weight: 600;
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 24px;

  p {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    max-width: 120px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 232px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 310px;
  }
`

export default ListThreeItemText
