import styled from "@emotion/styled"
import Link from "components/Link"

const BlogListNavigationButton = styled(Link)`
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: ${({ theme }) => theme.palette.gray[50]};

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 34px;
    height: 34px;
    border-radius: 8px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    width: 44px;
    height: 44px;
    border-radius: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 54px;
    height: 54px;
    border-radius: 15px;
  }
`

export default BlogListNavigationButton
