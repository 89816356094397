import styled from "@emotion/styled"
import HeroText from "components/Hero/HeroText"

const HeroAboutText = styled(HeroText)`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 25px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 655px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 655px;
  }
`

export default HeroAboutText
