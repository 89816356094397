import styled from "@emotion/styled"
import Image from "components/Image"

const ArticleRelatedArticleInfoImage = styled(Image)`
    width: 40px;
    height: 40px;
    border-radius: 50%!important;
`

export default ArticleRelatedArticleInfoImage
