import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant, flag }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[50]};
  border-radius: ${(flag && "20px 20px 0 0") || "20px"};
  ${flag
    ? `
    ${theme.breakpoints.down("lg")} {
      height: 86%;
    }

    ${theme.breakpoints.between("lg", "2xl")} {
      height: 86%;
    }

    ${theme.breakpoints.up("2xl")} {
      height: 86%;
    }`
    : "height: 96%;"}
`

const ListFourteenItemWrapper = styled.div`
  display: block;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 25px 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    padding: 20px 15px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding: 52px;
  }

  &:before {
    content: "";
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    ${dynamicStyle}
  }
`

export default ListFourteenItemWrapper
