import styled from "@emotion/styled"

const ArticleAsideFixedWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 40px;
    display: contents;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    position: sticky;
    display: flex;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    top: 120px;
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    top: 140px;
    gap: 30px;
  }
`

export default ArticleAsideFixedWrapper
