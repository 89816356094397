import * as React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import PostEventsContainer from "components/PostEventsList/PostEventsContainer"
import PostEventsHeader from "components/PostEventsList/PostEventsHeader"
import PostEventsTitle from "components/PostEventsList/PostEventsTitle"
import PostEventsWrapper from "components/PostEventsList/PostEventsWrapper"
import PostEventsEvents from "components/PostEventsList/PostEventsEvents"
import PostEventsEventsContainer from "components/PostEventsList/PostEventsEventsContainer"
import PostEventsEventsEvent from "components/PostEventsList/PostEventsEventsEvent"
import PostEventsEventPlace from "components/PostEventsList/PostEventsEventPlace"
import PostEventsBlogLink from "components/PostEventsList/PostEventsBlogLink"
import PostEventsEventBackground from "components/PostEventsList/PostEventsEventBackground"
import PostEventsEventWrapper from "components/PostEventsList/PostEventsEventWrapper"
import PostEventsEventControls from "components/PostEventsList/PostEventsEventControls"
import PostEventsEventTitle from "components/PostEventsList/PostEventsEventTitle"
import PostEventsEventContainer from "components/PostEventsList/PostEventsEventContainer"
import PostEventsEventInfo from "components/PostEventsList/PostEventsEventInfo"
import PostEventsEventDate from "components/PostEventsList/PostEventsEventDate"
import PostEventsEventValue from "components/PostEventsList/PostEventsEventValue"
import PostEventsSubtitle from "components/PostEventsList/PostEventsSubtitle"
import PostEventsEventButton from "components/PostEventsList/PostEventsEventButton"
import PostListItem from "components/PostItem/PostListItem"

// import DottedBg from "icons/dotted-bg-big.inline.svg"
import PostListItemText from "components/PostItem/PostListItemText"
import htmlFormatting from "utils/htmlFormatting"
import PostWrapperItems from "components/PostItem/PostWrapperItems"

const PostEventsList = ({
  title,
  titleTag,
  postItems,
  eventItems,
  secondTitle,
  secondTitleTag,
  showPoints,
  sectionId,
}) => {
  const swiperRef = React.useRef(null)
  const postSwiper = React.useRef(null)
  const swiperConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    spaceBetween: 24,
    allowTouchMove: false,
    ref: swiperRef,
  }

  const handleSlidePrev = () => swiperRef.current.swiper.slidePrev(300)
  const handleSlideNext = () => swiperRef.current.swiper.slideNext(300)

  const handlePostSlideNext = () => postSwiper.current.swiper.slideNext(300)
  const handlePostSlidePrev = () => postSwiper.current.swiper.slidePrev(300)

  return (
    <PostEventsWrapper id={sectionId}>
      <PostEventsContainer>
        <PostEventsEventContainer>
          {showPoints && (
            <PostEventsEventBackground>{/* <DottedBg /> */}</PostEventsEventBackground>
          )}
          <PostEventsEventWrapper>
            <PostEventsTitle as={titleTag}>{title}</PostEventsTitle>
            <Swiper {...swiperConfig} ref={postSwiper}>
              {postItems.map((el) => (
                <SwiperSlide key={el.id}>
                  <PostWrapperItems>
                    <PostEventsHeader>
                      <PostEventsSubtitle>Latest from Blog</PostEventsSubtitle>
                      <PostEventsBlogLink noFollow={true} to={el.link.endsWith("/") ? el.link : `${el.link}/`}>
                        {el.buttonText}
                      </PostEventsBlogLink>
                    </PostEventsHeader>
                    <PostListItem {...el} variant="WithoutLink" />
                  </PostWrapperItems>
                </SwiperSlide>
              ))}
            </Swiper>
            {postItems.length > 1 && (
              <PostEventsEventControls>
                <PostEventsEventButton
                  width={33}
                  height={28}
                  color="black"
                  variant="text"
                  direction="left"
                  onClick={handlePostSlideNext}
                />
                <PostEventsEventButton
                  width={33}
                  height={28}
                  color="black"
                  variant="text"
                  direction="right"
                  onClick={handlePostSlidePrev}
                />
              </PostEventsEventControls>
            )}
          </PostEventsEventWrapper>
        </PostEventsEventContainer>
        <PostEventsEventContainer variant="Dark">
          <PostEventsEventWrapper>
            <PostEventsTitle variant="Dark" as={secondTitleTag}>
              {secondTitle}
            </PostEventsTitle>
            <PostEventsEventsContainer>
              <PostEventsEvents>
                <Swiper ref={swiperRef} {...swiperConfig}>
                  {eventItems.map(
                    ({
                      id,
                      title: eventTitle,
                      buttonText: eventBtnText,
                      url,
                      when,
                      location,
                      text,
                    }) => (
                      <SwiperSlide key={id}>
                        <PostEventsEventsEvent>
                          <PostEventsHeader>
                            <PostEventsEventTitle>{eventTitle}</PostEventsEventTitle>
                            {url && (
                              <PostEventsBlogLink noFollow={true} to={url}>
                                {eventBtnText}
                              </PostEventsBlogLink>
                            )}
                          </PostEventsHeader>
                          <PostEventsEventInfo>
                            <PostEventsEventDate>
                              <PostEventsEventValue>{when}</PostEventsEventValue>
                            </PostEventsEventDate>
                            <PostEventsEventPlace>{location}</PostEventsEventPlace>
                            <div>
                              {text?.childMarkdownRemark?.html && (
                                <PostListItemText
                                  variant="Dark"
                                  dangerouslySetInnerHTML={{
                                    __html: htmlFormatting(text?.childMarkdownRemark?.html),
                                  }}
                                />
                              )}
                            </div>
                          </PostEventsEventInfo>
                        </PostEventsEventsEvent>
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              </PostEventsEvents>
            </PostEventsEventsContainer>
            {eventItems.length > 1 && (
              <PostEventsEventControls>
                <PostEventsEventButton
                  width={33}
                  height={28}
                  color="white"
                  variant="text"
                  direction="left"
                  onClick={handleSlidePrev}
                />
                <PostEventsEventButton
                  width={33}
                  height={28}
                  color="white"
                  variant="text"
                  direction="right"
                  onClick={handleSlideNext}
                />
              </PostEventsEventControls>
            )}
          </PostEventsEventWrapper>
        </PostEventsEventContainer>
      </PostEventsContainer>
    </PostEventsWrapper>
  )
}

PostEventsList.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  postItems: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      coverImage: PropTypes.object,
      shortContent: PropTypes.object,
      buttonText: PropTypes.string,
      link: PropTypes.string,
      date: PropTypes.string,
      category: PropTypes.array,
    }),
  ).isRequired,
  eventItems: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.object,
      buttonText: PropTypes.string,
      url: PropTypes.string,
      when: PropTypes.string,
      location: PropTypes.string,
    }),
  ).isRequired,
  secondTitle: PropTypes.string.isRequired,
  secondTitleTag: PropTypes.string.isRequired,
  showPoints: PropTypes.bool.isRequired,
}

PostEventsList.defaultProps = {
  sectionId: "",
}

export default PostEventsList
