import styled from "@emotion/styled"
import Image from "components/Image"

const TechItemImage = styled(Image)`
  width: 70%;
  height: 70%;

  svg {
    width: 100%;
    height: 100%;
  }
`
export default TechItemImage
