import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background-color: ${(colorVariant === "Dark" && theme.palette.gray[50]) ||
  (colorVariant === "Light" && theme.palette.gray[50]) ||
  theme.palette.common.white};
`

const ListNineWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  ${dynamicStyle}
`

export default ListNineWrapper
