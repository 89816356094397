import styled from "@emotion/styled"
import BtnArrow from "components/Buttons/BtnArrow"

const PostEventsEventButton = styled(BtnArrow)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (hover: none) {
    &::before {
      top: 50%;
      left: 50%;
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    &:hover {
      background-color: transparent;

      &::before {
        border-color: transparent;
      }
    }
  }
`

export default PostEventsEventButton
