import styled from "@emotion/styled"
import Image from "components/Image"

const ArticleRelatedArticleImage = styled(Image)`
  border: 1px solid #cccccc;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    border-radius: 15px;
  }
  
  ${({ theme }) => theme.breakpoints.up("md")} {
    border-radius: 20px;
  }

  & > img {
    object-fit: cover;
  }
`

export default ArticleRelatedArticleImage
