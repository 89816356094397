import styled from "@emotion/styled"

const HeroFormFormHeader = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    column-gap: 18px;
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    column-gap: 18px;
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 21px;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 26px;
    margin-bottom: 27px;
  }
`

export default HeroFormFormHeader
