import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, size, isIcon }) => css`
  ${size === "Small" && "text-align: center;"}

  ${theme.breakpoints.up("md")} {
    ${size === "Small" ? "flex-direction: column;" : "gap: 49px;"}
  }

  ${theme.breakpoints.between("md", "2xl")} {
    ${size === "Large" && "padding-left: 13%;"}
  }

  ${theme.breakpoints.down("xl")} {
    ${size === "Large" && isIcon && "padding-left: 71px;"}
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    ${size === "Large" && isIcon && "padding-left: 80px;"}
  }

  ${theme.breakpoints.up("2xl")} {
    ${size === "Large" && isIcon && "padding-left: 96px;"}
  }
`

const ListSixteenLinkStack = styled.div`
  display: flex;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    padding-left: 70px;
  }
  ${dynamicStyle}
`

export default ListSixteenLinkStack
