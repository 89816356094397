import styled from "@emotion/styled"
import Link from "components/Link"

const TechThreeLink = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
`

export default TechThreeLink
