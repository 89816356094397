import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ active, isBlog }) => css`
  ${active
    ? `
      margin-top: ${isBlog ? "20px" : "30px"};
      transition: 1s;
      max-height: 2000px;
    `
    : `
    max-height: 0;
    transition: 1.5s;
  `}
`

const ListSeventeenItemText = styled.div`
  font-size: ${({ isBlog }) => (isBlog ? `16px` : "18px")};
  line-height: ${({ isBlog }) => (isBlog ? "30px" : "23px")};
  overflow: hidden;

  & .content p {
    margin-bottom: 10px;
  }

  .content ul:not(:last-child),
  .content ol:not(:last-child) {
    margin-bottom: 10px;
  }

  br {
    height: 10px !important;
    margin-top: 0 !important;
  }

  li + li {
    margin-top: 10px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-left: 41px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-left: 56px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-left: 70px;
  }

  ${dynamicStyle}
`

export default ListSeventeenItemText
