import styled from "@emotion/styled"
import Image from "components/Image"

const ListFourteenItemImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

export default ListFourteenItemImage
