import styled from "@emotion/styled"
import Link from "components/Link"

const ListFiveItemLink = styled(Link)`
  display: inline-block;
  font-size: 18px;
  line-height: 29px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  margin-left: -1px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 40px;
  }
`

export default ListFiveItemLink
