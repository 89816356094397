import styled from "@emotion/styled"

const TextOneTitle = styled.h1`
  display: block;
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  font-weight: 700;
  margin: 0;

  @media screen and (min-width: 0px) and (max-width: 374.98px) {
    font-size: 20px;
    line-height: 20px;
  }

  @media screen and (min-width: 375px) and (max-width: 998.98px) {
    font-size: 20px;
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 20px;
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 24px;
  }
`

export default TextOneTitle
