import styled from "@emotion/styled"

const CertificationModal = styled.div`
  position: fixed;
  max-height: 90%;
  left: 50%;
  top: 50%;
  background-color: ${({ theme }) => theme.palette.common.white};
  transform: translate(-50%, -50%);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s;
  z-index: 16;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
`

export default CertificationModal
