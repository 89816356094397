import styled from "@emotion/styled"

const CaseStudyKey = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 18px;
  line-height: 1.87;
`

export default CaseStudyKey
