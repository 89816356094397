import PropTypes from "prop-types"

const IntlDate = ({ date, locale, options, ...props }) => {
  const formatter = new Intl.DateTimeFormat(locale, options)
  const fullDate = new Date(date)
  const dateString = formatter.format(fullDate)
  return <span {...props}>{dateString}</span>
}

IntlDate.propTypes = {
  date: PropTypes.string.isRequired,
  locale: PropTypes.string,
  options: PropTypes.object,
}

IntlDate.defaultProps = {
  locale: "en",
  options: { year: "numeric", month: "short", day: "numeric" },
}

export default IntlDate
