import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ variant }) => css`
  ${variant === "Wide" && "gap: 100px;"}
`

const PostListControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 43px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 34px;
  }

  ${dynamicStyle}
`

export default PostListControls
