import styled from '@emotion/styled'

const ArticleAsideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.gray[100]};
  border-radius: 15px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 17px;
    top: 80px;
    position: sticky;
    z-index: 9;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 30px 25px;
  }
`

export default ArticleAsideNavWrapper