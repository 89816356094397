import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.common.white) || theme.palette.gray[50]};
`

const ListFiveItemContainerSVG = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 100px;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    border-radius: 100px 0 0 100px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 128px;
    height: 128px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 160px;
    height: 160px;
  }

  ${dynamicStyle}
`

export default ListFiveItemContainerSVG
