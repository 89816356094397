import styled from "@emotion/styled"

const ListEightItem = styled.li`
  list-style-type: none;
  display: flex;
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    display: block;
    flex: 0 0 15px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    gap: 15px;
    align-items: baseline;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-weight: 500;
    font-size: 24px;
    line-height: 39px;
    align-items: baseline;
    max-width: 30%;
    width: 100%;
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-weight: 500;
    font-size: 24px;
    line-height: 39px;
    align-items: baseline;
    max-width: 30.18%;
    width: 100%;
    gap: 15px;
  }
`

export default ListEightItem
