import styled from "@emotion/styled"
import Link from "components/Link"

const ListSixteenItemLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: #6d6d6d;
  font-weight: 400;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 30px;
  }
`

export default ListSixteenItemLink
