import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ isHidden, direction }) => css`
  transform: ${direction === "left" ? "rotate(180deg)" : ""};

  &:after,
  &:before {
    background: ${isHidden ? "#C6C6DB" : "#38b148"};
  }
`

const BlogListRecommendationsBtn = styled.div`
  position: relative;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: none;
  }

  &:after {
    content: "";
    width: 80%;
    height: 3px;
    position: absolute;
    border-radius: 3px;
    top: 18%;
    transform: translate(0%, -15%) rotate(45deg);
  }

  &:before {
    content: "";
    width: 80%;
    height: 3px;
    position: absolute;
    border-radius: 3px;
    bottom: 18%;
    transform: translate(0%, -15%) rotate(-45deg);
  }

  ${dynamicStyle}
`
export default BlogListRecommendationsBtn
