import styled from "@emotion/styled"

const ListSevenItemText = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 29px;

  p {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    flex-basis: 150%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    flex-basis: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    flex-basis: 779px;
  }
`

export default ListSevenItemText
