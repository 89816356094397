import styled from "@emotion/styled"

const ArticleAsideFinalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  gap: 15px;
  z-index: 2;
`

export default ArticleAsideFinalWrapper
