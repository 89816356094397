import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListTenItem from "components/ListTen/ListTenItem"
import ListTenItemContent from "components/ListTen/ListTenItemContent"
import ListTenItemIcon from "components/ListTen/ListTenItemIcon"
import ListTenItemLink from "components/ListTen/ListTenItemLink"
import ListTenItemText from "components/ListTen/ListTenItemText"
import ListTenItemTitle from "components/ListTen/ListTenItemTitle"
import ListTenStack from "components/ListTen/ListTenStack"
import ListTenText from "components/ListTen/ListTenText"
import ListTenTitle from "components/ListTen/ListTenTitle"
import ListTenWrapper from "components/ListTen/ListTenWrapper"
import ListTenHeader from "components/ListTen/ListTenHeader"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListTen = ({
  title,
  titleTag,
  theme: colorVariant,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListTenWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListTenHeader>
        <ListTenTitle as={titleTag}>{title}</ListTenTitle>
        {subtitleText && (
          <ListTenText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListTenHeader>

      <ListTenStack lowerText={lowerText}>
        {listItems.map(({ id, icon, title: itemTitle, text: itemText, url, buttonTitle, titleTag: itemTitleTag }) => (
          <ListTenItem key={id}>
            <ListTenItemIcon {...icon} objectFit="contain" />
            <ListTenItemContent>
              {itemTitle && <ListTenItemTitle as={itemTitleTag}>{itemTitle}</ListTenItemTitle>}
              {itemText && (
                <ListTenItemText
                  dangerouslySetInnerHTML={{
                    __html: htmlFormatting(itemText?.childMarkdownRemark?.html),
                  }}
                />
              )}
              {url && <ListTenItemLink to={url}>{buttonTitle}</ListTenItemLink>}
            </ListTenItemContent>
          </ListTenItem>
        ))}
      </ListTenStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListTenWrapper>
)

ListTen.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      title: PropTypes.string,
      text: PropTypes.object,
      url: PropTypes.string,
      buttonTitle: PropTypes.string,
    }),
  ).isRequired,
}

ListTen.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListTen
