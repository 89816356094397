import styled from "@emotion/styled"

const CertificationAuthorWrap = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    order: -1;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 45px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    gap: 30px;
  }
`

export default CertificationAuthorWrap
