import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, isActive }) => css`
  background: ${isActive ? theme.palette.primary.main : "transparent"};
  color: ${isActive ? theme.palette.common.white : theme.palette.gray[900]};
`

const BlogListPaginationItem = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  ${dynamicStyle}

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 34px;
    height: 34px;
    border-radius: 8px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    width: 44px;
    height: 44px;
    border-radius: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 54px;
    height: 54px;
    border-radius: 15px;
  }
`

export default BlogListPaginationItem
