import styled from "@emotion/styled"

const ListElevenItemText = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 29px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 343px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    max-width: 500px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 425px;
  }
`

export default ListElevenItemText
