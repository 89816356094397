import styled from "@emotion/styled"

const CTAContent = styled.div`
  display: flex;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: center;
    justify-content: space-between;
  }
`

export default CTAContent
