import styled from "@emotion/styled"

const ListFiveText = styled.div`
  letter-spacing: 0.06em;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 41px;
  }

  p {
    margin: 0;
  }
`

export default ListFiveText
