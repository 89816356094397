import styled from "@emotion/styled"

const AuthorPostsTitle = styled.h2`
  font-weight: 700;
  font-family: "Gilroy";
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 40px;
  }
`

export default AuthorPostsTitle
