import styled from "@emotion/styled"

const CaseStudiesList = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    gap: 88px;
  }
`

export default CaseStudiesList
