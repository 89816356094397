import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText, isBlog }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}

  ${isBlog &&
  `
    ${theme.breakpoints.down("lg")} {
      margin-bottom: 40px;
    }
    
    ${theme.breakpoints.down("lg")} {
      margin-bottom: 60px;
    }
`}
`

const ListSeventeenStack = styled.div`
  display: flex;
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 20px;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: ${({ isBlog }) => isBlog ? `40px` : "32px" };
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    justify-content: center;
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export default ListSeventeenStack
