import styled from "@emotion/styled"

const BlogArticleTwoImages = styled.div`
  display: flex;
  gap: 30px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`

export default BlogArticleTwoImages
