import styled from "@emotion/styled"

const HeroFormFinalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    background-color: ${({ theme }) => theme.palette.gray[50]};
    border-radius: 10px;
    min-height: 688px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    min-height: 476px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-top: 88px;
    width: 503px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 503px;
    margin-right: auto;
    margin-left: 13.3%;
    margin-top: 88px;
  }
`

export default HeroFormFinalWrapper
