import styled from '@emotion/styled'

const ArticleAuthorContactInfoWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    flex-direction: column;
    gap: 11px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
`

export default ArticleAuthorContactInfoWrapper