import styled from "@emotion/styled"
import Container from "components/Container"

const TextFourContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    display: flex;
    gap: 74px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    display: flex;
    gap: 94px;
  }
`

export default TextFourContainer
