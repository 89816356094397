import styled from '@emotion/styled'
import Image from 'components/Image'

const GateForContentImage = styled(Image)`
  width: 100%;
  object-fit: contain;
  max-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    order: -1;
    margin: auto;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export default GateForContentImage