import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Light" && theme.palette.gray[50]) || theme.palette.common.white};
`

const ListFiveItemContainerCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  padding-top: 100%;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    bottom: -5px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    right: 0;
  }

  ${dynamicStyle}
`

export default ListFiveItemContainerCircle
