import styled from "@emotion/styled"

const ListSixItem = styled.div`
  list-style-type: none;
  display: grid;
  &::before {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    background: ${({ theme }) => theme.palette.primary.main};
    flex: 0 0 15px;
    border-radius: 50%;
    margin-bottom: auto;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid: min-content auto/15px auto;
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    width: 100%;
    grid: auto auto/15px auto;
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    width: 100%;
    grid: auto auto/15px auto;
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    &:before {
      margin-top: 4px;
    }
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    grid: auto auto/15px auto;
    column-gap: 15px;
    max-width: 471px;

    &:before {
      margin-top: 13px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 526px;
    width: 100%;
    grid: auto auto/15px auto;
    row-gap: 20px;
    column-gap: 18px;

    &:before {
      margin-top: 18px;
    }
  }
`

export default ListSixItem
