import styled from "@emotion/styled"

const CaseStudyParams = styled.div`
  display: grid;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 35% 65%;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    grid-template-columns: 42% 58%;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 12px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    gap: 9px;
  }
`

export default CaseStudyParams
