import styled from "@emotion/styled"

const Section404Content = styled.div`
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 341px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    max-width: 427px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 534px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 667px;
  }
`

export default Section404Content
