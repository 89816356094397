import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ variant, theme }) => css`
  ${(variant === "First" &&
    `
  ${theme.breakpoints.down("xl")} {
    margin-bottom: 20px;
  }

  ${theme.breakpoints.up("md")} {
    grid-template-columns: 4fr 4fr;
    column-gap: 25px;
  }

  ${theme.breakpoints.up("xl")} {
    margin-bottom: 30px;
  }
`) ||
  `
  ${theme.breakpoints.down("lg")} {
    margin-bottom: 18px;
  }

  ${theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 18px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 21px;
  }

  ${theme.breakpoints.up("2xl")} {
    margin-bottom: 26px;
  }
`}
`

const HeroFormForm = styled.div`
  display: grid;

  ${dynamicStyle}
`

export default HeroFormForm
