const Hexagon = () => (
  <svg
    width="877"
    height="888"
    viewBox="0 0 877 888"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M617.108 172.764C638.301 175.983 656.166 190.264 663.975 210.228L762.982 463.351C770.791 483.314 767.356 505.927 753.972 522.671L584.264 734.976C570.88 751.72 549.579 760.051 528.386 756.832L259.671 716.014C238.478 712.794 220.613 698.513 212.804 678.55L113.796 425.427C105.988 405.463 109.423 382.851 122.807 366.107L292.515 153.802C305.899 137.058 327.199 128.726 348.392 131.946L617.108 172.764Z"
      fill="url(#paint0_linear_417:236)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_417:236"
        x1="651.356"
        y1="177.967"
        x2="225.423"
        y2="710.811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default Hexagon
