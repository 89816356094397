import styled from "@emotion/styled"
import Title from "components/Title"

const BlogListTitle = styled(Title)`
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 44px;
    line-height: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 60px;
    line-height: 66px;
    margin-top: 40px;
  }
`

export default BlogListTitle
