import styled from "@emotion/styled"
import Image from "components/Image"

const ListEighteenItemIcon = styled(Image)`
  display: block;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.breakpoints.down("xl")} {
    height: 42%;
    width: 35%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    height: 56%;
    width: 44%;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    height: 43%;
    width: 34%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export default ListEighteenItemIcon
