import styled from "@emotion/styled"
import Title from "components/Title"

const ListTenTitle = styled(Title)`
  margin-top: 0;
  max-width: max-content;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 23px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    margin-bottom: 0;
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 0;
    font-size: 60px;
  }
`

export default ListTenTitle
