import styled from "@emotion/styled"

const HeroFormFormSubtitle = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamilyGothamPro};
  font-weight: 400;
  display: block;
  font-size: 18px;
  line-height: 27px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 27px;
  }
`

export default HeroFormFormSubtitle
