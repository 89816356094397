import styled from "@emotion/styled"

const ListThirteenItem = styled.div`
  display: grid;

  &::before {
    content: "";
    width: 15px;
    height: 15px;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    flex: 0 0 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    column-gap: 14px;
    row-gap: 24px;
    grid: min-content auto/15px auto;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 14px;
    row-gap: 24px;
    grid: min-content auto/15px auto;
    flex: 1 1 50%;
    &::before {
      margin-top: 12px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 14px;
    row-gap: 30px;
    grid: min-content auto/15px auto;

    &::before {
      margin-top: 16px;
    }
  }
`

export default ListThirteenItem
