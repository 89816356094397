import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ centeredBlock }) => css`
  ${centeredBlock &&
  `
    .swiper-wrapper {
      justify-content: center;
    }
 `}
`

const BlogListRecommendationsWrapperInner = styled.div`
  background: #f8f8fb;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: grid;

  .swiper {
    width: 100%;
    display: flex;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-right: 16px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    border-radius: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 26px 0px 26px 16px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 20px;
    grid-template-columns: 24px 1fr 24px;
    gap: 30px;
  }

  ${dynamicStyle}
`

export default BlogListRecommendationsWrapperInner
