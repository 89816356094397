import styled from "@emotion/styled"

const ListTenItemTitle = styled.p`
  margin-block-end: 0;
  margin-block-start: 0;
  font-style: normal;
  font-weight: bold;
  line-height: 110%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
  }
`

export default ListTenItemTitle
