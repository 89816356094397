import styled from "@emotion/styled"

const Quotes = styled.div`
  > svg {
    position: absolute;
    z-index: -1;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      display: none;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      right: -60px;
      bottom: -46px;
    }
  }
`

export default Quotes
