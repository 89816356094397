import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ fullHeightImage, textPosition, theme }) => css`
  ${theme.breakpoints.down("lg")} {
    ${textPosition === "Right" ? `order: 3; margin-top: 33px;` : "margin-bottom: 33px;"}
  }

  ${theme.breakpoints.between("lg", "xl")} {
    ${textPosition === "Left"
      ? `top: 23px;
        right: 23px;`
      : `top: 23px;
        left: 23px;`}
    ${textPosition === "Right" && !fullHeightImage
      ? `
      top: 0;
      right: 80px;
      `
      : ""}
    ${fullHeightImage && "height: calc(100% + 23px)"}
  }
  ${theme.breakpoints.up("xl")} {
    ${textPosition === "Left"
      ? `top: 23px;
        right: 23px;`
      : `top: 23px;
        left: 116px;`}
    ${textPosition === "Right" && !fullHeightImage
      ? `
      top: 0;
      right: 80px;
      `
      : ""}
    ${fullHeightImage && "height: calc(100% + 23px)"}
  }
`

const TextsTwoImageWrapper = styled.div`
  height: 100%;
  display: flex;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 45%;
  }
  ${dynamicStyle}
`
export default TextsTwoImageWrapper
