import styled from "@emotion/styled"

const EventListItemHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export default EventListItemHeaderTop
