import styled from "@emotion/styled"

const HeroCaseItemContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 181px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    align-items: flex-start;
  }
`

export default HeroCaseItemContent
