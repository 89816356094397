import styled from "@emotion/styled"
import Container from "components/Container"

const Section404Container = styled(Container)`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    gap: 32px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    gap: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 62px;
  }
`

export default Section404Container
