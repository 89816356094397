import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({
  buttonView = false,
  certificationImage = null,
  isFullWidth = false,
  theme,
}) => css`
  ${!isFullWidth &&
  `
    height:100%;

     ${theme.breakpoints.down("xl")} {
      display: flex;
      max-width: 793px;
    }

    ${theme.breakpoints.up("xl")} {
      display: flex;
    }
  `}

  ${isFullWidth &&
  certificationImage &&
  `
    display: grid;
    gap:28px;
    grid-template-columns: 1fr 1.16fr;
    margin-bottom: 40px;
  `}

  ${isFullWidth &&
  !certificationImage &&
  `
    margin-bottom: 40px;

    ${theme.breakpoints.down("xl")} {
      margin-bottom: 35px;
    }

    ${theme.breakpoints.up("xl")} {
      max-width: 793px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 50px;
    }
  `}

  ${!buttonView &&
  !isFullWidth &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 35px;
    }
  `}
`

const CertificationWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.gray[100]};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;

  ${({ theme }) => theme.breakpoints.down("md")} {
    min-height: 492px;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: space-between;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding: 30px 20px;
    margin-right: auto;
    margin-left: auto;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding: 50px;
  }

  ${dynamicStyle}
`

export default CertificationWrapper
