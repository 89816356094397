import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ colorVariant, theme }) => css`
  background-color: ${(colorVariant === "Dark" && "#1C1C1C") || "#EFEFF6"};
  color: ${colorVariant === "Dark" ? theme.palette.common.white : theme.palette.common.black};
`

const ArticleCTAWrapper = styled.section`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    border-radius: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 100%;
    border-radius: 16px;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 40px 30px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 40px 0 !important;
  }

  ${dynamicStyle}
`

export default ArticleCTAWrapper
