import styled from "@emotion/styled"

const BlogListRecommendationsItemTitle = styled.p`
  color: #000;
  font-style: normal;
  font-weight: 500;

  margin-top: 0;
  margin-bottom: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    -webkit-line-clamp: 2;
    font-size: 16px;
    line-height: 145%;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    -webkit-line-clamp: 1;
    font-size: 20px;
    line-height: 170%;
  }
`

export default BlogListRecommendationsItemTitle
