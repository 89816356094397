import styled from "@emotion/styled"

const BlogArticleTwoImagesItem = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
  }
`

export default BlogArticleTwoImagesItem
