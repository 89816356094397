import styled from "@emotion/styled"

const ListThirteenItemTitle = styled.h2`
  font-weight: bold;
  margin: 0;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 50px;
  }
`

export default ListThirteenItemTitle
