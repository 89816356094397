import styled from '@emotion/styled'

const CTAFormFormFieldWrapper = styled.div`
  width: 100%;

  ${({ theme, isBlog }) =>
    isBlog
      ? `
        ${theme.breakpoints.up("lg")} {
    &:nth-of-type(1) {
      width: 47.5%;
    }
    &:nth-of-type(2) {
      width: 47.5%;
    }
  }
      `
      : `
  ${theme.breakpoints.up("lg")} {
 &:nth-of-type(1) {
      width: 47.5%;
    }
    &:nth-of-type(2) {
      width: 47.5%;
    }
  }
  `};
`

export default CTAFormFormFieldWrapper