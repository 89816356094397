import styled from "@emotion/styled"
import Image from "components/Image"

const HeroFormFormImage = styled(Image)`
  overflow: hidden;
  border-radius: 50%;
  background-color: #c4c4c4;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 60px;
    max-height: 60px;
    flex: 0 0 60px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    max-width: 100px;
    max-height: 100px;
    flex: 0 0 100px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    max-width: 110px;
    max-height: 110px;
    flex: 0 0 110px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 120px;
    max-height: 120px;
    flex: 0 0 120px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 150px;
    max-height: 150px;
    flex: 0 0 150px;
  }
`

export default HeroFormFormImage
