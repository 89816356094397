import styled from "@emotion/styled"

const PostEventsEventControls = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    column-gap: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    column-gap: 55px;
    margin-left: -18px;
  }
`

export default PostEventsEventControls
