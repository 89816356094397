import styled from "@emotion/styled"

const BlogArticleTwoImagesItemTitle = styled.span`
  display: block;
  color: #c6c6db;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 18px;
    line-height: 34px;
    padding-top: 20px;
    margin-bottom: 21px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 12px;
    line-height: 22px;
    padding-top: 14px;
    margin-bottom: 26px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 14px;
    line-height: 27px;
    padding-top: 16px;
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 18px;
    line-height: 34px;
    padding-top: 20px;
    margin-bottom: 40px;
  }
`

export default BlogArticleTwoImagesItemTitle
