import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Title from "components/Title"

const dynamicStyle = ({ theme, variant = "Light" }) => css`
  ${variant === "Dark" && `color: ${theme.palette.common.white}`}
`

const PostEventsTitle = styled(Title)`
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 15px;
    line-height: 43px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 30px;
    line-height: 52px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "3xl")} {
    margin-bottom: 50px;
    line-height: 62px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    margin-bottom: 70px;
    line-height: 77px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "3xl")} {
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    font-size: 60px;
  }

  ${dynamicStyle}
`

export default PostEventsTitle
