import styled from "@emotion/styled"

const AuthorWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("3xl")} {
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    justify-content: right;
  }
`

export default AuthorWrapper
