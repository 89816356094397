import styled from "@emotion/styled"

const CertificationsList = styled.div`
  .swiper-slide {
    height: auto;
    margin-bottom: 50px;
  }
`

export default CertificationsList
