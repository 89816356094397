import * as React from "react"
import PropTypes from "prop-types"
import HeroSectionWrapper from "components/Hero/HeroSectionWrapper"
import HeroWrapper from "components/Hero/HeroWrapper"
import HeroContainer from "components/Hero/HeroContainer"
import Breadcrumbs from "components/Breadcrumbs"
import HeroBreadcrumbsLink from "components/Hero/HeroBreadcrumbsLink"
import HeroText from "components/Hero/HeroText"
import HeroTextBeforeTitle from "components/Hero/HeroTextBeforeTitle"
import HeroTitle from "components/Hero/HeroTitle"
import HeroPersonName from "components/Hero/HeroPersonName"
import HeroImage from "components/Hero/HeroImage"
import HeroImageContainer from "components/Hero/HeroImageContainer"
import HeroBackgroundImage from "components/Hero/HeroBackgroundImage"
import HeroArticle from "components/Hero/HeroArticle"
import HeroTextsWrapper from "components/Hero/HeroTextsWrapper"
import Link from "components/Link"
import Arrow from "components/Icons/Arrow"
import htmlFormatting from "utils/htmlFormatting"
import Button from "components/Buttons/Button"
import Meta from "components/Meta"
import MicromarkingWrapper from "components/MicromarkingWrapper"

const Hero = ({
  theme,
  breadcrumbs,
  textBeforeTitle,
  textBeforeTitleTag,
  addQuotes,
  title,
  titleTag,
  personName,
  heroText,
  buttonText,
  buttonLink,
  sectionScroll,
  backgroundImage,
  image,
  imageSize,
  rightText,
}) => {
  const isBrowser = typeof window !== "undefined"
  const handleScroll = () => {
    document.querySelector("#gatsby-focus-wrapper").style.overflow = "inherit"

    const el = document.getElementById(sectionScroll)
    const y = el.getBoundingClientRect().top + (isBrowser ? window.scrollY : 0) - 100

    if (isBrowser) {
      window.scrollTo({ top: y, behavior: "smooth" })
    }
    }

  return (
    <HeroSectionWrapper>
      <HeroWrapper colorVariant={theme} backgroundImage={backgroundImage}>
        <HeroContainer imgAndText={image !== null && rightText !== null}>
          {breadcrumbs?.length && (
            <MicromarkingWrapper itemscope="" itemtype="http://schema.org/BreadcrumbList">
              <Breadcrumbs
                itemprop="itemListElement"
                itemscope=""
                itemtype="http://schema.org/ListItem"
              >
                {breadcrumbs.map(
                  (el, idx) =>
                    (breadcrumbs.length - 1 !== idx && (
                      <>
                        <HeroBreadcrumbsLink
                          itemprop="item"
                          colorVariant={theme}
                          isActive={breadcrumbs.length - 1 === idx}
                          to={el.link}
                          key={el.id}
                        >
                          <Meta itemprop="name" content={el.title} />
                          <Meta itemprop="position" content={idx + 1} />
                          {el.title}
                        </HeroBreadcrumbsLink>
                        <Arrow
                          key={el.link}
                          direction="left"
                          color="#BBBBBB"
                          width={14}
                          height={12}
                        />
                      </>
                    )) || (
                      <HeroBreadcrumbsLink
                        colorVariant={theme}
                        key={el.id}
                        isActive={breadcrumbs.length - 1 === idx}
                      >
                        {el.title}
                      </HeroBreadcrumbsLink>
                    ),
                )}
              </Breadcrumbs>
            </MicromarkingWrapper>
          )}
          {textBeforeTitle && (
            <HeroTextBeforeTitle colorVariant={theme} as={textBeforeTitleTag}>
              {textBeforeTitle}
            </HeroTextBeforeTitle>
          )}
          <HeroTitle
            backgroundImage={backgroundImage}
            addQuotes={addQuotes}
            colorVariant={theme}
            as={titleTag}
          >
            {(addQuotes ? "“‎‎" : "") + title + (addQuotes ? "”‎" : "")}
          </HeroTitle>
          {personName && <HeroPersonName colorVariant={theme}>{personName}</HeroPersonName>}
          <HeroTextsWrapper imgAndText={image !== null && rightText !== null}>
            {heroText && (
              <HeroText
                colorVariant={theme}
                backgroundImage={backgroundImage}
                rightText={rightText}
                addQuotes={addQuotes}
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(heroText?.childMarkdownRemark?.html),
                }}
                imgAndText={image !== null && rightText !== null}
                imageExist={image !== null}
              />
            )}
            {rightText && (
              <HeroArticle
                imgAndText={!!image}
                colorVariant={theme}
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(rightText?.childMarkdownRemark?.html),
                }}
              />
            )}
          </HeroTextsWrapper>
          <HeroImageContainer
            imageExist={image !== null}
            backgroundImage={backgroundImage}
            addQuotes={addQuotes}
          >
            <HeroImage
              imageSize={imageSize}
              {...image}
              backgroundImage={backgroundImage}
              objectFit="contain"
            />
            {backgroundImage !== "None" && (
              <HeroBackgroundImage colorVariant={theme} backgroundImage={backgroundImage} />
            )}
          </HeroImageContainer>

          {buttonLink && buttonText && !sectionScroll && (
            <Button as={Link} to={buttonLink} variant="contained">
              {buttonText}
            </Button>
          )}
          {buttonText && sectionScroll && (
            <Button onClick={handleScroll} variant="contained">
              {buttonText}
            </Button>
          )}
        </HeroContainer>
      </HeroWrapper>
    </HeroSectionWrapper>
  )
}

Hero.propTypes = {
  theme: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  textBeforeTitle: PropTypes.string,
  textBeforeTitleTag: PropTypes.string.isRequired,
  addQuotes: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
  heroText: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  sectionScroll: PropTypes.string,
  backgroundImage: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageSize: PropTypes.string.isRequired,
  rightText: PropTypes.object,
}

Hero.defaultProps = {
  breadcrumbs: [],
  textBeforeTitle: "",
  image: null,
  rightText: null,
  sectionScroll: "",
}

export default Hero
