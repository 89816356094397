import * as React from "react"
import PropTypes from "prop-types"

import ArticleCTAWrapper from "components/Article/ArticleContentVariants/ArticleCTA/ArticleCTAWrapper"
import ArticleCTATitle from "components/Article/ArticleContentVariants/ArticleCTA/ArticleCTATitle"
import ArticleCTAText from "components/Article/ArticleContentVariants/ArticleCTA/ArticleCTAText"
import ArticleCTAIcon from "components/Article/ArticleContentVariants/ArticleCTA/ArticleCTAIcon"
import ArticleCTAContent from "components/Article/ArticleContentVariants/ArticleCTA/ArticleCTAContent"
import ArticleCTAButton from "components/Article/ArticleContentVariants/ArticleCTA/ArticleCTAButton"
import ArticleCTAInnerContent from "components/Article/ArticleContentVariants/ArticleCTA/ArticleCTAInnerContent"

import Link from "components/Link"
import * as Icons from "components/Icons"

const ArticleCTA = ({ title, titleTag, text, buttonTitle, buttonLink, theme, iconType }) => {
  let IconComponent

  if (iconType !== "None") {
    IconComponent = Icons[iconType]
  }

  return (
    <ArticleCTAWrapper colorVariant={theme}>
      <ArticleCTAContent>
        <ArticleCTATitle as={titleTag}>{title}</ArticleCTATitle>

        <ArticleCTAInnerContent>
          <ArticleCTAText>{text}</ArticleCTAText>
          <ArticleCTAButton as={Link} variant="contained" to={buttonLink}>
            {buttonTitle}
          </ArticleCTAButton>
        </ArticleCTAInnerContent>
      </ArticleCTAContent>
      {/* {iconType !== "None" && (
        <ArticleCTAIcon>
          <IconComponent />
        </ArticleCTAIcon>
      )} */}
    </ArticleCTAWrapper>
  )
}

ArticleCTA.propTypes = {
  theme: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
}


export default ArticleCTA