import styled from "@emotion/styled"
import NetlifyForm from "components/NetlifyForm"

const CTAFormContent = styled(NetlifyForm)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.gray[50]};

  ${({ theme, isBlog }) =>
    isBlog
      ? `
  ${theme.breakpoints.down("lg")} {
    padding-top: 60px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 41px;
    width: 100%;
  }

  ${theme.breakpoints.between("lg", "2xl")} {
    padding: 60px;
    width: 100%;
  }
  ${theme.breakpoints.between("xl", "2xl")} {
    width: 78%;
    padding-top: 72px;
    padding-left: 70px;
  }

  ${theme.breakpoints.up("2xl")} {
    padding: 60px;
    width: 100%;
    max-width: 883px;
  }
  `
      : `
       ${theme.breakpoints.down("lg")} {
    padding-top: 60px;
    padding-left: 16px;
    padding-right: 13px;
    padding-bottom: 60px;
    width: 100%;
  }

  ${theme.breakpoints.between("lg", "2xl")} {
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
    width: 100%;
  }
  ${theme.breakpoints.between("xl", "2xl")} {
    width: 78%;
    padding-top: 72px;
    padding-left: 70px;
  }

  ${theme.breakpoints.up("2xl")} {
    padding-top: 90px;
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: 76px;
    width: 100%;
    max-width: 883px;
  }
      `};
`

export default CTAFormContent
