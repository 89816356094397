import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Clutch from "components/Icons/Clutch"
import Link from "components/Link"

const TestimonialsInfoContainer = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const TestimonialsInfoTop = styled.div`
  display: flex;
`

const TestimonialsInfoText = styled.span`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
`

const TestimonialsInfoBottom = styled.div`
  font-size: 18px;
  line-height: 22px;
`

const TestimonialsInfoWrapper = styled(Link)`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 46px;
  text-decoration: none;
  color: inherit;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 22px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 77px;
  }
`

const TestimonialsInfo = ({ link, text, rating }) => {
  const StarsWrapper = styled.div`
    display: inline-block;
    font-size: 22px;
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;
    padding-left: 10px;

    &::before {
      content: "★★★★★";
      letter-spacing: 4px;
      background: linear-gradient(
        90deg,
        ${({ theme }) => theme.palette.primary.main} ${`${(rating / 5) * 100}%`},
        ${({ theme }) => theme.palette.gray[300]} ${`${(rating / 5) * 100}%`}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `

  return (
    <TestimonialsInfoWrapper to={link}>
      <Clutch />
      <TestimonialsInfoContainer>
        <TestimonialsInfoTop>
          <TestimonialsInfoText>{rating}</TestimonialsInfoText>
          <StarsWrapper rating={rating} />
        </TestimonialsInfoTop>
        <TestimonialsInfoBottom>{text}</TestimonialsInfoBottom>
      </TestimonialsInfoContainer>
    </TestimonialsInfoWrapper>
  )
}

TestimonialsInfo.propTypes = {
  text: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
}

export default TestimonialsInfo
