import styled from "@emotion/styled"

const ListTenItemText = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: 18px;
  line-height: 160%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 10px;
  }
`

export default ListTenItemText
