import styled from "@emotion/styled"
import Link from "components/Link"

const TestimonialLink = styled(Link)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: 0;
  text-decoration: none;
  line-height: 35px;
  font-size: 18px;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  margin-bottom: -1px;
  max-width: max-content;
  width: 100%;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export default TestimonialLink
