import styled from "@emotion/styled"

const HeroFormContent = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 50%;
  }
`

export default HeroFormContent
