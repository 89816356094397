import styled from '@emotion/styled'

const RelatedArticlesItemTitle = styled.p`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 0;  
`

export default RelatedArticlesItemTitle