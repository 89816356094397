import styled from "@emotion/styled"

const ArticleAuthorNameEl = (props) => <span {...props} />

const ArticleAuthorName = styled(ArticleAuthorNameEl)`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 5px;

  & > span {
    padding: 4px 10px;
    background-color: #def4e1;
    font-size: 12px;
    border-radius: 5px;
    margin-left: 8px;
  }
`

export default ArticleAuthorName
