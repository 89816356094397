import styled from "@emotion/styled"
import Image from "components/Image"

const TextOneImageContainer = styled(Image)`
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 45px;
  }
`

export default TextOneImageContainer
