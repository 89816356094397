import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ textPosition, theme }) => css`
  ${theme.breakpoints.up("lg")} {
    flex-direction: ${(textPosition === "Left" && "row-reverse") || "row"};
  }
`

const TextThreeContent = styled.div`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    column-gap: 99px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 139px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 174px;
  }

  ${dynamicStyle}
`

export default TextThreeContent
