import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListNineItem from "components/ListNine/ListNineItem"
import ListNineItemLink from "components/ListNine/ListNineItemLink"
import ListNineItemTitle from "components/ListNine/ListNineItemTitle"
import ListNineItemContainer from "components/ListNine/ListNineItemContainer"
import ListNineItemText from "components/ListNine/ListNineItemText"
import ListNineStack from "components/ListNine/ListNineStack"
import ListNineTitle from "components/ListNine/ListNineTitle"
import ListNineWrapper from "components/ListNine/ListNineWrapper"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListNine = ({ title, titleTag, theme, listItems, lowerText, sectionId }) => (
  <ListNineWrapper id={sectionId} colorVariant={theme}>
    <Container>
      <ListNineTitle as={titleTag}>{title}</ListNineTitle>
      <ListNineStack lowerText={lowerText}>
        {listItems.map(({ id, title: itemTitle, titleTag: itemTitleTag, text, buttonTitle, url }) => (
          <ListNineItem colorVariant={theme} key={id}>
            <ListNineItemContainer>
              <ListNineItemTitle as={itemTitleTag} colorVariant={theme}>{itemTitle}</ListNineItemTitle>
              {text && (
                <ListNineItemText
                  colorVariant={theme}
                  dangerouslySetInnerHTML={{
                    __html: htmlFormatting(text?.childMarkdownRemark?.html),
                  }}
                />
              )}
              {url && (
                <ListNineItemLink colorVariant={theme} to={url}>
                  {buttonTitle}
                </ListNineItemLink>
              )}
            </ListNineItemContainer>
          </ListNineItem>
        ))}
      </ListNineStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListNineWrapper>
)

ListNine.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.object,
      buttonTitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
}

ListNine.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListNine
