import styled from "@emotion/styled"

const CTAFormFields = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  ${({ theme, isBlog }) =>
    isBlog
      ? `
       ${theme.breakpoints.down("lg")} {
    flex-direction: column;
    row-gap: 30px;
    padding-right: 8px;
    padding-bottom: 21px;
    padding-top: 15px;
  }

  ${theme.breakpoints.up("lg")} {
    row-gap: 30px;
    padding-top: 30px;
  }

  ${theme.breakpoints.between("lg", "xl")} {
    column-gap: 5%;
    padding-bottom: 36px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    column-gap: 5%;
    padding-bottom: 36px;
  }

  ${theme.breakpoints.up("2xl")} {
    column-gap: 5%;
    padding-bottom: 40px;
  }
      `
      : `
    padding-top: 36px;
  
  ${theme.breakpoints.down("lg")} {
    flex-direction: column;
    row-gap: 30px;
    padding-right: 8px;
    padding-bottom: 36px;
  }

  ${theme.breakpoints.up("lg")} {
    row-gap: 30px;
  }

  ${theme.breakpoints.between("lg", "xl")} {
    column-gap: 5%;
    padding-bottom: 36px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    column-gap: 5%;
    padding-top: 9px;
    padding-bottom: 36px;
  }

  ${theme.breakpoints.up("2xl")} {
    column-gap: 35px;
    padding-bottom: 90px;
  }
  `};
`

export default CTAFormFields
