import styled from "@emotion/styled"

const ListFiveItemListItem = styled.li`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  column-gap: 20px;

  &:not(:last-child) {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-bottom: 10px;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    flex-shrink: 0;
  }
`

export default ListFiveItemListItem
