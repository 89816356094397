import styled from "@emotion/styled"

const CTAFormBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isBlog, theme }) =>
    isBlog
      ? `
      gap: 25px;
  flex-wrap: wrap;

  ${theme.breakpoints.down("lg")} {
    flex-direction: column;
    align-items: self-start;
  }
      `
      : `
    ${theme.breakpoints.down("2xl")} {
    flex-direction: column;
    row-gap: 25px;
    align-items: self-start;
  }
  `}
`

export default CTAFormBottom
