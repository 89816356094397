import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListElevenWrapper from "components/ListEleven/ListElevenWrapper"
import ListElevenTitle from "components/ListEleven/ListElevenTitle"
import ListElevenText from "components/ListEleven/ListElevenText"
import ListElevenStack from "components/ListEleven/ListElevenStack"
import ListElevenItem from "components/ListEleven/ListElevenItem"
import ListElevenItemNumber from "components/ListEleven/ListElevenItemNumber"
import ListElevenItemText from "components/ListEleven/ListElevenItemText"
import ListElevenItemTitle from "components/ListEleven/ListElevenItemTitle"
import ListElevenHeader from "components/ListEleven/ListElevenHeader"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListEleven = ({
  title,
  titleTag,
  theme: colorVariant,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListElevenWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListElevenHeader>
        <ListElevenTitle as={titleTag}>{title}</ListElevenTitle>
        {subtitleText && (
          <ListElevenText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListElevenHeader>
      <ListElevenStack lowerText={lowerText}>
        {listItems.map(({ id, number, title: itemTitle, text }) => (
          <ListElevenItem key={id}>
            <ListElevenItemTitle>
              {itemTitle}
              {number && <ListElevenItemNumber>{number}</ListElevenItemNumber>}
            </ListElevenItemTitle>
            {text && (
              <ListElevenItemText
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(text?.childMarkdownRemark?.html),
                }}
              />
            )}
          </ListElevenItem>
        ))}
      </ListElevenStack>
    </Container>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListElevenWrapper>
)

ListEleven.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      number: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.object,
    }),
  ).isRequired,
}

ListEleven.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListEleven
