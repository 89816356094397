import styled from "@emotion/styled"

const HeroFormFormPrivacyText = styled.div`
  font-size: 18px;
  line-height: 27px;
  margin-top: 0;
  padding-top: 1px;
  flex-basis: 70%;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 20px;
  }
`

export default HeroFormFormPrivacyText
