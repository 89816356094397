import styled from "@emotion/styled"

const BlogListPostTextEl = (props) => <div {...props} />

const BlogListPostText = styled(BlogListPostTextEl)`
  font-size: 16px;
  line-height: 27px;
  
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 747px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
  }
`

export default BlogListPostText
