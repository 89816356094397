import styled from "@emotion/styled"
import TextsText from "components/Texts/TextsText"

const TextCalendlyText = styled(TextsText)`
  max-width: 996px;
  margin-left: auto;
  margin-right: auto;
  width: auto;
`

export default TextCalendlyText
