import styled from "@emotion/styled"
import Image from "components/Image"

const FooterImage = styled(Image)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 120px;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 160px;
  }
`

export default FooterImage
