import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Arrow from "components/Icons/Arrow"

const Component = styled.div`
  text-align: center;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin: 0 auto;
  }
`

const TechOneButtonIcon = styled.span`
  width: 69px;
  height: 69px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  margin-bottom: 14px;
  margin-top: 14px;

  &::before {
    content: "";
    width: 83px;
    height: 83px;
    position: absolute;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`

const TechOneButtonText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: none;
  }
`

const TechOneButton = ({ buttonText }) => (
  <Component>
    <TechOneButtonIcon>
      <Arrow direction="right" width={21} height={17} color="#fff" />
    </TechOneButtonIcon>
    <TechOneButtonText>{buttonText}</TechOneButtonText>
  </Component>
)

TechOneButton.propTypes = {
  buttonText: PropTypes.string,
}

TechOneButton.defaultProps = {
  buttonText: "",
}

export default TechOneButton
