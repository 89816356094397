import styled from "@emotion/styled"

const HeroCaseDemoMobile = styled.div`
  position: absolute;
  bottom: 8.5%;
  background-color: ${({ theme }) => theme.palette.gray[50]};
  border: 2px solid ${({ theme }) => theme.palette.gray[300]};
  border-radius: 15% / 6%;
  padding: 1% 0.5%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    right: 9%;
    width: 17.5%;
    height: 58%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    right: 14%;
    width: 11.5%;
    height: 40%;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1%;
    left: 35%;
    width: 5%;
    padding-top: 5%;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 50%;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 1%;
    left: 50%;
    width: 10%;
    padding-top: 5%;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 999px;
  }

  & > * {
    border-radius: 12%/6%;
    border: 2px solid ${({ theme }) => theme.palette.common.white};
    width: 100%;
    height: 100%;
  }
`

export default HeroCaseDemoMobile
