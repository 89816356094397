import styled from '@emotion/styled'

const RelatedArticlesWrapper = styled.section`
  width: 100%;
  padding-bottom: 60px;

  .swiper {
    width: 100%;
  }

  & h1 {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      text-align: center;
      margin: 0 0 30px 0;
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
      text-align: auto;
      margin: 0 0 64px 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.gray[100]};
    padding-top: 60px;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: 100%;
    padding-top: 60px;
  }
`

export default RelatedArticlesWrapper