import styled from "@emotion/styled"

const PostListBackground = styled.div`
  height: 100%;
  position: absolute;
  width: 60%;
  z-index: -1;
  top: 0;
  left: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    transform: scale(-1);
  }

  svg {
    height: 100%;
  }
`

export default PostListBackground
