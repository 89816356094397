import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ colorVariant }) => css`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6,
    & > p {
      margin-bottom: 45px;
    }
    & div > p {
      max-width: ${colorVariant === "Light" ? "650px" : "760px"};
      line-height: 1.7;
    }
  }
`

const TextsTwoContent = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 51%;
  }

  ${dynamicStyle}
`
export default TextsTwoContent
