import styled from "@emotion/styled"

const ArticleInfoStack = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 21px;
    width: 100%;
    align-items: baseline;
    justify-content: flex-end;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    gap: 26px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 40px;
  }
`

export default ArticleInfoStack
