import styled from "@emotion/styled"

const CTAFormConfirmation = styled.span`
  line-height: 27px;
  max-width: 450px;
  padding-top: 1px;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    padding-left: 0;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-left: 16px;
  }

  ${({ theme, isBlog }) =>
    isBlog
      ? `  ${theme.breakpoints.down("lg")} {
    font-size: 12px;
    padding-left: 0;
  }

  ${theme.breakpoints.up("lg")} {
    font-size: 16px;
    padding-left: 16px;
  }`
      : `font-size: 18px;`};
`

export default CTAFormConfirmation
