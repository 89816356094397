import styled from "@emotion/styled"

const HeroFormFormDenyButton = styled.button`
  font-size: 18px;
  line-height: 34px;
  background: transparent;
  cursor: pointer;
  border: none;
  color: ${({ theme }) => theme.palette.gray[600]};
`

export default HeroFormFormDenyButton
