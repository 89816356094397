import styled from "@emotion/styled"

const ListSevenItemTitle = styled.span`
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 20px;
    line-height: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 20px;
    line-height: 32px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 33px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 34px;
  }
`

export default ListSevenItemTitle
