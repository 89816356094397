import styled from '@emotion/styled'

const ArticleAsideNavListItemTitle = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export default ArticleAsideNavListItemTitle