import styled from "@emotion/styled"

const HeroFormFinalText = styled.div`
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  margin-bottom: 40px;
  white-space: pre-wrap;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 18px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 20px;
  }
`

export default HeroFormFinalText
