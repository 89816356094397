import styled from "@emotion/styled"

const GateForContentStages = styled.div`
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    display: contents;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding: 40px 0 40px 40px;
  }
`

export default GateForContentStages
