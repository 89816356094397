import styled from "@emotion/styled"

const Section404Title = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  color: ${({ theme }) => theme.palette.gray[900]};

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 60px;
  }
`

export default Section404Title
