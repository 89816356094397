import styled from "@emotion/styled"

const ArticleAsideBannerText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.black};

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 18px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 30px;
  }
`

export default ArticleAsideBannerText
