import React from "react"
import PropTypes from "prop-types"

import htmlFormatting from "utils/htmlFormatting"

import TestimonialText from "components/Testimonial/TestimonialText"
import TestimonialTitle from "components/Testimonial/TestimonialTitle"
import Image from "components/Image"

import useHasMounted from "../../hooks/useHasMounted"

import CertificationWrapper from "./CertificationWrapper"
import CertificationButton from "./CertificationButton"
import CertificationLogo from "./CertificationLogo"
import CertificationAuthorWrap from "./CertificationAuthorWrap"
import CertificationAuthorInfo from "./CertificationAuthorInfo"
import CertificationAuthorRole from "./CertificationAuthorRole"
import CertificationAuthorPhoto from "./CertificationAuthorPhoto"
import CertificationContentWrap from "./CertificationContentWrap"
import CertificationInner from "./CertificationInner"
import CertificationPhoto from "./CertificationPhoto"
import CertificationPhotoWrap from "./CertificationPhotoWrap"

const Certification = ({
  title,
  titleTag,
  description,
  certificationImage,
  buttonText,
  logo,
  author,
  isFullWidth,
  buttonView,
  setModal,
}) => {
  const [textDesc, setTextDesc] = React.useState(null)
  const mounted = useHasMounted()
  const certificationShow = async (e) => {
    await setModal((prev) => !prev)
    const sectionEl = e.target.closest("div[id]")
    const modelContent = sectionEl.querySelector(".certificationModalImg")

    if (modelContent) {
      modelContent.innerHTML = `<picture><img src=${certificationImage.file.url} alt=${certificationImage.alt}/></picture> `
    }
  }

  React.useEffect(() => {
    if (description) {
      setTextDesc(htmlFormatting(description?.childMarkdownRemark?.html))
    }
    // eslint-disable-next-line
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <CertificationWrapper
      buttonView={buttonView}
      certificationImage={certificationImage}
      isFullWidth={isFullWidth}
    >
      <CertificationInner>
        <CertificationContentWrap>
          <TestimonialTitle tag={titleTag}>{title}</TestimonialTitle>

          {description && (
            <TestimonialText
              dangerouslySetInnerHTML={{
                __html: textDesc,
              }}
            />
          )}

          {certificationImage && (
            <CertificationButton onClick={certificationShow} isFullWidth={isFullWidth}>
              {buttonText}
            </CertificationButton>
          )}

          {author && (
            <CertificationAuthorWrap>
              <CertificationAuthorPhoto {...author.authorPhoto} />

              <CertificationAuthorInfo>
                <TestimonialTitle>{author.authorName}</TestimonialTitle>
                <CertificationAuthorRole>{author.authorRole}</CertificationAuthorRole>
              </CertificationAuthorInfo>
            </CertificationAuthorWrap>
          )}
        </CertificationContentWrap>

        <CertificationLogo certificationImage={certificationImage}>
          <Image {...logo} />
        </CertificationLogo>
      </CertificationInner>

      {isFullWidth && certificationImage && (
        <CertificationPhotoWrap onClick={certificationShow}>
          <CertificationPhoto {...certificationImage} />
        </CertificationPhotoWrap>
      )}
    </CertificationWrapper>
  )
}

export default Certification

Certification.propTypes = {
  setModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string,
  isFullWidth: PropTypes.bool,
  description: PropTypes.object.isRequired,
  certificationImage: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
  buttonView: PropTypes.bool,
}

Certification.defaultProps = {
  titleTag: 'h3',
  isFullWidth: false,
  buttonView: false,
  certificationImage: null,
}
