import styled from "@emotion/styled"
import Image from "components/Image"

const ListOneItemIcon = styled(Image)`
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    width: 85px;
    height: 85px;
    margin-bottom: 13px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
  }

  svg,
  img {
    width: 100%;
    height: 100%;
  }
`

export default ListOneItemIcon
