import styled from "@emotion/styled"
import Image from "components/Image"

const CertificationAuthorPhoto = styled(Image)`
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  z-index: 4;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 66px;
    height: 66px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 80px;
    height: 80px;
  }
`

export default CertificationAuthorPhoto
