import styled from "@emotion/styled"

const HeroAboutImageWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.breakpoints.down("xsm")} {
    height: auto;
  }

  ${({ theme }) => theme.breakpoints.between("xsm", "sm")} {
    div,
    svg {
      height: 280px;
    }
  }

  ${({ theme }) => theme.breakpoints.between("sm", "lg")} {
    margin-bottom: 43px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex: 0 0 60%;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    max-width: 421px;
    width: 100%;

    div,
    svg {
      max-width: 421px;
      width: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 568px;

    div,
    svg {
      max-width: 568px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 791px;
    max-height: 614px;

    div,
    svg {
      max-width: 791px;
      max-height: 614px;
    }
  }
`

export default HeroAboutImageWrapper
