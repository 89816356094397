import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListFifteenStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 41px;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    gap: 140px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 140px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    gap: 12.4%;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    gap: 15%;
  }
`

export default ListFifteenStack
