import styled from "@emotion/styled"

const TestimonialText = styled.div`
  font-size: 18px;
  letter-spacing: 0.1px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 35px;
  }
`

export default TestimonialText
