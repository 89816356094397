import { useEffect, useState } from "react"

const useScrollingUp = (offset = 0) => {
  const isBrowser = typeof window !== "undefined"
  let prevScroll
  if (isBrowser) {
    prevScroll = document.querySelector("#gatsby-focus-wrapper")?.scrollTop || 0
  }

  const [scrollingUp, setScrollingUp] = useState(true)

  const handleScroll = () => {
    const currScroll = isBrowser && document.querySelector("#gatsby-focus-wrapper").scrollTop
    if (currScroll > offset + 30) {
      setScrollingUp(!(currScroll > offset))
    }
    if (currScroll < offset /* && !scrollingUp */) {
      setScrollingUp(true)
    }
    prevScroll = currScroll
  }

  useEffect(() => {
    if (isBrowser) document
      .querySelector("#gatsby-focus-wrapper")
      .addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      if (isBrowser) document
        .querySelector("#gatsby-focus-wrapper")
        .removeEventListener("scroll", handleScroll, { passive: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return scrollingUp
}

export default useScrollingUp
