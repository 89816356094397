import styled from "@emotion/styled"

const CaseStudyTextEl = (props) => <div {...props} />

const CaseStudyText = styled(CaseStudyTextEl)`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.common.black};

  ${({ theme }) => theme.breakpoints.down("xl")} {
    line-height: 1.6;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    line-height: 160%;
    margin-bottom: 31px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    line-height: 1.7;
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    line-height: 1.7;
    margin-bottom: 40px;
  }
`

export default CaseStudyText
