import styled from "@emotion/styled"
import Container from "components/Container"

const PostEventsContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "xxl")} {
    max-width: ${({ theme }) => theme.breakpoints.values.xl};
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    max-width: ${({ theme }) => theme.breakpoints.values["2xl"]};
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    max-width: ${({ theme }) => theme.breakpoints.values["3xl"]};
  }

  ${({ theme }) => theme.breakpoints.between("xl", "3xl")} {
    padding-left: 50px;
    padding-right: 50px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
  }
`

export default PostEventsContainer
