import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"

import ListOneContainer from "components/ListOne/ListOneContainer"
import ListOneContent from "components/ListOne/ListOneContent"
import ListOneHeader from "components/ListOne/ListOneHeader"
import ListOneItemDescription from "components/ListOne/ListOneItemDescription"
import ListOneItemIcon from "components/ListOne/ListOneItemIcon"
import ListOneStack from "components/ListOne/ListOneStack"
import ListOneText from "components/ListOne/ListOneText"
import ListOneTitle from "components/ListOne/ListOneTitle"
import ListOneWrapper from "components/ListOne/ListOneWrapper"
import ListOneContentText from "components/ListOne/ListOneContentText"
import ListOneItem from "components/ListOne/ListOneItem"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListOne = ({
  title,
  titleTag,
  theme: colorVariant,
  textBottom,
  subtitleText,
  listItems,
  lowerText,
  sectionId,
}) => (
  <ListOneWrapper id={sectionId} colorVariant={colorVariant}>
    <ListOneContainer lowerText={lowerText}>
      <ListOneHeader>
        <ListOneTitle as={titleTag}>{title}</ListOneTitle>
        <ListOneText>{textBottom}</ListOneText>
      </ListOneHeader>
      <ListOneContent>
        <ListOneStack>
          {listItems.map(({ id, title: itemTitle, icon }) => (
            <ListOneItem key={id} colorVariant={colorVariant}>
              <ListOneItemIcon {...icon} />
              <ListOneItemDescription>{itemTitle}</ListOneItemDescription>
            </ListOneItem>
          ))}
        </ListOneStack>
        {subtitleText && (
          <ListOneContentText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(subtitleText?.childMarkdownRemark?.html),
            }}
          />
        )}
      </ListOneContent>
    </ListOneContainer>
    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListOneWrapper>
)

ListOne.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  textBottom: PropTypes.string.isRequired,
  subtitleText: PropTypes.object.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      title: PropTypes.string,
    }),
  ).isRequired,
}

ListOne.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListOne
