import styled from "@emotion/styled"

const TechOneCategories = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: flex;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    column-gap: 69px;
    display: grid;
    grid: repeat(5, auto) / 379px auto;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 69px;
    display: grid;
    grid: repeat(5, auto) / 379px auto;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 69px;
    display: grid;
    grid: repeat(5, auto) / 379px auto;
  }
`

export default TechOneCategories
