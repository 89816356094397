import styled from "@emotion/styled"

const PostEventsWrapper = styled.section`
  position: relative;
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 1920px;
  }

  &::before {
    ${({ theme }) => theme.breakpoints.up("xl")} {
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.palette.gray[100]};
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 50%;
      height: 100%;
    }
  }

  &::after {
    ${({ theme }) => theme.breakpoints.up("xl")} {
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.palette.gray[900]};
      z-index: -1;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
    }
  }
`

export default PostEventsWrapper
