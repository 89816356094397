import styled from "@emotion/styled"

const ArticleTagsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 90px;
  margin-top: 40px;

  & > .swiper {
    margin-left: unset;
    margin-right: unset;
  }
  & > .swiper .swiper-slide {
    width: min-content;
  }
`

export default ArticleTagsWrapper
