import styled from "@emotion/styled"
import Container from "components/Container"

const TextOneContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export default TextOneContainer
