import * as React from "react"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import PropTypes from "prop-types"
import TextFourWrapper from "components/TextFour/TextFourWrapper"
import TextFourTitle from "components/TextFour/TextFourTitle"
import TextFourText from "components/TextFour/TextFourText"
import TextFourSubtitle from "components/TextFour/TextFourSubtitle"
import TextFourHeader from "components/TextFour/TextFourHeader"
import TextFourContainer from "components/TextFour/TextFourContainer"
import htmlFormatting from "utils/htmlFormatting"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Image from "components/Image"
import ArticleVideoWrapper from "components/Article/ArticleVideoWrapper"
import ArticleImageTitle from "components/Article/ArticleImageTitle"
import ArticleContent from "components/Article/ArticleContent"

import {
  ArticleContentTitle,
  ArticleContentTable,
  ArticleContentTableRow,
  ArticleContentTableCell,
  ArticleContentLink,
  ArticleContentBold,
  ArticleContentUL,
  ArticleContentOL,
  ArticleContentText,
} from "components/Article"

import CTAText from "./CTAText"
import ArticleCTA from "./ArticleCTA"
import BlogArticleTwoImages from "./BlogArticleTwoImages"

const prettifyAnchorLink = (text) =>
  text.toLowerCase().replace(/\W/g, (symb) => (symb === " " ? "-" : ""))

const getText = (el) => {
  return typeof el === "string" ? el : getText(el.props.children)
}

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <ArticleContentBold>{text}</ArticleContentBold>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <ArticleContentTitle as="h1" fontSize="Large" fontFamily="Gilroy" mb={40} pt={90}>
        {children}
      </ArticleContentTitle>
    ),
    [BLOCKS.HEADING_2]: (node, children) => {
      const text = prettifyAnchorLink(
        // eslint-disable-next-line react/destructuring-assignment
        [...children.map((el) => getText(el).trim())].join(" ").trim(),
      )
      return (
        <ArticleContentTitle
          id={text}
          as="h2"
          fontSize="Normal"
          fontFamily="Gilroy"
          mb={40}
          pt={90}
        >
          {children}
        </ArticleContentTitle>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <ArticleContentTitle as="h3" fontSize="Small" fontFamily="Montserrat" mb={40} pt={60}>
        {children}
      </ArticleContentTitle>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <ArticleContentTitle as="h4" fontSize="exSmall" fontFamily="Montserrat" mb={40} pt={60}>
        {children}
      </ArticleContentTitle>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <ArticleContentTitle as="h5" fontSize="exSmall" fontFamily="Montserrat" mb={40} pt={60}>
        {children}
      </ArticleContentTitle>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <ArticleContentTitle as="h6" fontSize="exSmall" fontFamily="Montserrat" mb={40} pt={60}>
        {children}
      </ArticleContentTitle>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <ArticleContentText>{children}</ArticleContentText>,
    [BLOCKS.UL_LIST]: (node, children) => <ArticleContentUL>{children}</ArticleContentUL>,
    [BLOCKS.OL_LIST]: (node, children) => <ArticleContentOL>{children}</ArticleContentOL>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const {
        data: { target },
      } = node
      return (
        <>
          <Image itemprop="image" {...target} />
          {target.alt && <ArticleImageTitle>{target.alt}</ArticleImageTitle>}
        </>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const {
        data: { target },
      } = node

      if (target.component === "CTAText") {
        return <CTAText {...target} />
      }
      if (target.component === "ArticleCTA") {
        return <ArticleCTA {...target} />
      }

      if (target.component === "BlogArticleTwoImages") {
        return <BlogArticleTwoImages {...target} />
      }
      if (target.component === "Video") {
        return (
          <>
            <ArticleContentTitle as="h3" fontSize="Normal" fontFamily="Gilroy" mb={40} pt={60}>
              {target.title}
            </ArticleContentTitle>
            <ArticleVideoWrapper {...target} />
          </>
        )
      }
      if (target.component === "VideoIframe") {
        return <ArticleVideoWrapper iframe={target} />
      }
      return null
    },
    [BLOCKS.TABLE]: (node, children) => (
      <ArticleContentTable>
        <tbody>{children}</tbody>
      </ArticleContentTable>
    ),
    [BLOCKS.TABLE_ROW]: (node, children) => (
      <ArticleContentTableRow>{children}</ArticleContentTableRow>
    ),
    [BLOCKS.TABLE_CELL]: (node, children) => (
      <ArticleContentTableCell>
        <ArticleContentTableRow>{children}</ArticleContentTableRow>
      </ArticleContentTableCell>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const {
        data: {
          target: { title, link, noFollow },
        },
      } = node
      return (
        <ArticleContentLink target="_blank" to={link} noFollow={noFollow}>
          {title}
        </ArticleContentLink>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      const linkAttrs = uri.match(/\[.+\]/)?.[0]

      return (
        <ArticleContentLink
          rel={linkAttrs ? linkAttrs.replace("[", "").replace("]", "") : ""}
          to={uri.replace(linkAttrs, "")}
          target="_blank"
        >
          {children}
        </ArticleContentLink>
      )
    },
  },
}


const TextFour = ({
  title,
  theme: colorVariant,
  titleTag,
  subtitle,
  subtitleTag,
  longText,
  sectionId,
  richText
}) => (
  <TextFourWrapper id={sectionId} colorVariant={colorVariant}>
    <TextFourContainer>
      <TextFourHeader>
        <TextFourTitle as={titleTag}>{title}</TextFourTitle>
        <TextFourSubtitle as={subtitleTag}>{subtitle}</TextFourSubtitle>
      </TextFourHeader>
      {longText && (
        <TextFourText
          dangerouslySetInnerHTML={{
            __html: htmlFormatting(longText?.childMarkdownRemark?.html),
          }}
        />
      )}
       {richText !== null && Object.keys(richText)?.length && (
        <ArticleContent fullwidth>{renderRichText(richText, options)}</ArticleContent>
      )}
    </TextFourContainer>
  </TextFourWrapper>
)

TextFour.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleTag: PropTypes.string.isRequired,
  longText: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  richText: PropTypes.object,

}

TextFour.defaultProps = {
  sectionId: "",
  richText: {},

}

export default TextFour
