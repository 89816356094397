import styled from '@emotion/styled'

const HeroFormFormFieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:last-child {
    ${({ theme }) => theme.breakpoints.up("md")} {
      grid-column: 1 / 3;
    }
  }
`

export default HeroFormFormFieldWrapper