import styled from "@emotion/styled"

const EventListItemText = styled.div`
  margin-block-start: 0;
  margin-block-end: 0;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.06em;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 18px;
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    line-height: 31px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 31px;
    max-height: 685px;
  }
`

export default EventListItemText
