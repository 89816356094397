import styled from "@emotion/styled"

const ListTwelveItemList = styled.div`
  display: flex;
  padding-left: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    gap: 13px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    gap: 18px;
  }

  &::before {
    content: "";
    height: 15px;
    width: 15px;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    display: block;
    flex: 0 0 15px;

    ${({ theme }) => theme.breakpoints.down("2xl")} {
      margin-top: 7.5px;
    }

    ${({ theme }) => theme.breakpoints.up("2xl")} {
      margin-top: 9.5px;
    }
  }
`

export default ListTwelveItemList
