import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, variant = "Light" }) => css`
  ${(variant === "Dark" &&
    `
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.gray[900]};
  `) ||
  `background-color: ${theme.palette.gray[100]};`}
`

const PostEventsEventContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 120vw;
      height: 100%;
      left: -22vw;
      top: 0;
      ${dynamicStyle}
    }
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 50%;
  }
`

export default PostEventsEventContainer
