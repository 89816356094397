import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ colorVariant, theme, addPadding = false }) => css`
  background-color: ${(colorVariant === "Dark" && theme.palette.gray[900]) ||
  (colorVariant === "Light" && theme.palette.gray[50]) ||
  theme.palette.common.white};
  overflow: hidden;
  flex-shrink: 0;

  ${addPadding &&
  `
  ${theme.breakpoints.down("md")} {
    padding-left: 15px;
    padding-right: 15px;
  }

  ${theme.breakpoints.between("md", "xl")} {
    padding-left: 60px;
    padding-right: 60px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    padding-left: 70px;
    padding-right: 70px;
  }
  ${theme.breakpoints.between("2xl", "3xl")} {
    padding-left: 90px;
    padding-right: 90px;
  }

  ${theme.breakpoints.up("3xl")} {
    padding-left: 150px;
    padding-right: 150px;
  }
  `}
`

const TextsWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  ${dynamicStyle}
`

export default TextsWrapper
