import * as React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import htmlFormatting from "utils/htmlFormatting"

import RelatedArticlesWrapper from "components/RelatedArticles/RelatedArticlesWrapper"
import RelatedArticlesItem from "components/RelatedArticles/RelatedArticlesItem"
import RelatedArticlesItemHeading from "components/RelatedArticles/RelatedArticlesItemHeading"
import RelatedArticlesItemImage from "components/RelatedArticles/RelatedArticlesItemImage"
import RelatedArticlesItemContent from "components/RelatedArticles/RelatedArticlesItemContent" 
import RelatedArticlesItemText from "components/RelatedArticles/RelatedArticlesItemText"
import RelatedArticlesItemTitle from "components/RelatedArticles/RelatedArticlesItemTitle"
import RelatedArticlesTitle from "components/RelatedArticles/RelatedArticlesTitle"
import RelatedArticlesControls from "components/RelatedArticles/RelatedArticlesControls"
import RelatedArticlesBtnArrow from "components/RelatedArticles/RelatedArticlesBtnArrow"
import RelatedArticlesSwiperWrapper from "components/RelatedArticles/RelatedArticlesSwiperWrapper"
import Container from "components/Container"

import useHasMounted from "../hooks/useHasMounted"

const RelatedArticles = ({ items, title="More Interesting" }) => {
  const swiperRef = React.useRef(null)
  const swiperConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: items?.length > 1,
    spaceBetween: 20,
    allowTouchMove: true,
    ref: swiperRef,
    breakpoints: {
      992: {
        slidesPerView: 2,
        slidesPerGroup: 1,
      },
    },
  }
  

  const handleSlidePrev = () => swiperRef.current.swiper.slidePrev(300)
  const handleSlideNext = () => swiperRef.current.swiper.slideNext(300)
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  if (!items?.length) return null

  return (
    <RelatedArticlesWrapper>
      <Container>
        <RelatedArticlesTitle as="h2">{title}</RelatedArticlesTitle>
        <RelatedArticlesSwiperWrapper>
          <Swiper {...swiperConfig}>
            {items.map(({ id, title: itemTitle, link, coverImage, shortContent }) => (
              <SwiperSlide key={id}>
                <RelatedArticlesItem>
                  <RelatedArticlesItemHeading to={link}>
                    <RelatedArticlesItemTitle>{itemTitle}</RelatedArticlesItemTitle>
                    <RelatedArticlesItemImage {...coverImage} />
                  </RelatedArticlesItemHeading>
                  <RelatedArticlesItemContent>
                    <RelatedArticlesItemText
                      dangerouslySetInnerHTML={{
                        __html: htmlFormatting(shortContent.childMarkdownRemark.html),
                      }}
                    />
                  </RelatedArticlesItemContent>
                </RelatedArticlesItem>
              </SwiperSlide>
            ))}
          </Swiper>
          <RelatedArticlesControls>
            {items.length !== 1 && (
              <RelatedArticlesBtnArrow
                as="button"
                hideArrow={items.length <= 2}
                direction="left"
                width={30}
                height={25}
                color="black"
                className="arrow-icon"
                onClick={handleSlidePrev}
              />
            )}
            {/* <Button as={Link} to="/blog" variant="outlined">
              More news
            </Button> */}
            {items.length !== 1 && (
              <RelatedArticlesBtnArrow
                as="button"
                hideArrow={items.length <= 2}
                direction="right"
                width={30}
                height={25}
                color="black"
                className="arrow-icon"
                onClick={handleSlideNext}
              />
            )}
          </RelatedArticlesControls>
        </RelatedArticlesSwiperWrapper>
      </Container>
    </RelatedArticlesWrapper>
  )
}

RelatedArticles.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
      coverImage: PropTypes.object,
      shortContent: PropTypes.object,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
}

export default RelatedArticles