import styled from "@emotion/styled"

const ListSevenLowerTextWrap = styled.div`
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-left: 15px;
    padding-right: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    padding-left: 60px;
    padding-right: 60px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-left: 70px;
    padding-right: 70px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    padding-left: 90px;
    padding-right: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    padding-left: 150px;
    padding-right: 150px;
  }
`

export default ListSevenLowerTextWrap
