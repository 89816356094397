import styled from "@emotion/styled"
import PropTypes from "prop-types"
import htmlFormatting from "utils/htmlFormatting"
import EventListItemDate from "./EventListItemDate"
import EventListItemLink from "./EventListItemLink"
import EventListItemHeader from "./EventListItemHeader"
import EventListItemSubtitle from "./EventListItemSubtitle"
import EventListItemTag from "./EventListItemTag"
import EventListItemText from "./EventListItemText"
import EventListItemTitle from "./EventListItemTitle"
import EventListItemHeaderTop from "./EventListItemHeaderTop"

const Component = styled.div`
  width: 100%;
`

const EventListItem = ({
  title: postTitle,
  text,
  buttonText: buttonTitle,
  url: postLink,
  when,
  location,
}) => (
  <Component>
    <EventListItemHeader>
      <EventListItemSubtitle>
        <EventListItemHeaderTop>
          <EventListItemTag>{location}</EventListItemTag>
          {postLink && (
            <EventListItemLink noFollow={true} to={postLink} variant="PC">
              {buttonTitle}
            </EventListItemLink>
          )}
        </EventListItemHeaderTop>
        <EventListItemDate>{when}</EventListItemDate>
      </EventListItemSubtitle>
    </EventListItemHeader>
    <EventListItemTitle>{postTitle}</EventListItemTitle>
    <div>
      {text && (
        <EventListItemText
          dangerouslySetInnerHTML={{
            __html: htmlFormatting(text?.childMarkdownRemark?.html),
          }}
        />
      )}
      {postLink && (
        <EventListItemLink noFollow={true} to={postLink} variant="Mobile">
          {buttonTitle}
        </EventListItemLink>
      )}
    </div>
  </Component>
)

EventListItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  when: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
}

export default EventListItem
