import styled from "@emotion/styled"
import Image from "components/Image"
import HexagonCTA from "components/Icons/HexagonCTA"
import Hex from "components/Icons/Hexagon"

const CTAImage = styled(Image)`
  ${({ theme }) => theme.breakpoints.down("2xl")} {
    width: 374px;
    height: 376px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 514px;
    height: 514px;
    left: 44px;
  }
`

const CTAImageWrapper = ({ ...props }) => {
  const Component = styled.div`
    position: relative;
    height: min-content;
    margin-top: auto;
    ${({ theme }) => theme.breakpoints.down("lg")} {
      order: 3;
      bottom: -60px;
    }

    ${({ theme }) => theme.breakpoints.down("xl")} {
      margin-left: -11px;
    }
  `

  return (
    <Component>
      <HexagonCTA>
        <Hex />
      </HexagonCTA>
      <CTAImage {...props} />
    </Component>
  )
}

export default CTAImageWrapper
