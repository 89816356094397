import PropTypes from "prop-types"
import styled from "@emotion/styled"
import * as Icons from "components/Icons"

const ArticleAsideBannerImage = ({ backgroundImage }) => {
  if (
    backgroundImage === "None" ||
    backgroundImage === "" ||
    backgroundImage === undefined ||
    backgroundImage === null
  )
    return null

  const IconComponent = Icons[backgroundImage]
  const Component = styled.div`
    > svg {
      position: absolute;
      bottom: -100px;
      right: -50px;
      width: 285px;
      height: 285px;
      z-index: 1;

      > g {
        > path {
          opacity: 0.5;
          fill: #fff;
        }
      }

      > path {
        opacity: 0.5;
        fill: #fff;
      }
    }
  `
  return (
    <Component>
      <IconComponent />
    </Component>
  )
}

ArticleAsideBannerImage.propTypes = {
  backgroundImage: PropTypes.string,
}

ArticleAsideBannerImage.defaultProps = {
  backgroundImage: "None",
}

export default ArticleAsideBannerImage
