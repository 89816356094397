import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import Button from "components/Buttons/Button"
import CTAFormSpinner from "components/CTAForm/CTAFormSpinner"

import GateForContentFirstStage from "components/GateForContent/GateForContentFirstStage"
import GateForContentStageHeading from "components/GateForContent/GateForContentStageHeading"
import GateForContentStageNotice from "components/GateForContent/GateForContentStageNotice"
import GateForContentStageTitle from "components/GateForContent/GateForContentStageTitle"
import GateForContentForm from "components/GateForContent/GateForContentForm"
import GateForContentFormField from "components/GateForContent/GateForContentFormField"
import GateForContentConfirmation from "components/GateForContent/GateForContentConfirmation"
import GateForContentConfirmationLink from "components/GateForContent/GateForContentConfirmationLink"
import GateForContentButton from "components/GateForContent/GateForContentButton"
import GateForContentFormFieldWrapper from "components/GateForContent/GateForContentFormFieldWrapper"
import GateForContentFormFieldName from "components/GateForContent/GateForContentFormFieldName"
import GateForContentThirdStageText from "components/GateForContent/GateForContentThirdStageText"
import GateForContentThirdStageTitle from "components/GateForContent/GateForContentThirdStageTitle"
import GateForContentButtonWrapper from "components/GateForContent/GateForContentButtonWrapper"



import GateForContentImage from "components/GateForContent/GateForContentImage"
import GateForContentSecondStage from "components/GateForContent/GateForContentSecondStage"
import GateForContentStages from "components/GateForContent/GateForContentStages"
import GateForContentThirdStage from "components/GateForContent/GateForContentThirdStage"
import GateForContentWrapper from "components/GateForContent/GateForContentWrapper"
import FormFinalImage from "components/Icons/FormFinalImage"
import Link from "components/Link"
import PropTypes from "prop-types"

const validationSchema = yup.object({
  name: yup.string("Full Name").required("Name is required"),
  email: yup.string("Email").email("Enter a valid email").required("Email is required"),
})

const GateForContent = ({
  buttonText,
  title,
  titleTag,
  sectionId,
  confirmationLink,
  confirmationLinkText,
  confirmationText,
  finalStageText,
  finalStageBtnText,
  formFieldNameTitle,
  formFieldEmailTitle,
  formPlaceholderEmail,
  formPlaceholderName,
  formId,
  image,

  notice,
  linkToFile,
  recaptcha,
  finalStageTitle,
}) => {
  const formik = useFormik({
    initialValues: {
      formId,
      name: "",
      email: "",
    },
    isInitialValid: false,
    validationSchema,
  })

    const [prevFormState, setPrevFormState] = React.useState(formik.initialValues)
    const [sending, setSending] = React.useState(false)
    const [showFinal, setShowFinal] = React.useState(false)

    const showSendingSpinnerHandler = () => {
      setSending(!sending)
    }

    const handleReCaptchaVerify = React.useCallback(async () => {
      if (!recaptcha) {
        // eslint-disable-next-line no-console
        console.log("Execute recaptcha not yet available")
        return
      }
      if (typeof window !== "undefined") {

        try {
          showSendingSpinnerHandler()
          const token = await recaptcha.execute("submit")
          const response = await window
            .fetch(`/api/recaptcha-check`, {
              method: "POST",
              body: JSON.stringify({
                token,
              }),
            })
            .then((res) => res.json())
          // eslint-disable-next-line consistent-return
          return response.success && response.score > 0.5
        } catch (error) {
          // eslint-disable-next-line consistent-return
          return false
        }
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recaptcha])

    const showFinalHandler = () => {
      setShowFinal(!showFinal)
  }
  
  return (
    <GateForContentWrapper>
      <GateForContentStages>
        {/* FIRST STAGE  */}
        <GateForContentFirstStage
          id="gateForContentForm"
          formName="gateForContentForm"
          formValues={formik.values}
          preSubmit={handleReCaptchaVerify}
          postSubmit={() => {
            formik.resetForm()
            setSending(false)
            showFinalHandler()
            if (typeof window !== "undefined") {
              window.open(linkToFile)
            }
          }}
        >
          <GateForContentStageHeading>
            <GateForContentStageNotice>{notice}</GateForContentStageNotice>
            <GateForContentStageTitle as={titleTag}>{title}</GateForContentStageTitle>
          </GateForContentStageHeading>
          <GateForContentForm>
            <GateForContentFormField type="hidden" key="formId" name="formId" />
            <GateForContentFormFieldWrapper>
              <GateForContentFormFieldName>
                {formFieldNameTitle}
                <span>*</span>
              </GateForContentFormFieldName>
              <GateForContentFormField
                error={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
                touched={formik.touched?.name}
                type="text"
                key="name"
                name="name"
                required
                onFocus={() => {
                  formik.setFieldTouched("name", true, true)
                }}
                onBlur={() => {
                  if (formik.values.name !== "" && prevFormState.name !== formik.values.name)
                    setPrevFormState(formik.values)
                }}
                placeholder={formPlaceholderName}
              />
            </GateForContentFormFieldWrapper>
            <GateForContentFormFieldWrapper>
              <GateForContentFormFieldName>
                {formFieldEmailTitle}
                <span>*</span>
              </GateForContentFormFieldName>
              <GateForContentFormField
                error={formik.errors.email}
                onChange={formik.handleChange}
                value={formik.values.email || ""}
                touched={formik.touched?.email}
                type="email"
                key="email"
                name="email"
                required
                onFocus={() => {
                  formik.setFieldTouched("email", true, true)
                }}
                onBlur={() => {
                  if (formik.values.email !== "" && prevFormState.email !== formik.values.email)
                    setPrevFormState(formik.values)
                }}
                placeholder={formPlaceholderEmail}
              />
            </GateForContentFormFieldWrapper>
          </GateForContentForm>
          <GateForContentConfirmation>
            {confirmationText}{" "}
            <GateForContentConfirmationLink to={confirmationLink} target="_blank">
              {confirmationLinkText}
            </GateForContentConfirmationLink>
          </GateForContentConfirmation>
          <GateForContentButtonWrapper>
            <GateForContentButton
              variant="contained"
              id="gate-for-content-form-submit"
              type="submit"
            >
              {buttonText}
            </GateForContentButton>
          </GateForContentButtonWrapper>
        </GateForContentFirstStage>

        {/* SECOND STAGE  */}
        {sending && (
          <GateForContentSecondStage>
            <CTAFormSpinner />
          </GateForContentSecondStage>
        )}

        {/* THIRD STAGE */}
        {showFinal && (
          <GateForContentThirdStage>
            <FormFinalImage />
            <GateForContentThirdStageTitle>{finalStageTitle}</GateForContentThirdStageTitle>
            <GateForContentThirdStageText>
              {finalStageText.finalStageText}
            </GateForContentThirdStageText>
            <Button variant="contained" onClick={showFinalHandler}>
              {finalStageBtnText}
            </Button>
          </GateForContentThirdStage>
        )}
      </GateForContentStages>
      <GateForContentImage objectFit="contain" {...image} />
    </GateForContentWrapper>
  )
}

export default GateForContent

GateForContent.propTypes = {
  buttonText: PropTypes.string.isRequired,
  recaptcha: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  confirmationLink: PropTypes.string.isRequired,
  confirmationLinkText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  finalStageText: PropTypes.any.isRequired,
  finalStageBtnText: PropTypes.string.isRequired,
  formPlaceholderEmail: PropTypes.string.isRequired,
  formPlaceholderName: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  notice: PropTypes.string.isRequired,
  linkToFile: PropTypes.string.isRequired,
  finalStageTitle: PropTypes.string.isRequired,
  formFieldNameTitle: PropTypes.string.isRequired,
  formFieldEmailTitle: PropTypes.string.isRequired
}
