import styled from "@emotion/styled"

const ListEighteenItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 52px;

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-left: 28px;
    padding-right: 28px;
  }
`

export default ListEighteenItemContent
