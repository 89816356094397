import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, variant = "Light" }) => css`
  ${variant === "Dark" && `color: ${theme.palette.common.white}`}
`

const PostListItemText = styled.div`
  margin-block-start: 0;
  margin-block-end: 0;
  letter-spacing: 0.06em;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 18px;
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    line-height: 31px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 31px;
    max-height: 685px;
  }

  ${dynamicStyle}
`

export default PostListItemText
