import styled from "@emotion/styled"
import TextsText from "components/Texts/TextsText"

const TextSixText = styled(TextsText)`
  font-weight: 600;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 20px;
    line-height: 25px;
    max-width: 270px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 31px;
    max-width: 423px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 39px;
    max-width: 580px;
  }
`

export default TextSixText
