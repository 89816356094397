import styled from "@emotion/styled"
import Image from "components/Image"

const TextsTwoImage = styled(Image)`
  object-fit: contain;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  height: 100%;
  & > svg {
    width: 100%;
    height: 100%;
  }
`
export default TextsTwoImage
