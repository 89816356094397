import styled from "@emotion/styled"

const TestimonialsWrapper = styled.section`
  background-color: ${({ theme }) => theme.palette.gray[50]};

  ${({ theme }) => theme.breakpoints.down("xl")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

export default TestimonialsWrapper
