import PropTypes from "prop-types"
import CTASectionWrapper from "components/CTAText/CTASectionWrapper"
import CTASubtitle from "components/CTAText/CTASubtitle"
import CTATitle from "components/CTAText/CTATitle"
import CTANotice from "components/CTAText/CTANotice"
import CTAImageWrapper from "components/CTAText/CTAImageWrapper"
import CTAContainer from "components/CTAText/CTAContainer"
import CTAContent from "components/CTAText/CTAContent"
import CTATextWrapper from "components/CTAText/CTATextWrapper"
import CTAButton from "components/CTAText/CTAButton"
import Link from "components/Link"
import htmlFormatting from "utils/htmlFormatting"
import Button from "components/Buttons/Button"
import CTABackgroundIconWrapper from "components/CTAText/CTABackgroundIconWrapper"
import * as Icons from "components/Icons"
import CTABackgroundIconInner from "components/CTAText/CTABackgroundIconInner"

const CTAText = ({
  theme,
  textBeforeTitle,
  titleTag,
  titleSize,
  title,
  textSize,
  text,
  buttonTitle,
  buttonLink,
  image,
  iconType,
  sectionId,
}) => {
  let IconComponent

  if (iconType !== "None") {
    IconComponent = Icons[iconType]
  }

  return (
    <CTASectionWrapper id={sectionId} showImage={!!image} colorVariant={theme}>
      <CTAContainer largePadding={textBeforeTitle !== null} showImage={!!image}>
        {!!image && <CTAImageWrapper {...image} />}
        <CTAContent>
          <CTATextWrapper showImage={!!image}>
            {textBeforeTitle && (
              <CTASubtitle
                colorVariant={theme}
                dangerouslySetInnerHTML={{ __html: htmlFormatting(textBeforeTitle) }}
              />
            )}
            {title && (
              <CTATitle size={titleSize} as={titleTag} addMargin={text !== null}>
                {title}
              </CTATitle>
            )}
            {text && (
              <CTANotice
                size={textSize}
                colorVariant={theme}
                dangerouslySetInnerHTML={{ __html: htmlFormatting(text) }}
              />
            )}
          </CTATextWrapper>
          <CTAButton as={Link} to={buttonLink} variant="contained" showImage={!!image}>
            {buttonTitle}
          </CTAButton>
        </CTAContent>
      </CTAContainer>
      {IconComponent && (
        <CTABackgroundIconWrapper>
          <CTABackgroundIconInner theme={theme}>
            <IconComponent />
          </CTABackgroundIconInner>
        </CTABackgroundIconWrapper>
      )}
    </CTASectionWrapper>
  )
}

CTAText.propTypes = {
  iconType: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  textBeforeTitle: PropTypes.string,
  titleTag: PropTypes.string.isRequired,
  titleSize: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  textSize: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  image: PropTypes.object,
  sectionId: PropTypes.string,
}

CTAText.defaultProps = {
  text: null,
  image: null,
  textBeforeTitle: null,
  sectionId: "",
}

export default CTAText
