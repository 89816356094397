import styled from "@emotion/styled"

const TextOneTitleContainer = styled.div`
  text-align: center;
  position: absolute;

  @media screen and (min-width: 0px) and (max-width: 374.98px) {
    top: 83px;
    left: 52px;
  }

  @media screen and (min-width: 375px) and (max-width: 991.98px) {
    max-width: 120px;
    top: 104px;
    left: 75px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    max-width: 172px;
    top: 180px;
    left: 132px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 224px;
    top: 185px;
    left: 115px;
  }

  ${({ theme }) => theme.breakpoints.between("2xl", "3xl")} {
    max-width: 280px;
    top: 270px;
    left: 190px;
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    max-width: 280px;
    top: 310px;
    left: 230px;
  }
`

export default TextOneTitleContainer
