import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ centeredBtn }) =>
  css`
    justify-content: ${(centeredBtn && "center") || "space-between"};
  `

const TestimonialsControls = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    ${dynamicStyle}
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: center;
  }
`

export default TestimonialsControls
