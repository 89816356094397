import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Link from "components/Link"

const dynamicStyle = ({ theme, active }) => css`
  background: ${active ? theme.palette.primary.main : theme.palette.gray[100]};
  color: ${active ? theme.palette.common.white : theme.palette.gray[900]};
`

const BlogListCategoryItem = styled(Link)`
  text-decoration: none;
  border: none;
  white-space: nowrap;
  border-radius: 30px;
  font-weight: 600;
  display: block;
  font-size: 16px;
  line-height: 25px;
  padding: 5px 20px;
  ${dynamicStyle};
`

export default BlogListCategoryItem
