import styled from "@emotion/styled"

const HeroCaseDemo = styled.div`
  width: 100%;
  position: relative;
  padding-top: 60.5%;
  display: block;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    border-radius: 6%/9%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    border-radius: 4%/6%;
  }
`

export default HeroCaseDemo
