import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ textSize, colorVariant, isQuote, theme }) => css`
  line-height: ${isQuote ? 1.6 : 1.5};
  color: ${(colorVariant === "Dark" && theme.palette.common.white) || theme.palette.common.black};

  ${theme.breakpoints.up("xl")} {
    font-size: ${textSize === "Large" ? "22px" : "18px"};
  }
`

const TextsText = styled.div`
  letter-spacing: -0.1px;
  width: max-content;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 18px;
  }

  ${dynamicStyle}
`

export default TextsText
