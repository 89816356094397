import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

const Link = ({
  to = "/",
  target,
  noFollow,
  children,
  className,
  onClick,
  ariaLabel,
  withoutNoreferer,
  ...props
}) => {
  const pathTo = to?.[to.length - 1] === "/" ? to : `${to}/`

  if (
    /^(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?$/.test(to) ||
    /^mailto:([^?]*)$/.test(to) ||
    /^tel:([^?]*)$/.test(to) ||
    target !== null ||
    noFollow === true
  )
    return (
        // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={to}
        target="_blank"
        rel={`${withoutNoreferer ? "" : "noopener noreferrer"} ${noFollow ? "nofollow" : ""}`}
        className={className}
        aria-label={ariaLabel}
        {...props}
      >
        {children}
      </a>
    )

  return (
    <GatsbyLink to={pathTo} className={className} aria-label={ariaLabel} {...props}>
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  noFollow: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  withoutNoreferer: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

Link.defaultProps = {
  target: null,
  noFollow: null,
  withoutNoreferer: false,
  className: "",
  onClick: null,
  ariaLabel: "",
}

export default Link
