import styled from "@emotion/styled"

const ArticleAsideBannerWrapper = styled.div`
  background: ${({ theme }) => theme.palette.gray[100]};
  position: relative;
  padding: 30px;
  border-radius: 15px;
  min-height: 231px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    order: 3;
  }
`

export default ArticleAsideBannerWrapper
