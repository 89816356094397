import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, articleSeparatorTop, articleSeparatorBottom, pt, pb }) => css`
  ${theme.breakpoints.down("xl")} {
    padding-bottom: ${articleSeparatorTop ? "30px" : "0"};
    padding-top: ${articleSeparatorBottom ? "30px" : "0"};
  }

  ${theme.breakpoints.up("xl")} {
    padding-bottom: ${articleSeparatorTop ? "40px" : "0"};
    padding-top: ${articleSeparatorBottom ? "40px" : "0"};
  }
`

const BlogListPostStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  ${dynamicStyle}

`

export default BlogListPostStack
