import styled from "@emotion/styled"

const ArticleContentTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  display: block;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin: 30px 0;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 40px 0;
  }

  & tr > td,
  & tr > th {
    border: 1px solid #e1e1e1;
  }

  & tr:first-child th {
    border-top: 0;
  }

  & tr td:first-child,
  & tr th:first-child {
    border-left: 0;
    border-bottom: 0;
  }

  & tr td:last-child,
  & tr th:last-child {
    border-right: 0;
    border-bottom: 0;
  }

  & tbody {
    & > tr:first-of-type {
      background-color: rgba(56, 176, 72, 0.15);
      & td {
        text-align: center;
      }
      & tr,
      & p {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }

    & > tr {
      & > td:first-of-type {
        font-size: 16px;
        vertical-align: top;
      }
    }
  }

  & p {
    margin-top: 0 !important;
  }

  & td {
    border: none;
  }
`

export default ArticleContentTable
