import PropTypes from "prop-types"
import * as React from "react"
import * as yup from "yup"
import { useFormik } from "formik"

import CTAFormWrapper from "components/CTAForm/CTAFormWrapper"
import CTAFormContainer from "components/CTAForm/CTAFormContainer"
import CTAFormContent from "components/CTAForm/CTAFormContent"
import CTAFormSideBar from "components/CTAForm/CTAFormSideBar"
import CTAFormClientTitle from "components/CTAForm/CTAFormClientTitle"
import CTAFormClientMessage from "components/CTAForm/CTAFormClientMessage"
import CTAFormClientName from "components/CTAForm/CTAFormClientName"
import CTAFormClientPosition from "components/CTAForm/CTAFormClientPosition"
import CTAFormTitle from "components/CTAForm/CTAFormTitle"
import CTAFormFields from "components/CTAForm/CTAFormFields"
import CTAFormField from "components/CTAForm/CTAFormField"
import CTAFormBottom from "components/CTAForm/CTAFormBottom"
import CTAFormConfirmation from "components/CTAForm/CTAFormConfirmation"
import CTAFormLink from "components/CTAForm/CTAFormLink"
import CTAFormSpinner from "components/CTAForm/CTAFormSpinner"
import CTAFormSpinnerWrapper from "components/CTAForm/CTAFormSpinnerWrapper"
import CTAFormFinalBtn from "components/CTAForm/CTAFormFinalBtn"
import CTAFormFinalText from "components/CTAForm/CTAFormFinalText"
import CTAFormFinalWrapper from "components/CTAForm/CTAFormFinalWrapper"
import CTAFormFormFieldWrapper from "components/CTAForm/CTAFormFormFieldWrapper"
import CTAFormFormFieldName from "components/CTAForm/CTAFormFormFieldName"

import FormFinalImage from "components/Icons/FormFinalImage"
import htmlFormatting from "utils/htmlFormatting"
import pushGTMEvent from "utils/pushGTMEvent"
import Button from "components/Buttons/Button"
import useUserId from "../hooks/useUserId"

const validationSchema = yup.object({
  name: yup.string("Full Name").required("Name is required"),
  email: yup.string("Email").email("Enter a valid email").required("Email is required"),
  message: yup.string("About project").required("Message is required"),
})

const CTAForm = ({
  title,
  titleTag,
  formId,
  clientTitle,
  clientMessage,
  clientName,
  clientPosition,
  buttonText,
  topPartTheme,
  bottomPartTheme,
  confirmationText,
  confirmationLinkText,
  confirmationLink,
                  formFieldNameTitle,
                formFieldEmailTitle,
                formFieldMessageTitle,
  formPlaceholderEmail,
  formPlaceholderName,
  formPlaceholderMessage,
  finalStageBtnText,
  finalStageText,
  recaptcha,
  sectionId,
  isBlog
}) => {
 
  const userID = useUserId()

  const formik = useFormik({
    initialValues: {
      userID,
      formId,
      name: "",
      email: "",
      message: "",
    },
    isInitialValid: false,
    validationSchema,
  })


  const [prevFormState, setPrevFormState] = React.useState(formik.initialValues)
  const [sending, setSending] = React.useState(false)
  const [showFinal, setShowFinal] = React.useState(false)

  const showSendingSpinnerHandler = () => {
    setSending(!sending)
  }

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!recaptcha) {
      // eslint-disable-next-line no-console
      console.log("Execute recaptcha not yet available")
      return
    }
    if (typeof window !== "undefined") {
      try {
        showSendingSpinnerHandler()
        const token = await recaptcha.execute("submit")
        const response = await window
          .fetch(`/api/recaptcha-check`, {
            method: "POST",
            body: JSON.stringify({
              token,
            }),
          })
          .then((res) => res.json())
        // eslint-disable-next-line consistent-return
        return response.success && response.score > 0.5
      } catch (error) {
        // eslint-disable-next-line consistent-return
        return false
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptcha])

  const showFinalHandler = () => {
    setShowFinal(!showFinal)
  }

  return (
    <CTAFormWrapper
      isBlog={isBlog}
      id={sectionId}
      topPartTheme={topPartTheme}
      bottomPartTheme={bottomPartTheme}
    >
      <CTAFormContainer isBlog={isBlog}>
        <CTAFormContent
          isBlog={isBlog}
          id="ctaForm"
          formName="CTA"
          formValues={formik.values}
          preSubmit={handleReCaptchaVerify}
          postSubmit={() => {
            formik.resetForm()
            setSending(false)
            showFinalHandler()
          }}
        >
          <CTAFormTitle isBlog={isBlog} as={titleTag}>
            {title}
          </CTAFormTitle>
          <CTAFormFields isBlog={isBlog}>
            {typeof window === "undefined" && (
              <CTAFormField type="text" key="formId" name="formId" />
            )}
            <CTAFormFormFieldWrapper isBlog={isBlog}>
              <CTAFormFormFieldName isBlog={isBlog}>
                {(formFieldNameTitle && `${formFieldNameTitle}`) || "Full name "}
                <span>*</span>
              </CTAFormFormFieldName>
              <CTAFormField
                isBlog={isBlog}
                error={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
                touched={formik.touched?.name}
                placeholder={formPlaceholderName}
                type="text"
                key="name"
                name="name"
                required
                onFocus={() => {
                  formik.setFieldTouched("name", true, true)
                }}
                onBlur={() => {
                  if (formik.values.name !== "" && prevFormState.name !== formik.values.name)
                    setPrevFormState(formik.values)
                  if (
                    formik.values.name !== "" &&
                    !formik.errors.name &&
                    prevFormState.name !== formik.values.name
                  )
                    pushGTMEvent("CTAFormName", formik.values.name)
                }}
              />
            </CTAFormFormFieldWrapper>
            <CTAFormFormFieldWrapper isBlog={isBlog}>
              <CTAFormFormFieldName isBlog={isBlog}>
                {(formFieldEmailTitle && `${formFieldEmailTitle}`) || "Email "}
                <span>*</span>
              </CTAFormFormFieldName>
              <CTAFormField
                isBlog={isBlog}
                error={formik.errors.email}
                onChange={formik.handleChange}
                value={formik.values.email || ""}
                touched={formik.touched?.email}
                placeholder={formPlaceholderEmail}
                type="email"
                key="email"
                name="email"
                required
                onFocus={() => {
                  formik.setFieldTouched("email", true, true)
                }}
                onBlur={() => {
                  if (formik.values.email !== "" && prevFormState.email !== formik.values.email)
                    setPrevFormState(formik.values)
                  if (
                    formik.values.email !== "" &&
                    !formik.errors.email &&
                    prevFormState.email !== formik.values.email
                  )
                    pushGTMEvent("CTAFormEmail", formik.values.email)
                }}
              />
            </CTAFormFormFieldWrapper>
            <CTAFormFormFieldWrapper isBlog={isBlog}>
              <CTAFormFormFieldName isBlog={isBlog}>
                {(formFieldMessageTitle && `${formFieldMessageTitle}`) || "About project "}
                <span>*</span>
              </CTAFormFormFieldName>
              <CTAFormField
                isBlog={isBlog}
                as="textarea"
                error={formik.errors.message}
                onChange={formik.handleChange}
                value={formik.values.message || ""}
                touched={formik.touched?.message}
                placeholder={formPlaceholderMessage}
                type="text"
                key="message"
                name="message"
                required
                rows="5"
                onFocus={() => {
                  formik.setFieldTouched("message", true, true)
                }}
                onBlur={() => {
                  if (
                    formik.values.message !== "" &&
                    prevFormState.message !== formik.values.message
                  )
                    setPrevFormState(formik.values)
                  if (
                    formik.values.message !== "" &&
                    !formik.errors.message &&
                    prevFormState.message !== formik.values.message
                  )
                    pushGTMEvent("CTAFormMessage", formik.values.message)
                }}
              />
            </CTAFormFormFieldWrapper>
          </CTAFormFields>
          <CTAFormBottom isBlog={isBlog}>
            <CTAFormConfirmation isBlog={isBlog}>
              {confirmationText}
              <CTAFormLink isBlog={isBlog} target="_blank" to={confirmationLink}>
                {confirmationLinkText}
              </CTAFormLink>
            </CTAFormConfirmation>

            <Button id="cta-form-submit" variant="contained" type="submit">
              {buttonText}
            </Button>
          </CTAFormBottom>
        </CTAFormContent>
        <CTAFormSideBar isBlog={isBlog}>
          <CTAFormClientTitle isBlog={isBlog}> {clientTitle}</CTAFormClientTitle>
          <CTAFormClientMessage isBlog={isBlog}> {clientMessage}</CTAFormClientMessage>
          <CTAFormClientName isBlog={isBlog}> {clientName}</CTAFormClientName>
          <CTAFormClientPosition isBlog={isBlog}> {clientPosition}</CTAFormClientPosition>
        </CTAFormSideBar>
        {sending && (
          <CTAFormSpinnerWrapper>
            <CTAFormSpinner />
          </CTAFormSpinnerWrapper>
        )}
        {showFinal && (
          <CTAFormFinalWrapper>
            <FormFinalImage />

            {finalStageText && (
              <CTAFormFinalText
                dangerouslySetInnerHTML={{
                  __html: htmlFormatting(finalStageText?.childMarkdownRemark?.html),
                }}
              />
            )}

            <CTAFormFinalBtn onClick={showFinalHandler}>
              {finalStageBtnText || "Close"}
            </CTAFormFinalBtn>
          </CTAFormFinalWrapper>
        )}
      </CTAFormContainer>
    </CTAFormWrapper>
  )
}

CTAForm.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  clientTitle: PropTypes.string.isRequired,
  clientMessage: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  clientPosition: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  topPartTheme: PropTypes.string.isRequired,
  bottomPartTheme: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  confirmationLinkText: PropTypes.string.isRequired,
  confirmationLink: PropTypes.string.isRequired,
  formFieldNameTitle: PropTypes.string.isRequired,
  formFieldEmailTitle: PropTypes.string.isRequired,
  formFieldMessageTitle: PropTypes.string.isRequired,
  formPlaceholderEmail: PropTypes.string,
  formPlaceholderName: PropTypes.string,
  formPlaceholderMessage: PropTypes.string,
  finalStageText: PropTypes.object.isRequired,
  finalStageBtnText: PropTypes.string.isRequired,
  isBlog: PropTypes.bool.isRequired,
  recaptcha: PropTypes.object,
  sectionId: PropTypes.string,
}

CTAForm.defaultProps = {
  recaptcha: null,
  formPlaceholderEmail: null,
  formPlaceholderName: null,
  formPlaceholderMessage: null,
  sectionId: "",
}

export default CTAForm
