import styled from "@emotion/styled"

const BlogListPostHeading = styled.div`
  display: flex;
  gap: 13px;
  flex-direction: column;
  width: 100%;
`

export default BlogListPostHeading
