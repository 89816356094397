import PropTypes from "prop-types"
import styled from "@emotion/styled"
import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"
import FooterImage from "./FooterImage"

const FooterRatingWrap = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 5px;
  align-items: center;
`

const FooterRatingText = styled.span`
  color: ${({ theme }) => theme.palette.gray["600"]};

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 18px;
  }
`

const FooterRating = ({ quantityReviews, logoImage, ratingFooter, ratingText }) => {
  const StarsWrapper = styled.div`
    display: inline-block;
    font-size: 30px;
    font-family: Times; // make sure ★ appears correctly
    transform: translateY(-2px);

    &::before {
      content: "★★★★★";
      letter-spacing: 4px;
      background: linear-gradient(
        90deg,
        ${({ theme }) => theme.palette.primary.main} ${`${(ratingFooter / 5) * 100}%`},
        ${({ theme }) => theme.palette.gray[300]} ${`${(ratingFooter / 5) * 100}%`}
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `

  return (
    <MicromarkingWrapper
      itemprop="aggregateRating"
      itemscope=""
      itemtype="https://schema.org/AggregateRating"
    >
      <Meta itemprop="bestRating" content="5" />
      <Meta itemprop="ratingValue" content={ratingFooter} />
      <Meta itemprop="ratingCount" content={quantityReviews} />

      <FooterRatingWrap>
        <StarsWrapper />
        <FooterRatingText>{ratingText}</FooterRatingText>
        <MicromarkingWrapper
          itemprop="itemReviewed"
          itemscope=""
          itemtype="https://schema.org/Organization"
        >
          {/* <FooterImage itemprop="image" {...logoImage} /> */}
          <Meta itemprop="name" content="Adexin" />
          <Meta itemprop="telephone" content="+1 267 892 9500" />
          <Meta itemprop="url" href="https://adexin.com/" />
          <Meta itemprop="email" content="info@adexin.com" />
          <Meta itemprop="address" content="Plymouth Meeting, PA, USA" />
          <Meta itemprop="email" content="eu@adexin.com" />
          <Meta itemprop="address" content="Tallinn, Estonia" />
          <Meta itemprop="email" content="dev@adexin.com" />
          <Meta itemprop="address" content="Remote, Poland" />
          <Meta itemprop="email" content="uk@adexin.com" />
        </MicromarkingWrapper>
      </FooterRatingWrap>
    </MicromarkingWrapper>
  )
}

FooterRating.propTypes = {
  ratingFooter: PropTypes.number.isRequired,
  ratingText: PropTypes.string.isRequired,
  logoImage: PropTypes.object.isRequired,
  quantityReviews: PropTypes.number.isRequired,
}

export default FooterRating
