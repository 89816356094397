import PropTypes from "prop-types"
import Section404Wrapper from "components/Section404/Section404Wrapper"
import Section404Container from "components/Section404/Section404Container"
import Section404Content from "components/Section404/Section404Content"
import Section404Title from "components/Section404/Section404Title"
import Section404Text from "components/Section404/Section404Text"
import Section404Stack from "components/Section404/Section404Stack"
import Section404Image from "components/Section404/Section404Image"
import Section404Link from "components/Section404/Section404Link"

const Section404 = ({ title, text, links, image }) => {
  return (
    <Section404Wrapper>
      <Section404Container>
        <Section404Image {...image} />
        <Section404Content>
          {title && <Section404Title>{title}</Section404Title>}
          {text && <Section404Text>{text}</Section404Text>}
          <Section404Stack>
            {links.map(({ id, title: linkTitle, link }) => (
              <Section404Link to={link} key={id}>
                {linkTitle}
              </Section404Link>
            ))}
          </Section404Stack>
        </Section404Content>
      </Section404Container>
    </Section404Wrapper>
  )
}

Section404.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  image: PropTypes.object.isRequired,
}

export default Section404
