import styled from "@emotion/styled"

const TechThreeTitleWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  height: 44px;
  align-items: center;
  margin-bottom: 30px;
  ${({ theme }) => theme.breakpoints.down("xl")} {
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    justify-content: flex-start;
  }
`

export default TechThreeTitleWrapper
