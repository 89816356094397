import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListFourteenStack = styled.div`
  display: grid;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    max-width: 425px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 22px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    gap: 28px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    gap: 35px;
  }
`

export default ListFourteenStack
