import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyles = ({ theme, isQuote }) => css`
  ${theme.breakpoints.down("2xl")} {
    max-width: ${isQuote ? "792px" : "968px"};
  }

  ${theme.breakpoints.up("2xl")} {
    max-width: ${isQuote ? "990px" : "1210px"};
  }
`

const TextFiveWrapper = styled.div`
  z-index: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  ${dynamicStyles}
`

export default TextFiveWrapper
