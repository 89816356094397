import styled from "@emotion/styled"

const TextOneHeadingWrapper = styled.div`
  position: relative;

  @media screen and (min-width: 0px) and (max-width: 374.98px) {
    width: 290px;
  }

  @media screen and (min-width: 375px) and (max-width: 991.98px) {
    width: 342px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    width: 555px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    width: 588px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 960px;
  }
`

export default TextOneHeadingWrapper
