import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ colorVariant, theme }) => css`
  color: ${colorVariant === "Dark" ? theme.palette.common.white : theme.palette.common.black};
`

const CTASubtitle = styled.div`
  display: block;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 10px;
    br {
      display: none;
    }
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 80px;
    font-size: 60px;
  }

  ${dynamicStyle}
`

export default CTASubtitle
