const Hexagone = () => (
  <svg viewBox="0 0 988 990" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M332.816 18.9633C347.777 3.61256 369.792 -2.59015 390.567 2.6917L827.873 113.873C848.648 119.154 865.027 135.118 870.84 155.751L993.208 590.059C999.021 610.692 993.386 632.859 978.424 648.209L663.485 971.337C648.524 986.688 626.509 992.891 605.734 987.609L168.428 876.428C147.653 871.146 131.274 855.182 125.461 834.55L3.09311 400.241C-2.72019 379.608 2.91548 357.442 17.8772 342.091L332.816 18.9633Z"
      fill="url(#paint0_linear_2:670)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2:670"
        x1="356.994"
        y1="-5.84391"
        x2="639.307"
        y2="996.144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFEFF6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default Hexagone
