import styled from "@emotion/styled"
import Image from "components/Image"

const Section404Image = styled(Image)`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 406px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    max-width: 507px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 634px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 793px;
  }
`

export default Section404Image
