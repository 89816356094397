import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dynamicStyle = ({ theme, active }) => css`
  ${theme.breakpoints.up("lg")} {
    ${active && "display: flex;"}
  }
`

const TechOneStack = styled.div`
  display: flex;
  gap: 39px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 48px;
    position: relative;

    .swiper {
      position: static;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: black;
      top: -45px;

      display: inline-block;
      width: 28px;
      height: 24px;
      transition: 0.3s;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        border: solid #000;
        border-width: 0 2px 2px 0;
        display: block;
        width: ${(24 * 0.7).toFixed(2)}px;
        height: ${(24 * 0.7).toFixed(2)}px;
        transition: 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 2px;
        background-color: #000;
        transition: 0.3s;
      }
    }

    .swiper-button-prev {
      left: 20px;
      &::before {
        left: 9%;
        transform: translateY(-50%) rotate(135deg);
      }
    }

    .swiper-button-next {
      right: 20px;
      &::before {
        right: 9%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    .swiper-slide {
      width: auto;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 45px;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  ${({ theme }) => theme.breakpoints.between("sm", "lg")} {
    margin-bottom: 45px;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: none;
    flex-wrap: wrap;
    gap: 30px;
    grid-area: 1/2/ 6 / span 1;
    row-gap: 30px;
  }
  ${dynamicStyle}
`

export default TechOneStack
