import styled from "@emotion/styled"

const ListTwelveItemListItem = styled.span`
  display: block;
  list-style-type: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 34px;
`

export default ListTwelveItemListItem
