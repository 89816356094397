import * as React from "react"
import PropTypes from "prop-types"
import Button from "components/Buttons/Button"
import Link from "components/Link"
import { Swiper, SwiperSlide } from "swiper/react"
import PostListWrapper from "components/PostEvents/PostListWrapper"
import PostListStack from "components/PostEvents/PostListStack"
import PostListContainer from "components/PostEvents/PostListContainer"
import EventListItem from "components/EventItem/EventListItem"
import PostListTitle from "components/PostEvents/PostListTitle"
import PostListBtnArrow from "components/PostEvents/PostListBtnArrow"
import PostListControls from "components/PostEvents/PostListControls"

const EventList = ({ title, titleTag, eventItems, url, buttonText, sectionId }) => {
  const swiperRef = React.useRef(null)
  const swiperConfig = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    spaceBetween: 0,
    allowTouchMove: false,
    ref: swiperRef,
    breakpoints: {
      1280: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 140,
      },
    },
  }

  const handleSlidePrev = () => swiperRef.current.swiper.slidePrev(300)
  const handleSlideNext = () => swiperRef.current.swiper.slideNext(300)

  return (
    <PostListWrapper id={sectionId}>
      <PostListContainer>
        <PostListTitle as={titleTag}>{title}</PostListTitle>
        <PostListStack>
          {eventItems.length === 1 ? (
            <EventListItem {...eventItems[0]} />
          ) : (
            <Swiper {...swiperConfig}>
              {eventItems.map(({ id, ...props }) => (
                <SwiperSlide key={id}>
                  <EventListItem {...props} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </PostListStack>
      </PostListContainer>
      <PostListControls variant="Wide">
        {eventItems.length !== 1 && (
          <PostListBtnArrow
            as="button"
            hideArrow={eventItems.length <= 2}
            direction="left"
            width={33}
            height={28}
            color="black"
            className="arrow-icon"
            onClick={handleSlidePrev}
          />
        )}
        {!!url && buttonText && (
          <Button as={Link} to={url} variant="outlined">
            {buttonText}
          </Button>
        )}
        {eventItems.length !== 1 && (
          <PostListBtnArrow
            as="button"
            hideArrow={eventItems.length <= 2}
            direction="right"
            width={33}
            height={28}
            color="black"
            className="arrow-icon"
            onClick={handleSlideNext}
          />
        )}
      </PostListControls>
    </PostListWrapper>
  )
}

EventList.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  eventItems: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      text: PropTypes.object,
      buttonText: PropTypes.string,
      url: PropTypes.string,
      when: PropTypes.string,
      category: PropTypes.string,
    }),
  ).isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
}

EventList.defaultProps = {
  sectionId: "",
}

export default EventList
