import * as React from "react"
import PropTypes from "prop-types"
import HeroSectionWrapper from "components/Hero/HeroSectionWrapper"
import HeroWrapper from "components/Hero/HeroWrapper"
import HeroContainer from "components/Hero/HeroContainer"
import Breadcrumbs from "components/Breadcrumbs"
import HeroBreadcrumbsItem from "components/Hero/HeroBreadcrumbsItem"
import HeroBreadcrumbsLink from "components/Hero/HeroBreadcrumbsLink"
import HeroCaseText from "components/HeroCase/HeroCaseText"
import HeroTextBeforeTitle from "components/Hero/HeroTextBeforeTitle"
import HeroCaseTitle from "components/HeroCase/HeroCaseTitle"

import HeroImageContainer from "components/Hero/HeroImageContainer"
import HeroBackgroundImage from "components/Hero/HeroBackgroundImage"

import HeroCaseDemo from "components/HeroCase/HeroCaseDemo"
import HeroCaseDemoWrapper from "components/HeroCase/HeroCaseDemoWrapper"
import HeroCaseDescription from "components/HeroCase/HeroCaseDescription"
import HeroCaseItem from "components/HeroCase/HeroCaseItem"
import HeroCaseItemLink from "components/HeroCase/HeroCaseItemLink"
import HeroCaseItemText from "components/HeroCase/HeroCaseItemText"
import HeroCaseItemTitle from "components/HeroCase/HeroCaseItemTitle"
import HeroCaseDemoBigMobile from "components/HeroCase/HeroCaseDemoBigMobile"

import Arrow from "components/Icons/Arrow"
import HeroCaseDemoDesktop from "components/HeroCase/HeroCaseDemoDesktop"
import HeroCaseDemoMobile from "components/HeroCase/HeroCaseDemoMobile"
import HeroCaseInfo from "components/HeroCase/HeroCaseInfo"
import HeroCaseLinkClutch from "components/HeroCase/HeroCaseLinkClutch"
import HeroCaseItemContent from "components/HeroCase/HeroCaseItemContent"
import Image from "components/Image"
import htmlFormatting from "utils/htmlFormatting"
import MicromarkingWrapper from "components/MicromarkingWrapper"
import Meta from "components/Meta"

const HeroCase = ({
  breadcrumbs,
  textBeforeTitle,
  textBeforeTitleTag,
  addQuotes,
  title,
  titleTag,
  heroText,
  backgroundImage,
  imageDesktop,
  imageMobile,
  type,
  units,
  clutchRating,
  clutchText,
  clutchLink,
}) => (
  <HeroSectionWrapper>
    <HeroWrapper backgroundImage={backgroundImage} addQuotes={addQuotes}>
      <HeroContainer>
        {breadcrumbs && (
          <MicromarkingWrapper itemscope="" itemtype="http://schema.org/BreadcrumbList">
            <Breadcrumbs
              itemprop="itemListElement"
              itemscope=""
              itemtype="http://schema.org/ListItem"
            >
              {breadcrumbs.map(
                (el, idx) =>
                  (breadcrumbs.length - 1 !== idx && (
                    <HeroBreadcrumbsItem>
                      <HeroBreadcrumbsLink
                        itemprop="item"
                        isActive={breadcrumbs.length - 1 === idx}
                        to={el.link}
                        key={el.id}
                      >
                        <Meta itemprop="name" content={el.title} />
                        <Meta itemprop="position" content={idx + 1} />
                        {el.title}
                      </HeroBreadcrumbsLink>
                      <Arrow
                        key={el.link}
                        direction="left"
                        color="#BBBBBB"
                        width={14}
                        height={12}
                      />
                    </HeroBreadcrumbsItem>
                  )) || (
                    <HeroBreadcrumbsLink key={el.id} isActive={breadcrumbs.length - 1 === idx}>
                      {el.title}
                    </HeroBreadcrumbsLink>
                  ),
              )}
            </Breadcrumbs>
          </MicromarkingWrapper>
        )}
        {textBeforeTitle && (
          <HeroTextBeforeTitle as={textBeforeTitleTag}>
            {textBeforeTitle}1212121
          </HeroTextBeforeTitle>
        )}
        <MicromarkingWrapper itemscope="" itemtype="https://schema.org/CreativeWork">
          <Meta itemprop="name" content={title} />

          <HeroCaseTitle backgroundImage={backgroundImage} addQuotes={addQuotes} as={titleTag}>
            {(addQuotes ? "“‎‎" : "") + title + (addQuotes ? "”‎" : "")}
          </HeroCaseTitle>
          {heroText && (
            <HeroCaseText
              itemprop="description"
              backgroundImage={backgroundImage}
              addQuotes={addQuotes}
              dangerouslySetInnerHTML={{
                __html: htmlFormatting(heroText?.childMarkdownRemark?.html),
              }}
            />
          )}
          <HeroImageContainer backgroundImage={backgroundImage} addQuotes={addQuotes}>
            {backgroundImage !== "None" && (
              <HeroBackgroundImage backgroundImage={backgroundImage} />
            )}
          </HeroImageContainer>

          <HeroCaseDemoWrapper>
            <HeroCaseDemo>
              {imageDesktop && (type === "Desktop" || type === "Desktop-mobile") && (
                <HeroCaseDemoDesktop>
                  <Image itemprop="image" {...imageDesktop} />
                </HeroCaseDemoDesktop>
              )}
              {imageMobile && type === "Desktop-mobile" && (
                <HeroCaseDemoMobile>
                  <Image {...imageMobile} />
                </HeroCaseDemoMobile>
              )}
              {imageMobile && imageDesktop && type === "Mobile-mobile" && (
                <>
                  <HeroCaseDemoBigMobile>
                    <Image {...imageDesktop} objectFit="cover" />
                  </HeroCaseDemoBigMobile>
                  <HeroCaseDemoBigMobile>
                    <Image {...imageMobile} objectFit="cover" />
                  </HeroCaseDemoBigMobile>
                </>
              )}
              {imageMobile && type === "Mobile" && (
                <HeroCaseDemoBigMobile>
                  <Image {...imageMobile} objectFit="cover" />
                </HeroCaseDemoBigMobile>
              )}
            </HeroCaseDemo>
          </HeroCaseDemoWrapper>
          <HeroCaseDescription>
            {units?.map(({ id, title: itemTitle, values }) => (
              <HeroCaseItem key={id}>
                <HeroCaseItemTitle>{itemTitle}</HeroCaseItemTitle>

                {values && (
                  <HeroCaseItemContent>
                    {values.map(({ id: valueId, title: valueTitle, link }) =>
                      link === null ? (
                        <>
                          <HeroCaseItemText key={valueId}>{valueTitle}</HeroCaseItemText>
                          {!isNaN(parseInt(valueTitle, 16)) && (
                            <Meta itemprop="dateCreated" content={valueTitle} />
                          )}
                        </>
                      ) : (
                        <HeroCaseItemLink key={valueId} to={link}>
                          {valueTitle}
                          <Meta itemprop="keywords" content={valueTitle} />
                        </HeroCaseItemLink>
                      ),
                    )}
                  </HeroCaseItemContent>
                )}
              </HeroCaseItem>
            ))}

            <HeroCaseItem>
              {clutchLink && clutchRating && clutchText && (
                <>
                  <HeroCaseItemTitle> Clutch </HeroCaseItemTitle>
                  <HeroCaseLinkClutch to={clutchLink}>
                    <HeroCaseInfo text={clutchText || ""} rating={clutchRating} />
                  </HeroCaseLinkClutch>
                </>
              )}
            </HeroCaseItem>
          </HeroCaseDescription>
        </MicromarkingWrapper>
      </HeroContainer>
    </HeroWrapper>
  </HeroSectionWrapper>
)

HeroCase.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  textBeforeTitle: PropTypes.string.isRequired,
  textBeforeTitleTag: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  addQuotes: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  heroText: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  imageDesktop: PropTypes.string.isRequired,
  imageMobile: PropTypes.string.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  clutchRating: PropTypes.string.isRequired,
  clutchText: PropTypes.string.isRequired,
  clutchLink: PropTypes.string.isRequired,
}

export default HeroCase
