import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Link from "components/Link"
import htmlFormatting from "utils/htmlFormatting"
import PostListItemDate from "./PostListItemDate"
import PostListItemLink from "./PostListItemLink"
import PostListItemHeader from "./PostListItemHeader"
import PostListItemSubtitle from "./PostListItemSubtitle"
import PostListItemTag from "./PostListItemTag"
import PostListItemText from "./PostListItemText"
import PostListItemTitle from "./PostListItemTitle"

const Component = styled.div`
  width: 100%;
`

const PostListItem = ({
  title: postTitle,
  shortContent,
  buttonText: buttonTitle,
  link: postLink,
  date,
  category,
  variant,
}) => {
  const { link: categoryLink, title: categoryTitle } =
    category.length > 1 ? category.filter(({ link }) => link !== "/blog")[0] : category[0]

  return (
    <Component>
      <PostListItemHeader>
        <PostListItemSubtitle>
          <PostListItemTag as={Link} to={categoryLink}>
            {categoryTitle}
          </PostListItemTag>
          <PostListItemDate
            date={date}
            options={{ year: "numeric", month: "long", day: "numeric" }}
          />
        </PostListItemSubtitle>
        {variant !== "WithoutLink" && postLink && (
          <PostListItemLink to={postLink} variant="PC">
            {buttonTitle}
          </PostListItemLink>
        )}
      </PostListItemHeader>
      <PostListItemTitle>{postTitle}</PostListItemTitle>
      <div>
        {shortContent && shortContent.childMarkdownRemark && (
          <PostListItemText
            dangerouslySetInnerHTML={{
              __html: htmlFormatting(shortContent?.childMarkdownRemark?.html),
            }}
          />
        )}
        {variant !== "WithoutLink" && postLink && (
          <PostListItemLink variant="Mobile" to={postLink}>
            {buttonTitle}
          </PostListItemLink>
        )}
      </div>
    </Component>
  )
}

PostListItem.propTypes = {
  title: PropTypes.string.isRequired,
  shortContent: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  variant: PropTypes.string,
}

PostListItem.defaultProps = {
  variant: "Standart",
}

export default PostListItem
