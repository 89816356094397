import styled from "@emotion/styled"

const CertificationPhotoWrap = styled.div`
  cursor: pointer;

  > div {
    height: 100%;
  }
`

export default CertificationPhotoWrap
