import styled from '@emotion/styled'
import Title from 'components/Title'

const RelatedArticlesTitle = styled(Title)`
  margin-top: 0;
  margin-bottom: 20px;
  max-width: max-content;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 40px;
  }
`

export default RelatedArticlesTitle