import styled from "@emotion/styled"

const HeaderWrapper = styled.header`
  position: sticky;
  transition: 0.3s;
  top: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.gray["50"]};

  ${({ theme }) => theme.breakpoints.between("sm", "xl")} {
    padding-top: ${({ isScrollingUp }) => `${isScrollingUp ? 8 : 4}px`};
    padding-bottom: ${({ isScrollingUp }) => `${isScrollingUp ? 8 : 4}px`};
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    box-shadow: 0 12px 30px rgba(118, 118, 129, 0.15);
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.0625rem 0.375rem 0px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding-top: ${({ isScrollingUp }) => `${isScrollingUp ? 20 : 10}px`};
    padding-bottom: ${({ isScrollingUp }) => `${isScrollingUp ? 20 : 10}px`};
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding-top: ${({ isScrollingUp }) => `${isScrollingUp ? 20 : 10}px`};
    padding-bottom: ${({ isScrollingUp }) => `${isScrollingUp ? 20 : 10}px`};
  }
`

export default HeaderWrapper
