import styled from "@emotion/styled"
import Link from "components/Link"

const HeroCaseItemLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  border-bottom: 1px solid transparent;
  line-height: 20px;
  margin-bottom: 8px;
  transition: all 0.2s;
  max-width: max-content;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export default HeroCaseItemLink
