import styled from "@emotion/styled"
import Title from "components/Title"

const PostListTitle = styled(Title)`
  margin-block-start: 0;
  margin-block-end: 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: 41px;
    max-width: 280px;
    margin-bottom: 33px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    text-align: center;
    line-height: 41px;
    margin-bottom: 33px;
    font-size: 36px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    text-align: center;
    line-height: 41px;
    margin-bottom: 100px;
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    text-align: center;
    font-size: 60px;
    line-height: 77px;
    margin-bottom: 135px;
  }
`

export default PostListTitle
