import styled from "@emotion/styled"

const AuthorRole = styled.p`
  line-height: 1.3;
  font-weight: 700;
  font-family: "Gilroy";
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;

  &:before {
    content: "";
    display: block;
    height: 2px;
    width: 130px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 18px;
    padding-top: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 24px;
    padding-top: 10px;
  }
`
export default AuthorRole
