import styled from '@emotion/styled'

const GateForContentFormFieldName = styled.span`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyGilroy};
  font-weight: 600;
  color: #7e7e7e;

  & > span {
    color: #38b048;
  }
`

export default GateForContentFormFieldName