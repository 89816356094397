import styled from "@emotion/styled"
import Image from "components/Image"

const AuthorPhoto = styled(Image)`
  border-radius: 50%;
  display: inline-block;
  position: relative;
  z-index: 4;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 280px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2xl")} {
    width: 300px;
    height: 300px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    width: 324px;
    height: 324px;
  }
`
export default AuthorPhoto
