import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  background: ${(colorVariant === "Dark" && theme.palette.gray[900]) ||
  (colorVariant === "Light" && theme.palette.gray[50]) ||
  theme.palette.common.white} !important;
`

const TextSixWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 1920px;
  }

  position: relative;
  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: 0;

    ${({ theme }) => theme.breakpoints.down("xl")} {
      height: 50%;
      width: 100%;
      bottom: 0;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      height: 100%;
      top: 0;
    }

    ${dynamicStyle}
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    background: ${({ theme }) => theme.palette.gray[900]} !important;

    ${({ theme }) => theme.breakpoints.down("xl")} {
      height: 50%;
      width: 100%;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      height: 100%;
      width: 50%;
    }
  }
`

export default TextSixWrapper
