import styled from "@emotion/styled"

const HeroFormFormLead = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilyGothamPro};
  margin-top: 0;
  line-height: 27px;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 22px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 34px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 46px;
  }
`

export default HeroFormFormLead
