import styled from "@emotion/styled"

const PostEventsEventsContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 91px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 61px;
  }
`
export default PostEventsEventsContainer
