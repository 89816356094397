import styled from "@emotion/styled"

const ListOneContentText = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: 18px;
  letter-spacing: 0.06em;

  ${({ theme }) => theme.breakpoints.down("2xl")} {
    line-height: 29px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 32px;
  }
`

export default ListOneContentText
