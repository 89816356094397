import styled from "@emotion/styled"
import Image from "components/Image"

const TextThreeImage = styled(Image)`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 344px;
    margin-bottom: 28px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    max-width: 379px;
    flex: 0 0 379px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 414px;
    flex: 0 0 414px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    max-width: 517px;
    flex: 0 0 517px;
  }
`

export default TextThreeImage
