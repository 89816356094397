import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Arrow from "components/Icons/Arrow"

const dynamicStyle = ({ hideArrow, theme }) => css`
  ${theme.breakpoints.up("xl")} {
    ${hideArrow ? "display: none" : ""}
  }
`

const PostListBtnArrow = styled(Arrow)`
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-left: 0;
    margin-right: 0;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    margin-left: 15px;
    margin-right: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-left: 31px;
    margin-right: 31px;
  }

  ${dynamicStyle}
`

export default PostListBtnArrow
