import styled from "@emotion/styled"
import { css } from "@emotion/react"

const dynamicStyle = ({ theme, colorVariant }) => css`
  ${(colorVariant === "Dark" &&
    `
    ${theme.breakpoints.down("lg")} {
      background-color: #222222;
      &:nth-child(2n) {
        background-color: #413f3f;
      }
    }
   ${theme.breakpoints.up("lg")} {
      &:nth-child(1) {
        background-color: #222222;
      }
      &:nth-child(2) {
        background-color: #413f3f;
      }
      &:nth-child(4n + 1),
      &:nth-child(4n + 4) {
        background-color: #222222;
      }
      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        background-color: #413f3f;
      }
    }
  `) ||
  (colorVariant === "Light" && `background-color: ${theme.palette.common.white}`) ||
  `background-color: ${theme.palette.gray[50]}`}
`

const ListNineItem = styled.div`
  border-radius: 20px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    min-height: 330px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "3xl")} {
    min-height: 330px;
    width: calc(50% - 14px);
  }

  ${({ theme }) => theme.breakpoints.up("3xl")} {
    min-height: 421px;
    width: calc(50% - 17px);
  }
  ${dynamicStyle}
`

export default ListNineItem
