import styled from "@emotion/styled"

const BlogListPaginationStack = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.gray[50]};
  border-radius: 15px;
`

export default BlogListPaginationStack
