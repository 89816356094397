import { css } from "@emotion/react"
import styled from "@emotion/styled"

const dinamicStyles = ({ theme, lowerText }) => css`
  ${lowerText &&
  `
    ${theme.breakpoints.down("xl")} {
      margin-bottom: 79px; 
    }

    ${theme.breakpoints.between("xl", "2xl")} {
      margin-bottom: 94px;  
    }

    ${theme.breakpoints.up("2xl")} {
      margin-bottom: 137px;  
    }
  `}
`

const ListTwelveStack = styled.div`
  ${dinamicStyles}

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    display: flex;
    gap: 36px;
  }
  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
  }
  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    display: grid;
    row-gap: 47px;
    grid-template-columns: repeat(auto-fit, minmax(312px, 257px));
    grid-template-rows: repeat(2, auto);
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 39px;
    display: grid;
    row-gap: 58px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-template-rows: repeat(2, auto);
    width: 100%;
  }
`

export default ListTwelveStack
