import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Container from "components/Container"
import ListSevenLowerTextWrap from "components/ListSeven/ListSevenLowerTextWrap"
import ListSevenLowerText from "components/ListSeven/ListSevenLowerText"

import ListFifteenWrapper from "components/ListFifteen/ListFifteenWrapper"
import ListFifteenTitle from "components/ListFifteen/ListFifteenTitle"
import ListFifteenStack from "components/ListFifteen/ListFifteenStack"
import ListFifteenItemTitle from "components/ListFifteen/ListFifteenItemTitle"
import ListFifteenItemText from "components/ListFifteen/ListFifteenItemText"
import ListFifteenItemHeader from "components/ListFifteen/ListFifteenItemHeader"
import ListFifteenItemEmail from "components/ListFifteen/ListFifteenItemEmail"
import ListFifteenItem from "components/ListFifteen/ListFifteenItem"

import htmlFormatting from "utils/htmlFormatting"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <ListSevenLowerText>{children}</ListSevenLowerText>,
  },
}

const ListFifteen = ({ title, theme: colorVariant, titleTag, listItems, lowerText, sectionId }) => (
  <ListFifteenWrapper id={sectionId} colorVariant={colorVariant}>
    <Container>
      <ListFifteenTitle as={titleTag}>{title}</ListFifteenTitle>
      <ListFifteenStack lowerText={lowerText}>
        {listItems.map(({ id, title: itemTitle, text, email }) => (
          <ListFifteenItem key={id}>
            <div>
              <ListFifteenItemHeader>
                {itemTitle && <ListFifteenItemTitle>{itemTitle}</ListFifteenItemTitle>}
                {email && <ListFifteenItemEmail>{email}</ListFifteenItemEmail>}
              </ListFifteenItemHeader>
              {text && (
                <ListFifteenItemText
                  dangerouslySetInnerHTML={{
                    __html: htmlFormatting(text?.childMarkdownRemark?.html),
                  }}
                />
              )}
            </div>
          </ListFifteenItem>
        ))}
      </ListFifteenStack>
    </Container>

    {lowerText && (
      <ListSevenLowerTextWrap>{renderRichText(lowerText, options)}</ListSevenLowerTextWrap>
    )}
  </ListFifteenWrapper>
)

ListFifteen.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  lowerText: PropTypes.object,
  sectionId: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      text: PropTypes.object.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

ListFifteen.defaultProps = {
  lowerText: null,
  sectionId: "",
}

export default ListFifteen
