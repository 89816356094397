import { css } from "@emotion/react"
import styled from "@emotion/styled"

const sizesPC = {
  small: "150px",
  normal: "150px",
  large: "180px",
}

const sizesMediumPC = {
  small: "141px",
  normal: "141px",
  large: "160px",
}

const sizesSmallPC = {
  small: "120px",
  normal: "120px",
  large: "134px",
}

const sizesTablet = {
  small: "120px",
  normal: "120px",
  large: "100px",
}

const sizesMobile = {
  small: "105px",
  normal: "150px",
  large: "150px",
}

const dynamicStyle = ({ url, theme, size }) => css`
  ${url !== undefined &&
  url !== null &&
  ` 
  color: #38b148;

  &::after {
    content: "";
    display: flex;
    background-color: #38b148;
    margin-left: 4px;
    border-radius: 50%;

    ${theme.breakpoints.down("lg")} {
      width: 8px;
      height: 8px;
    }
    ${theme.breakpoints.between("lg", "xl")} {
      width: 8px;
      height: 8px;
    }
    ${theme.breakpoints.between("xl", "2xl")} {
      width: 8px;
      height: 8px;
    }
    ${theme.breakpoints.up("2xl")} {
      width: 8px;
      height: 8px;
    }
  }

  `}

  ${theme.breakpoints.down("lg")} {
    max-width: ${sizesMobile[size]};
  }

  ${theme.breakpoints.between("lg", "xl")} {
    max-width: ${sizesTablet[size]};
  }
  ${theme.breakpoints.between("xl", "2xl")} {
    max-width: ${sizesSmallPC[size]};
  }
  ${theme.breakpoints.between("2xl", "3xl")} {
    max-width: ${sizesMediumPC[size]};
  }
  ${theme.breakpoints.up("3xl")} {
    max-width: ${sizesPC[size]};
  }
`

const TechItemTitle = styled.span`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    line-height: 19px;
    max-width: 62px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    line-height: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    line-height: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    line-height: 28px;
  }

  ${dynamicStyle}
`
export default TechItemTitle
