import styled from "@emotion/styled"

const ListSixStack = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    flex-direction: column;
    gap: 50px;
    padding-inline-start: 0;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    flex-wrap: wrap;
    row-gap: 48px;
    column-gap: 94px;
    padding-inline-start: 0;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    flex-wrap: wrap;
    column-gap: 239px;
    padding-inline-start: 8px;
    row-gap: 67px;
  }
`

export default ListSixStack
