import styled from "@emotion/styled"

const ListOneText = styled.div`
  font-weight: 400;
  letter-spacing: 0.06em;
  margin-block-end: 0;
  margin-block-start: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 20px;
    line-height: 26px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    max-width: 310px;
    font-size: 20px;
    line-height: 26px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 33px;
    max-width: 400px;
  }
`

export default ListOneText
