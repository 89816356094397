import styled from "@emotion/styled"
import TextsTitle from "components/Texts/TextsTitle"

const TextCalendlyTitle = styled(TextsTitle)`
  max-width: 996px;
  margin-left: auto;
  margin-right: auto;
`

export default TextCalendlyTitle
