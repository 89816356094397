"use client"

import { createElement, useEffect, useRef } from "react"
import PropTypes from 'prop-types'

const DangerouslySetHtmlContent = ({ html, dangerouslySetInnerHTML, allowRerender, ...rest }) => {
  const divRef = useRef(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can't be null")
    if (!isFirstRender.current) return
    isFirstRender.current = Boolean(allowRerender)

    const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ""
    divRef.current.appendChild(slotHtml)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html, divRef])

  return createElement("div", { ...rest, ref: divRef })
}

DangerouslySetHtmlContent.propTypes = {
  html: PropTypes.any.isRequired,
  dangerouslySetInnerHTML: PropTypes.any.isRequired,
  allowRerender: PropTypes.any.isRequired,
}


export default DangerouslySetHtmlContent