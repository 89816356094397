import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Link from "components/Link"

const dynamicStyle = ({ theme, variant }) => css`
  ${(variant === "PC" &&
    `

  ${theme.breakpoints.down("lg")} {
    display: none;
  }

  ${theme.breakpoints.up("lg")} {
    display: initial;
  }
`) ||
  `
${theme.breakpoints.up("lg")} {
    display: none;
  }
`}
`

const EventListItemLink = styled(Link)`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
  letter-spacing: 0.06em;
  border-bottom: 1px solid transparent;
  transition: all 0.2s;
  margin-bottom: -1px;
  max-width: max-content;
  width: 100%;
  height: min-content;

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${dynamicStyle}
`

export default EventListItemLink
