import styled from "@emotion/styled"
import { SpinnerDotted } from "spinners-react"

const ArticleAsideSpinner = styled(SpinnerDotted)`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    width: 70px !important;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 100px !important;
  }
`

export default ArticleAsideSpinner
