import styled from "@emotion/styled"
import TextsTitle from "components/Texts/TextsTitle"

const TextSixTitle = styled(TextsTitle)`
  text-align: center;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 26px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 35px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 44px;
  }
`

export default TextSixTitle
