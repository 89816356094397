import styled from "@emotion/styled"

const ListSeventeenHeader = styled.div`

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.between("md", "xl")} {
    margin-left: auto;
    margin-right: auto;
    gap: 70px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    column-gap: 70px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    column-gap: 100px;
  }

  ${({ theme, isBlog }) =>
    isBlog
      ? `
  ${theme.breakpoints.down("lg")} {
    margin-bottom: 20px;
  }

  ${theme.breakpoints.up("lg")} {
    margin-bottom: 40px;
  }
      `
      : `
    ${theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }

  ${theme.breakpoints.between("md", "xl")} {
    margin-bottom: 50px;
  }

  ${theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 80px;
  }

  ${theme.breakpoints.up("2xl")} {
    margin-bottom: 100px;
  }
  `}
`

export default ListSeventeenHeader
