import styled from "@emotion/styled"

const HeroFormFormText = styled.div`
  line-height: 32px;
  font-size: 18px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-bottom: 24px;
  }
`

export default HeroFormFormText
