import styled from "@emotion/styled"

const ListSixItemTitle = styled.span`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 20px;
    line-height: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 24px;
    line-height: 50px;
  }
`

export default ListSixItemTitle
