import styled from "@emotion/styled"

const CTAFormClientMessage = styled.span`
  display: block;
  font-weight: 400;
  font-family: ${({ theme }) => theme.typography.fontFamilyMontserrat};

  ${({ theme, isBlog }) =>
    isBlog
      ? `
        font-size: 16px;
  line-height: 24px;

  ${theme.breakpoints.down("xl")} {
    margin-bottom: 20px;
  }
  ${theme.breakpoints.up("xl")} {
    margin-bottom: 30px;
  }
      `
      : `
    font-size: 18px;

  ${theme.breakpoints.between("xl", "2xl")} {
    line-height: 160%;
    margin-bottom: 33px;
  }

  ${theme.breakpoints.up("2xl")} {
    line-height: 170%;
    margin-bottom: 43px;
  }
  `};
`

export default CTAFormClientMessage
