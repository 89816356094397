import styled from "@emotion/styled"
import HeroText from "../Hero/HeroText"

const HeroCaseText = styled(HeroText)`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 33px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-bottom: 80px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-bottom: 122px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 152px;
  }
`

export default HeroCaseText
