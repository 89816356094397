import styled from "@emotion/styled"

const ListFourItemText = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.06em;
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-bottom: 20px;
  }
`

export default ListFourItemText
